import { Business, User } from '@bookinbio/interface';
import { getDoc } from 'firebase/firestore';

export const getProfessionalsByBusiness = async (
    business?: Business,
): Promise<User[]> => {
    if (!business) {
        throw new Error('Missing business');
    }

    const promises = [];
    for (const memberRer of business.memberRefs) {
        const userSnap = await getDoc(memberRer);
        if (!userSnap.exists()) {
            continue;
        }
        promises.push({ ...userSnap.data(), id: userSnap.id });
    }

    return promises;
};
