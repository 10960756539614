import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Button,
    DeleteModal,
    FixedHeader,
    Form,
    IconButton,
    IconType,
    PlacesAutocomplete,
    TextareaInput,
    TextInput,
} from '@bookinbio/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

import { useBusiness } from '../../../context/BusinessContext';
import { getLocationByBusiness } from '../../../utils/firebase/api/get-location-business';
import {
    deleteLocation,
    updateLocation,
} from '../../../utils/firebase/callable/location';
import { FormSectionHeader } from '../../FormSectionHeader';

import {
    LocationForm,
    LocationFormProps,
    LocationSchema,
} from './CreateLocation';

export const EditLocation = () => {
    const { id: locationId } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const { business, locationsRefetch } = useBusiness();
    const navigate = useNavigate();

    const methods = useForm<LocationForm>({
        mode: 'all',
        reValidateMode: 'onSubmit',
        resolver: zodResolver(LocationSchema),
    });

    // BE data
    const { data: location } = useQuery({
        queryKey: ['business-location', business?.id, locationId],
        queryFn: () => getLocationByBusiness(business, locationId),
        enabled: !!business && !!locationId,
    });

    const { mutateAsync, isLoading } = useMutation({
        mutationFn: updateLocation,
    });

    const { mutateAsync: deleteLocat, isLoading: isDeleting } = useMutation({
        mutationFn: deleteLocation,
    });

    // Effects
    useEffect(() => {
        if (location) {
            methods.reset({
                name: location.name,
                address: location.address,
                description: location.description,
            });
        }
    }, [methods, location]);

    // Handlers
    const handleClose = () => {
        navigate('/management/locations');
    };

    const handleOpenDeleteModal = () => {
        navigate('delete');
    };

    const handleCloseDeleteModal = () => {
        navigate(-1);
    };

    const handleDeleteLocation = async () => {
        if (!locationId || !business) {
            return;
        }

        try {
            await deleteLocat({ locationId, businessId: business.id });
            await locationsRefetch();
            toast.success(t('location.delete.success'));
            handleClose();
        } catch (error) {
            console.error(error);
            toast.error(t('location.delete.error'));
        }
    };

    const handleSubmit = async (data: LocationForm) => {
        if (!business || !locationId) {
            return;
        }

        try {
            await mutateAsync({
                ...data,
                locationId,
                businessId: business.id,
            });
            await locationsRefetch();
            toast.success(t('location.update.success'));
            handleClose();
        } catch (error) {
            console.error(error);
            toast.error(t('location.update.error'));
        }
    };

    return (
        <Form
            methods={methods}
            className="min-h-full w-full"
            onSubmit={handleSubmit}
        >
            <FixedHeader>
                {({ isMoved }) => (
                    <div
                        className={classNames(
                            'fixed top-0 left-0 z-50 flex w-full flex-row-reverse items-center justify-end px-8 pt-6 pb-5 md:h-[88px] md:flex-row md:justify-between',
                            isMoved
                                ? 'shadow-xl backdrop-blur-xl'
                                : 'bg-transparent',
                        )}
                    >
                        <div className="text-right md:flex-1 md:text-left">
                            <IconButton
                                name={IconType.Cross}
                                onClick={handleClose}
                            />
                        </div>
                        <h2
                            className={classNames(
                                'flex-1 text-center text-2xl font-bold tracking-wider',
                                isMoved ? 'visible' : 'invisible',
                            )}
                        >
                            {t('location.edit')}
                        </h2>
                        <div className="hidden flex-1 items-center justify-end gap-x-6 text-right md:flex">
                            <Button
                                type="button"
                                color="error"
                                className="rounded-lg !px-12 !py-3 font-bold tracking-wider"
                                onClick={handleOpenDeleteModal}
                            >
                                {t('delete')}
                            </Button>
                            <Button
                                type="submit"
                                color="black"
                                className="rounded-lg !px-14 !py-3 font-bold tracking-wider"
                                disabled={isLoading}
                            >
                                {t('save')}
                            </Button>
                        </div>
                    </div>
                )}
            </FixedHeader>
            <div className="mx-auto max-w-2xl px-5 pt-10 pb-20 md:pt-[88px] md:pb-[60px]">
                <h1 className="text-left text-3xl font-bold tracking-wider md:text-center">
                    {t('location.edit')}
                </h1>
                <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('location.basic.info')}
                        subTitle={t('location.basic.info.subtitle')}
                    />
                    <div className="mt-6 grid grid-cols-1 gap-y-4">
                        <TextInput<LocationFormProps>
                            label={t('location.name')}
                            placeholder={t('location.name.placeholder')}
                            name="name"
                        />
                        <PlacesAutocomplete<LocationFormProps>
                            label={t('location.address')}
                            name="address"
                        />
                        <TextareaInput<LocationFormProps>
                            label={t('description.optional')}
                            placeholder={t('location.description.placeholder')}
                            name="description"
                        />
                    </div>
                </div>
            </div>
            <div
                className={classNames(
                    'fixed bottom-0 left-0 z-50 grid h-20 w-full grid-cols-2 items-center justify-between gap-x-2 px-5 py-5 shadow-xl backdrop-blur-xl md:hidden',
                )}
            >
                <Button
                    color="error"
                    className="flex-shrink-0 rounded-lg !py-3 font-bold tracking-wide"
                    onClick={handleOpenDeleteModal}
                >
                    {t('delete')}
                </Button>
                <Button
                    type="submit"
                    color="black"
                    className="rounded-lg !py-3 font-bold tracking-wide"
                    disabled={isLoading}
                >
                    {t('save')}
                </Button>
            </div>
            <Routes>
                <Route
                    path="delete"
                    element={
                        <DeleteModal
                            title={t('location.delete')}
                            onDelete={handleDeleteLocation}
                            close={handleCloseDeleteModal}
                            isLoading={isDeleting}
                        />
                    }
                />
            </Routes>
        </Form>
    );
};
