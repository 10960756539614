import { Business, Location } from '@bookinbio/interface';
import { collection, doc, getDoc } from 'firebase/firestore';

import { businessesColl } from '../collections';

export const getLocationByBusiness = async (
    business?: Business,
    locationId?: string,
): Promise<Location> => {
    if (!business || !locationId) {
        throw new Error('Missing business id');
    }

    const locationSnap = await getDoc(
        doc(
            collection(doc(businessesColl, business.id), 'locations'),
            locationId,
        ),
    );

    return { ...locationSnap.data(), id: locationSnap.id } as Location;
};
