import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';

interface ModalProps {
    isOpen: boolean;
    children: ReactNode;
    className?: string;
    close: () => void;
}

export const Modal = ({ isOpen, className, children, close }: ModalProps) => {
    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog onClose={close} className="relative z-[1050]">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="inset-0 hidden bg-black/30 md:fixed md:block" />
                </Transition.Child>
                <div className="fixed inset-0 h-screen overflow-y-auto md:h-auto md:px-2">
                    <div className="flex h-full items-center justify-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className={classNames(
                                    'h-full w-full rounded-xl bg-white px-6 pt-16 md:h-auto md:max-w-lg md:py-6',
                                    className,
                                )}
                            >
                                {children}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
