import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import {
    Button,
    FixedHeader,
    Form,
    IconButton,
    IconType,
    SelectOption,
    Switch,
} from '@bookinbio/ui';
import { transformToDate } from '@bookinbio/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { addDays } from 'date-fns';
import { z } from 'zod';

import { getProfessionalByBusiness } from '../../../utils/firebase/api/get-professional-business';
import { getWorkingHoursByStartDate } from '../../../utils/firebase/api/get-working-hours';
import { updateUsersWorkingHours } from '../../../utils/firebase/callable/profile';
import {
    DaySettings,
    DaysSettingsSchema,
} from '../../settings/working-hours/DaySettings';
import { WeekSelect } from '../../settings/working-hours/WorkingHours';

// Constants
export const DAY_ARRAY = [1, 2, 3, 4, 5, 6, 0];

// Verifications schemas
const WorkingHoursSchema = z.object({
    initialized: z.boolean(),
    daySettings: DaysSettingsSchema,
});

// Form typings
type WorkingHoursForm = z.infer<typeof WorkingHoursSchema>;

const DEFAULT_WH_OPTION = { label: 'working.hours.default', value: 'default' };
const DEFAULT_WH = {
    0: {
        timeSlots: [],
        isOpen: false,
    },
    1: {
        timeSlots: [{ start: '09:00', end: '17:00' }],
        isOpen: true,
    },
    2: {
        timeSlots: [{ start: '09:00', end: '17:00' }],
        isOpen: true,
    },
    3: {
        timeSlots: [{ start: '09:00', end: '17:00' }],
        isOpen: true,
    },
    4: {
        timeSlots: [{ start: '09:00', end: '17:00' }],
        isOpen: true,
    },
    5: {
        timeSlots: [{ start: '09:00', end: '17:00' }],
        isOpen: true,
    },
    6: {
        timeSlots: [],
        isOpen: false,
    },
};

export const EditTeamMemberWorkingHours = () => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [whOption, setWhOption] = useState<SelectOption>(DEFAULT_WH_OPTION);

    const methods = useForm<WorkingHoursForm>({
        mode: 'all',
        reValidateMode: 'onChange',
        resolver: zodResolver(WorkingHoursSchema),
    });
    const initialized = methods.watch('initialized');

    const {
        data: professional,
        isLoading: isLoadingTm,
        refetch,
    } = useQuery({
        queryKey: ['business-professional', id],
        queryFn: () => getProfessionalByBusiness(id),
        enabled: !!id,
    });

    const { data: workingHours, isLoading: isLoadingWh } = useQuery({
        queryKey: [
            'professional-working-hours',
            whOption.value,
            professional?.id,
        ],
        queryFn: () =>
            getWorkingHoursByStartDate(
                whOption.value as string,
                professional?.id,
            ),
        enabled:
            whOption.value !== DEFAULT_WH_OPTION.value &&
            !isLoadingTm &&
            !!professional,
    });

    const { mutateAsync: updateWorkingHours, isLoading: isUpdating } =
        useMutation({
            mutationFn: updateUsersWorkingHours,
        });

    useEffect(() => {
        if (whOption.value === 'default' && professional) {
            methods.reset({
                initialized: true,
                daySettings: {
                    ...(professional.calendarSettings?.daySettings ??
                        DEFAULT_WH),
                },
            });
            return;
        }

        if (workingHours) {
            methods.reset({
                initialized: true,
                daySettings: { ...workingHours },
            });
        } else {
            methods.reset({
                initialized: false,
                daySettings: DEFAULT_WH,
            });
        }
    }, [methods, workingHours, whOption, professional]);

    const handleSubmit = async (data: WorkingHoursForm) => {
        if (!professional) {
            return;
        }

        const whType = whOption.value === 'default' ? 'default' : 'date-range';
        const startDate =
            whOption.value !== 'default' &&
            whOption.value !== null &&
            typeof whOption.value === 'string'
                ? transformToDate(whOption.value).toISOString()
                : null;

        try {
            await updateWorkingHours({
                userId: professional.id,
                daySettings: data.daySettings,
                whType,
                startDate,
            });
            toast.success(t('working.hours.update.success'));
            refetch();
        } catch (error) {
            toast.error(t('working.hours.update.error'));
        }
    };

    const handleWhSelect = (option: SingleValue<SelectOption>) => {
        if (!option) {
            return;
        }
        setWhOption(option);
    };

    const handleClose = () => {
        navigate('/management/team');
    };

    return (
        <Form<WorkingHoursForm>
            methods={methods}
            onSubmit={handleSubmit}
            className="min-h-full w-full"
        >
            <FixedHeader>
                {({ isMoved }) => (
                    <div
                        className={classNames(
                            'fixed top-0 left-0 z-50 flex w-full flex-row-reverse items-center justify-end px-8 pt-6 pb-5 md:h-[88px] md:flex-row md:justify-between',
                            isMoved
                                ? 'shadow-xl backdrop-blur-xl'
                                : 'bg-transparent',
                        )}
                    >
                        <div className="text-right md:flex-1 md:text-left">
                            <IconButton
                                name={IconType.Cross}
                                onClick={handleClose}
                            />
                        </div>
                        <h2
                            className={classNames(
                                'flex-1 text-center text-2xl font-bold tracking-wider',
                                isMoved ? 'visible' : 'invisible',
                            )}
                        >
                            {t('team.edit')}
                        </h2>
                        <div className="hidden flex-1 items-center justify-end gap-x-6 text-right md:flex">
                            <Button
                                type="submit"
                                color="black"
                                className="rounded-lg !px-14 !py-3 font-bold tracking-wider"
                                disabled={isUpdating || !initialized}
                            >
                                {t('save')}
                            </Button>
                        </div>
                    </div>
                )}
            </FixedHeader>
            <div className="mx-auto max-w-2xl px-5 pt-10 pb-20 md:pt-[88px] md:pb-[60px]">
                <h1 className="text-left text-3xl font-bold tracking-wider md:text-center">
                    {t('team.edit.working.hours')}
                </h1>
                <div className="mt-8 mb-6 w-1/2">
                    <WeekSelect value={whOption} onSelect={handleWhSelect} />
                </div>

                <Switch>
                    <Switch.Case
                        condition={isLoadingWh && whOption.value !== 'default'}
                    >
                        <div>Loading...</div>
                    </Switch.Case>
                    <Switch.Case condition={!isLoadingWh && !initialized}>
                        <div className="mt-28 mb-28 flex flex-col gap-y-8 text-center md:mb-0">
                            {t('no.working.hours')}
                            <Button
                                type="button"
                                onClick={() =>
                                    methods.setValue('initialized', true)
                                }
                                className="self-center"
                            >
                                {t('add.new.working.hours')}
                            </Button>
                        </div>
                    </Switch.Case>
                    <Switch.Case
                        condition={
                            (whOption.value === 'default' || !isLoadingWh) &&
                            initialized
                        }
                    >
                        <div
                            key={`${whOption.value}`}
                            className="mb-28 grid grid-cols-1 gap-y-4 md:mb-0"
                        >
                            {DAY_ARRAY.map((el, index) => (
                                <DaySettings
                                    key={`${el}-day-setting-${index}`}
                                    name={`daySettings.${el}`}
                                    day={el}
                                    date={
                                        whOption.value !== 'default'
                                            ? addDays(
                                                  transformToDate(
                                                      whOption.value as string,
                                                  ),
                                                  index,
                                              )
                                            : undefined
                                    }
                                />
                            ))}
                        </div>
                    </Switch.Case>
                    <Switch.Default>{null}</Switch.Default>
                </Switch>
            </div>
            <div
                className={classNames(
                    'fixed bottom-0 left-0 z-50 h-20 w-full items-center justify-between px-5 py-5 shadow-xl backdrop-blur-xl md:hidden',
                )}
            >
                <Button
                    type="submit"
                    color="black"
                    className="w-full rounded-lg !py-3 font-bold tracking-wide"
                    disabled={isUpdating || !initialized}
                >
                    {t('save')}
                </Button>
            </div>
        </Form>
    );
};
