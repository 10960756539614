import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PriceType } from '@bookinbio/enums';
import { Service } from '@bookinbio/interface';
import { Button, Icon, IconButton, IconType, Switch } from '@bookinbio/ui';
import { formatDuration2, unitAmountToPrice } from '@bookinbio/utils';
import { groupBy } from 'lodash';

import { Checkbox } from './ClientSearchSelect';
import { AppointmentForm } from './NewAppointmentDrawer';

interface ServiceFinderSelectProps {
    services?: Service[];
    isLoading: boolean;
    close: () => void;
    onSelect?: (id: string) => void;
}

export const ServiceSearchSelect = ({
    services,
    isLoading,
    onSelect,
    close,
}: ServiceFinderSelectProps) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState<string>('');
    const navigate = useNavigate();
    const { control } = useFormContext<AppointmentForm>();
    const {
        field: { onChange, value: values },
    } = useController({ name: 'serviceIds', control });

    const filteredServices = services
        ? services.filter((service) =>
              service.basicInfo.name.default
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase()),
          )
        : undefined;

    const groupedServices = groupBy(services, 'category.name.default');

    const handleClientSelect = (value: string) => {
        let newValues = [...values];
        if (values.includes(value)) {
            newValues = values.filter((i) => i !== value);
        } else {
            newValues.push(value);
        }
        onChange(newValues);
    };

    return (
        <div className="flex flex-col gap-y-4">
            <div>
                <IconButton
                    name={IconType.ChevronLeft}
                    onClick={close}
                    size={28}
                />
                <h3 className="mt-3 text-2xl font-bold tracking-wider">
                    {t('service.select')}
                </h3>
            </div>
            <div className="flex w-full items-center gap-x-2">
                <div className="flex flex-1 rounded-lg px-4 py-2 ring-1 ring-black sm:text-sm">
                    <input
                        placeholder={t('service.search')}
                        type="search"
                        className="flex-1 bg-transparent text-base outline-none"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <Button
                    size="sm"
                    className="flex h-[40px] items-center gap-x-2 !rounded-lg"
                    color="white-black"
                    onClick={() => navigate('/management/services/create')}
                >
                    <Icon
                        name={IconType.Plus}
                        size={14}
                        className="leading-4"
                    />
                    <span className="leading-4">{t('addnew')}</span>
                </Button>
            </div>

            <Switch>
                <Switch.Case condition={isLoading}>
                    <div>{t('loading')}</div>
                </Switch.Case>
                <Switch.Case
                    condition={
                        !!filteredServices && filteredServices.length > 0
                    }
                >
                    {Object.keys(groupedServices).map((key) => {
                        const services = groupedServices[key];
                        return (
                            <div key={`group-${key}`} className="flex flex-col">
                                <div className="flex items-center justify-between border-b border-gray-400 text-sm text-gray-400">
                                    <span>{key}</span>
                                </div>
                                {services.map((service) => {
                                    return (
                                        <div
                                            className="flex w-full items-center gap-x-4 rounded-lg px-1 py-2 hover:bg-gray-100"
                                            key={`service-item-${service.id}`}
                                        >
                                            <Checkbox
                                                checked={values.includes(
                                                    service.id,
                                                )}
                                                onClick={() => {
                                                    handleClientSelect(
                                                        service.id,
                                                    );
                                                    onSelect &&
                                                        onSelect(service.id);
                                                }}
                                            />
                                            <button
                                                type="button"
                                                className="flex w-full items-center gap-x-4"
                                                onClick={() => {
                                                    handleClientSelect(
                                                        service.id,
                                                    );
                                                    onSelect &&
                                                        onSelect(service.id);
                                                    close();
                                                }}
                                            >
                                                <div className="flex-1 truncate text-left">
                                                    {
                                                        service.basicInfo.name
                                                            .default
                                                    }
                                                </div>
                                                <div className="w-[20%] truncate text-left">
                                                    {service.serviceSettings
                                                        .amount &&
                                                    service.serviceSettings
                                                        .priceType ===
                                                        PriceType.Fixed
                                                        ? unitAmountToPrice(
                                                              service
                                                                  .serviceSettings
                                                                  .amount,
                                                              'eur',
                                                          )
                                                        : t('free')}
                                                </div>
                                                <div className="w-[20%] truncate text-right">
                                                    {formatDuration2(
                                                        service.serviceSettings
                                                            .duration,
                                                    )}
                                                </div>
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </Switch.Case>
                <Switch.Default>
                    <div className="pt-3 text-gray-500">
                        {t('service.empty')}
                    </div>
                </Switch.Default>
            </Switch>
        </div>
    );
};

// interface ServiceItemProps {
//     service: Service;
//     onClick: () => void;
// }

// const ServiceItem = ({ service, onClick }: ServiceItemProps) => {
//     const { t } = useTranslation();

//     return (
//         <div
//             className="flex items-center w-full px-1 py-2 rounded-lg gap-x-4 group-hover:bg-gray-100"
//             key={`client-item-${client.id}`}
//         >
//             <Checkbox
//                 checked={values.includes(client.id)}
//                 onClick={() => {
//                     handleClientSelect(client.id);
//                     onSelect && onSelect(client.id);
//                 }}
//             />
//             <button
//                 type="button"
//                 key={`service-item-card`}
//                 className="flex items-center w-full py-3 pl-8 pr-2 my-1 rounded-lg hover:bg-gray-100"
//                 onClick={onClick}
//             >
//                 <div className="flex-1 text-left truncate">
//                     {service.basicInfo.name.default}
//                 </div>
//                 <div className="w-[20%] truncate text-left">
//                     {service.serviceSettings.amount &&
//                     service.serviceSettings.priceType === PriceType.Fixed
//                         ? unitAmountToPrice(
//                               service.serviceSettings.amount,
//                               'eur',
//                           )
//                         : t('free')}
//                 </div>
//                 <div className="w-[20%] truncate text-right">
//                     {formatDuration2(service.serviceSettings.duration)}
//                 </div>
//             </button>
//         </div>
//     );
// };
