import classNames from 'classnames';

interface RadioBoxProps {
    checked: boolean;
    label: string;
    onClick: () => void;
}

export const RadioBox = ({ checked, label, onClick }: RadioBoxProps) => {
    return (
        <div className="flex items-center justify-start gap-x-6">
            <div
                className="h-6 w-6 cursor-pointer rounded border border-black p-[3px] text-gray-500"
                onClick={onClick}
            >
                <div
                    className={classNames(
                        'h-full w-full rounded',
                        checked ? 'bg-black' : 'bg-white',
                    )}
                />
                <input
                    type="radio"
                    checked={checked}
                    className="hidden"
                    onChange={onClick}
                />
            </div>
            <span>{label}</span>
        </div>
    );
};
