import { useTranslation } from 'react-i18next';
import { Appointment } from '@bookinbio/interface';
import { getPosFromTime } from '@bookinbio/utils';
import classNames from 'classnames';
import { addMinutes, format } from 'date-fns';

import { SchedulerConfig } from '../../utils/config';

import { useCalendar } from './AppointmentsContext';

interface AppointmentCardProps {
    appointment: Appointment;
    additionalTop?: boolean;
    className?: string;
    onClick: () => void;
}

export const AppointmentItem = ({
    appointment,
    className,
    additionalTop = false,
    onClick,
}: AppointmentCardProps) => {
    const { setHover } = useCalendar();
    const { t } = useTranslation();
    const appointmentStyle = getPosFromTime(
        appointment.start.toDate(),
        appointment.duration,
        {
            gridCellTypeNumber: SchedulerConfig.gridCellTypeNumber,
            gridPixelHeight: SchedulerConfig.gridPixelHeight,
            additionalTop,
        },
    );

    const start = appointment.start.toDate();
    const startStr = format(start, 'HH:mm');
    const endStr = format(addMinutes(start, appointment.duration), 'HH:mm');

    const serviceNameList = appointment.serviceDatas.reduce(
        (acc, curr, index) =>
            index === 0
                ? curr.basicInfo.name.default
                : acc + ', ' + curr.basicInfo.name.default,
        '',
    );

    return (
        <button
            type="button"
            className={classNames(
                'rounded-smd absolute z-50 flex w-full cursor-pointer flex-col justify-start gap-x-5 border border-black bg-black px-3 text-white outline-none ring-blue-700 hover:z-[51] hover:ring-2',
                appointment.duration > 45 ? 'py-3' : 'py-1',
                className,
            )}
            style={{
                top: appointmentStyle.top,
                height: appointmentStyle.height,
            }}
            onClick={onClick}
            onMouseEnter={() => {
                setHover(null);
            }}
        >
            <div className="flex w-full items-start justify-between">
                <div className="flex-1">
                    <div className="mb-1 text-left text-sm font-semibold">
                        {appointment.clientDatas &&
                        appointment.clientDatas.length !== 0
                            ? appointment.clientDatas.length > 1
                                ? t('group')
                                : appointment.clientDatas[0].fullName
                            : t('walkin')}
                    </div>
                    <div className="text-left text-xs">{serviceNameList}</div>
                </div>
                <div className="flex flex-col items-center">
                    <div className="text-sm">{startStr}</div>
                    <div className="text-xs text-gray-400">{endStr}</div>
                </div>
            </div>
        </button>
    );
};
