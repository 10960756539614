import { collection, doc, getDocs, query, where } from 'firebase/firestore';

import { businessesColl, customersColl } from '../collections';

export const getBusinessSubscription = async (
    businessId?: string,
): Promise<boolean> => {
    if (!businessId) {
        return false;
    }

    const businessRef = doc(businessesColl, businessId);

    const q = query(customersColl, where('business', '==', businessRef));

    const customerSnaps = await getDocs(q);

    if (customerSnaps.empty) {
        // throw new Error(
        //     'Stripe customer does not exist by provided businessId.',
        // );
        return false;
    }

    const subscriptionCol = collection(
        customersColl,
        customerSnaps.docs[0].id,
        'subscriptions',
    );

    const subQ = query(
        subscriptionCol,
        where('status', 'in', ['trialing', 'active']),
    );

    const subscriptionSnaps = await getDocs(subQ);

    return !subscriptionSnaps.empty;
};
