import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';
import {
    Route,
    Routes,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    EndType,
    FrequencyType,
    OverrideType,
    RecurringUpdateOption,
    RepeatType,
} from '@bookinbio/enums';
import {
    Button,
    DatePickerInput,
    Form,
    IconButton,
    IconType,
    MultiSelectInput,
    SelectInput,
    SelectInputController,
    Switch,
    TextareaInput,
    TextInput,
    TextInputController,
    WithLabel,
} from '@bookinbio/ui';
import {
    combineDateAndTime,
    END_TYPE_OPTIONS,
    FREQUENCY_TYPE_OPTIONS,
    REPEAT_TYPE_OPTIONS,
    TIME_OPTIONS,
    timeToMinutes,
} from '@bookinbio/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { addMinutes, addMonths, addYears, format } from 'date-fns';

import { useBusiness } from '../../context/BusinessContext';
import { getOverrideById } from '../../utils/firebase/api/get-override-by-id';
import { deleteOverride, updateOverride } from '../../utils/firebase/callable';
import { useCalendar } from '../calendar/AppointmentsContext';
import { DaysCheckbox } from '../CheckboxDays';
import { SideDrawerContainer } from '../SideDrawerContainer';

import {
    TimeOffForm,
    TimeOffFormProps,
    TimeOffSchema,
} from './NewTimeOffDrawer';
import { DeleteConfirmModal } from './ToffDeleteModal';
import { UpdateConfirmModal } from './ToffUpdateModal';

interface TimeOffDrawerProps {
    close: () => void;
}

export const TimeOffDrawer = ({ close }: TimeOffDrawerProps) => {
    const { t } = useTranslation();
    const { id: overrideId } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { business, professionals, rents, isLoading, isProfsLoading } =
        useBusiness();
    const { refetchOverrides } = useCalendar();

    const methods = useForm<TimeOffForm>({
        mode: 'all',
        reValidateMode: 'onChange',
        resolver: zodResolver(TimeOffSchema),
    });
    const [repeatType, endType, frequencyType] = methods.watch([
        'repeatType',
        'endType',
        'customRepeatSettings.frequencyType',
    ]);

    const { data: override } = useQuery({
        queryKey: ['business-override', business, overrideId],
        queryFn: () => getOverrideById(business?.id, overrideId),
        enabled: !!overrideId && !!business,
    });
    const { mutateAsync, isLoading: isUpdating } = useMutation({
        mutationFn: updateOverride,
    });

    const { mutateAsync: delOverride, isLoading: isDeleting } = useMutation({
        mutationFn: deleteOverride,
    });

    const professionalsOptions = useMemo(() => {
        return professionals
            ? professionals.map((professional) => ({
                  value: professional.id,
                  label: professional.fullName ?? 'Unknown',
              }))
            : [];
    }, [professionals]);

    const rentsOptions = useMemo(() => {
        return rents
            ? rents.map((rent) => ({
                  value: rent.id,
                  label: rent.name ?? 'Unknown',
              }))
            : [];
    }, [rents]);

    useEffect(() => {
        if (override) {
            const start = override.start.toDate();

            let professionalOptIds = undefined;
            if (professionals && override.professionalRefs) {
                const profIds = override.professionalRefs.map(
                    (prof) => prof.id,
                );
                professionalOptIds = professionalsOptions.filter((profOpt) =>
                    profIds.includes(profOpt.value),
                );
            }

            let rentOptIds = undefined;
            if (rents && override.rentRefs) {
                const rentIds = override.rentRefs.map((rent) => rent.id);
                rentOptIds = rentsOptions.filter((profOpt) =>
                    rentIds.includes(profOpt.value),
                );
            }

            methods.reset({
                title: override.title,
                description: override.description,
                date: start,
                startTime: format(start, 'HH:mm'),
                endTime: format(addMinutes(start, override.duration), 'HH:mm'),
                endType:
                    override.repeatSettings?.endType ??
                    END_TYPE_OPTIONS[0].value,
                repeatType: override.repeatType,
                endDate:
                    override.repeatSettings?.end?.toDate() ??
                    addMonths(new Date(), 1),
                occurenceValue: override.repeatSettings?.occurenceValue ?? 5,
                professionalIds: professionalOptIds,
                rentIds: rentOptIds,
                customRepeatSettings: {
                    value:
                        override.repeatSettings?.customRepeatSettings?.value ??
                        1,
                    days: override.repeatSettings?.customRepeatSettings
                        ?.days ?? [1, 3, 5],
                    frequencyType:
                        override.repeatSettings?.customRepeatSettings
                            ?.frequencyType ?? FrequencyType.Week,
                },
            });
        }
    }, [
        override,
        professionals,
        methods,
        professionalsOptions,
        rentsOptions,
        rents,
    ]);

    // Handlers
    const handleModalClose = () => {
        navigate(-1);
    };

    const handleOpenDeleteModal = () => {
        navigate(`delete?${searchParams.toString()}`);
    };

    const handleOpenUpdateModal = () => {
        navigate(`confirm?${searchParams.toString()}`);
    };

    const handleSubmit = async () => {
        if (!overrideId) {
            return;
        }

        const data = methods.getValues();

        if (override?.repeatType === RepeatType.DoesNotRepeat) {
            if (data.repeatType === RepeatType.DoesNotRepeat) {
                await handleUpdateOverride(RecurringUpdateOption.OnlySelected);
            } else {
                await handleUpdateOverride(RecurringUpdateOption.All);
            }
        } else {
            handleOpenUpdateModal();
        }
    };

    const handleUpdateOverride = async (
        option: RecurringUpdateOption,
        isSingle = false,
    ) => {
        if (!business || !overrideId) {
            return;
        }
        const data = methods.getValues();

        const endDate = data.endDate ? data.endDate.toISOString() : null;

        const start = combineDateAndTime(
            data.date.toISOString(),
            data.startTime,
        ).toISOString();
        const duration =
            timeToMinutes(data.endTime) - timeToMinutes(data.startTime);

        try {
            await mutateAsync({
                ...data,
                customRepeatSettings:
                    data.repeatType === RepeatType.Custom
                        ? data.customRepeatSettings
                        : null,
                endType:
                    data.repeatType !== RepeatType.DoesNotRepeat
                        ? data.endType
                        : null,
                occurenceValue:
                    data.endType === EndType.After ? data.occurenceValue : null,
                endDate: data.endType === EndType.SpecificDate ? endDate : null,
                overrideId,
                start,
                duration: duration,
                type: OverrideType.OffTime,
                professionalIds: data.professionalIds
                    ? data.professionalIds.map((p) => p.value)
                    : null,
                rentIds: data.rentIds ? data.rentIds.map((r) => r.value) : null,
                businessId: business.id,
                timeZone: business.calendarSettings.timeZone,
                isSingle,
                updateOption: option,
            });
            toast.success(t('timeoff.update.success'));
            refetchOverrides();
            close();
        } catch (error) {
            console.error(error);
            toast.error(t('timeoff.update.error'));
        }
    };

    const handleDeleteOverride = async (
        id: string,
        option: RecurringUpdateOption,
    ) => {
        if (!business) {
            return;
        }
        try {
            await delOverride({
                businessId: business.id,
                overrideId: id,
                deleteOption: option,
            });
            await refetchOverrides();
            toast.success(t('timeoff.delete.success'));
            close();
        } catch (error) {
            toast.error(t('timeoff.delete.error'));
        }
    };

    return (
        <SideDrawerContainer isOpen>
            <Switch>
                <Switch.Case condition={isProfsLoading || isLoading}>
                    <span className="flex h-full items-center justify-center">
                        {t('loading')}
                    </span>
                </Switch.Case>
                <Switch.Case condition={!!professionals && !!business}>
                    <Form<TimeOffForm>
                        methods={methods}
                        className="flex h-full flex-col"
                    >
                        <div className="mb-2 flex items-center md:mb-6">
                            <IconButton
                                name={IconType.Cross}
                                onClick={close}
                                size={20}
                            />
                        </div>
                        <div className="grid flex-1 gap-y-6 gap-x-4 overflow-auto pb-4 md:gap-y-4">
                            <TextInput<TimeOffFormProps>
                                label={t('title')}
                                name="title"
                                placeholder={t('timeoff.title.placeholder')}
                            />
                            <DatePickerInput<TimeOffFormProps>
                                label={t('date')}
                                name="date"
                                fromYear={new Date().getFullYear()}
                                toYear={addYears(new Date(), 5).getFullYear()}
                            />
                            <SelectInput<TimeOffFormProps>
                                label={t('time.start')}
                                name="startTime"
                                translate={false}
                                options={TIME_OPTIONS}
                            />
                            <SelectInput<TimeOffFormProps>
                                label={t('time.end')}
                                name="endTime"
                                translate={false}
                                options={TIME_OPTIONS}
                            />
                            <MultiSelectInput<TimeOffFormProps>
                                label={t('team.members')}
                                name="professionalIds"
                                options={professionalsOptions}
                            />
                            <MultiSelectInput<TimeOffFormProps>
                                label={t('rentals')}
                                name="rentIds"
                                options={rentsOptions}
                            />
                            <SelectInput<TimeOffFormProps>
                                label={t('repeat')}
                                name="repeatType"
                                options={REPEAT_TYPE_OPTIONS}
                                menuPlacement="top"
                            />
                            {repeatType !== RepeatType.DoesNotRepeat ? (
                                <>
                                    {repeatType === RepeatType.Custom ? (
                                        <>
                                            <WithLabel
                                                label={t('repeat.every')}
                                            >
                                                <div className="flex items-center justify-start gap-x-4">
                                                    <TextInputController<TimeOffFormProps>
                                                        name="customRepeatSettings.value"
                                                        type="number"
                                                        asType="number"
                                                        ctrClassName="!w-32"
                                                    />
                                                    <div className="flex-1">
                                                        <SelectInputController<TimeOffFormProps>
                                                            name="customRepeatSettings.frequencyType"
                                                            options={
                                                                FREQUENCY_TYPE_OPTIONS
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </WithLabel>
                                            {frequencyType ===
                                            FrequencyType.Week ? (
                                                <WithLabel label={t('ondays')}>
                                                    <DaysCheckbox<TimeOffFormProps> name="customRepeatSettings.days" />
                                                </WithLabel>
                                            ) : null}
                                        </>
                                    ) : null}
                                    <SelectInput<TimeOffFormProps>
                                        name="endType"
                                        label={t('ends')}
                                        options={END_TYPE_OPTIONS}
                                    />
                                    {endType === EndType.After ? (
                                        <div className="flex items-center">
                                            <TextInputController<TimeOffFormProps>
                                                name="occurenceValue"
                                                type="number"
                                                asType="number"
                                                min={1}
                                            />
                                            <span>{t('occurences')}</span>
                                        </div>
                                    ) : null}
                                    {endType === EndType.SpecificDate ? (
                                        <DatePickerInput<TimeOffFormProps>
                                            label={t('date.end')}
                                            name="endDate"
                                            fromYear={new Date().getFullYear()}
                                            toYear={addYears(
                                                new Date(),
                                                5,
                                            ).getFullYear()}
                                        />
                                    ) : null}
                                </>
                            ) : null}
                            <TextareaInput<TimeOffFormProps>
                                label={t('description.optional')}
                                name="description"
                                placeholder={t(
                                    'timeoff.description.placeholder',
                                )}
                            />
                        </div>
                        <div className="flex gap-x-4">
                            <IconButton
                                onClick={handleOpenDeleteModal}
                                name={IconType.BinFilled}
                                className="rounded-smd flex h-full w-[56px] flex-shrink-0 items-center justify-center transition-all hover:bg-gray-100 hover:text-red-700"
                            />
                            <Button
                                type="button"
                                color="black"
                                className="w-full"
                                disabled={isUpdating || isDeleting}
                                onClick={handleSubmit}
                            >
                                {isUpdating ? (
                                    <div className="flex items-center justify-center">
                                        <ThreeDots
                                            height="24"
                                            radius="9"
                                            color="#ffffff"
                                            ariaLabel="three-dots-loading"
                                            visible={true}
                                        />
                                    </div>
                                ) : (
                                    t('save.changes')
                                )}
                            </Button>
                        </div>
                    </Form>
                </Switch.Case>
            </Switch>
            <Routes>
                <Route
                    path="delete"
                    element={
                        <DeleteConfirmModal
                            isDeleting={isDeleting}
                            isMultiple={
                                override?.repeatType !==
                                RepeatType.DoesNotRepeat
                            }
                            close={handleModalClose}
                            onDelete={handleDeleteOverride}
                        />
                    }
                />
                <Route
                    path="confirm"
                    element={
                        <UpdateConfirmModal
                            isUpdating={isUpdating}
                            close={handleModalClose}
                            onUpdate={handleUpdateOverride}
                        />
                    }
                />
            </Routes>
        </SideDrawerContainer>
    );
};
