import { Business, Client } from '@bookinbio/interface';
import { collection, doc, getDoc } from 'firebase/firestore';

import { businessesColl } from '../collections';

export const getClientByBusiness = async (
    business?: Business,
    clientId?: string,
): Promise<Client> => {
    if (!business || !clientId) {
        throw new Error('Missing business id');
    }

    const clientsColl = collection(doc(businessesColl, business.id), 'clients');

    const serviceSnap = await getDoc(doc(clientsColl, clientId));

    if (!serviceSnap.exists()) {
        throw new Error('Client does not exists');
    }

    return { ...(serviceSnap.data() as Client), id: serviceSnap.id };
};
