import { Business, Service } from '@bookinbio/interface';
import { collection, doc, getDoc } from 'firebase/firestore';

import { businessesColl } from '../collections';

export const getServiceByBusiness = async (
    business?: Business,
    serviceId?: string,
): Promise<Service> => {
    if (!business || !serviceId) {
        throw new Error('Missing business id');
    }

    const servicesColl = collection(
        doc(businessesColl, business.id),
        'services',
    );

    const serviceSnap = await getDoc(doc(servicesColl, serviceId));

    if (!serviceSnap.exists()) {
        throw new Error('Service does not exists');
    }

    return {
        ...serviceSnap.data(),
        rentRefs: serviceSnap.data().rentRefs ?? [],
        id: serviceSnap.id,
    } as Service;
};
