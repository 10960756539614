import { Path, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Button,
    FixedHeader,
    Form,
    IconButton,
    IconType,
    TextareaInput,
    TextInput,
    Toggle,
} from '@bookinbio/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { z } from 'zod';

import { useBusiness } from '../../../context/BusinessContext';
import i18n from '../../../locales/i18n';
import { createRent } from '../../../utils/firebase/callable/rent';
import { FormSectionHeader } from '../../FormSectionHeader';
import {
    DaySettings,
    DaysSettingsSchema,
} from '../../settings/working-hours/DaySettings';
import { DAY_ARRAY } from '../../settings/working-hours/WorkingHours';

export const RentSchema = z.object({
    name: z.string().min(1, i18n.t('rental.name.error.message')),
    description: z.string().optional(),
    allowBookings: z.boolean(),
    workingHours: DaysSettingsSchema,
});

export type RentForm = z.infer<typeof RentSchema>;
export type RentFormProps = Path<RentForm>;

export const CreateRent = () => {
    const { t } = useTranslation();
    const { business, rentsRefetch } = useBusiness();
    const navigate = useNavigate();

    const methods = useForm<RentForm>({
        defaultValues: {
            allowBookings: false,
            workingHours: {
                0: {
                    timeSlots: [],
                    isOpen: false,
                },
                1: {
                    timeSlots: [{ start: '09:00', end: '17:00' }],
                    isOpen: true,
                },
                2: {
                    timeSlots: [{ start: '09:00', end: '17:00' }],
                    isOpen: true,
                },
                3: {
                    timeSlots: [{ start: '09:00', end: '17:00' }],
                    isOpen: true,
                },
                4: {
                    timeSlots: [{ start: '09:00', end: '17:00' }],
                    isOpen: true,
                },
                5: {
                    timeSlots: [{ start: '09:00', end: '17:00' }],
                    isOpen: true,
                },
                6: {
                    timeSlots: [],
                    isOpen: false,
                },
            },
        },
        mode: 'all',
        reValidateMode: 'onSubmit',
        resolver: zodResolver(RentSchema),
    });

    // BE data
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: createRent,
    });

    // Effects
    const handleClose = () => {
        navigate(-1);
    };

    const handleSubmit = async (data: RentForm) => {
        if (!business) {
            return;
        }

        try {
            await mutateAsync({
                ...data,
                workingHours: data.workingHours,
                businessId: business.id,
            });
            toast.success(t('rental.create.success'));
            await rentsRefetch();
            handleClose();
        } catch (error) {
            console.error(error);
            toast.error(t('rental.create.error'));
        }
    };

    return (
        <Form
            methods={methods}
            className="min-h-full w-full"
            onSubmit={handleSubmit}
        >
            <FixedHeader>
                {({ isMoved }) => (
                    <div
                        className={classNames(
                            'fixed top-0 left-0 z-50 flex w-full flex-row-reverse items-center justify-end px-8 pt-6 pb-5 md:h-[88px] md:flex-row md:justify-between',
                            isMoved
                                ? 'shadow-xl backdrop-blur-xl'
                                : 'bg-transparent',
                        )}
                    >
                        <div className="text-right md:flex-1 md:text-left">
                            <IconButton
                                name={IconType.Cross}
                                onClick={handleClose}
                            />
                        </div>
                        <h2
                            className={classNames(
                                'flex-1 text-center text-2xl font-bold tracking-wider',
                                isMoved ? 'visible' : 'invisible',
                            )}
                        >
                            {t('rental.create')}
                        </h2>
                        <div className="hidden flex-1 text-right md:block">
                            <Button
                                type="submit"
                                color="black"
                                className="rounded-lg !px-14 !py-3 font-bold tracking-wider"
                                disabled={isLoading}
                            >
                                {t('save')}
                            </Button>
                        </div>
                    </div>
                )}
            </FixedHeader>
            <div className="mx-auto max-w-2xl px-5 pt-10 pb-20 md:pt-[88px] md:pb-[60px]">
                <h1 className="text-left text-3xl font-bold tracking-wider md:text-center">
                    {t('rental.create')}
                </h1>
                <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('basic.information')}
                        subTitle={t('rental.basic.information.subtitle')}
                    />
                    <div className="mt-6 grid grid-cols-1 gap-y-4">
                        <TextInput<RentFormProps>
                            label={t('rental.name')}
                            placeholder={t('rental.name.placeholder')}
                            name="name"
                        />
                        <TextareaInput<RentFormProps>
                            label={t('description.optional')}
                            placeholder={t('rental.description.placeholder')}
                            name="description"
                        />
                    </div>
                </div>
                <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('booking.settings')}
                        subTitle={t('rental.booking.settings.subtitle')}
                    />
                    <Toggle<RentFormProps>
                        name="allowBookings"
                        ctrClassName="flex items-center gap-x-6 mt-4"
                    >
                        <div>{t('allow.calendar.bookings')}</div>
                    </Toggle>
                    <div className="mt-6 grid grid-cols-1 gap-y-4">
                        {DAY_ARRAY.map((el) => (
                            <DaySettings
                                key={`${el}-day-setting`}
                                name={`workingHours.${el}`}
                                day={el}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div
                className={classNames(
                    'fixed bottom-0 left-0 z-50 h-20 w-full items-center justify-between px-5 py-5 shadow-xl backdrop-blur-xl md:hidden',
                )}
            >
                <Button
                    type="submit"
                    color="black"
                    className="w-full rounded-lg !py-3 font-bold tracking-wide"
                    disabled={isLoading}
                >
                    {t('save')}
                </Button>
            </div>
        </Form>
    );
};
