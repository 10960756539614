import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import classNames from 'classnames';

import '../assets/css/editor.css';

const EXTENSIONS = [
    StarterKit.configure({
        bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
    }),
    Link.configure({
        openOnClick: false,
    }),
    Underline,
];

export interface TextEditorShowProps {
    content?: string;
    ctrClassName?: string;
}

export const ContentReader = ({
    content,
    ctrClassName,
}: TextEditorShowProps) => {
    const editor = useEditor({
        extensions: EXTENSIONS,
        content: content,
        editable: false,
    });

    if (!editor) {
        return null;
    }

    return (
        <div
            className={classNames(
                'read-content-only relative w-full',
                ctrClassName,
            )}
        >
            <EditorContent editor={editor} />
        </div>
    );
};
