import { InitialSetup } from '@bookinbio/enums';
import { doc, serverTimestamp, setDoc, Timestamp } from 'firebase/firestore';

import { businessesColl } from '../collections';

export const changeBusinessInitSetupStep = async (
    step: InitialSetup,
    businessId?: string,
) => {
    if (!businessId) {
        throw new Error('Missing business id');
    }

    const businessRef = doc(businessesColl, businessId);

    await setDoc(
        businessRef,
        { initialSetup: step, updatedAt: serverTimestamp() as Timestamp },
        { merge: true },
    );
};
