import { useCallback, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CharacterCount from '@tiptap/extension-character-count';
import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import { BubbleMenu, Editor, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import classNames from 'classnames';

import { Button } from './Button';
import { Icon, IconType } from './Icon';
import { Modal } from './Modal';
import { WithLabel, WithLabelProps } from './WithLabel';

import '../assets/css/editor.css';

const EXTENSIONS = [
    StarterKit.configure({
        bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
    }),
    Link.configure({
        openOnClick: false,
    }),
    Underline,
];

export interface TextEditorControllerProps<T extends string> {
    name: T;
    limit?: number;
    className?: string;
    ctrClassName?: string;
}

export const TextEditorController = <T extends string = string>({
    name,
    limit,
    ctrClassName,
}: TextEditorControllerProps<T>) => {
    const { control } = useFormContext();
    const {
        field: { onChange, value },
    } = useController({ name, control });

    const editor = useEditor({
        extensions: [...EXTENSIONS, CharacterCount.configure({ limit })],
        content: value,
        onUpdate: ({ editor }) => {
            // If not checking content whether is empty, it will return <p></p>
            onChange(editor.isEmpty ? '' : editor.getHTML());
        },
    });

    if (!editor) {
        return null;
    }

    return (
        <div className={classNames('relative w-full', ctrClassName)}>
            <EditorMenu editor={editor} />
            <EditorContent editor={editor} />
            <EditorFooter editor={editor} limit={limit} />
        </div>
    );
};

export interface TextEditorProps<T extends string>
    extends TextEditorControllerProps<T>,
        Omit<WithLabelProps, 'name' | 'children' | 'ctrClassName'> {
    label: string;
    className?: string;
    labelClassName?: string;
    inputCtrClassName?: string;
    inputClassName?: string;
}

export const TextEditor = <T extends string = string>({
    name,
    label,
    limit,
    className,
    labelClassName,
    inputCtrClassName,
    inputClassName,
    right,
}: TextEditorProps<T>) => {
    return (
        <WithLabel
            label={label}
            name={name}
            className={classNames(className, 'gap-y-2')}
            labelClassName={labelClassName}
            right={right}
        >
            <TextEditorController
                name={name}
                limit={limit}
                ctrClassName={inputCtrClassName}
                className={inputClassName}
            />
        </WithLabel>
    );
};

interface EditorMenuProps {
    editor: Editor;
}

const EditorMenu = ({ editor }: EditorMenuProps) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [url, setUrl] = useState<string>('');

    const openModal = useCallback(() => {
        setUrl(editor.getAttributes('link').href ?? '');
        setIsOpen(true);
    }, [editor]);

    const closeModal = useCallback(() => {
        setIsOpen(false);
        setUrl('');
    }, [setIsOpen, setUrl]);

    const saveLink = useCallback(() => {
        if (url) {
            editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url, target: '_blank' })
                .run();
        } else {
            editor.chain().focus().extendMarkRange('link').unsetLink().run();
        }
        closeModal();
    }, [editor, url, closeModal]);

    const removeLink = useCallback(() => {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
        closeModal();
    }, [editor, closeModal]);

    const toggleBold = useCallback(() => {
        editor.chain().focus().toggleBold().run();
    }, [editor]);

    const toggleUnderline = useCallback(() => {
        editor.chain().focus().toggleUnderline().run();
    }, [editor]);

    const toggleItalic = useCallback(() => {
        editor.chain().focus().toggleItalic().run();
    }, [editor]);

    const toggleStrike = useCallback(() => {
        editor.chain().focus().toggleStrike().run();
    }, [editor]);

    const toggleBulletList = useCallback(() => {
        editor.chain().focus().toggleBulletList().run();
    }, [editor]);

    const toggleOrderedList = useCallback(() => {
        editor.chain().focus().toggleOrderedList().run();
    }, [editor]);

    return (
        <>
            <div className="absolute top-[1px] left-0 z-[1] flex w-full gap-x-2 border-b border-b-gray-400 p-1">
                <Button
                    className="relative flex h-8 w-8 items-center justify-center rounded !p-0 !text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-200 hover:!text-black disabled:!bg-transparent disabled:!text-gray-400"
                    size="sm"
                    color="clear"
                    onClick={() => editor.chain().focus().undo().run()}
                    disabled={!editor.can().undo()}
                >
                    <Icon name={IconType.Undo} size={18} />
                </Button>
                <Button
                    className="relative flex h-8 w-8 items-center justify-center rounded !p-0 !text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-200 hover:!text-black disabled:!bg-transparent disabled:!text-gray-400"
                    size="sm"
                    color="clear"
                    onClick={() => editor.chain().focus().redo().run()}
                    disabled={!editor.can().redo()}
                >
                    <Icon name={IconType.Redo} size={18} />
                </Button>
                <Button
                    className={classNames(
                        'relative flex h-8 w-8 items-center justify-center rounded !p-0 !text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-200 hover:!text-black',
                        {
                            '!bg-gray-300 !text-black': editor.isActive('link'),
                        },
                    )}
                    size="sm"
                    color="clear"
                    onClick={openModal}
                >
                    <Icon name={IconType.Hyperlink} size={18} />
                </Button>
                <Button
                    className={classNames(
                        'relative flex h-8 w-8  items-center justify-center rounded !p-0 !text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-200 hover:!text-black',
                        {
                            '!bg-gray-300 !text-black': editor.isActive('bold'),
                        },
                    )}
                    size="sm"
                    color="clear"
                    onClick={toggleBold}
                >
                    <Icon name={IconType.Bold} size={18} />
                </Button>
                <Button
                    className={classNames(
                        'relative flex h-8 w-8  items-center justify-center rounded !p-0 !text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-200 hover:!text-black',
                        {
                            '!bg-gray-300 !text-black':
                                editor.isActive('underline'),
                        },
                    )}
                    size="sm"
                    color="clear"
                    onClick={toggleUnderline}
                >
                    <Icon name={IconType.Underline} size={18} />
                </Button>
                <Button
                    className={classNames(
                        'relative flex h-8 w-8  items-center justify-center rounded !p-0 !text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-200 hover:!text-black',
                        {
                            '!bg-gray-300 !text-black':
                                editor.isActive('italic'),
                        },
                    )}
                    size="sm"
                    color="clear"
                    onClick={toggleItalic}
                >
                    <Icon name={IconType.Italic} size={18} />
                </Button>
                <Button
                    className={classNames(
                        'relative flex h-8 w-8  items-center justify-center rounded !p-0 !text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-200 hover:!text-black',
                        {
                            '!bg-gray-300 !text-black':
                                editor.isActive('strike'),
                        },
                    )}
                    size="sm"
                    color="clear"
                    onClick={toggleStrike}
                >
                    <Icon name={IconType.Strikethrough} size={18} />
                </Button>
                <Button
                    className={classNames(
                        'relative flex h-8 w-8 items-center justify-center rounded !p-0 !text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-200 hover:!text-black',
                        {
                            '!bg-gray-300 !text-black':
                                editor.isActive('bulletList'),
                        },
                    )}
                    size="sm"
                    color="clear"
                    onClick={toggleBulletList}
                >
                    <Icon name={IconType.BulletList} size={18} />
                </Button>
                <Button
                    className={classNames(
                        'relative flex h-8 w-8  items-center justify-center rounded !p-0 !text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-200 hover:!text-black',
                        {
                            '!bg-gray-300 !text-black':
                                editor.isActive('orderedList'),
                        },
                    )}
                    size="sm"
                    color="clear"
                    onClick={toggleOrderedList}
                >
                    <Icon name={IconType.OrderedList} size={18} />
                </Button>
                {/* TODO: Font resizing via dropdown like in iPhone Notes (Title, Subtitle, Heading, Normal text and etc.) */}
                {/* TODO: Text align (left, center, right) and indents */}
                {/* <Button
                    className={classNames(
                        'relative flex h-8 w-8  items-center justify-center rounded !p-0 !text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-100 hover:!text-black',
                    )}
                    size="sm"
                    color="clear"
                >
                    <Icon name={IconType.IncreaseFont} size={18} />
                </Button>
                <Button
                    className={
                        'relative flex h-8 w-8  items-center justify-center rounded !p-0 !text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-100 hover:!text-black'
                    }
                    size="sm"
                    color="clear"
                >
                    <Icon name={IconType.DecreaseFont} size={18} />
                </Button> */}
            </div>
            <BubbleMenu
                className="rounded border border-gray-400 bg-white p-2"
                tippyOptions={{ duration: 150 }}
                editor={editor}
                shouldShow={({ editor, from, to }) => {
                    // only show the bubble menu for links.
                    // return false;
                    return from === to && editor.isActive('link');
                }}
            >
                <div className="flex justify-end gap-x-4">
                    <Button
                        type="button"
                        size="xs"
                        onClick={openModal}
                        color="white-black"
                    >
                        {t('edit')}
                    </Button>
                    <Button
                        color="black"
                        type="button"
                        size="xs"
                        onClick={saveLink}
                    >
                        {t('remove')}
                    </Button>
                </div>
            </BubbleMenu>
            <LinkModal
                url={url}
                isOpen={isOpen}
                close={() => setIsOpen(false)}
                onUrlChange={(value) => setUrl(value)}
                removeLink={removeLink}
                saveLink={saveLink}
            />
        </>
    );
};

interface LinkModalProps {
    isOpen: boolean;
    url: string;
    onUrlChange: (value: string) => void;
    close: () => void;
    removeLink: () => void;
    saveLink: () => void;
}

const urlRegex = new RegExp(
    '^(http(s)://.)[-a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$',
);

const LinkModal = ({
    isOpen,
    url,
    onUrlChange,
    close,
    saveLink,
    removeLink,
}: LinkModalProps) => {
    const { t } = useTranslation();
    const [isError, setIsError] = useState<boolean>(false);
    return (
        <Modal isOpen={isOpen} close={close} className="flex flex-col gap-y-4">
            <div className="text-xl">{t('edit.link')}</div>
            <input
                type="text"
                value={url}
                placeholder="https://yourlink.example"
                onChange={(e) => {
                    const value = e.target.value;

                    if (!urlRegex.test(value)) {
                        setIsError(true);
                    } else {
                        setIsError(false);
                    }

                    onUrlChange(value);
                }}
                className="w-full border-b border-gray-500 bg-transparent px-3 py-3 text-base outline-none sm:text-base"
            />
            {isError ? (
                <p
                    className={classNames(
                        'mb-2 h-3 text-left text-xs text-red-700',
                    )}
                >
                    {/* TODO: Add to resources (Please enter valid URL) */}
                    {t('link.error')}
                </p>
            ) : null}
            <div className="flex justify-end gap-x-4">
                <Button
                    type="button"
                    size="sm"
                    onClick={() => {
                        setIsError(false);
                        removeLink();
                    }}
                    color="white-black"
                >
                    {t('remove')}
                </Button>
                <Button
                    color="black"
                    type="button"
                    size="sm"
                    disabled={isError}
                    onClick={saveLink}
                >
                    {t('save')}
                </Button>
            </div>
        </Modal>
    );
};

interface EditorFooter {
    editor: Editor;
    limit?: number;
}

const EditorFooter = ({ editor, limit }: EditorFooter) => {
    const { t } = useTranslation();
    const characterCount = editor.storage.characterCount.characters();

    return (
        <div className="absolute bottom-0 left-0 flex w-full justify-end border-t border-gray-400 p-1 text-gray-500">
            {limit ? (
                <div className="text-sm">
                    {characterCount}/{limit}
                </div>
            ) : (
                <div className="text-sm">{t('Unlimited characters')}</div>
            )}
        </div>
    );
};
