import { ChangeEvent, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Combobox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import usePlacesAutocomplete from 'use-places-autocomplete';

import { WithLabel } from './WithLabel';

export interface PlacesAutocompleteControllerProps<T extends string> {
    name: T;
    placeholder?: string;
    className?: string;
    ctrClassName?: string;
}

// FYI: Needs to include useLoadScript [@react-google-maps/api]
export const PlacesAutocompleteController = <T extends string = string>({
    name,
    placeholder,
    className,
    ctrClassName,
}: PlacesAutocompleteControllerProps<T>) => {
    const { control } = useFormContext();
    const {
        suggestions: { status, data },
        setValue,
    } = usePlacesAutocomplete({ callbackName: 'initMap' });
    const hasSuggestions = status === 'OK';

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                return (
                    <Combobox
                        value={value}
                        onChange={(value) => {
                            setValue(value, false);
                            onChange(value);
                        }}
                    >
                        <div
                            className={classNames(
                                'relative w-full',
                                ctrClassName,
                            )}
                        >
                            <div
                                className={classNames(
                                    'relative flex w-full items-center gap-x-1 border-b border-gray-500',
                                )}
                            >
                                <Combobox.Input
                                    className={classNames(
                                        'flex-1 py-3 px-3 text-base outline-none sm:text-base',
                                        className,
                                    )}
                                    onChange={handleInput}
                                    placeholder={placeholder}
                                    autoComplete="off"
                                />
                            </div>
                            {hasSuggestions ? (
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-base">
                                        {data.map(
                                            ({ place_id, description }) => (
                                                <Combobox.Option
                                                    key={place_id}
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-4 pr-4 sm:pl-10 ${
                                                            active
                                                                ? 'bg-blue-500 text-white'
                                                                : 'text-gray-900'
                                                        }`
                                                    }
                                                    value={description}
                                                >
                                                    {({ selected }) => (
                                                        <div
                                                            className={`block truncate ${
                                                                selected
                                                                    ? 'font-medium'
                                                                    : 'font-normal'
                                                            }`}
                                                        >
                                                            {description}
                                                        </div>
                                                    )}
                                                </Combobox.Option>
                                            ),
                                        )}
                                    </Combobox.Options>
                                </Transition>
                            ) : null}
                        </div>
                    </Combobox>
                );
            }}
        />
    );
};

export interface PlacesAutocompleteProps<T extends string>
    extends PlacesAutocompleteControllerProps<T> {
    label: string;
    className?: string;
    labelClassName?: string;
    inputCtrClassName?: string;
    inputClassName?: string;
}

export const PlacesAutocomplete = <T extends string = string>({
    name,
    label,
    placeholder,
    className,
    labelClassName,
    inputCtrClassName,
    inputClassName,
}: PlacesAutocompleteProps<T>) => {
    return (
        <WithLabel
            label={label}
            name={name}
            className={className}
            labelClassName={labelClassName}
        >
            <PlacesAutocompleteController
                name={name}
                placeholder={placeholder}
                ctrClassName={inputCtrClassName}
                className={inputClassName}
            />
        </WithLabel>
    );
};
