interface FormSectionHeaderProps {
    title: string;
    subTitle?: string;
}

export const FormSectionHeader = ({
    title,
    subTitle,
}: FormSectionHeaderProps) => {
    return (
        <div className="border-b-[1px] border-b-black pb-2">
            <h2 className="font-excon text-2xl font-semibold">{title}</h2>
            {subTitle ? (
                <span className="text-sm text-gray-700">{subTitle}</span>
            ) : null}
        </div>
    );
};
