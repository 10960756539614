import { ReactNode } from 'react';

export type ButtonColor =
    | 'blue'
    | 'white'
    | 'white-black'
    | 'black'
    | 'warning'
    | 'error'
    | 'clear'
    | 'clear-blue'
    | 'clear-warning';

export interface ButtonProps {
    children: ReactNode;
    className?: string;
    disabled?: boolean;
    type?: 'button' | 'submit';
    color?: ButtonColor;
    size?: 'sm' | 'md' | 'xs';
    onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Button = ({
    type = 'button',
    className = '',
    disabled = false,
    color = 'blue',
    size = 'md',
    children,
    onClick,
}: ButtonProps) => {
    const colorStyles = (
        color:
            | 'black'
            | 'blue'
            | 'white'
            | 'white-black'
            | 'warning'
            | 'error'
            | 'clear'
            | 'clear-blue'
            | 'clear-warning',
    ) => {
        switch (color) {
            case 'black': {
                return 'bg-black text-white hover:bg-gray-800 border border-black disabled:bg-gray-500 disabled:border-gray-500 ring-1 ring-black';
            }
            case 'blue': {
                return 'bg-blue-600 text-white hover:bg-blue-700 border border-blue-600 disabled:bg-gray-400 disabled:border-gray-400';
            }
            case 'white': {
                return 'bg-white text-gray-700 hover:bg-zinc-200 border border-gray-500 disabled:bg-gray-400 disabled:text-white disabled:bg-gray-400 disabled:border-gray-400';
            }
            case 'white-black': {
                return 'bg-white text-black hover:bg-zinc-100 ring-1 ring-black disabled:bg-gray-400 disabled:text-white disabled:border-gray-400';
            }
            case 'warning': {
                return 'bg-yellow-500 text-white hover:bg-yellow-600 border border-yellow-500 disabled:bg-gray-400';
            }
            case 'error': {
                return 'bg-red-500 text-white hover:bg-red-600 border border-red-500 disabled:bg-gray-400 disabled:border-gray-400';
            }
            case 'clear-blue': {
                return 'bg-transparent text-blue-600 hover:text-blue-800 disabled:text-gray-400';
            }
            case 'clear-warning': {
                return 'bg-transparent text-red-500 hover:text-red-600 disabled:text-gray-400';
            }
            case 'clear': {
                return 'bg-transparent text-black hover:text-blue-800 disabled:text-gray-400';
            }
            default: {
                return 'bg-blue-600 text-white hover:bg-blue-700 border border-blue-600 disabled:bg-gray-400';
            }
        }
    };

    const sizeStyles = (size: 'sm' | 'md' | 'xs') => {
        switch (size) {
            case 'md': {
                return 'py-4 px-8';
            }
            case 'xs':
                return 'py-1 px-3 text-xs';
            case 'sm':
            default: {
                return 'py-2 px-5 text-sm';
            }
        }
    };

    return (
        <button
            type={type}
            className={`${className} rounded-smd text-center ${sizeStyles(
                size,
            )} ${colorStyles(color)} transition-all duration-200`}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
};
