import { format, isSameDay } from 'date-fns';
import { TIME_DURATION_OPTIONS } from './time';

export const transformToDate = (input: string) => {
    const parts = input.split('-');
    const formattedInput = `${parts[1]}/${parts[0]}/${parts[2]}`;
    const date = new Date(formattedInput);
    return date;
};

export const getMonday = (input: Date): Date => {
    const date = new Date(input.getTime());
    const day = date.getDay() || 7;
    if (day !== 1) {
        date.setHours(-24 * (day - 1));
    }
    return date;
};

export const getNextMonday = (input: Date): Date => {
    const date = new Date(input.getTime());
    const day = date.getDay();
    const diff = (day === 0 ? 1 : 8 - day) * 24;

    date.setHours(diff, 0, 0, 0);
    return date;
};

export const getLastMonday = (input: Date): Date => {
    const day = input.getDay();
    let diffToLastMonday = day === 0 ? 6 : day - 1;
    diffToLastMonday = diffToLastMonday === 0 ? 7 : diffToLastMonday;

    const lastMonday = new Date(input);
    lastMonday.setDate(input.getDate() - diffToLastMonday);

    return lastMonday;
};

export const getNextDay = (input: Date): Date => {
    const date = new Date(input.getTime());
    date.setDate(date.getDate() + 1);
    return date;
};

export const getPrevDay = (input: Date): Date => {
    const date = new Date(input.getTime());
    date.setDate(date.getDate() - 1);
    return date;
};

export const getNextSevenDates = (date: Date): Date[] => {
    const startDate = getMonday(date);
    const dates: Date[] = [];
    for (let i = 0; i < 7; i++) {
        const nextDate = new Date(startDate);
        nextDate.setDate(startDate.getDate() + i);
        dates.push(nextDate);
    }
    return dates;
};

export const getDayOfWeek = (input: number) => {
    switch (input) {
        case 1:
            return { title: 'Monday', titleShort: 'Mon' };
        case 2:
            return { title: 'Tuesday', titleShort: 'Tue' };
        case 3:
            return { title: 'Wednesday', titleShort: 'Wed' };
        case 4:
            return { title: 'Thurdsay', titleShort: 'Thu' };
        case 5:
            return { title: 'Friday', titleShort: 'Fri' };
        case 6:
            return { title: 'Saturday', titleShort: 'Sat' };
        case 0:
        default:
            return { title: 'Sunday', titleShort: 'Sun' };
    }
};

export const timeIdToTime = (timeId: number, gridCellTypeNumber = 30) => {
    const hours = Math.trunc((timeId * gridCellTypeNumber) / 60);
    const minutes = (timeId * gridCellTypeNumber) % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}`;
};

export const positionToTime = (position: number, gridPixelHeight: number) => {
    // if half hour, should be SchedulerConfig.gridPixelHeight * 2
    const minuteInPixels = (gridPixelHeight * 2) / 60;

    const totalMinutes = Math.round(position / minuteInPixels);

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}`;
};

export const positionToTimeSearchParam = (
    position: number,
    gridPixelHeight: number,
) => {
    // if half hour, should be SchedulerConfig.gridPixelHeight * 2
    const minuteInPixels = (gridPixelHeight * 2) / 60;

    const totalMinutes = Math.round(position / minuteInPixels);

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours.toString().padStart(2, '0')}${minutes
        .toString()
        .padStart(2, '0')}`;
};

export const timeSearchParamToPosition = (
    timeSearchParam: string,
    gridPixelHeight: number,
) => {
    if (timeSearchParam.length !== 4) {
        return null;
    }

    // Split the time string into hours and minutes
    const hours = Number(timeSearchParam[0] + timeSearchParam[1]);
    const minutes = Number(timeSearchParam[2] + timeSearchParam[3]);

    // if half hour, should be SchedulerConfig.gridPixelHeight * 2
    const minuteInPixels = (gridPixelHeight * 2) / 60;

    const totalMinutes = hours * 60 + minutes;

    // Calculate the position based on the total minutes
    const position = totalMinutes * minuteInPixels;

    return position;
};

export const timeSearchParamToTime = (timeSearchParam: string) => {
    if (timeSearchParam.length !== 4) {
        return null;
    }

    return `${timeSearchParam[0]}${timeSearchParam[1]}:${timeSearchParam[2]}${timeSearchParam[3]}`;
};

export const timeToSearchParamTime = (timeSearchParam: string) => {
    if (timeSearchParam.length !== 5) {
        return null;
    }

    return `${timeSearchParam[0]}${timeSearchParam[1]}${timeSearchParam[3]}${timeSearchParam[4]}`;
};

export const timeToPosition = (time: string, gridPixelHeight: number) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = time.split(':').map(Number);

    // if half hour, should be SchedulerConfig.gridPixelHeight * 2
    const minuteInPixels = (gridPixelHeight * 2) / 60;

    const totalMinutes = hours * 60 + minutes;

    // Calculate the position based on the total minutes
    const position = totalMinutes * minuteInPixels;

    return position;
};

export const minutesToPosition = (
    minutes: number,
    gridPixelHeight: number,
): number => {
    // If half hour, should be SchedulerConfig.gridPixelHeight * 2
    const minuteInPixels = (gridPixelHeight * 2) / 60;
    return minutes * minuteInPixels;
};

export const getPosFromTime = (
    start: Date,
    duration: number | undefined,
    pixelSettings: {
        gridPixelHeight: number;
        gridCellTypeNumber: number;
        additionalTop: boolean;
    },
) => {
    if (!duration) {
        return { top: 0, height: 0 };
    }

    const minuteInPixels =
        pixelSettings.gridPixelHeight / pixelSettings.gridCellTypeNumber;
    const scale = 60 / pixelSettings.gridCellTypeNumber;

    const hours = start.getHours();
    const minutes = start.getMinutes();

    const additionalTopPixel = pixelSettings.additionalTop ? 8 : 0;

    return {
        top:
            hours * pixelSettings.gridPixelHeight * scale +
            minutes * minuteInPixels +
            additionalTopPixel,
        height: duration * minuteInPixels,
    };
};

interface PixelSettings {
    gridPixelHeight: number;
    gridCellTypeNumber: number;
}

export const getCurrentTimePos = (
    date: Date,
    pixelSettings: PixelSettings,
    add = 8,
) => {
    const currentDate = new Date();

    if (!isSameDay(currentDate, date)) {
        return null;
    }

    const minuteInPixels =
        pixelSettings.gridPixelHeight / pixelSettings.gridCellTypeNumber;
    const scale = 60 / pixelSettings.gridCellTypeNumber;

    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    return (
        hours * pixelSettings.gridPixelHeight * scale +
        minutes * minuteInPixels +
        add
    );
};

export const getCurrentTimeAndPosition = (
    pixelSettings: PixelSettings,
    add = 8,
) => {
    const currentDate = new Date();

    const dateName = format(currentDate, `dd-MM-yyyy`);

    const minuteInPixels =
        pixelSettings.gridPixelHeight / pixelSettings.gridCellTypeNumber;
    const scale = 60 / pixelSettings.gridCellTypeNumber;

    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    return {
        [dateName]:
            hours * pixelSettings.gridPixelHeight * scale +
            minutes * minuteInPixels +
            add,
    };
};

export const formatDuration = (minutes: number): string => {
    const hours: number = Math.floor(minutes / 60);
    const remainingMinutes: number = minutes % 60;
    // Pad the minutes with a leading zero if less than 10 for proper formatting
    const formattedMinutes: string = remainingMinutes
        .toString()
        .padStart(2, '0');
    return `${hours}:${formattedMinutes}`;
};

export const formatDuration2 = (minutes: number): string => {
    const find = TIME_DURATION_OPTIONS.find((t) => t.value === minutes);
    if (!find) return '';

    return find.label;
};

export const durationToHeight = (
    duration: number,
    pixelSettings: {
        gridPixelHeight: number;
        gridCellTypeNumber: number;
    },
) => {
    const minuteInPixels =
        pixelSettings.gridPixelHeight / pixelSettings.gridCellTypeNumber;

    return duration * minuteInPixels;
};

export const combineDateAndTime = (dateString: string, timeString: string) => {
    const date = new Date(dateString);

    const [hours, minutes] = timeString.split(':').map(Number);
    date.setHours(hours, minutes, 0, 0);

    return date;
};

export const timeToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
};

export const formatDurationToHoursMinutes = (
    minutes: number,
): { hours: number; minutes: number } => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return { hours, minutes: remainingMinutes };
};

export const getDurationDetails = (durationInMinutes: number) => {
    const daysInMonth = 30; // Assuming an average month length

    const days = Math.floor(durationInMinutes / (24 * 60));
    const remainingHours = durationInMinutes % (24 * 60);
    const hours = Math.floor(remainingHours / 60);
    const minutes = remainingHours % 60;

    const months = Math.floor(days / daysInMonth);
    const remainingDays = days % daysInMonth;

    return {
        months,
        days: remainingDays,
        hours,
        minutes,
    };
};
