import { useEffect } from 'react';
import { Path, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Button,
    // Checkbox2,
    DeleteModal,
    FixedHeader,
    Form,
    IconButton,
    IconType,
    PhoneInput,
    SelectInput,
    TextInput,
    // Toggle,
} from '@bookinbio/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { z } from 'zod';

import { useAuth } from '../../../context/AuthContext';
import { useBusiness } from '../../../context/BusinessContext';
import { getProfessionalByBusiness } from '../../../utils/firebase/api/get-professional-business';
import {
    deleteTeamMember,
    updateTeamMember,
} from '../../../utils/firebase/callable/team-members';
import { FormSectionHeader } from '../../FormSectionHeader';

// import { SelectAllCheckbox } from '../services/CreateService';
import { MEMBER_ROLE_OPTIONS } from './CreateTeamMember';

export const EditTeamMemberSchema = z.object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    phoneNumber: z.string().optional(),
    jobTitle: z.string().optional(),
    userRole: z.string(),
    allowBookings: z.boolean(),
});

export type EditTeamMemberForm = z.infer<typeof EditTeamMemberSchema>;
export type EditTeamMemberFormProps = Path<EditTeamMemberForm>;

export const EditTeamMember = () => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const { user } = useAuth();
    const { business, services, businessRefetch, professionalsRefetch } =
        useBusiness();
    const navigate = useNavigate();

    const methods = useForm<EditTeamMemberForm>({
        mode: 'all',
        reValidateMode: 'onSubmit',
        resolver: zodResolver(EditTeamMemberSchema),
        defaultValues: {
            // serviceRefs: [],
            allowBookings: true,
        },
    });
    // const [servRefs] = methods.watch(['serviceRefs']);

    // BE data
    const { data: professional } = useQuery({
        queryKey: ['business-professional', business?.id, id],
        queryFn: () => getProfessionalByBusiness(id),
        enabled: !!id,
    });

    const { mutateAsync, isLoading } = useMutation({
        mutationFn: updateTeamMember,
    });

    const { mutateAsync: deleteTeamMem, isLoading: isDeleting } = useMutation({
        mutationFn: deleteTeamMember,
    });

    // Effects
    useEffect(() => {
        if (professional && services) {
            // const serviceRefs = [];
            // for (const serv of services) {
            //     for (const prof of serv.professionalRefs) {
            //         if (prof.id === professional.id) {
            //             serviceRefs.push(serv.id);
            //         }
            //     }
            // }

            methods.reset({
                ...professional,
                firstName: professional.firstName ?? undefined,
                lastName: professional.lastName ?? undefined,
                // username: professional.username ?? undefined,
                phoneNumber: professional.phoneNumber ?? undefined,
                // email: professional.email ?? undefined,
                jobTitle: professional.jobTitle ?? undefined,
                // serviceRefs: serviceRefs,
            });
        }
    }, [methods, professional, services]);

    // Handlers
    const handleClose = () => {
        navigate('/management/team');
    };

    const handleOpenDeleteModal = () => {
        navigate('delete');
    };

    const handleCloseDeleteModal = () => {
        navigate(-1);
    };

    const handleDeleteTeamMember = async () => {
        if (!id || !business) {
            return;
        }

        try {
            await deleteTeamMem({ userId: id, businessId: business.id });
            handleClose();
            toast.success(t('team.delete.success'));
            await businessRefetch();
            await professionalsRefetch();
        } catch (error) {
            console.error(error);
            toast.error(t('team.delete.error'));
        }
    };

    const handleSubmit = async (data: EditTeamMemberForm) => {
        if (!business || !id) {
            return;
        }

        try {
            await mutateAsync({
                ...data,
                userId: id,
                businessId: business.id,
            });
            toast.success(t('team.update.success'));
            handleClose();
            await professionalsRefetch();
        } catch (error) {
            console.error(error);
            toast.error(t('team.update.error'));
        }
    };

    // const handleSelectAll = () => {
    //     if (services) {
    //         const profLength = services.length;

    //         if (profLength === servRefs.length) {
    //             methods.setValue('serviceRefs', []);
    //         } else {
    //             methods.setValue(
    //                 'serviceRefs',
    //                 services.map((serv) => serv.id),
    //             );
    //         }
    //     }
    // };

    // const selectAllValue = services
    //     ? services.length === servRefs.length
    //         ? 'all'
    //         : servRefs.length > 0
    //         ? 'some'
    //         : 'none'
    //     : 'none';

    return (
        <Form
            methods={methods}
            className="min-h-full w-full"
            onSubmit={handleSubmit}
        >
            <FixedHeader>
                {({ isMoved }) => (
                    <div
                        className={classNames(
                            'fixed top-0 left-0 z-50 flex w-full flex-row-reverse items-center justify-end px-8 pt-6 pb-5 md:h-[88px] md:flex-row md:justify-between',
                            isMoved
                                ? 'shadow-xl backdrop-blur-xl'
                                : 'bg-transparent',
                        )}
                    >
                        <div className="text-right md:flex-1 md:text-left">
                            <IconButton
                                name={IconType.Cross}
                                onClick={handleClose}
                            />
                        </div>
                        <h2
                            className={classNames(
                                'flex-1 text-center text-2xl font-bold tracking-wider',
                                isMoved ? 'visible' : 'invisible',
                            )}
                        >
                            {t('team.edit')}
                        </h2>
                        <div className="hidden flex-1 items-center justify-end gap-x-6 text-right md:flex">
                            {user?.id !== id ? (
                                <Button
                                    type="button"
                                    color="error"
                                    className="rounded-lg !px-12 !py-3 font-bold tracking-wider"
                                    onClick={handleOpenDeleteModal}
                                >
                                    {t('delete')}
                                </Button>
                            ) : null}
                            <Button
                                type="submit"
                                color="black"
                                className="rounded-lg !px-14 !py-3 font-bold tracking-wider"
                                disabled={isLoading}
                            >
                                {t('save')}
                            </Button>
                        </div>
                    </div>
                )}
            </FixedHeader>
            <div className="mx-auto max-w-2xl px-5 pt-10 pb-20 md:pt-[88px] md:pb-[60px]">
                <h1 className="text-left text-3xl font-bold tracking-wider md:text-center">
                    {t('team.edit')}
                </h1>
                <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('basic.information')}
                        subTitle={t('team.basic.information.subtitle')}
                    />
                    <div className="mt-6 grid grid-cols-2 gap-y-4 gap-x-4">
                        <TextInput<EditTeamMemberFormProps>
                            label={t('firstname')}
                            placeholder={t('firstname.placeholder')}
                            name="firstName"
                        />
                        <TextInput<EditTeamMemberFormProps>
                            label={t('lastname')}
                            placeholder={t('lastname.placeholder')}
                            name="lastName"
                        />
                        {/* <TextInput<TeamMemberFormProps>
                            label={t('username')}
                            placeholder={t('username.placeholder')}
                            prefixClassName="text-blue-700"
                            prefix={`/${business?.socialName}/p/`}
                            name="username"
                            className="col-span-2"
                        /> */}
                        {/* <TextInput<EditTeamMemberFormProps>
                            label={t('email')}
                            placeholder={t('email.placeholder')}
                            name="email"
                            className="col-span-2"
                        /> */}
                        <PhoneInput<EditTeamMemberFormProps>
                            label={t('phonenumber.optional')}
                            name="phoneNumber"
                            className="col-span-2"
                        />
                        <TextInput<EditTeamMemberFormProps>
                            label={t('team.jobtitle.optional')}
                            placeholder={t('jobtitle.placeholder')}
                            name="jobTitle"
                        />
                        <SelectInput<EditTeamMemberFormProps>
                            label={t('user.role')}
                            placeholder={t('team.user.role.placeholder')}
                            name="userRole"
                            options={MEMBER_ROLE_OPTIONS}
                        />
                    </div>
                </div>
                {/* <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('team.services.booking')}
                        subTitle={t('team.service.choose')}
                    />
                    <div className="grid grid-cols-1 mt-6 gap-y-7">
                        <div className="flex items-center gap-x-6">
                            <SelectAllCheckbox
                                onClick={handleSelectAll}
                                value={selectAllValue}
                            />
                            <span className="text-lg">{t('select.all')}</span>
                        </div>
                        <div className="grid grid-cols-1 gap-y-2">
                            {services && services.length > 0
                                ? services.map((serv) => (
                                      <div
                                          key={`prof-item-${serv.id}`}
                                          className="flex items-center gap-x-6"
                                      >
                                          <Checkbox2<TeamMemberFormProps>
                                              name="serviceRefs"
                                              ctrClassName="!items-center"
                                              labelClassName="flex items-center gap-x-6"
                                              label={
                                                  <div className="text-lg">
                                                      {
                                                          serv.basicInfo.name
                                                              .default
                                                      }
                                                  </div>
                                              }
                                              value={serv.id}
                                          />
                                      </div>
                                  ))
                                : null}
                        </div>
                        <Toggle<TeamMemberFormProps>
                            name="allowBookings"
                            ctrClassName="flex items-center gap-x-6 mt-4"
                        >
                            <div>{t('allow.calendar.bookings')}</div>
                        </Toggle>
                    </div>
                </div> */}
            </div>
            <div
                className={classNames(
                    'fixed bottom-0 left-0 z-50 grid h-20 w-full grid-cols-2 items-center justify-between gap-x-2 px-5 py-5 shadow-xl backdrop-blur-xl md:hidden',
                )}
            >
                <Button
                    color="error"
                    className="flex-shrink-0 rounded-lg !py-3 font-bold tracking-wide"
                    onClick={handleOpenDeleteModal}
                >
                    {t('delete')}
                </Button>
                <Button
                    type="submit"
                    color="black"
                    className="rounded-lg !py-3 font-bold tracking-wide"
                    disabled={isLoading}
                >
                    {t('save')}
                </Button>
            </div>
            <Routes>
                <Route
                    path="delete"
                    element={
                        <DeleteModal
                            title={t('team.delete')}
                            onDelete={handleDeleteTeamMember}
                            close={handleCloseDeleteModal}
                            isLoading={isDeleting}
                        />
                    }
                />
            </Routes>
        </Form>
    );
};
