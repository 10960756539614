import { Appointment } from '@bookinbio/interface';
import { collection, doc, getDoc } from 'firebase/firestore';

import { businessesColl } from '../collections';

export const getAppointmentById = async (
    businessId?: string,
    id?: string,
): Promise<Appointment> => {
    if (!id || !businessId) {
        throw new Error('Missing appointment id');
    }

    const appointmentsColl = collection(
        businessesColl,
        businessId,
        'appointments',
    );
    const appointmentSnap = await getDoc(doc(appointmentsColl, id));

    if (!appointmentSnap.exists()) {
        throw new Error('Appointment with this id do not exists');
    }

    const appointmentData = appointmentSnap.data() as Appointment;

    return {
        ...appointmentData,
        id: appointmentSnap.id,
    };
};
