import { Business, Category } from '@bookinbio/interface';
import { collection, doc, getDocs } from 'firebase/firestore';

import { businessesColl } from '../collections';

export const getCategoriesByBusiness = async (business?: Business) => {
    if (!business) {
        throw new Error('Missing business');
    }

    const categoriesSnaps = await getDocs(
        collection(doc(businessesColl, business.id), 'categories'),
    );

    return categoriesSnaps.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() } as Category),
    );
};
