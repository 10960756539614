import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PriceType, ServiceType } from '@bookinbio/enums';
import {
    Button,
    Checkbox2,
    CreatableSelectInput,
    DeleteModal,
    FixedHeader,
    Form,
    IconButton,
    IconType,
    SelectInput,
    TextareaInput,
    TextInput,
    Toggle,
} from '@bookinbio/ui';
import {
    stringPriceToUnitAmount,
    TIME_DURATION_OPTIONS,
    unitAmountToString,
} from '@bookinbio/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

import { useBusiness } from '../../../context/BusinessContext';
import { getCategoriesByBusiness } from '../../../utils/firebase/api/get-categories-business';
import { getServiceByBusiness } from '../../../utils/firebase/api/get-service-business';
import { createCategory } from '../../../utils/firebase/callable/category';
import {
    deleteService,
    updateService,
} from '../../../utils/firebase/callable/services';
import { FormSectionHeader } from '../../FormSectionHeader';

import {
    PRICE_TYPE_OPTIONS,
    SelectAllCheckbox,
    SERVICE_TYPE_OPTIONS,
    ServiceForm,
    ServiceFormProps,
    ServiceSchema,
} from './CreateService';

export const EditService = () => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        business,
        professionals: allProfs,
        rents: allRents,
        servicesRefetch,
    } = useBusiness();
    const professionals = useMemo(
        () => (allProfs ? allProfs.filter((p) => p.allowBookings) : []),
        [allProfs],
    );
    const rents = useMemo(
        () => (allRents ? allRents.filter((p) => p.allowBookings) : []),
        [allRents],
    );

    const methods = useForm<ServiceForm>({
        mode: 'all',
        reValidateMode: 'onSubmit',
        resolver: zodResolver(ServiceSchema),
        defaultValues: {
            professionalRefs: [],
            rentRefs: [],
        },
    });
    const [profRefs, rentRefs, priceType, serviceType, hasDayLimitBookings] =
        methods.watch([
            'professionalRefs',
            'rentRefs',
            'priceType',
            'serviceType',
            'hasDayLimitBookings',
        ]);

    // BE data
    const { data: service } = useQuery({
        queryKey: ['business-service', business?.id, id],
        queryFn: () => getServiceByBusiness(business, id),
        enabled: !!business,
    });

    const { refetch: refetchCategories, data: categories } = useQuery({
        queryKey: ['business-categories', business?.id],
        queryFn: () => getCategoriesByBusiness(business),
        enabled: !!business,
    });

    const { mutateAsync, isLoading } = useMutation({
        mutationFn: updateService,
    });

    const { mutateAsync: createCateg, isLoading: isLoadingCategory } =
        useMutation({
            mutationFn: createCategory,
        });

    const { mutateAsync: deleteServ, isLoading: isDeleting } = useMutation({
        mutationFn: deleteService,
    });

    // Options
    const categoryOptions = categories
        ? categories.map((category) => ({
              value: category.id,
              label: category.name.default,
          }))
        : [];

    // Effects
    useEffect(() => {
        if (service) {
            methods.reset({
                ...service.basicInfo,
                name: service.basicInfo.name.default,
                ...service.notificationReminder,
                ...service.serviceSettings,
                hasDayLimitBookings: service.serviceSettings.dayLimitBookings
                    ? true
                    : false,
                dayLimitBookings: service.serviceSettings.dayLimitBookings ?? 5,
                serviceType:
                    service.basicInfo.serviceType ?? ServiceType.Service,
                categoryId: service.basicInfo.categoryRef.id,
                isActiveRebookReminder: service.isActiveRebookReminder,
                timeNotification: service.notificationReminder
                    ? service.notificationReminder.value
                    : 4,
                amount: service.serviceSettings.amount
                    ? unitAmountToString(service.serviceSettings.amount)
                    : undefined,
                professionalRefs: service.professionalRefs.map((p) => p.id),
                rentRefs: service.rentRefs.map((p) => p.id),
            });
        }
    }, [methods, service]);

    // Handlers
    const handleCreateCategory = async (inputName: string) => {
        if (!business) {
            return;
        }

        const result = await createCateg({
            businessId: business.id,
            name: {
                default: inputName,
            },
            description: null,
            color: null,
        });

        await refetchCategories();

        methods.setValue('categoryId', result.data);
    };

    const handleClose = () => {
        navigate('/management/services');
    };

    const handleOpenDeleteModal = () => {
        navigate('delete');
    };

    const handleCloseDeleteModal = () => {
        navigate(-1);
    };

    const handleDeleteService = async () => {
        if (!id || !business) {
            return;
        }

        try {
            await deleteServ({ serviceId: id, businessId: business.id });
            handleClose();
            servicesRefetch();
            toast.success(t('service.delete.success'));
        } catch (error) {
            console.error(error);
            toast.error(t('service.delete.error'));
        }
    };

    const handleSubmit = async (data: ServiceForm) => {
        if (!business || !service) {
            return;
        }

        try {
            await mutateAsync({
                ...data,
                serviceId: service.id,
                basicInfo: {
                    categoryId: data.categoryId,
                    name: {
                        default: data.name,
                    },
                    serviceType: data.serviceType,
                    linkName: data.linkName,
                    description: data.description,
                    afterCareDescription: data.afterCareDescription,
                },
                serviceSettings: {
                    duration: data.duration,
                    priceType: data.priceType,
                    amount:
                        data.priceType === PriceType.Fixed && data.amount
                            ? stringPriceToUnitAmount(data.amount, 'eur')
                            : null,
                    currency: 'eur',
                    isCombinable: data.isCombinable,
                    allowBookings: data.allowBookings,
                    dayLimitBookings: data.hasDayLimitBookings
                        ? data.dayLimitBookings
                        : null,
                },
                professionalRefs:
                    data.serviceType === ServiceType.Service
                        ? data.professionalRefs
                        : [],
                rentRefs:
                    data.serviceType === ServiceType.Rent ? data.rentRefs : [],
                isActiveRebookReminder: data.isActiveRebookReminder,
                notificationReminder:
                    data.isActiveRebookReminder &&
                    data.timeNotification &&
                    data.afterType
                        ? {
                              value: data.timeNotification,
                              afterType: data.afterType,
                          }
                        : null,
                businessId: business.id,
            });

            toast.success(t('service.update.success'));
            handleClose();
            await servicesRefetch();
        } catch (error) {
            console.error(error);
            toast.error(t('service.update.error'));
        }
    };

    const handleSelectAllProfessionals = () => {
        if (professionals) {
            const profLength = professionals.length;

            if (profLength === profRefs.length) {
                methods.setValue('professionalRefs', []);
            } else {
                methods.setValue(
                    'professionalRefs',
                    professionals.map((prof) => prof.id),
                );
            }
        }
    };

    const handleSelectAllRents = () => {
        if (rents) {
            const rentLength = rents.length;

            if (rentLength === rentRefs.length) {
                methods.setValue('rentRefs', []);
            } else {
                methods.setValue(
                    'rentRefs',
                    rents.map((rent) => rent.id),
                );
            }
        }
    };

    const selectAllValue = professionals
        ? professionals.length === profRefs.length
            ? 'all'
            : profRefs.length > 0
            ? 'some'
            : 'none'
        : 'none';

    const selectAllValueRents = rents
        ? rents.filter((prof) => prof.allowBookings).length === rentRefs.length
            ? 'all'
            : rentRefs.length > 0
            ? 'some'
            : 'none'
        : 'none';

    return (
        <Form
            methods={methods}
            className="min-h-full w-full"
            onSubmit={handleSubmit}
        >
            <FixedHeader>
                {({ isMoved }) => (
                    <div
                        className={classNames(
                            'fixed top-0 left-0 z-50 flex w-full flex-row-reverse items-center justify-end px-8 pt-6 pb-5 md:h-[88px] md:flex-row md:justify-between',
                            isMoved
                                ? 'shadow-xl backdrop-blur-xl'
                                : 'bg-transparent',
                        )}
                    >
                        <div className="text-right md:flex-1 md:text-left">
                            <IconButton
                                name={IconType.Cross}
                                onClick={handleClose}
                            />
                        </div>
                        <h2
                            className={classNames(
                                'flex-1 text-center text-2xl font-bold tracking-wider',
                                isMoved ? 'visible' : 'invisible',
                            )}
                        >
                            {t('service.update')}
                        </h2>
                        <div className="hidden flex-1 items-center justify-end gap-x-6 text-right md:flex">
                            <Button
                                type="button"
                                color="error"
                                className="rounded-lg !px-12 !py-3 font-bold tracking-wider"
                                onClick={handleOpenDeleteModal}
                            >
                                {t('delete')}
                            </Button>
                            <Button
                                type="submit"
                                color="black"
                                className="rounded-lg !px-14 !py-3 font-bold tracking-wider"
                                disabled={isLoading}
                            >
                                {t('save')}
                            </Button>
                        </div>
                    </div>
                )}
            </FixedHeader>
            <div className="mx-auto max-w-2xl px-5 pt-10 pb-20 md:pt-[88px] md:pb-[60px]">
                <h1 className="text-left text-3xl font-bold tracking-wider md:text-center">
                    {t('service.update')}
                </h1>
                <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('basic.information')}
                        subTitle={t('service.basic.information.subtitle')}
                    />
                    <div className="mt-6 grid grid-cols-1 gap-y-4">
                        <TextInput<ServiceFormProps>
                            label={t('service.name')}
                            placeholder={t('service.name.placeholder')}
                            name="name"
                            additionalChangeHandler={(val) => {
                                methods.setValue(
                                    'linkName',
                                    val
                                        .replace(/[^a-zA-Z0-9]+/g, '-')
                                        .replace(/^-|-$/g, '')
                                        .toLocaleLowerCase(),
                                );
                                methods.trigger('linkName');
                            }}
                            errorless={false}
                        />
                        <TextInput<ServiceFormProps>
                            label={t('service.link')}
                            prefix={`/${business?.socialName}/`}
                            prefixClassName="text-blue-500"
                            placeholder={t('service.link.placeholder')}
                            name="linkName"
                            errorless={false}
                        />
                        <SelectInput<ServiceFormProps>
                            label={t('service.type')}
                            name="serviceType"
                            options={SERVICE_TYPE_OPTIONS}
                        />
                        <CreatableSelectInput<ServiceFormProps>
                            label={t('category')}
                            placeholder={t('category.placeholder')}
                            name="categoryId"
                            options={categoryOptions}
                            onCreateOption={handleCreateCategory}
                            isLoading={isLoadingCategory}
                        />
                        <TextareaInput<ServiceFormProps>
                            label={t('description.optional')}
                            placeholder={t('description.short.placeholder')}
                            name="description"
                            errorless={false}
                        />
                        <TextareaInput<ServiceFormProps>
                            label={t('service.description.after')}
                            placeholder={t(
                                'service.description.after.placeholder',
                            )}
                            name="afterCareDescription"
                            errorless={false}
                        />
                    </div>
                </div>
                {serviceType === ServiceType.Service ? (
                    <div className="grid grid-cols-1 py-10">
                        <FormSectionHeader
                            title={t('team')}
                            subTitle={t('service.assign.team.member.subtitle')}
                        />
                        <div className="mt-6 grid grid-cols-1 gap-y-4">
                            <div className="flex items-center gap-x-6">
                                <SelectAllCheckbox
                                    onClick={handleSelectAllProfessionals}
                                    value={selectAllValue}
                                />
                                <span className="text-lg">
                                    {t('select.all')}
                                </span>
                            </div>
                            <div className="mt-4 grid grid-cols-1">
                                {professionals && professionals.length > 0
                                    ? professionals.map((prof) => (
                                          <div
                                              key={`prof-item-${prof.id}`}
                                              className="flex items-center gap-x-6"
                                          >
                                              <Checkbox2<ServiceFormProps>
                                                  name="professionalRefs"
                                                  ctrClassName="!items-center"
                                                  labelClassName="flex items-center gap-x-6"
                                                  label={
                                                      <>
                                                          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-black text-white">
                                                              {prof.firstName &&
                                                              prof.lastName
                                                                  ? prof.firstName[0].toLocaleUpperCase() +
                                                                    prof.lastName[0].toLocaleUpperCase()
                                                                  : 'U'}
                                                          </div>
                                                          <div>
                                                              {prof.fullName}
                                                          </div>
                                                      </>
                                                  }
                                                  value={prof.id}
                                              />
                                          </div>
                                      ))
                                    : null}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 py-10">
                        <FormSectionHeader
                            title={t('rentals')}
                            subTitle={t('service.assign.rental.subtitle')}
                        />
                        <div className="mt-6 grid grid-cols-1 gap-y-4">
                            <div className="flex items-center gap-x-6">
                                <SelectAllCheckbox
                                    onClick={handleSelectAllRents}
                                    value={selectAllValueRents}
                                />
                                <span className="text-lg">
                                    {t('select.all')}
                                </span>
                            </div>
                            <div className="mt-4 grid grid-cols-1 gap-y-4">
                                {rents && rents.length > 0
                                    ? rents.map((rent) => (
                                          <div
                                              key={`rent-item-${rent.id}`}
                                              className="flex items-center gap-x-6"
                                          >
                                              <Checkbox2<ServiceFormProps>
                                                  name="rentRefs"
                                                  ctrClassName="!items-center"
                                                  labelClassName="flex items-center gap-x-6"
                                                  label={<div>{rent.name}</div>}
                                                  value={rent.id}
                                              />
                                          </div>
                                      ))
                                    : null}
                            </div>
                        </div>
                    </div>
                )}
                <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('service.price.duration')}
                        subTitle={t('service.price.duration.subtitle')}
                    />
                    <div className="mt-6 grid grid-cols-1 gap-y-4">
                        <SelectInput<ServiceFormProps>
                            label={t('price.type')}
                            name="priceType"
                            options={PRICE_TYPE_OPTIONS}
                        />
                        {priceType === PriceType.Fixed ? (
                            <TextInput<ServiceFormProps>
                                label={t('price')}
                                name="amount"
                                placeholder={`15.99`}
                                type="number"
                                step={0.01}
                                prefix="€"
                            />
                        ) : null}
                        <SelectInput<ServiceFormProps>
                            label={t('service.duration')}
                            name={`duration`}
                            options={TIME_DURATION_OPTIONS}
                        />
                        {/* {serviceType === ServiceType.Service ? (
                            <Checkbox<ServiceFormProps>
                                name="isCombinable"
                                label={t('service.combinable')}
                                ctrClassName="mt-3"
                            />
                        ) : null} */}
                        <Toggle<ServiceFormProps>
                            name="allowBookings"
                            ctrClassName="flex items-center gap-x-6 mt-4"
                        >
                            <div>{t('allow.calendar.bookings')}</div>
                        </Toggle>
                        <Toggle<ServiceFormProps>
                            name="hasDayLimitBookings"
                            ctrClassName="flex items-center gap-x-6 mt-4"
                        >
                            <div>{t('has.day.limit.bookings')}</div>
                        </Toggle>
                        {hasDayLimitBookings ? (
                            <TextInput<ServiceFormProps>
                                name="dayLimitBookings"
                                type="number"
                                min={1}
                                asType="number"
                                label={t('day.limit.bookings')}
                            />
                        ) : null}
                    </div>
                </div>
                {/* <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('notifications')}
                        subTitle={t('service.automated.message')}
                    />
                    <div className="grid grid-cols-2 mt-6 gap-x-4 gap-y-4">
                        <Checkbox<ServiceFormProps>
                            name="isActiveRebookReminder"
                            ctrClassName="col-span-2"
                            label={t('reminder.rebook')}
                        />
                        {isActiveRebookReminder ? (
                            <>
                                <TextInputController<ServiceFormProps>
                                    name="timeNotification"
                                    type="number"
                                    min={1}
                                    asType="number"
                                />
                                <SelectInputController<ServiceFormProps>
                                    name="afterType"
                                    options={NOTIFICATION_DURATION_OPTIONS}
                                />
                            </>
                        ) : null}
                    </div>
                </div> */}
            </div>
            <div
                className={classNames(
                    'fixed bottom-0 left-0 z-50 grid h-20 w-full grid-cols-2 items-center justify-between gap-x-2 px-5 py-5 shadow-xl backdrop-blur-xl md:hidden',
                )}
            >
                <Button
                    color="error"
                    className="flex-shrink-0 rounded-lg !py-3 font-bold tracking-wide"
                    onClick={handleOpenDeleteModal}
                >
                    {t('delete')}
                </Button>
                <Button
                    type="submit"
                    color="black"
                    className="rounded-lg !py-3 font-bold tracking-wide"
                    disabled={isLoading}
                >
                    {t('save')}
                </Button>
            </div>
            <Routes>
                <Route
                    path="delete"
                    element={
                        <DeleteModal
                            isLoading={isDeleting}
                            title={t('service.delete')}
                            onDelete={handleDeleteService}
                            close={handleCloseDeleteModal}
                        />
                    }
                />
            </Routes>
        </Form>
    );
};
