import { UserRole } from '@bookinbio/enums';
import { Business } from '@bookinbio/interface';
import { doc, getDocs, query, where } from 'firebase/firestore';

import { businessesColl, usersColl } from '../collections';

export const getBusinessByMember = async (
    userId?: string,
    role?: UserRole,
): Promise<Business> => {
    if (!userId) {
        throw new Error('Missing user id');
    }

    const userRef = doc(usersColl, userId);

    let q;
    if (role === UserRole.SuperAdmin) {
        q = query(businessesColl, where('ownerRef', '==', userRef));
    } else {
        q = query(
            businessesColl,
            where('memberRefs', 'array-contains', userRef),
        );
    }

    const businessSnaps = await getDocs(q);

    if (businessSnaps.empty) {
        throw new Error('No business found for the provided user.');
    }

    return { id: businessSnaps.docs[0].id, ...businessSnaps.docs[0].data() };
};
