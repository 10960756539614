import { useState } from 'react';
import { RecurringUpdateOption } from '@bookinbio/enums';
import { Button, Modal } from '@bookinbio/ui';
import { t } from 'i18next';

import { RadioBox } from '../RadioBox';

interface UpdateConfirmModalProps {
    isUpdating: boolean;
    close: () => void;
    onUpdate: (option: RecurringUpdateOption) => void;
}

export const UpdateConfirmModal = ({
    isUpdating,
    close,
    onUpdate,
}: UpdateConfirmModalProps) => {
    const [deleteOption, setUpdateOption] = useState<RecurringUpdateOption>(
        RecurringUpdateOption.OnlySelected,
    );

    return (
        <Modal isOpen={true} close={close}>
            <h2
                className={'flex-1 text-left text-2xl font-bold tracking-wider'}
            >
                {t('appointment.update')}
            </h2>
            <div className="my-10 flex flex-col gap-y-6 md:my-8">
                <p className="text-left">{t('appointment.update.warning')}</p>
                <div className="flex flex-col gap-y-4">
                    <RadioBox
                        checked={
                            deleteOption === RecurringUpdateOption.OnlySelected
                        }
                        onClick={() =>
                            setUpdateOption(RecurringUpdateOption.OnlySelected)
                        }
                        label={t('update.appointment.only')}
                    />
                    <RadioBox
                        checked={
                            deleteOption ===
                            RecurringUpdateOption.SelectedAndFuture
                        }
                        onClick={() =>
                            setUpdateOption(
                                RecurringUpdateOption.SelectedAndFuture,
                            )
                        }
                        label={t('update.appointment.future')}
                    />
                    <RadioBox
                        checked={deleteOption === RecurringUpdateOption.All}
                        onClick={() =>
                            setUpdateOption(RecurringUpdateOption.All)
                        }
                        label={t('update.appointment.all')}
                    />
                </div>
            </div>
            <div className="fixed bottom-0 left-0 flex h-24 w-full items-center justify-center gap-x-4 px-6 py-6 md:relative md:h-auto md:w-auto md:justify-end md:px-0 md:py-0">
                <Button
                    type="button"
                    color="white-black"
                    size="sm"
                    className="h-full flex-1 md:h-auto md:flex-none"
                    onClick={close}
                >
                    {t('close')}
                </Button>
                <Button
                    type="button"
                    color="error"
                    size="sm"
                    className="h-full flex-1 md:h-auto md:flex-none"
                    onClick={() => onUpdate(deleteOption)}
                    disabled={isUpdating}
                >
                    {t('save.changes')}
                </Button>
            </div>
        </Modal>
    );
};
