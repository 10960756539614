import { httpsCallable } from 'firebase/functions';

import { functions } from '../../firebase.config';

type CreateCategorytArgs = {
    businessId: string;

    name: Record<string, string>;
    description: string | null;
    color: string | null;
};

export const createCategory = httpsCallable<CreateCategorytArgs, string>(
    functions,
    'createCategory',
);

type UpdateCategoryArgs = {
    businessId: string;
    categoryId: string;

    name: Record<string, string>;
    description: string | null;
    color: string | null;
};

export const updateCategory = httpsCallable<UpdateCategoryArgs, string>(
    functions,
    'updateCategory',
);

type DeleteCategoryArgs = {
    businessId: string;
    categoryId: string;
};

export const deleteCategory = httpsCallable<DeleteCategoryArgs, string>(
    functions,
    'deleteCategory',
);
