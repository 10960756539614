import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Button,
    DeleteModal,
    FixedHeader,
    Form,
    IconButton,
    IconType,
    TextareaInput,
    TextInput,
    Toggle,
} from '@bookinbio/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

import { useBusiness } from '../../../context/BusinessContext';
import { getRentByBusiness } from '../../../utils/firebase/api/get-rent-business';
import { deleteRent, updateRent } from '../../../utils/firebase/callable/rent';
import { FormSectionHeader } from '../../FormSectionHeader';
import { DaySettings } from '../../settings/working-hours/DaySettings';
import { DAY_ARRAY } from '../../settings/working-hours/WorkingHours';

import { RentForm, RentFormProps, RentSchema } from './CreateRent';

export const EditRent = () => {
    const { id: rentId } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const { business, rentsRefetch } = useBusiness();
    const navigate = useNavigate();

    const methods = useForm<RentForm>({
        mode: 'all',
        reValidateMode: 'onSubmit',
        resolver: zodResolver(RentSchema),
    });

    // BE data
    const { data: rent } = useQuery({
        queryKey: ['business-rent', business?.id, rentId],
        queryFn: () => getRentByBusiness(business, rentId),
        enabled: !!business && !!rentId,
    });

    const { mutateAsync, isLoading } = useMutation({
        mutationFn: updateRent,
    });

    const { mutateAsync: delRent, isLoading: isDeleting } = useMutation({
        mutationFn: deleteRent,
    });

    // Effects
    useEffect(() => {
        if (rent) {
            methods.reset({
                name: rent.name,
                description: rent.description,
                allowBookings: rent.allowBookings,
                workingHours: rent.workingHours,
            });
        }
    }, [methods, rent]);

    // Handlers
    const handleClose = () => {
        navigate('/management/rents');
    };

    const handleOpenDeleteModal = () => {
        navigate('delete');
    };

    const handleCloseDeleteModal = () => {
        navigate(-1);
    };

    const handleDeleteRent = async () => {
        if (!rentId || !business) {
            return;
        }

        try {
            await delRent({ rentId, businessId: business.id });
            await rentsRefetch();
            toast.success(t('rental.delete.success'));
            handleClose();
        } catch (error) {
            console.error(error);
            toast.error(t('rental.delete.error'));
        }
    };

    const handleSubmit = async (data: RentForm) => {
        if (!business || !rentId) {
            return;
        }

        try {
            await mutateAsync({
                ...data,
                rentId,
                businessId: business.id,
            });
            await rentsRefetch();
            toast.success(t('rental.update.success'));
            handleClose();
        } catch (error) {
            console.error(error);
            toast.error(t('rental.update.error'));
        }
    };

    return (
        <Form
            methods={methods}
            className="min-h-full w-full"
            onSubmit={handleSubmit}
        >
            <FixedHeader>
                {({ isMoved }) => (
                    <div
                        className={classNames(
                            'fixed top-0 left-0 z-50 flex w-full flex-row-reverse items-center justify-end px-8 pt-6 pb-5 md:h-[88px] md:flex-row md:justify-between',
                            isMoved
                                ? 'shadow-xl backdrop-blur-xl'
                                : 'bg-transparent',
                        )}
                    >
                        <div className="text-right md:flex-1 md:text-left">
                            <IconButton
                                name={IconType.Cross}
                                onClick={handleClose}
                            />
                        </div>
                        <h2
                            className={classNames(
                                'flex-1 text-center text-2xl font-bold tracking-wider',
                                isMoved ? 'visible' : 'invisible',
                            )}
                        >
                            {t('rental.edit')}
                        </h2>
                        <div className="hidden flex-1 items-center justify-end gap-x-6 text-right md:flex">
                            <Button
                                type="button"
                                color="error"
                                className="rounded-lg !px-12 !py-3 font-bold tracking-wider"
                                onClick={handleOpenDeleteModal}
                            >
                                {t('delete')}
                            </Button>
                            <Button
                                type="submit"
                                color="black"
                                className="rounded-lg !px-14 !py-3 font-bold tracking-wider"
                                disabled={isLoading}
                            >
                                {t('save')}
                            </Button>
                        </div>
                    </div>
                )}
            </FixedHeader>
            <div className="mx-auto max-w-2xl px-5 pt-10 pb-20 md:pt-[88px] md:pb-[60px]">
                <h1 className="text-left text-3xl font-bold tracking-wider md:text-center">
                    {t('rental.edit')}
                </h1>
                <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('basic.information')}
                        subTitle={t('rental.basic.information.subtitle')}
                    />
                    <div className="mt-6 grid grid-cols-1 gap-y-4">
                        <TextInput<RentFormProps>
                            label={t('rental.name')}
                            placeholder={t('rental.name.placeholder')}
                            name="name"
                        />
                        <TextareaInput<RentFormProps>
                            label={t('description.optional')}
                            placeholder={t('rental.description.placeholder')}
                            name="description"
                        />
                    </div>
                </div>
                <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('booking.settings')}
                        subTitle={t('rental.booking.settings.subtitle')}
                    />
                    <Toggle<RentFormProps>
                        name="allowBookings"
                        ctrClassName="flex items-center gap-x-6 mt-4"
                    >
                        <div>{t('allow.calendar.bookings')}</div>
                    </Toggle>
                    <div
                        key={rent?.id}
                        className="mt-6 grid grid-cols-1 gap-y-4"
                    >
                        {DAY_ARRAY.map((el) => (
                            <DaySettings
                                key={`${el}-day-setting`}
                                name={`workingHours.${el}`}
                                day={el}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div
                className={classNames(
                    'fixed bottom-0 left-0 z-50 grid h-20 w-full grid-cols-2 items-center justify-between gap-x-2 px-5 py-5 shadow-xl backdrop-blur-xl md:hidden',
                )}
            >
                <Button
                    color="error"
                    className="flex-shrink-0 rounded-lg !py-3 font-bold tracking-wide"
                    onClick={handleOpenDeleteModal}
                >
                    {t('delete')}
                </Button>
                <Button
                    type="submit"
                    color="black"
                    className="rounded-lg !py-3 font-bold tracking-wide"
                    disabled={isLoading}
                >
                    {t('save')}
                </Button>
            </div>
            <Routes>
                <Route
                    path="delete"
                    element={
                        <DeleteModal
                            title={t('rental.delete')}
                            onDelete={handleDeleteRent}
                            close={handleCloseDeleteModal}
                            isLoading={isDeleting}
                        />
                    }
                />
            </Routes>
        </Form>
    );
};
