import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Notification } from '@bookinbio/interface';
import { Button, Icon, IconButton, IconType } from '@bookinbio/ui';
import { format } from 'date-fns';
import {
    doc,
    limit,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
    where,
} from 'firebase/firestore';

import { useAuth } from '../../context/AuthContext';
import { notificationsColl, usersColl } from '../../utils/firebase/collections';
import { SideDrawerContainer } from '../SideDrawerContainer';

export const NotificationsDropdown = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [unreadCount, setUnreadCount] = useState<number>(0);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!user) {
            return;
        }

        const userDoc = doc(usersColl, user.id);

        const notificationsQuery = query(
            notificationsColl,
            where('receiverRefs', 'array-contains', userDoc),
            orderBy('createdAt', 'desc'),
            limit(50),
        );

        const unsubscribe = onSnapshot(notificationsQuery, (snapshot) => {
            const newNotifications: Notification[] = [];
            let count = 0;
            snapshot.docs.forEach((doc) => {
                const notification = {
                    ...doc.data(),
                    id: doc.id,
                } as unknown as Notification;

                if (user && !notification.seenIds.includes(user.id)) {
                    count++;
                }

                newNotifications.push(notification);
            });
            setUnreadCount(count);
            setNotifications(newNotifications);
        });

        return () => unsubscribe();
    }, [user]);

    const handleReadNotification = async () => {
        try {
            const promises: Promise<void>[] = [];
            notifications.forEach((noti) => {
                if (user && !noti.seenIds.includes(user.id)) {
                    const notiRef = doc(notificationsColl, noti.id);
                    promises.push(
                        updateDoc(notiRef, {
                            seenIds: [...noti.seenIds, user.id],
                        }),
                    );
                }
            });
            await Promise.all(promises);
            setUnreadCount(0);
        } catch (error) {
            console.error('There was an error updating seendIds', error);
        }
    };

    const handleOpenDrawer = async () => {
        setIsOpen(true);
        await handleReadNotification();
    };

    const handleOpenAppointment = (id: string) => {
        setIsOpen(false);
        navigate(`${location.pathname}/appointment/${id}${location.search}`);
    };

    return (
        <>
            <Button
                className="relative flex h-10 w-10 items-center justify-center rounded !p-0 !text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-100 hover:!text-black"
                size="sm"
                color="clear"
                onClick={handleOpenDrawer}
            >
                <Icon name={IconType.Notification} size={28} />
                {unreadCount ? (
                    <span
                        style={{
                            top: -8,
                            right: -8,
                        }}
                        className="absolute flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-xs text-white"
                    >
                        {unreadCount < 10 ? unreadCount : '+' + unreadCount}
                    </span>
                ) : null}
            </Button>
            <SideDrawerContainer isOpen={isOpen}>
                <div className="mb-3 flex items-center gap-x-10">
                    <IconButton
                        name={IconType.Cross}
                        onClick={() => setIsOpen(false)}
                        size={20}
                    />
                    <h3 className="font-excon text-2xl font-[600] tracking-wider">
                        {t('notification.center')}
                    </h3>
                </div>
                <div className="overflow-y-auto">
                    {notifications.length > 0 ? (
                        <div className="divide-y">
                            {notifications.map((noti) => (
                                <NotificationItem
                                    key={noti.id}
                                    notification={noti}
                                    onClick={() =>
                                        handleOpenAppointment(
                                            noti.details.appointmentId,
                                        )
                                    }
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="h-10 p-2 text-center text-gray-500">
                            {t('notification.empty')}
                        </div>
                    )}
                </div>
            </SideDrawerContainer>
        </>
    );
};

interface NotificationItemProps {
    notification: Notification;
    onClick: () => void;
}

const NotificationItem = ({ notification, onClick }: NotificationItemProps) => {
    return (
        <button
            type="button"
            className="flex w-full flex-col gap-y-4 px-1 py-4 hover:bg-gray-100"
            onClick={onClick}
        >
            <div className="flex w-full justify-between">
                <span className="font-medium">{notification.title}</span>
                <span className="text-sm text-gray-500">
                    {format(notification.createdAt.toDate(), 'HH:mm, dd.MM')}
                </span>
            </div>
            <div className="overflow-auto text-left text-sm text-gray-700">
                {notification.message}
            </div>
        </button>
    );
};
