import { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { getNestedError } from './utils';

interface CheckboxControllerProps<T extends string> {
    name: T;
    label: string | ReactNode;
    placeholder?: string;
    className?: string;
    ctrClassName?: string;
    labelClassName?: string;
    errorless?: boolean;
}

export const Checkbox = <T extends string = string>({
    name,
    label,
    placeholder,
    className,
    errorless,
    ctrClassName,
    labelClassName,
}: CheckboxControllerProps<T>) => {
    const { t } = useTranslation();
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const error = getNestedError(errors, name);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => {
                return (
                    <div className={ctrClassName}>
                        <div
                            className={classNames(
                                'flex w-full items-center justify-start gap-x-3',
                            )}
                        >
                            <div>
                                <div
                                    className={classNames(
                                        'h-6 w-6 cursor-pointer rounded border border-black p-[3px] text-gray-500',
                                        className,
                                    )}
                                    onClick={() => onChange(!value)}
                                >
                                    <div
                                        className={classNames(
                                            'h-full w-full rounded',
                                            value ? 'bg-black' : 'bg-white',
                                        )}
                                    ></div>
                                </div>
                                <input
                                    type="checkbox"
                                    className="hidden"
                                    defaultChecked={value}
                                    onChange={onChange}
                                    placeholder={placeholder}
                                />
                            </div>
                            <div className={classNames(labelClassName)}>
                                {label}
                            </div>
                        </div>
                        {!errorless && error ? (
                            <p
                                className={classNames(
                                    'h-3 text-left text-xs text-red-700',
                                )}
                            >
                                {t(error.message as string)}
                            </p>
                        ) : null}
                    </div>
                );
            }}
        />
    );
};

interface Checkbox2ControllerProps<T extends string> {
    name: T;
    label: string | ReactNode;
    value: string;
    placeholder?: string;
    className?: string;
    ctrClassName?: string;
    labelClassName?: string;
}

export const Checkbox2 = <T extends string = string>({
    name,
    label,
    value,
    placeholder,
    className,
    ctrClassName,
    labelClassName,
}: Checkbox2ControllerProps<T>) => {
    const { control } = useFormContext();

    const handleCheck = (values: string[], value: string) => {
        if (values.includes(value)) {
            return values.filter((i) => i !== value);
        } else {
            const newValues = [...values];
            newValues.push(value);
            return newValues;
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value: arrValue, onChange } }) => {
                const isThere = arrValue.includes(value);
                return (
                    <div
                        className={classNames(
                            'flex w-full items-center justify-start gap-x-6',
                            ctrClassName,
                        )}
                    >
                        <div>
                            <button
                                className={classNames(
                                    'h-6 w-6 cursor-pointer rounded border border-black p-[3px] text-gray-500',
                                    className,
                                )}
                                type="button"
                                onClick={() =>
                                    onChange(handleCheck(arrValue, value))
                                }
                            >
                                <div
                                    className={classNames(
                                        'h-full w-full rounded',
                                        isThere ? 'bg-black' : 'bg-white',
                                    )}
                                ></div>
                            </button>
                            <input
                                type="checkbox"
                                className="hidden"
                                checked={isThere}
                                onChange={onChange}
                                placeholder={placeholder}
                            />
                        </div>
                        <div className={classNames(labelClassName)}>
                            {label}
                        </div>
                    </div>
                );
            }}
        />
    );
};
