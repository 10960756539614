import { httpsCallable } from 'firebase/functions';

import { functions } from '../../firebase.config';

type CreateTeamMemberArgs = {
    businessId: string;

    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    email: string;

    jobTitle?: string;

    serviceRefs: string[];
    allowBookings: boolean;
};

export const createTeamMember = httpsCallable<CreateTeamMemberArgs>(
    functions,
    'createTeamMember',
);

type UpdateTeamMemberArgs = {
    businessId: string;
    userId: string;

    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    // email: string;

    jobTitle?: string;

    // serviceRefs: string[];
    allowBookings: boolean;
};

export const updateTeamMember = httpsCallable<UpdateTeamMemberArgs>(
    functions,
    'editTeamMember',
);

type DeleteClientArgs = {
    businessId: string;
    userId: string;
};

export const deleteTeamMember = httpsCallable<DeleteClientArgs>(
    functions,
    'deleteTeamMember',
);

type AcceptInviteArgs = {
    token: string;
    password: string;
};

export const acceptTeamInvite = httpsCallable<AcceptInviteArgs>(
    functions,
    'acceptTeamInvite',
);
