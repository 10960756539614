import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { RecurringUpdateOption } from '@bookinbio/enums';
import { Button, Modal } from '@bookinbio/ui';
import { t } from 'i18next';

import { RadioBox } from '../RadioBox';

interface DeleteConfirmModalProps {
    isDeleting: boolean;
    isMultiple: boolean;
    close: () => void;
    onDelete: (id: string, option: RecurringUpdateOption) => void;
}

export const CancelConfirmModal = ({
    isDeleting,
    isMultiple,
    close,
    onDelete,
}: DeleteConfirmModalProps) => {
    const { id } = useParams<{ id: string }>();
    const [deleteOption, setDeleteOption] = useState<RecurringUpdateOption>(
        RecurringUpdateOption.OnlySelected,
    );

    if (!id) {
        return;
    }

    return (
        <Modal isOpen={true} close={close}>
            <h2
                className={'flex-1 text-left text-2xl font-bold tracking-wider'}
            >
                {t('appointment.cancel')}
            </h2>
            <div className="my-10 flex flex-col gap-y-6 md:my-8">
                <p className="text-left">{t('cancel.appointment.warning')}</p>
                {isMultiple ? (
                    <div className="flex flex-col gap-y-4">
                        <RadioBox
                            checked={
                                deleteOption ===
                                RecurringUpdateOption.OnlySelected
                            }
                            onClick={() =>
                                setDeleteOption(
                                    RecurringUpdateOption.OnlySelected,
                                )
                            }
                            label={t('cancel.appointment.only')}
                        />
                        <RadioBox
                            checked={
                                deleteOption ===
                                RecurringUpdateOption.SelectedAndFuture
                            }
                            onClick={() =>
                                setDeleteOption(
                                    RecurringUpdateOption.SelectedAndFuture,
                                )
                            }
                            label={t('cancel.appointment.future')}
                        />
                        <RadioBox
                            checked={deleteOption === RecurringUpdateOption.All}
                            onClick={() =>
                                setDeleteOption(RecurringUpdateOption.All)
                            }
                            label={t('cancel.appointment.all')}
                        />
                    </div>
                ) : null}
            </div>
            <div className="fixed bottom-0 left-0 flex h-24 w-full items-center justify-center gap-x-4 px-6 py-6 md:relative md:h-auto md:w-auto md:justify-end md:px-0 md:py-0">
                <Button
                    type="button"
                    color="white-black"
                    size="sm"
                    className="h-full flex-1 md:h-auto md:flex-none"
                    onClick={close}
                >
                    {t('close')}
                </Button>
                <Button
                    type="button"
                    color="error"
                    size="sm"
                    className="h-full flex-1 md:h-auto md:flex-none"
                    onClick={() => onDelete(id, deleteOption)}
                    disabled={isDeleting}
                >
                    {t('cancel')}
                </Button>
            </div>
        </Modal>
    );
};
