import { ReactNode } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

export interface FormProps<T extends FieldValues> {
    children: ReactNode;
    methods: UseFormReturn<T>;
    className?: string;
    onSubmit?: (data: T) => void;
}

export const Form = <T extends FieldValues>({
    children,
    methods,
    className,
    onSubmit,
}: FormProps<T>) => {
    const { handleSubmit } = methods;

    return (
        <FormProvider {...methods}>
            <form
                className={className}
                onSubmit={handleSubmit((data) => onSubmit && onSubmit(data))}
            >
                {children}
            </form>
        </FormProvider>
    );
};
