import { httpsCallable } from 'firebase/functions';

import { functions } from '../../firebase.config';

type CreateLocationArgs = {
    businessId: string;
    name: string;
    address: string;
    description?: string;
};

export const createLocation = httpsCallable<CreateLocationArgs>(
    functions,
    'createLocation',
);

type UpdateLocationArgs = {
    locationId: string;
    businessId: string;
    name: string;
    address: string;
    description?: string;
};

export const updateLocation = httpsCallable<UpdateLocationArgs>(
    functions,
    'updateLocation',
);

type DeleteLocationArgs = {
    businessId: string;
    locationId: string;
};

export const deleteLocation = httpsCallable<DeleteLocationArgs>(
    functions,
    'deleteLocation',
);

type UpdateMainLocationArgs = {
    locationId: string;
    businessId: string;
};

export const updateMainLocation = httpsCallable<UpdateMainLocationArgs>(
    functions,
    'updateMainLocation',
);

type UpdateOrderLocationArgs = {
    locationId: string;
    businessId: string;
    order: number;
};

export const updateOrderLocation = httpsCallable<UpdateOrderLocationArgs>(
    functions,
    'updateOrderLocation',
);
