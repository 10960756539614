import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { useSidebar } from '../../context/SidebarContext';

export type NavItemType = 'link' | 'menu';

export interface NavItem {
    id: string;
    type: NavItemType;
    title: string;
    to: string;
    subItems?: NavItem[];
    isProd: boolean;
    requestsCount?: number;
}

interface MobileSideNavbarItemProps {
    item: NavItem;
    className?: string;
}

export const MobileSideNavbarItem = ({
    item,
    className,
}: MobileSideNavbarItemProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { close } = useSidebar();

    if (item.type === 'link') {
        return (
            <NavLink to={{ pathname: item.to }} onClick={close}>
                {({ isActive }) => (
                    <div
                        className={classNames(
                            'group flex items-center gap-6 text-2xl font-medium text-white',
                            className,
                        )}
                    >
                        <span
                            className={classNames(
                                'h-1 w-5 transition-all duration-200 group-hover:bg-gray-300',
                                isActive ? 'bg-white' : 'bg-transparent',
                            )}
                        ></span>
                        <span
                            className={classNames(
                                isActive ? 'text-white' : 'text-gray-400',
                                'relative transition-all duration-200 group-hover:text-gray-300',
                            )}
                        >
                            {item.title}
                            {item.requestsCount ? (
                                <span className="absolute right-[-20px] top-[-6px] flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-xs text-white">
                                    {item.requestsCount}
                                </span>
                            ) : null}
                        </span>
                    </div>
                )}
            </NavLink>
        );
    }

    return (
        <div>
            <button
                type="button"
                className={classNames(
                    'group flex items-center gap-6 text-2xl font-medium text-gray-400',
                )}
                onClick={() => setIsOpen((p) => !p)}
            >
                <span
                    className={classNames(
                        'h-1 w-5 transition-all duration-200 group-hover:bg-gray-300',
                    )}
                ></span>
                <span
                    className={classNames(
                        'transition-all duration-200 group-hover:text-gray-300',
                    )}
                >
                    {item.title}
                </span>
            </button>
            {isOpen ? (
                <div className="mt-6 ml-4 flex flex-col gap-y-4">
                    {item.subItems?.map((item) => (
                        <MobileSideNavbarItem item={item} className="text-xl" />
                    ))}
                </div>
            ) : null}
        </div>
    );
};
