import { FunctionComponent, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

export interface RouteTabsProps {
    children: ReactNode;
    className?: string;
}

export const RouteTabs = ({ children, className }: RouteTabsProps) => {
    return (
        <ul className={classNames('flex gap-x-2 sm:gap-x-4', className)}>
            {children}
        </ul>
    );
};

export interface RouteTabProps {
    to: string;
    children: FunctionComponent<{ isActive: boolean }> | ReactNode;
}

RouteTabs.Tab = ({ to, children }: RouteTabProps) => {
    return (
        <li>
            <NavLink to={to}>
                {({ isActive }) =>
                    typeof children === 'function'
                        ? children({ isActive })
                        : children
                }
            </NavLink>
        </li>
    );
};

export interface DefaultTabContentProps {
    isActive: boolean;
    children: ReactNode;
}

RouteTabs.DefaultTabContent = ({
    isActive,
    children,
}: DefaultTabContentProps) => {
    return (
        <div className="flex flex-col">
            {children}
            <span
                className={classNames(
                    'mx-auto mt-1 h-1 w-6 rounded-sm',
                    isActive ? 'bg-black' : 'bg-transparent',
                )}
            ></span>
        </div>
    );
};
