import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    Client,
    DayNumbers,
    DaySetting,
    GroupedGoogleEvents,
    Rent,
    Service,
    User,
} from '@bookinbio/interface';
import { SelectOption } from '@bookinbio/ui';
import { transformToDate } from '@bookinbio/utils';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import { useAuth } from '../../context/AuthContext';
import { useBusiness } from '../../context/BusinessContext';
import { useMobile } from '../../context/MobileContext';
import {
    ExtendedAppointments,
    getAppointmentsByWeekProfessional,
} from '../../utils/firebase/api/get-appointment-week-professional';
import { getCategoriesByBusiness } from '../../utils/firebase/api/get-categories-business';
import { getClientsByBusiness } from '../../utils/firebase/api/get-clients-business';
import {
    getOverridesByBusiness,
    GroupedOverrides,
} from '../../utils/firebase/api/get-overrides-business';
import { getServicesByBusiness } from '../../utils/firebase/api/get-services-business';
import { getWorkingHoursByStartDate } from '../../utils/firebase/api/get-working-hours';
import { getUsersWeekGoogleEvents } from '../../utils/firebase/callable';

import { HoverState } from './HoverItem';

export interface TempAppointmentProps {
    time: number | null;
    duration: number | null;
    isOpen: boolean;
    date: string | null;
    service: Service | null;
    client: Client | null;
    notes?: string;
}

export enum ViewType {
    DayView = 'day',
    WeekView = 'week',
}

export interface CalendarViewOptions {
    label: string;
    value: ViewType;
}

interface ServiceOption extends SelectOption {
    duration: number;
}

interface CalendarContextProps {
    hover: HoverState | null;
    setHover: (hover: HoverState | null) => void;

    appointments?: ExtendedAppointments;
    googleEvents?: GroupedGoogleEvents;
    overrides?: GroupedOverrides;

    currentRent?: Rent | null;
    currentProfessional?: User | null;
    currentWeekWorkingHours?: Record<DayNumbers, DaySetting> | null;

    refetchAppointments: () => Promise<void>;
    refetchOverrides: () => Promise<void>;
    refetchCategories: () => Promise<void>;
    refetchServices: () => Promise<void>;
    refetchClients: () => Promise<void>;

    serviceOptions: ServiceOption[];

    categoryOptions: SelectOption[];

    services?: Service[];
    isServicesLoading: boolean;

    clients?: Client[];
    isClientsLoading: boolean;

    currentDate: Date;
}

const CalendarContext = createContext<CalendarContextProps>({
    hover: null,
    setHover: (hover: HoverState | null) => {
        console.log('AppointmentContext [setHover]', hover);
    },

    serviceOptions: [],
    categoryOptions: [],

    refetchCategories: () => {
        return new Promise(() => {
            console.log('AppointmentContext [refetchCategories]');
        });
    },

    refetchClients: () => {
        return new Promise(() => {
            console.log('AppointmentContext [refetchClients]');
        });
    },

    refetchOverrides: () => {
        return new Promise(() => {
            console.log('AppointmentContext [refetchOverrides]');
        });
    },

    refetchServices: () => {
        return new Promise(() => {
            console.log('AppointmentContext [refetchServices]');
        });
    },

    isClientsLoading: false,
    isServicesLoading: false,

    currentDate: new Date(),

    refetchAppointments: () => {
        return new Promise(() => {
            console.log('AppointmentContext [refetchAppointments]');
        });
    },
});

export const CALENDAR_VIEW_OPTIONS: CalendarViewOptions[] = [
    {
        label: 'day.view',
        value: ViewType.DayView,
    },
    {
        label: 'week.view',
        value: ViewType.WeekView,
    },
];

export const useCalendar = () => useContext(CalendarContext);

interface AppointmentProviderProps {
    children: ReactNode;
}

export const AppointmentsProvider = ({
    children,
}: AppointmentProviderProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const viewType = searchParams.get('view') ?? ViewType.WeekView;
    const dateString =
        searchParams.get('date') ?? format(new Date(), 'dd-MM-yyyy');
    const transformedDate = transformToDate(dateString);
    const professionalId = searchParams.get('professional_id');
    const rentId = searchParams.get('rent_id');

    const { isMobileView: isMobile } = useMobile();
    const { user } = useAuth();
    const { business, rents, professionals } = useBusiness();

    const currentRent = rents ? rents.find((rent) => rent.id === rentId) : null;
    const currentProfessional = professionals
        ? professionals.find(
              (professional) => professional.id === professionalId,
          )
        : null;

    const [hover, setHover] = useState<HoverState | null>(null);

    // BE data
    const { data: appointments, refetch: refetchAppointments } = useQuery({
        queryKey: [
            'professional-appointments',
            business?.id,
            professionalId,
            rentId,
            transformedDate,
        ],
        queryFn: () =>
            getAppointmentsByWeekProfessional(
                transformedDate,
                business,
                professionalId,
                rentId,
            ),
        enabled: !!business && (!!professionalId || !!rentId),
    });

    // TODO: Add check if current user is the same as selected professional
    const { data: googleEvents } = useQuery({
        queryKey: ['user-google-events', transformedDate, user?.id],
        queryFn: () =>
            getUsersWeekGoogleEvents({
                userId: user?.id,
                date: transformedDate.toISOString(),
            }),
        enabled: !!business && !!user,
    });

    const { data: categories, refetch: refetchCategories } = useQuery({
        queryKey: ['business-categories', business?.id],
        queryFn: () => getCategoriesByBusiness(business),
        enabled: !!business,
    });

    const {
        refetch: refetchServices,
        data: services,
        isLoading: isServicesLoading,
    } = useQuery({
        queryKey: ['business-services', business],
        queryFn: () => getServicesByBusiness(business),
        enabled: !!business,
    });

    const {
        refetch: refetchClients,
        data: clients,
        isLoading: isClientsLoading,
    } = useQuery({
        queryKey: ['business-clients', business],
        queryFn: () => getClientsByBusiness(business),
        enabled: !!business,
    });

    const { data: overrides, refetch: refetchOverrides } = useQuery({
        queryKey: [
            'business-overrides',
            business,
            professionalId,
            rentId,
            transformedDate,
        ],
        queryFn: () =>
            getOverridesByBusiness(
                transformedDate,
                business,
                professionalId,
                rentId,
            ),
        enabled: !!business && (!!professionalId || !!rentId),
    });

    const { data: workingHours } = useQuery({
        queryKey: ['professional-working-hours', dateString, professionalId],
        queryFn: () => getWorkingHoursByStartDate(dateString, professionalId),
        enabled: !!professionalId,
    });

    // Options
    const serviceOptions = services
        ? services.map((service) => ({
              value: service.id,
              label: service.basicInfo.name.default,
              duration: service.serviceSettings.duration,
          }))
        : [];

    const categoryOptions = categories
        ? categories.map((category) => ({
              value: category.id,
              label: category.name.default,
          }))
        : [];

    // Refetches
    const handleRefetchServices = async () => {
        refetchServices();
    };
    const handleRefetchClients = async () => {
        refetchClients();
    };
    const handleRefetchCategories = async () => {
        refetchCategories();
    };
    const handleRefetchAppointments = async () => {
        refetchAppointments();
    };
    const handleRefetchOverrides = async () => {
        refetchOverrides();
    };

    const handleHoverState = (hover: HoverState | null) => {
        setHover(hover);
    };

    useEffect(() => {
        if (isMobile && viewType !== ViewType.DayView) {
            searchParams.set('view', ViewType.DayView);
            setSearchParams(searchParams);
        }
    }, [isMobile, searchParams, setSearchParams, viewType]);

    return (
        <CalendarContext.Provider
            value={{
                hover,
                setHover: handleHoverState,

                appointments,
                googleEvents: googleEvents?.data,

                currentRent,
                currentProfessional,
                currentWeekWorkingHours: workingHours,

                overrides,

                categoryOptions,

                isServicesLoading,
                services,
                serviceOptions,

                isClientsLoading,
                clients,

                currentDate: transformedDate,

                refetchOverrides: handleRefetchOverrides,
                refetchCategories: handleRefetchCategories,
                refetchServices: handleRefetchServices,
                refetchClients: handleRefetchClients,
                refetchAppointments: handleRefetchAppointments,
            }}
        >
            {children}
        </CalendarContext.Provider>
    );
};
