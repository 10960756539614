import { Override } from '@bookinbio/interface';
import { collection, doc, getDoc } from 'firebase/firestore';

import { businessesColl } from '../collections';

export const getOverrideById = async (
    businessId?: string,
    id?: string,
): Promise<Override> => {
    if (!id || !businessId) {
        throw new Error('Missing override id');
    }

    const overridesColl = collection(businessesColl, businessId, 'overrides');

    const overrideSnap = await getDoc(doc(overridesColl, id));

    if (!overrideSnap.exists()) {
        throw new Error('Override with this id do not exists');
    }

    const appointmentData = overrideSnap.data() as Override;

    return {
        ...appointmentData,
        id: overrideSnap.id,
    };
};
