import { AppointmentStatusType, UserRole } from '@bookinbio/enums';
import { Appointment, Business, Rent, User } from '@bookinbio/interface';
import { format } from 'date-fns';
import {
    collection,
    doc,
    DocumentData,
    getDocs,
    query,
    QuerySnapshot,
    where,
} from 'firebase/firestore';

import { businessesColl, usersColl } from '../collections';

export type ExtendedPendindAppointments = Record<string, Appointment[]>;

export const getRequestsByProfessional = async (
    business?: Business,
    professional?: User[] | null,
    rents?: Rent[] | null,
    currentUser?: User | null,
    selectedUser?: User | null,
) => {
    if (!business || !currentUser) {
        throw new Error('Error occured while fetching appointments');
    }

    const appointmentsColl = collection(
        businessesColl,
        business.id,
        'appointments',
    );

    if (currentUser.userRole === UserRole.Member) {
        const profRef = doc(usersColl, currentUser.id);

        const pendingProfApptsQuery = query(
            appointmentsColl,
            where('professionalRefs', 'array-contains', profRef),
            where('status', '==', AppointmentStatusType.Pending),
        );

        const requestSnapshots = await getDocs(pendingProfApptsQuery);

        const extendedAppointments: ExtendedPendindAppointments = {};

        for (const pendingAppt of requestSnapshots.docs) {
            if (!pendingAppt.exists()) {
                continue;
            }

            const appointmentData = pendingAppt.data() as Omit<
                Appointment,
                'id'
            >;

            const dateName = format(
                appointmentData.start.toDate(),
                'dd-MM-yyyy',
            );

            extendedAppointments[dateName] =
                extendedAppointments[dateName] || [];
            extendedAppointments[dateName].push({
                ...appointmentData,
                id: pendingAppt.id,
            });
        }

        return extendedAppointments;
    }

    if (selectedUser) {
        const profRef = doc(usersColl, selectedUser.id);
        const pendingProfApptsQuery = query(
            appointmentsColl,
            where('professionalRefs', 'array-contains', profRef),
            where('status', '==', AppointmentStatusType.Pending),
        );

        const requestSnapshots = await getDocs(pendingProfApptsQuery);
        const extendedAppointments: ExtendedPendindAppointments = {};

        for (const pendingAppt of requestSnapshots.docs) {
            if (!pendingAppt.exists()) {
                continue;
            }

            const appointmentData = pendingAppt.data() as Omit<
                Appointment,
                'id'
            >;

            const dateName = format(
                appointmentData.start.toDate(),
                'dd-MM-yyyy',
            );

            extendedAppointments[dateName] =
                extendedAppointments[dateName] || [];
            extendedAppointments[dateName].push({
                ...appointmentData,
                id: pendingAppt.id,
            });
        }

        return extendedAppointments;
    }

    const pendingProfApptsQuery = query(
        appointmentsColl,
        where('status', '==', AppointmentStatusType.Pending),
    );

    const promises: Promise<QuerySnapshot<DocumentData, DocumentData>>[] = [];
    promises.push(getDocs(pendingProfApptsQuery));

    // TODO: Change to SUPERADMIN and ADMIN
    if (
        (currentUser.userRole === UserRole.SuperAdmin ||
            currentUser.userRole === UserRole.Admin) &&
        rents &&
        rents.length > 0
    ) {
        const rentsColl = collection(doc(businessesColl, business.id), 'rents');
        for (const rent of rents) {
            const rentRef = doc(rentsColl, rent.id);
            const rentsApptsQuery = query(
                appointmentsColl,
                where('rentRefs', 'array-contains', rentRef),
                where('status', '==', AppointmentStatusType.Pending),
            );

            promises.push(getDocs(rentsApptsQuery));
        }
    }

    const requestSnapshots = await Promise.all(promises);

    const allRequests = [];
    for (const snapshots of requestSnapshots) {
        if (!snapshots.empty) {
            allRequests.push(...snapshots.docs);
        }
    }

    const extendedAppointments: ExtendedPendindAppointments = {};
    const requestIds: string[] = [];
    // Process each appointment
    for (const pendingAppt of allRequests) {
        if (!pendingAppt.exists() || requestIds.includes(pendingAppt.id)) {
            continue;
        }

        // TODO: Add filter for professional and rents
        const appointmentData = pendingAppt.data() as Omit<Appointment, 'id'>;

        const dateName = format(appointmentData.start.toDate(), 'dd-MM-yyyy');

        extendedAppointments[dateName] = extendedAppointments[dateName] || [];
        extendedAppointments[dateName].push({
            ...appointmentData,
            id: pendingAppt.id,
        });
        requestIds.push(pendingAppt.id);
    }

    return extendedAppointments;
};

export const getTotalAppointmentCount = (
    requests?: ExtendedPendindAppointments,
): number => {
    if (!requests) {
        return 0;
    }

    let totalCount = 0;
    for (const date in requests) {
        totalCount += requests[date].length;
    }
    return totalCount;
};
