import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Appointment } from '@bookinbio/interface';
import { format, formatDistance } from 'date-fns';

interface RequestItemProps {
    appointment: Appointment;
}

export const RequestItem = ({ appointment }: RequestItemProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const openInfoDrawer = () => {
        navigate(`appointment/${appointment.id}`);
    };

    const servicesList = appointment.serviceDatas.reduce(
        (acc, curr, index) =>
            index === 0
                ? curr.basicInfo.name.default
                : acc + curr.basicInfo.name.default,
        '',
    );

    return (
        <button
            className="flex w-full cursor-pointer items-center gap-x-4 rounded p-1 py-3 hover:bg-gray-100"
            onClick={openInfoDrawer}
        >
            <div className="flex flex-1 flex-col text-left md:flex-[3] md:flex-row">
                <div className="flex-1">
                    {appointment.clientDatas
                        ? appointment.clientDatas[0]?.fullName
                        : t('undefined')}
                </div>
                <div className="text-clas flex-1 text-sm text-gray-500 md:text-base md:text-black">
                    {servicesList}
                </div>
            </div>

            <div className="flex-1 text-right md:text-left">
                {format(appointment.start.toDate(), 'HH:mm')}
            </div>
            <div className="hidden flex-1 text-left md:block">
                {formatDistance(appointment.createdAt.toDate(), new Date(), {
                    addSuffix: true,
                })}
            </div>
        </button>
    );
};
