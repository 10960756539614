import { ReactNode, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { Controller, useFormContext } from 'react-hook-form';
import {
    autoUpdate,
    flip,
    FloatingFocusManager,
    offset,
    Placement,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole,
} from '@floating-ui/react';
import classNames from 'classnames';
import { format } from 'date-fns';

import { getNestedError } from './utils';
import { WithLabel } from './WithLabel';

export interface DatePickerInputControllerProps<T extends string> {
    name: T;
    placeholder?: string;
    errorClassName?: string;
    fromYear?: number;
    toYear?: number;
    disabled?: boolean;
    onChange?: (date?: Date) => void;
}

export const DatePickerInputController = <T extends string = string>({
    name,
    placeholder = 'Select a date',
    fromYear = 1900,
    toYear = new Date().getFullYear(),
    onChange: onAdditionalChange,
    disabled,
}: DatePickerInputControllerProps<T>) => {
    const [isOpen, setIsOpen] = useState(false);
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            offset(10),
            flip({ fallbackAxisSideDirection: 'end' }),
            shift(),
        ],
        placement: 'left-start',
        whileElementsMounted: autoUpdate,
    });

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        dismiss,
        role,
    ]);

    const error = getNestedError(errors, name);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                return (
                    <>
                        <button
                            type="button"
                            className={classNames(
                                'w-full border-b px-3 py-3 text-left text-base outline-none disabled:text-gray-400 sm:text-base',
                                value ? 'text-black' : 'text-gray-400',
                                error ? 'border-red-500' : 'border-gray-500',
                            )}
                            ref={refs.setReference}
                            {...getReferenceProps()}
                            disabled={disabled}
                        >
                            {value
                                ? format(value, 'dd.MM.yyyy (E)')
                                : placeholder}
                        </button>
                        {isOpen && (
                            <FloatingFocusManager
                                context={context}
                                modal={false}
                            >
                                <div
                                    ref={refs.setFloating}
                                    style={floatingStyles}
                                    {...getFloatingProps()}
                                    className="z-50 w-80 rounded-lg bg-white shadow-lg"
                                >
                                    <DayPicker
                                        mode="single"
                                        onSelect={(date) => {
                                            onChange(date);
                                            onAdditionalChange &&
                                                onAdditionalChange(date);
                                        }}
                                        selected={value}
                                        captionLayout="dropdown-buttons"
                                        fromYear={fromYear}
                                        toYear={toYear}
                                        className="!m-0 w-full"
                                        classNames={{
                                            months: 'flex w-full',
                                            month: 'rdp-month w-full',
                                            table: 'w-full table-fixed',
                                            head_row: 'h-full w-full flex',
                                            head_cell:
                                                'leading-7 text-sm font-normal flex-1',
                                            day_selected:
                                                '!bg-black !text-white',
                                            row: 'h-full w-full flex',
                                            cell: 'flex justify-center items-center flex-1 text-sm',
                                        }}
                                        fixedWeeks
                                        showOutsideDays
                                        weekStartsOn={1}
                                        formatters={{
                                            formatWeekdayName: (
                                                date,
                                                options,
                                            ) => {
                                                return format(
                                                    date,
                                                    'E',
                                                    options,
                                                );
                                            },
                                        }}
                                    />
                                </div>
                            </FloatingFocusManager>
                        )}
                    </>
                );
            }}
        />
    );
};

export interface DatePickerInputProps<T extends string>
    extends DatePickerInputControllerProps<T> {
    label: string;
    className?: string;
    labelClassName?: string;
    inputCtrClassName?: string;
    inputClassName?: string;
    right?: ReactNode;
}

export const DatePickerInput = <T extends string = string>({
    name,
    label,
    placeholder,
    className,
    labelClassName,
    right,
    fromYear,
    toYear,
    disabled,
    onChange,
}: DatePickerInputProps<T>) => {
    return (
        <WithLabel
            label={label}
            name={name}
            className={className}
            labelClassName={labelClassName}
            right={right}
        >
            <DatePickerInputController
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                fromYear={fromYear}
                toYear={toYear}
                disabled={disabled}
            />
        </WithLabel>
    );
};

interface CustomDatePickerProps {
    placeholder?: string;
    value: Date;
    fromYear?: number;
    toYear?: number;
    placement?: Placement;
    onChange: (date: Date) => void;
}

export const CustomDatePicker = ({
    placeholder = 'Select a date',
    value,
    placement = 'left-start',
    fromYear = 1900,
    toYear = new Date().getFullYear(),
    onChange,
}: CustomDatePickerProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            offset(10),
            flip({ fallbackAxisSideDirection: 'end' }),
            shift(),
        ],
        placement: placement,
        whileElementsMounted: autoUpdate,
    });

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        dismiss,
        role,
    ]);

    return (
        <>
            <button
                type="button"
                className={classNames(
                    'w-full border-b border-gray-700 px-0 py-1 text-left text-base outline-none sm:px-3 sm:py-3 sm:text-base',
                    value ? 'text-black' : 'text-gray-400',
                )}
                ref={refs.setReference}
                {...getReferenceProps()}
            >
                {value ? format(value, 'dd.MM.yyyy') : placeholder}
            </button>
            {isOpen && (
                <FloatingFocusManager context={context} modal={false}>
                    <div
                        ref={refs.setFloating}
                        style={floatingStyles}
                        {...getFloatingProps()}
                        className="z-50 w-80 rounded-lg bg-white shadow-lg"
                    >
                        <DayPicker
                            mode="single"
                            onSelect={(d) => d && onChange(d)}
                            selected={value}
                            captionLayout="dropdown-buttons"
                            fromYear={fromYear}
                            toYear={toYear}
                            className="!m-0 w-full"
                            classNames={{
                                months: 'flex w-full',
                                month: 'rdp-month w-full',
                                table: 'w-full table-fixed',
                                head_row: 'h-full w-full flex',
                                head_cell:
                                    'leading-7 text-sm font-normal flex-1',
                                day_selected: '!bg-black !text-white',
                                row: 'h-full w-full flex',
                                cell: 'flex justify-center items-center flex-1 text-sm',
                            }}
                            fixedWeeks
                            showOutsideDays
                            weekStartsOn={1}
                            formatters={{
                                formatWeekdayName: (date, options) => {
                                    return format(date, 'E', options);
                                },
                            }}
                        />
                    </div>
                </FloatingFocusManager>
            )}
        </>
    );
};
