import { NotificationsDropdown } from './NotificationsDrawer';
import { ProfileDropdown } from './ProfileDropdown';

export const ProfileRow = () => {
    return (
        <div className="hidden items-center justify-between gap-x-8 md:flex">
            <NotificationsDropdown />
            <ProfileDropdown />
        </div>
    );
};
