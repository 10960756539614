import { DayNumbers, DaySetting } from '@bookinbio/interface';
import { httpsCallable } from 'firebase/functions';

import { functions } from '../../firebase.config';

type CreateRentArgs = {
    businessId: string;
    name: string;
    description?: string;
    allowBookings: boolean;
    workingHours: Record<DayNumbers, DaySetting>;
};

export const createRent = httpsCallable<CreateRentArgs>(
    functions,
    'createRent',
);

type UpdateRentArgs = {
    rentId: string;
    businessId: string;
    name: string;
    description?: string;
    allowBookings: boolean;
    workingHours: Record<DayNumbers, DaySetting>;
};

export const updateRent = httpsCallable<UpdateRentArgs>(
    functions,
    'updateRent',
);

type DeleteRentArgs = {
    businessId: string;
    rentId: string;
};

export const deleteRent = httpsCallable<DeleteRentArgs>(
    functions,
    'deleteRent',
);
