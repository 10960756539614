import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Client } from '@bookinbio/interface';
import { Button, Icon, IconButton, IconType, Switch } from '@bookinbio/ui';
import classNames from 'classnames';

import { AppointmentForm } from './NewAppointmentDrawer';

interface ClientSelectProps {
    clients?: Client[];
    isLoading: boolean;
    close: () => void;
    onSelect?: (id: string) => void;
}

export const ClientSearchSelect = ({
    clients,
    isLoading,
    close,
    onSelect,
}: ClientSelectProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [search, setSearch] = useState<string>('');
    const { control } = useFormContext<AppointmentForm>();
    const {
        field: { onChange, value: values },
    } = useController({ name: 'clientIds', control });

    const filteredClients = clients
        ? clients.filter((client) => {
              if (!client.lastName) {
                  return client.firstName
                      .toLocaleLowerCase()
                      .includes(search.toLocaleLowerCase());
              }

              return (
                  client.firstName
                      .toLocaleLowerCase()
                      .includes(search.toLocaleLowerCase()) ||
                  client.lastName
                      .toLocaleLowerCase()
                      .includes(search.toLocaleLowerCase())
              );
          })
        : undefined;

    const handleClientSelect = (value: string) => {
        let newValues = values ? [...values] : [];
        if (newValues.includes(value)) {
            newValues = newValues.filter((i) => i !== value);
        } else {
            newValues.push(value);
        }

        onChange(newValues.length === 0 ? null : newValues);
    };

    return (
        <div className="flex flex-col gap-y-4">
            <div>
                <IconButton
                    name={IconType.ChevronLeft}
                    onClick={close}
                    size={28}
                />
                <div className="mt-3 flex items-center justify-between">
                    <h3 className="text-2xl font-bold tracking-wider">
                        {t('client.select')}
                    </h3>
                </div>
            </div>
            <div className="flex w-full items-center gap-x-2">
                <div className="flex flex-1 rounded-lg px-4 py-2 text-base ring-1 ring-black sm:text-sm">
                    <input
                        placeholder={t('client.search')}
                        type="search"
                        className="flex-1 bg-transparent text-base outline-none"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <Button
                    size="sm"
                    className="flex h-[40px] items-center gap-x-2 !rounded-lg"
                    color="white-black"
                    onClick={() => navigate('/clients/create')}
                >
                    <Icon
                        name={IconType.Plus}
                        size={14}
                        className="leading-4"
                    />
                    <span className="leading-4">{t('addnew')}</span>
                </Button>
            </div>
            <Switch>
                <Switch.Case condition={isLoading}>
                    <div>{t('loading')}</div>
                </Switch.Case>
                <Switch.Case
                    condition={!!filteredClients && filteredClients.length > 0}
                >
                    <div className="grid w-full grid-cols-1 gap-y-1">
                        {filteredClients?.map((client) => {
                            return (
                                <div
                                    className="flex w-full items-center gap-x-4 rounded-lg px-1 py-2 hover:bg-gray-100"
                                    key={`client-item-${client.id}`}
                                >
                                    <Checkbox
                                        checked={
                                            values
                                                ? values.includes(client.id)
                                                : false
                                        }
                                        onClick={() => {
                                            handleClientSelect(client.id);
                                            onSelect && onSelect(client.id);
                                        }}
                                    />
                                    <button
                                        type="button"
                                        className="flex w-full items-center gap-x-4"
                                        onClick={() => {
                                            handleClientSelect(client.id);
                                            onSelect && onSelect(client.id);
                                            close();
                                        }}
                                    >
                                        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-black text-white">
                                            {`${client.firstName[0].toUpperCase()}${
                                                client.lastName
                                                    ? client.lastName[0].toUpperCase()
                                                    : ''
                                            }`}
                                        </div>
                                        <div>
                                            {`${client.firstName} ${client.lastName}`}
                                        </div>
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </Switch.Case>
                <Switch.Default>
                    <div className="pt-3 text-gray-500">
                        {t('client.empty')}
                    </div>
                </Switch.Default>
            </Switch>
        </div>
    );
};

interface CheckboxProps {
    className?: string;
    checked: boolean;
    onClick: () => void;
}

export const Checkbox = ({ className, checked, onClick }: CheckboxProps) => {
    return (
        <div>
            <button
                className={classNames(
                    'group h-6 w-6 cursor-pointer rounded border border-black bg-white p-[3px] text-gray-500 transition duration-300',
                    className,
                )}
                type="button"
                onClick={onClick}
            >
                <div
                    className={classNames(
                        'h-full w-full rounded',
                        checked
                            ? 'bg-black group-hover:bg-gray-800'
                            : 'bg-white group-hover:bg-gray-400',
                    )}
                ></div>
            </button>
            <input
                type="checkbox"
                className="hidden"
                checked={checked}
                onChange={onClick}
            />
        </div>
    );
};
