import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';
import {
    Route,
    Routes,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    AppointmentHandleOption,
    AppointmentStatusType,
    EndType,
    FrequencyType,
    RecurringUpdateOption,
    RepeatType,
} from '@bookinbio/enums';
import {
    Button,
    DatePickerInput,
    DropdownMenu,
    Form,
    IconButton,
    IconType,
    SelectInput,
    SelectInputController,
    Switch,
    TextareaInput,
    TextInputController,
    WithLabel,
} from '@bookinbio/ui';
import {
    combineDateAndTime,
    END_TYPE_OPTIONS,
    FREQUENCY_TYPE_OPTIONS,
    REPEAT_TYPE_OPTIONS,
    TIME_OPTIONS,
    unitAmountToPrice,
} from '@bookinbio/utils';
import { Tab } from '@headlessui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { addMonths, addYears, format } from 'date-fns';

import { useBusiness } from '../../context/BusinessContext';
import { approveRequest } from '../../utils/firebase/api/approve-request';
import { declineRequest } from '../../utils/firebase/api/decline-request';
import { getAppointmentById } from '../../utils/firebase/api/get-appointment-by-id';
import { getClientsByBusiness } from '../../utils/firebase/api/get-clients-business';
import {
    cancelAppointment,
    updateAppointment,
} from '../../utils/firebase/callable';
import { useCalendar } from '../calendar/AppointmentsContext';
import { ClientSearchSelect } from '../calendar/sidebar/ClientSearchSelect';
import {
    AppointmentForm,
    AppointmentFormProps,
    AppointmentSchema,
    ClientCard,
    ServiceCard,
} from '../calendar/sidebar/NewAppointmentDrawer';
import { ServiceSearchSelect } from '../calendar/sidebar/ServicesSearchSelect';
import { DaysCheckbox } from '../CheckboxDays';
import { DefaultTab } from '../DefaultTab';
import { SideDrawerContainer } from '../SideDrawerContainer';

import { CancelConfirmModal } from './AppointmentCancelModal';
import { UpdateConfirmModal } from './AppointmentUpdateModal';
import { GuardModal } from './GuardModal';

interface AppointmentDrawerProps {
    close: () => void;
    onApprove?: () => void;
    onDecline?: () => void;
}

export const AppointmentDrawer = ({
    close,
    onApprove,
    onDecline,
}: AppointmentDrawerProps) => {
    const { t } = useTranslation();
    const { id: appointmentId } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { business, services, professionals, locations } = useBusiness();
    const { isServicesLoading, refetchAppointments } = useCalendar();
    const [isServicesShowing, setIsServicesShowing] = useState(false);
    const [isClientsShowing, setIsClientsShowing] = useState(false);

    const methods = useForm<AppointmentForm>({
        mode: 'all',
        reValidateMode: 'onChange',
        resolver: zodResolver(AppointmentSchema),
    });
    const [clientIds, serviceIds, repeatType, endType, frequencyType] =
        methods.watch([
            'clientIds',
            'serviceIds',
            'repeatType',
            'endType',
            'customRepeatSettings.frequencyType',
        ]);

    // BE
    const {
        data: appointment,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['business-appointment', business, appointmentId],
        queryFn: () => getAppointmentById(business?.id, appointmentId),
        enabled: !!appointmentId && !!business,
    });

    const { data: clients, isLoading: isClientsLoading } = useQuery({
        queryKey: ['business-clients', business],
        queryFn: () => getClientsByBusiness(business),
        enabled: !!business,
    });

    const { mutateAsync, isLoading: isUpdating } = useMutation({
        mutationFn: updateAppointment,
    });

    const { mutateAsync: cancelAppt, isLoading: isCancelling } = useMutation({
        mutationFn: cancelAppointment,
    });

    const { mutateAsync: declineReqst, isLoading: isDeclining } = useMutation({
        mutationFn: declineRequest,
    });

    const { mutateAsync: approveReqst, isLoading: isApproving } = useMutation({
        mutationFn: approveRequest,
    });

    useEffect(() => {
        if (appointment) {
            const startTime = format(appointment.start.toDate(), 'HH:mm');
            methods.reset({
                serviceIds: appointment.serviceRefs.map(
                    (service) => service.id,
                ),
                clientIds: appointment.clientRefs
                    ? appointment.clientRefs.map((client) => client.id)
                    : undefined,
                professionalIds: appointment.professionalRefs
                    ? appointment.professionalRefs.map((prof) => prof.id)
                    : undefined,
                rentIds: appointment.rentRefs
                    ? appointment.rentRefs.map((prof) => prof.id)
                    : undefined,
                date: appointment.start.toDate(),
                startTime: startTime,
                repeatType: appointment.repeatType,
                endType: appointment.repeatSettings?.endType,
                endDate:
                    appointment.repeatSettings?.end?.toDate() ??
                    addMonths(new Date(), 1),
                occurenceValue: appointment.repeatSettings?.occurenceValue ?? 5,
                customRepeatSettings: {
                    value:
                        appointment.repeatSettings?.customRepeatSettings
                            ?.value ?? 1,
                    days: appointment.repeatSettings?.customRepeatSettings
                        ?.days ?? [1, 3, 5],
                    frequencyType:
                        appointment.repeatSettings?.customRepeatSettings
                            ?.frequencyType ?? FrequencyType.Week,
                },
                notes: appointment.notes,
            });
        }
    }, [appointment, clients, methods, professionals, services]);

    const selectedClients =
        clients && clientIds
            ? clients.filter((client) => clientIds.includes(client.id))
            : null;

    const selectedServices =
        services && serviceIds
            ? services.filter((service) => serviceIds.includes(service.id))
            : null;

    const total = selectedServices
        ? selectedServices.reduce(
              (acc, curr) => acc + (curr.serviceSettings.amount ?? 0),
              0,
          )
        : 0;

    const duration = selectedServices
        ? selectedServices.reduce(
              (acc, curr) => acc + curr.serviceSettings.duration,
              0,
          )
        : 0;
    // Handlers
    const handleModalClose = () => {
        navigate(-1);
    };

    const handleOpenCancelModal = () => {
        navigate(`cancel?${searchParams.toString()}`);
    };

    const handleOpenUpdateModal = () => {
        navigate(`confirm?${searchParams.toString()}`);
    };

    const handleOpenApproveModal = () => {
        navigate(`approve?${searchParams.toString()}`);
    };

    const handleOpenDeclineModal = () => {
        navigate(`decline?${searchParams.toString()}`);
    };

    const handleSubmit = async () => {
        if (!appointmentId) {
            return;
        }

        const data = methods.getValues();

        if (appointment?.repeatType === RepeatType.DoesNotRepeat) {
            if (data.repeatType === RepeatType.DoesNotRepeat) {
                await handleUpdateAppointment(
                    RecurringUpdateOption.OnlySelected,
                );
            } else {
                await handleUpdateAppointment(RecurringUpdateOption.All);
            }
        } else {
            handleOpenUpdateModal();
        }
    };

    const handleCancelAppointment = async (
        id: string,
        option: RecurringUpdateOption,
    ) => {
        if (!business) {
            return;
        }
        try {
            await cancelAppt({
                businessId: business.id,
                appointmentId: id,
                cancelOption: option,
                purpose: AppointmentHandleOption.ActionByBusiness,
            });
            toast.success(t('appointment.cancel.success'));
            close();
            refetchAppointments();
        } catch (error) {
            console.error(error);
            toast.error(t('appointment.cancel.error'));
        }
    };

    const handleUpdateAppointment = async (option: RecurringUpdateOption) => {
        if (
            !business ||
            !appointmentId ||
            !appointment ||
            !selectedServices ||
            !locations
        ) {
            return;
        }
        const data = methods.getValues();

        const start = combineDateAndTime(
            data.date.toISOString(),
            data.startTime,
        ).toISOString();

        const endDate = data.endDate ? data.endDate.toISOString() : null;

        try {
            await mutateAsync({
                appointmentId: appointmentId,
                locationId: locations[0].id ?? '',
                serviceIds: data.serviceIds,
                serviceType: selectedServices[0].basicInfo.serviceType,
                businessId: business.id,
                rentIds: data.rentIds,
                professionalIds: data.professionalIds,
                clientIds: data.clientIds ? data.clientIds : null,
                start: start,
                timeZone: '',
                duration: duration,
                notes: data.notes,
                repeatType: data.repeatType,
                customRepeatSettings:
                    data.repeatType === RepeatType.Custom
                        ? data.customRepeatSettings
                        : null,
                endType:
                    data.repeatType !== RepeatType.DoesNotRepeat
                        ? data.endType
                        : null,
                occurenceValue:
                    data.endType === EndType.After ? data.occurenceValue : null,
                endDate: data.endType === EndType.SpecificDate ? endDate : null,
                updateOption: option,
            });
            toast.success(t('appointment.update.success'));
            refetchAppointments();
            handleModalClose();
        } catch (error) {
            console.error(error);
            toast.error(t('appointment.update.error'));
        }
    };

    const handleDeclineRequest = async () => {
        if (!business || !appointmentId) {
            return;
        }
        try {
            await declineReqst({
                businessId: business.id,
                appointmentId,
                isProposed: false,
            });
            toast.success(t('appointment.decline.success'));
            close();
            refetchAppointments();
            onDecline && onDecline();
        } catch (error) {
            console.error(error);
            toast.error(t('appointment.decline.error'));
        }
    };

    // const handlePropose = async () => {
    //     if (!business || !appointmentId || !appointment) {
    //         return;
    //     }
    //     try {
    //         await declineReqst({
    //             businessId: business.id,
    //             appointmentId,
    //             isProposed: true,
    //         });

    //         const apptDate = appointment?.start.toDate();
    //         const searchParms = new URLSearchParams({
    //             view: ViewType.WeekView,
    //             date: format(apptDate, 'dd-MM-yyyy'),
    //             appt_date: format(apptDate, 'dd-MM-yyyy'),
    //             appt_time: format(apptDate, 'kkmm'),
    //             appt_service_ids: JSON.stringify(
    //                 appointment.serviceRefs.map((serviceRef) => serviceRef.id),
    //             ),
    //             is_proposition: 'true',
    //         });

    //         if (appointment.serviceType === ServiceType.Rent) {
    //             if (
    //                 appointment.professionalRefs &&
    //                 appointment.professionalRefs.length > 0
    //             ) {
    //                 searchParams.set(
    //                     'appt_professional_ids',
    //                     JSON.stringify(
    //                         appointment.professionalRefs.map(
    //                             (profRef) => profRef.id,
    //                         ),
    //                     ),
    //                 );
    //             }

    //             if (appointment.rentRefs && appointment.rentRefs.length > 0) {
    //                 searchParams.set(
    //                     'appt_rent_ids',
    //                     JSON.stringify(
    //                         appointment.rentRefs.map((rentRef) => rentRef.id),
    //                     ),
    //                 );
    //                 searchParams.set('rent_id', appointment.rentRefs[0].id);
    //             }
    //         } else if (appointment.serviceType === ServiceType.Service) {
    //             if (
    //                 appointment.professionalRefs &&
    //                 appointment.professionalRefs.length > 0
    //             ) {
    //                 searchParams.set(
    //                     'appt_professional_ids',
    //                     JSON.stringify(
    //                         appointment.professionalRefs.map(
    //                             (profRef) => profRef.id,
    //                         ),
    //                     ),
    //                 );
    //                 searchParams.set(
    //                     'professional_id',
    //                     appointment.professionalRefs[0].id,
    //                 );
    //             }
    //         }

    //         if (appointment.clientRefs && appointment.clientRefs.length > 0) {
    //             searchParams.set(
    //                 'appt_client_ids',
    //                 JSON.stringify(
    //                     appointment.clientRefs.map((clientRef) => clientRef.id),
    //                 ),
    //             );
    //         }

    //         navigate(`/calendar/new-appointment?${searchParms.toString()}`);
    //         refetchAppointments();
    //         onDecline && onDecline();
    //     } catch (error) {
    //         console.error(error);
    //         toast.error(t('appointment.decline.error'));
    //     }
    // };

    const handleApproveRequest = async () => {
        if (!business || !appointmentId) {
            return;
        }
        try {
            await approveReqst({
                businessId: business.id,
                appointmentId,
            });
            toast.success(t('appointment.approve.success'));
            close();
            refetchAppointments();
            onApprove && onApprove();
        } catch (error) {
            console.error(error);
            toast.error(t('appointment.approve.error'));
        }
    };

    const isDisabled = appointment
        ? appointment?.status === AppointmentStatusType.Pending ||
          appointment?.status === AppointmentStatusType.Declined ||
          appointment?.status === AppointmentStatusType.Cancelled
        : false;

    return (
        <SideDrawerContainer isOpen>
            <>
                <Switch>
                    <Switch.Case condition={!isLoading && isError}>
                        <span className="flex h-full flex-col items-center justify-between">
                            <div className="flex w-full items-center justify-start">
                                <IconButton
                                    name={IconType.Cross}
                                    onClick={close}
                                    size={20}
                                    className="flex h-10 w-10 items-center justify-center rounded text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-100 hover:!text-black"
                                />
                            </div>
                            {t('error')}
                            <div className="invisible"></div>
                        </span>
                    </Switch.Case>
                    <Switch.Case condition={isClientsLoading || isLoading}>
                        <span className="flex h-full flex-col items-center justify-between">
                            <div className="flex w-full items-center justify-start">
                                <IconButton
                                    name={IconType.Cross}
                                    onClick={close}
                                    size={20}
                                    className="flex h-10 w-10 items-center justify-center rounded text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-100 hover:!text-black"
                                />
                            </div>
                            {t('loading')}
                            <div className="invisible"></div>
                        </span>
                    </Switch.Case>
                    <Switch.Case condition={!!professionals && !!business}>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-x-10">
                                <IconButton
                                    name={IconType.Cross}
                                    onClick={close}
                                    size={20}
                                    className="flex h-10 w-10 items-center justify-center rounded text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-100 hover:!text-black"
                                />
                                {appointment?.status ===
                                AppointmentStatusType.Pending ? (
                                    <h3 className="font-excon text-2xl font-bold tracking-wider">
                                        {t('request')}
                                    </h3>
                                ) : null}
                            </div>
                            <div className="flex items-center gap-x-8">
                                <Switch>
                                    <Switch.Case
                                        condition={
                                            !!appointment &&
                                            appointment?.status ===
                                                AppointmentStatusType.Approved
                                        }
                                    >
                                        <span className="rounded-xl border border-black bg-white px-3 py-1">
                                            {t('approved')}
                                        </span>
                                    </Switch.Case>
                                    <Switch.Case
                                        condition={
                                            !!appointment &&
                                            appointment?.status ===
                                                AppointmentStatusType.Pending
                                        }
                                    >
                                        <span className="rounded-xl border border-black bg-white px-3 py-1">
                                            {t('pending')}
                                        </span>
                                    </Switch.Case>
                                    <Switch.Case
                                        condition={
                                            !!appointment &&
                                            appointment?.status ===
                                                AppointmentStatusType.Cancelled
                                        }
                                    >
                                        <span className="rounded-xl bg-red-500 px-3 py-1 text-white">
                                            {t('cancelled')}
                                        </span>
                                    </Switch.Case>
                                    <Switch.Case
                                        condition={
                                            !!appointment &&
                                            appointment?.status ===
                                                AppointmentStatusType.Declined
                                        }
                                    >
                                        <span className="rounded-xl bg-red-500 px-3 py-1 text-white">
                                            {t('declined')}
                                        </span>
                                    </Switch.Case>
                                </Switch>
                                <DropdownMenu
                                    trigger={
                                        <IconButton
                                            name={IconType.MenuDotsVertical}
                                            size={26}
                                            className="flex h-10 w-10 items-center justify-center rounded text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-100 hover:!text-black"
                                        />
                                    }
                                    className="z-[100] w-40"
                                >
                                    {appointment?.status ===
                                    AppointmentStatusType.Approved ? (
                                        <DropdownMenu.DefaultItem
                                            onClick={handleOpenCancelModal}
                                            disabled={
                                                isUpdating ||
                                                isCancelling ||
                                                isDeclining ||
                                                isApproving
                                            }
                                        >
                                            {t('cancel')}
                                        </DropdownMenu.DefaultItem>
                                    ) : null}
                                </DropdownMenu>
                            </div>
                        </div>
                        <Form<AppointmentForm>
                            methods={methods}
                            onSubmit={handleSubmit}
                            className="flex flex-1 flex-col overflow-hidden"
                        >
                            <Tab.Group>
                                <Tab.List className="flex items-center gap-x-6 pt-3 pb-6">
                                    <DefaultTab>{t('information')}</DefaultTab>
                                    <DefaultTab>{t('notes')}</DefaultTab>
                                </Tab.List>
                                <Tab.Panels className="flex flex-1 flex-col overflow-auto">
                                    <Tab.Panel className="flex flex-col gap-y-5 pb-6">
                                        <ClientCard
                                            clients={selectedClients}
                                            edit={() =>
                                                setIsClientsShowing(true)
                                            }
                                            disabled={isDisabled}
                                        />
                                        <ServiceCard
                                            services={selectedServices}
                                            edit={() =>
                                                setIsServicesShowing(true)
                                            }
                                            disabled={isDisabled}
                                        />
                                        <DatePickerInput<AppointmentFormProps>
                                            label={t('date')}
                                            name="date"
                                            disabled={
                                                appointment?.status ===
                                                AppointmentStatusType.Pending
                                            }
                                        />
                                        <SelectInput<AppointmentFormProps>
                                            label={t('time.start')}
                                            name="startTime"
                                            options={TIME_OPTIONS}
                                            menuPlacement="top"
                                            isDisabled={isDisabled}
                                            translate={false}
                                        />
                                        <SelectInput<AppointmentFormProps>
                                            label={t('repeat')}
                                            name="repeatType"
                                            options={REPEAT_TYPE_OPTIONS}
                                            className="col-span-2"
                                            menuPlacement="top"
                                            isDisabled={isDisabled}
                                        />
                                        {repeatType !==
                                        RepeatType.DoesNotRepeat ? (
                                            <>
                                                {repeatType ===
                                                RepeatType.Custom ? (
                                                    <>
                                                        <WithLabel
                                                            label={t(
                                                                'repeat.every',
                                                            )}
                                                            className="col-span-2 "
                                                        >
                                                            <div className="flex items-center justify-start gap-x-4">
                                                                <TextInputController<AppointmentFormProps>
                                                                    name="customRepeatSettings.value"
                                                                    type="number"
                                                                    asType="number"
                                                                    ctrClassName="!w-32"
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                />
                                                                <div className="flex-1">
                                                                    <SelectInputController<AppointmentFormProps>
                                                                        name="customRepeatSettings.frequencyType"
                                                                        options={
                                                                            FREQUENCY_TYPE_OPTIONS
                                                                        }
                                                                        isDisabled={
                                                                            isDisabled
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </WithLabel>
                                                        {frequencyType ===
                                                        FrequencyType.Week ? (
                                                            <WithLabel
                                                                label={t(
                                                                    'ondays',
                                                                )}
                                                                className="col-span-2"
                                                            >
                                                                <DaysCheckbox<AppointmentFormProps>
                                                                    name="customRepeatSettings.days"
                                                                    disabled={
                                                                        isDisabled
                                                                    }
                                                                />
                                                            </WithLabel>
                                                        ) : null}
                                                    </>
                                                ) : null}
                                                <SelectInput<AppointmentFormProps>
                                                    name="endType"
                                                    label={t('ends')}
                                                    options={END_TYPE_OPTIONS}
                                                    className="col-span-2"
                                                    isDisabled={isDisabled}
                                                />
                                                {endType === EndType.After ? (
                                                    <div className="flex items-center">
                                                        <TextInputController<AppointmentFormProps>
                                                            name="occurenceValue"
                                                            type="number"
                                                            asType="number"
                                                            disabled={
                                                                isDisabled
                                                            }
                                                            min={1}
                                                        />
                                                        <span>
                                                            {t('occurences')}
                                                        </span>
                                                    </div>
                                                ) : null}
                                                {endType ===
                                                EndType.SpecificDate ? (
                                                    <DatePickerInput<AppointmentFormProps>
                                                        label={t('date.end')}
                                                        name="endDate"
                                                        className="col-span-2"
                                                        fromYear={new Date().getFullYear()}
                                                        toYear={addYears(
                                                            new Date(),
                                                            5,
                                                        ).getFullYear()}
                                                        disabled={isDisabled}
                                                    />
                                                ) : null}
                                            </>
                                        ) : null}
                                    </Tab.Panel>
                                    <Tab.Panel className="flex flex-1 flex-col pb-16">
                                        <TextareaInput<AppointmentFormProps>
                                            label={t('notes')}
                                            placeholder={t(
                                                'notes.appointment.placeholder',
                                            )}
                                            name="notes"
                                            className="flex-1"
                                            inputCtrClassName="flex-1"
                                            inputClassName="h-full"
                                            disabled={isDisabled}
                                        />
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                            <div className="flex w-full flex-col justify-center gap-y-5">
                                <div className="flex items-center justify-between text-lg">
                                    <div>{t('total')}</div>
                                    <div className="font-bold">
                                        {total > 0
                                            ? unitAmountToPrice(total, 'eur')
                                            : t('free')}
                                    </div>
                                </div>
                                <Switch>
                                    <Switch.Case
                                        condition={
                                            !!appointment &&
                                            appointment?.status ===
                                                AppointmentStatusType.Pending
                                        }
                                    >
                                        <div className="flex items-center gap-x-6">
                                            <Button
                                                type="button"
                                                color="black"
                                                className="w-full"
                                                onClick={handleOpenApproveModal}
                                                disabled={
                                                    isUpdating ||
                                                    isCancelling ||
                                                    isApproving ||
                                                    isDeclining
                                                }
                                            >
                                                {isApproving ? (
                                                    <div className="flex items-center justify-center">
                                                        <ThreeDots
                                                            height="24"
                                                            radius="9"
                                                            color="#ffffff"
                                                            ariaLabel="three-dots-loading"
                                                            visible={true}
                                                        />
                                                    </div>
                                                ) : (
                                                    t('approve')
                                                )}
                                            </Button>
                                            <Button
                                                type="button"
                                                color="error"
                                                className="w-full"
                                                onClick={handleOpenDeclineModal}
                                                disabled={
                                                    isUpdating ||
                                                    isCancelling ||
                                                    isApproving ||
                                                    isDeclining
                                                }
                                            >
                                                {isDeclining ? (
                                                    <div className="flex items-center justify-center">
                                                        <ThreeDots
                                                            height="24"
                                                            radius="9"
                                                            color="#ffffff"
                                                            ariaLabel="three-dots-loading"
                                                            visible={true}
                                                        />
                                                    </div>
                                                ) : (
                                                    t('decline')
                                                )}
                                            </Button>
                                        </div>
                                    </Switch.Case>
                                    <Switch.Case condition={!isDisabled}>
                                        <Button
                                            type="button"
                                            color="black"
                                            className="w-full"
                                            onClick={handleSubmit}
                                            disabled={
                                                isUpdating || isCancelling
                                            }
                                        >
                                            {isUpdating || isCancelling ? (
                                                <div className="flex items-center justify-center">
                                                    <ThreeDots
                                                        height="24"
                                                        radius="9"
                                                        color="#ffffff"
                                                        ariaLabel="three-dots-loading"
                                                        visible={true}
                                                    />
                                                </div>
                                            ) : (
                                                t('save.changes')
                                            )}
                                        </Button>
                                    </Switch.Case>
                                </Switch>
                            </div>
                            <SideDrawerContainer isOpen={isServicesShowing}>
                                <ServiceSearchSelect
                                    services={services}
                                    isLoading={isServicesLoading}
                                    close={() => setIsServicesShowing(false)}
                                />
                            </SideDrawerContainer>
                            <SideDrawerContainer isOpen={isClientsShowing}>
                                <ClientSearchSelect
                                    clients={clients}
                                    isLoading={isClientsLoading}
                                    close={() => setIsClientsShowing(false)}
                                />
                            </SideDrawerContainer>
                        </Form>
                    </Switch.Case>
                </Switch>
                <Routes>
                    <Route
                        path="cancel"
                        element={
                            <CancelConfirmModal
                                isDeleting={isCancelling}
                                isMultiple={
                                    appointment?.repeatType !==
                                    RepeatType.DoesNotRepeat
                                }
                                close={handleModalClose}
                                onDelete={handleCancelAppointment}
                            />
                        }
                    />
                    <Route
                        path="confirm"
                        element={
                            <UpdateConfirmModal
                                isUpdating={isUpdating}
                                close={handleModalClose}
                                onUpdate={handleUpdateAppointment}
                            />
                        }
                    />
                    <Route
                        path="approve"
                        element={
                            <GuardModal
                                title={t('request.approve')}
                                text={t('request.approve.warning')}
                                isLoading={isApproving}
                                buttonText={t('approve')}
                                onClick={handleApproveRequest}
                                close={handleModalClose}
                                buttonColor="black"
                            />
                        }
                    />
                    <Route
                        path="decline"
                        element={
                            <GuardModal
                                title={t('request.decline')}
                                text={t('request.decline.warning')}
                                isLoading={isDeclining}
                                buttonText={t('decline')}
                                // additionalButton={
                                //     <Button
                                //         type="button"
                                //         size="sm"
                                //         className="flex-1 h-full md:h-auto md:flex-none"
                                //         onClick={handlePropose}
                                //         color="black"
                                //         disabled={isLoading}
                                //     >
                                //         {t('propose')}
                                //     </Button>
                                // }
                                onClick={handleDeclineRequest}
                                close={handleModalClose}
                            />
                        }
                    />
                </Routes>
            </>
        </SideDrawerContainer>
    );
};
