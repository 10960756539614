import { Business, Location } from '@bookinbio/interface';
import { collection, doc, getDocs } from 'firebase/firestore';

import { businessesColl } from '../collections';

export const getLocationsByBusiness = async (
    business?: Business,
): Promise<Location[]> => {
    if (!business) {
        throw new Error('Missing business id');
    }

    const locationSnaps = await getDocs(
        collection(doc(businessesColl, business.id), 'locations'),
    );

    if (locationSnaps.empty) {
        return [];
    }

    return locationSnaps.docs.map((location) => {
        return { ...location.data(), id: location.id } as Location;
    });
};
