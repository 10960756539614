import { AppointmentStatusType } from '@bookinbio/enums';
import { Appointment, Business } from '@bookinbio/interface';
import { endOfWeek, format, startOfWeek } from 'date-fns';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';

import { businessesColl, usersColl } from '../collections';

export type ExtendedAppointments = Record<string, Appointment[]>;

export const getAppointmentsByWeekProfessional = async (
    date: Date,
    business?: Business,
    professionalId?: string | null,
    rentId?: string | null,
) => {
    if (!business) {
        throw new Error('Missing business');
    }

    if (!professionalId && !rentId) {
        throw new Error('Missing professional id and rent id');
    }

    const type = professionalId ? 'service' : rentId ? 'rent' : null;

    if (!type) {
        throw new Error('Error occured while resolving type');
    }

    const startOfTheWeek = startOfWeek(date, { weekStartsOn: 1 });
    const endOfTheWeek = endOfWeek(date, { weekStartsOn: 1 });

    const appointmentsColl = collection(
        businessesColl,
        business.id,
        'appointments',
    );

    let approvedApptsQ = null;

    if (type === 'service' && professionalId) {
        const profRef = doc(usersColl, professionalId);

        approvedApptsQ = query(
            appointmentsColl,
            where('professionalRefs', 'array-contains', profRef),
            where('status', '==', AppointmentStatusType.Approved),
            where('start', '>=', startOfTheWeek),
            where('start', '<=', endOfTheWeek),
        );
    }

    if (type === 'rent' && rentId) {
        const rentRef = doc(
            collection(businessesColl, business.id, 'rents'),
            rentId,
        );

        approvedApptsQ = query(
            appointmentsColl,
            where('rentRefs', 'array-contains', rentRef),
            where('status', '==', AppointmentStatusType.Approved),
            where('start', '>=', startOfTheWeek),
            where('start', '<=', endOfTheWeek),
        );
    }

    if (!approvedApptsQ) {
        return {};
    }

    const approvedAppointements = await getDocs(approvedApptsQ);
    const allAppointments = [...approvedAppointements.docs];

    const extendedAppointments: ExtendedAppointments = {};

    allAppointments.forEach((appointment) => {
        if (!appointment.exists()) {
            return;
        }

        const appointmentData = appointment.data() as Omit<Appointment, 'id'>;
        const dateName = format(appointmentData.start.toDate(), 'dd-MM-yyyy');

        extendedAppointments[dateName] = extendedAppointments[dateName] || [];
        extendedAppointments[dateName].push({
            ...appointmentData,
            id: appointment.id,
        });
    });

    return extendedAppointments;
};
