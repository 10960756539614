import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    IconButton,
    IconType,
    SelectInputController,
    Toggle,
} from '@bookinbio/ui';
import { TIME_OPTIONS } from '@bookinbio/utils';
import classNames from 'classnames';
import { format, setDay, startOfWeek } from 'date-fns';
import { z } from 'zod';

import { useMobile } from '../../../context/MobileContext';
import i18n from '../../../locales/i18n';

const TimeSlot = z
    .object({
        start: z.string(),
        end: z.string(),
    })
    .superRefine((data, ctx) => {
        if (timeToMinutes(data.start) >= timeToMinutes(data.end)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: i18n.t('end.time.error'),
                path: ['end'],
            });
            return false;
        }
        return true;
    });

const DaySetting = z
    .object({
        timeSlots: TimeSlot.array(),
        isOpen: z.boolean(),
    })
    .superRefine((data, ctx) => {
        if (data.isOpen) {
            if (data.timeSlots.length === 0) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: i18n.t('time.slots.error'),
                    path: ['timeSlots'],
                });
            }
            for (let i = 0; i < data.timeSlots.length - 1; i++) {
                if (
                    timeToMinutes(data.timeSlots[i].end) >=
                    timeToMinutes(data.timeSlots[i + 1].start)
                ) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: i18n.t('time.slots.overlap.error'),
                        path: ['timeSlots', i, 'end'],
                    });
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: i18n.t('time.slots.overlap.error'),
                        path: ['timeSlots', i + 1, 'start'],
                    });
                }
            }
        } else {
            if (data.timeSlots.length > 0) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: i18n.t('time.slots.empty.error'),
                    path: ['timeSlots'],
                });
            }
        }
    });

export const DaysSettingsSchema = z.object({
    0: DaySetting,
    1: DaySetting,
    2: DaySetting,
    3: DaySetting,
    4: DaySetting,
    5: DaySetting,
    6: DaySetting,
});

interface DaySettingsProps {
    name: string;
    day: number;
    date?: Date;
}

export const DaySettings = ({ name, day, date }: DaySettingsProps) => {
    const { t } = useTranslation();
    const { isMobileView: isMobile } = useMobile();
    const { control, watch, setValue } = useFormContext();
    const { fields, append, replace, remove } = useFieldArray({
        control: control,
        name: `${name}.timeSlots`,
    });
    const daySettings: {
        isOpen: boolean;
        timeSlots: { start: string; end: string }[];
    } = watch(`${name}`);

    // Handlers
    const handleNewTimeSlot = () => {
        const endTime = daySettings.timeSlots.at(-1)?.end;
        if (endTime) {
            append(calculateNewTime(endTime));
        }
    };

    const handleIsOpenToggle = (value: boolean) => {
        if (value) {
            append({ start: '09:00', end: '17:00' });
        } else {
            replace([]);
        }
    };

    const handleAddFirstTimeShift = () => {
        setValue(`${name}.isOpen`, true);
        append({ start: '09:00', end: '17:00' });
    };

    const handleRemoveTimeSlot = (idx: number) => {
        remove(idx);
        if (daySettings.timeSlots.length === 0) {
            setValue(`${name}.isOpen`, false);
        }
    };

    return (
        <div className="grid items-start gap-x-4 md:grid-cols-3">
            <div className="flex flex-col gap-y-3 md:h-12 md:flex-row md:items-center">
                <div
                    className={classNames(
                        'w-auto text-base font-medium md:flex-1',
                        date
                            ? 'flex flex-col items-start justify-start'
                            : 'items-center',
                    )}
                >
                    <span>{getDayName(day)}</span>
                    {date ? (
                        <span className="text-xs font-normal text-gray-500">
                            {format(date, 'dd.MM.yyyy')}
                        </span>
                    ) : null}
                </div>
                <Toggle
                    ctrClassName="md:flex w-24 items-center gap-x-2 md:flex-1 justify-start hidden"
                    name={`${name}.isOpen`}
                    onChange={handleIsOpenToggle}
                />
            </div>
            <div className="flex-1 md:col-span-2">
                {fields.length > 0 && daySettings.isOpen ? (
                    <>
                        <div className="grid grid-cols-1 gap-y-4">
                            {fields.map((field, idx) => {
                                return (
                                    <div
                                        key={`${field.id}`}
                                        className="flex gap-x-4"
                                    >
                                        <div className="flex-1">
                                            <SelectInputController
                                                name={`${name}.timeSlots.${idx}.start`}
                                                options={TIME_OPTIONS}
                                                translate={false}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <SelectInputController
                                                name={`${name}.timeSlots.${idx}.end`}
                                                options={TIME_OPTIONS}
                                                translate={false}
                                            />
                                        </div>
                                        <IconButton
                                            name={IconType.BinFilled}
                                            onClick={() =>
                                                handleRemoveTimeSlot(idx)
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <button
                            type="button"
                            className="py-1 text-blue-700 hover:text-blue-500"
                            onClick={handleNewTimeSlot}
                        >
                            {t('time.slot.add')}
                        </button>
                    </>
                ) : (
                    <>
                        <div className="flex h-12 items-center text-gray-500">
                            {t('no.work.shift')}
                        </div>
                        {isMobile ? (
                            <button
                                type="button"
                                className="py-1 text-blue-700 hover:text-blue-500"
                                onClick={handleAddFirstTimeShift}
                            >
                                {t('time.slot.add')}
                            </button>
                        ) : null}
                    </>
                )}
            </div>
        </div>
    );
};

const getDayName = (dayNumber: number) => {
    const date = startOfWeek(new Date());
    const targetDate = setDay(date, dayNumber);
    return format(targetDate, 'EEEE');
};

const calculateNewTime = (input: string): { start: string; end: string } => {
    // Parse the input hour
    const hour = parseInt(input.split(':')[0], 10);

    // Initialize variables for start and end hours
    let startHour: number;
    let endHour: number;

    // Check for the edge case (22:00 or later)
    if (hour >= 22) {
        return { start: '23:00', end: '23:55' };
    } else {
        // Calculate start and end times for other cases
        startHour = hour + 1;
        endHour = hour + 2;

        // Convert hours back to strings
        const start = `${startHour}:00`;
        const end = `${endHour}:00`;

        return { start, end };
    }
};

const timeToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
};
