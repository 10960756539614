import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { default as ReactPhoneInput } from 'react-phone-input-2';
import classNames from 'classnames';

import { getNestedError } from './utils';
import { WithLabel } from './WithLabel';

import 'react-phone-input-2/lib/plain.css';
import '../assets/css/phone-input.css';

export interface PhoneInputControllerProps<T extends string> {
    name: T;
    placeholder?: string;
    country?: string;
    errorless?: boolean;
    inputClassName?: string;
    errorClassName?: string;
    buttonClassName?: string;
}

export const PhoneInputController = <T extends string = string>({
    name,
    placeholder,
    country = 'sk',
    errorless = false,
    inputClassName,
    errorClassName,
    buttonClassName,
}: PhoneInputControllerProps<T>) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const {
        field,
        formState: { errors },
    } = useController({
        name,
        control,
    });

    const error = getNestedError(errors, name);

    return (
        <div>
            <ReactPhoneInput
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                placeholder={placeholder}
                specialLabel=""
                country={country}
                regions={'europe'}
                inputClass={classNames(
                    inputClassName,
                    error && !errorless ? '!border-red-500' : 'border-gray-500',
                )}
                buttonClass={buttonClassName}
            />
            {!errorless && error ? (
                <p
                    className={classNames(
                        'mb-2 h-3 text-left text-xs text-red-700',
                        errorClassName,
                    )}
                >
                    {t(error.message as string)}
                </p>
            ) : null}
        </div>
    );
};

export interface PhoneInputProps<T extends string>
    extends PhoneInputControllerProps<T> {
    label: string;
    className?: string;
    labelClassName?: string;
}

export const PhoneInput = <T extends string = string>({
    label,
    name,
    className,
    placeholder = '',
    country,
    errorless,
    labelClassName,
    inputClassName,
    buttonClassName,
}: PhoneInputProps<T>) => {
    return (
        <WithLabel
            label={label}
            name={name}
            className={className}
            labelClassName={labelClassName}
        >
            <PhoneInputController
                name={name}
                placeholder={placeholder}
                country={country}
                errorless={errorless}
                inputClassName={inputClassName}
                buttonClassName={buttonClassName}
            />
        </WithLabel>
    );
};
