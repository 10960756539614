import { DayNumbers, DaySetting } from '@bookinbio/interface';
import { transformToDate } from '@bookinbio/utils';
import { startOfDay, startOfWeek } from 'date-fns';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';

import { firestore } from '../../firebase.config';

export const getWorkingHoursByStartDate = async (
    startDate: string,
    userId?: string | null,
): Promise<Record<DayNumbers, DaySetting> | null> => {
    if (!userId) {
        throw new Error('Missing user id');
    }

    const userWhColl = collection(
        doc(collection(firestore, 'users'), userId),
        'working-hours',
    );

    const start = startOfDay(
        startOfWeek(transformToDate(startDate), { weekStartsOn: 1 }),
    );

    const q = query(userWhColl, where('startDate', '==', start));
    const workingHoursSnaps = await getDocs(q);

    if (workingHoursSnaps.empty) {
        return null;
    }

    return workingHoursSnaps.docs[0].data().daySettings as Record<
        DayNumbers,
        DaySetting
    >;
};
