import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { BrowserTracing, init, Replay } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { App } from './app/App';
import { AuthProvider } from './context/AuthContext';
import { AppConfig, SentryConfig } from './utils/config';

import './locales/i18n';

import './assets/css/styles.css';

if (SentryConfig.enabled) {
    init({
        dsn: SentryConfig.dsn,
        integrations: [
            new BrowserTracing({
                // Set ‘tracePropagationTargets’ to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    'localhost',
                    'htto://localhost:4200',
                    'https://admin.bookinb.io',
                    'https://bookinb.io',
                ],
            }),
            new Replay(),
        ],
        enabled: SentryConfig.enabled,
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you’re not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

console.log(AppConfig.appVersion);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
    <StrictMode>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </QueryClientProvider>
        </BrowserRouter>
    </StrictMode>,
);
