import { User } from '@bookinbio/interface';
import { doc, getDoc } from 'firebase/firestore';

import { usersColl } from '../collections';

export const getProfessionalByBusiness = async (
    professionalId?: string,
): Promise<User> => {
    if (!professionalId) {
        throw new Error('Missing professionalId');
    }

    const userRef = doc(usersColl, professionalId);

    const serviceSnap = await getDoc(userRef);

    if (!serviceSnap.exists()) {
        throw new Error('Service does not exists');
    }

    return { ...(serviceSnap.data() as User), id: serviceSnap.id };
};
