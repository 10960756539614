export const RequestsPlaceholder = () => {
    return Array.from({ length: 5 }).map((_, idx) => {
        return (
            <div
                key={`client-placeholder-${idx}`}
                className="flex animate-pulse items-center gap-x-4 py-3"
            >
                <div className="h-10 w-10 rounded-full bg-gray-300"></div>
                <div className="h-6 w-[20%] rounded-lg bg-gray-300"></div>
                <div className="h-6 w-[20%] rounded-lg bg-gray-300"></div>
                <div className="h-6 flex-1 rounded-lg bg-gray-300"></div>
            </div>
        );
    });
};
