import { Path, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, TextInput } from '@bookinbio/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import i18next from 'i18next';
import { z } from 'zod';

import { acceptTeamInvite } from '../utils/firebase/callable/team-members';

const InvitationFormObject = z
    .object({
        password: z
            .string()
            .min(1, i18next.t('password.error') as string)
            .min(8, i18next.t('password.error.lenght') as string),
        repassword: z.string().min(1, i18next.t('password.again') as string),
    })
    .refine(
        (data) => {
            if (data.password !== data.repassword) {
                return false;
            }
            return true;
        },
        {
            path: ['repassword'],
            message: i18next.t('password.not.same') as string,
        },
    );

type InvitationForm = z.infer<typeof InvitationFormObject>;
type InvitationFormProps = Path<InvitationForm>;

export const Invitation = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const methods = useForm<InvitationForm>({
        resolver: zodResolver(InvitationFormObject),
    });

    const { mutateAsync: acceptInvite } = useMutation({
        mutationFn: acceptTeamInvite,
    });

    const onSubmit = async (data: InvitationForm) => {
        const token = searchParams.get('invite_token');

        if (!token) {
            return;
        }

        try {
            await acceptInvite({ token: token, password: data.password });
            toast.success(t('accept.invite.success'));
            navigate('/login');
        } catch (error) {
            // console.log(error);
            toast.error(t('accept.invite.error'));
        }
    };

    return (
        <div className="flex w-full max-w-xs flex-col items-center">
            <div className="my-2 text-gray-500">
                {t('invite.team.enter.password')}
            </div>
            <Form<InvitationForm>
                onSubmit={onSubmit}
                className="w-full"
                methods={methods}
            >
                <TextInput<InvitationFormProps>
                    name="password"
                    type="password"
                    label={t('password')}
                    placeholder={t('password.new')}
                    className="mb-3"
                    errorless={false}
                />
                <TextInput<InvitationFormProps>
                    name="repassword"
                    type="password"
                    label={t('repassword')}
                    placeholder={t('repassword.placeholder') as string}
                    className="mb-3"
                    errorless={false}
                />
                <Button type="submit" className="w-full">
                    {t('submit')}
                </Button>
            </Form>
        </div>
    );
};
