import { ReactNode, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select, { MenuPlacement } from 'react-select';
import classNames from 'classnames';

import { SelectOption } from './SelectDropdown';
import { getNestedError } from './utils';
import { WithLabel } from './WithLabel';

export interface MultiSelectInputControllerProps<T extends string> {
    name: T;
    isClearable?: boolean;
    isSearchable?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    errorClassName?: string;
    options: SelectOption[];
    menuPlacement?: MenuPlacement;
}

export const MultiSelectInputController = <T extends string = string>({
    name,
    placeholder,
    isClearable = false,
    isSearchable = false,
    isDisabled = false,
    errorClassName,
    menuPlacement = 'auto',
    options,
}: MultiSelectInputControllerProps<T>) => {
    const { t } = useTranslation();

    const {
        control,
        formState: { errors },
    } = useFormContext();

    const error = getNestedError(errors, name);

    const translatedOpts = useMemo(
        () =>
            options.map((opt) => ({
                label: t(opt.label),
                value: opt.value,
            })),
        [options, t],
    );

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                return (
                    <>
                        <Select
                            value={value as SelectOption[]}
                            options={translatedOpts}
                            onChange={onChange}
                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            isMulti={true}
                            placeholder={placeholder}
                            isDisabled={isDisabled}
                            menuPlacement={menuPlacement}
                            classNames={{
                                indicatorSeparator: () => 'hidden',
                                control: () =>
                                    classNames(
                                        '!border-r-none !border-b flex cursor-pointer h-[49px] select-none text-left text-base sm:text-base',
                                        error
                                            ? '!border-red-500'
                                            : '!border-gray-500',
                                    ),
                                placeholder: () => classNames('!text-gray-400'),
                                menu: () => classNames('!z-[1000]'),
                                option: () => 'text-xs md:text-base text-left',
                            }}
                            styles={{
                                control: () => ({
                                    borderTop: 'none',
                                    borderLeft: 'none',
                                    borderRight: 'none',
                                }),
                                menu: (base) => ({
                                    ...base,
                                    transform: 'translate3d(0, 0, 0)',
                                }),
                            }}
                        />
                        {error ? (
                            <p
                                className={classNames(
                                    'mb-2 h-3 text-left text-xs text-red-700',
                                    errorClassName,
                                )}
                            >
                                {t(error.message as string)}
                            </p>
                        ) : null}
                    </>
                );
            }}
        />
    );
};

export interface MultiSelectInputProps<T extends string>
    extends MultiSelectInputControllerProps<T> {
    label: string;
    className?: string;
    labelClassName?: string;
    inputCtrClassName?: string;
    inputClassName?: string;
    right?: ReactNode;
}

export const MultiSelectInput = <T extends string = string>({
    name,
    label,
    placeholder,
    className,
    labelClassName,
    options,
    right,
    menuPlacement,
    isClearable,
    isDisabled,
    isSearchable,
    errorClassName,
}: MultiSelectInputProps<T>) => {
    return (
        <WithLabel
            label={label}
            name={name}
            className={className}
            labelClassName={labelClassName}
            right={right}
        >
            <MultiSelectInputController
                name={name}
                placeholder={placeholder}
                options={options}
                menuPlacement={menuPlacement}
                isClearable={isClearable}
                isDisabled={isDisabled}
                isSearchable={isSearchable}
                errorClassName={errorClassName}
            />
        </WithLabel>
    );
};
