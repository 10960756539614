import { httpsCallable } from 'firebase/functions';

import { functions } from '../../firebase.config';

type CreateOverrideArgs = {
    businessId: string;

    type: string;

    title: string;
    professionalIds: string[] | null;
    rentIds: string[] | null;
    start: string;
    duration: number;
    repeatType: string;
    endType: string | null;
    endDate: string | null;
    occurenceValue: number | null;
    description: string;
    timeZone: string;
    customRepeatSettings: {
        value: number;
        frequencyType: string;
        days: number[] | null;
    } | null;
};

export const createOverride = httpsCallable<CreateOverrideArgs>(
    functions,
    'createOverride',
);

type UpdateOverrideArgs = {
    businessId: string;
    overrideId: string;

    type: string;

    isSingle: boolean;
    updateOption: string;

    title: string;
    professionalIds: string[] | null;
    rentIds: string[] | null;
    start: string;
    duration: number;
    repeatType: string;
    endType: string | null;
    endDate: string | null;
    occurenceValue: number | null;
    description: string;
    timeZone: string;
    customRepeatSettings: {
        value: number;
        frequencyType: string;
        days: number[] | null;
    } | null;
};

export const updateOverride = httpsCallable<UpdateOverrideArgs>(
    functions,
    'updateOverride',
);

type DeleteOverrideArgs = {
    businessId: string;
    overrideId: string;
    deleteOption: string;
};

export const deleteOverride = httpsCallable<DeleteOverrideArgs>(
    functions,
    'deleteOverride',
);
