import { ReactNode } from 'react';
import classNames from 'classnames';

export interface WithLabelProps {
    label: string;
    name?: string;
    className?: string;
    labelClassName?: string;
    right?: ReactNode;
    children: ReactNode;
}

export const WithLabel = ({
    label,
    name,
    className,
    labelClassName,
    right,
    children,
}: WithLabelProps) => {
    const transformedLabel = label.replace('mail', 'ma\u200cil');

    return (
        <div className={classNames(`flex flex-col`, className)}>
            <div className="flex justify-between">
                <label
                    className={classNames(
                        `mb-1 font-medium text-gray-800`,
                        labelClassName,
                    )}
                    htmlFor={name}
                >
                    {transformedLabel}
                </label>
                {right ? right : null}
            </div>
            {children}
        </div>
    );
};
