import { ReactNode } from 'react';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';

interface SideDrawerContainerProps {
    isOpen: boolean;
    size?: 'sm' | 'lg';
    className?: string;
    children: ReactNode;
}

export const SideDrawerContainer = ({
    isOpen,
    size = 'sm',
    className,
    children,
}: SideDrawerContainerProps) => {
    return (
        <Transition
            show={isOpen}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
            className={classNames(
                'shadow-left fixed top-0 right-0 z-[100] flex h-full w-full flex-col bg-white p-6 md:p-10',
                className,
                size === 'sm' ? 'md:w-[500px]' : null,
                size === 'lg' ? 'md:w-[800px]' : null,
            )}
        >
            {children}
        </Transition>
    );
};
