import { httpsCallable } from 'firebase/functions';

import { functions } from '../../firebase.config';

type CreateServiceArgs = {
    businessId: string;

    basicInfo: {
        categoryId: string;
        serviceType: string;

        name: Record<string, string>;
        linkName: string;
        description?: string;
        afterCareDescription?: string;
    };

    serviceSettings: {
        duration: number;
        priceType: string;
        amount: number | null;
        currency: string;
        isCombinable: boolean;
        allowBookings: boolean;
        dayLimitBookings?: number | null;
    };

    professionalRefs: string[];
    rentRefs: string[];

    isActiveRebookReminder: boolean;
    notificationReminder: {
        value: number;
        afterType: string;
    } | null;
};

export const createService = httpsCallable<CreateServiceArgs>(
    functions,
    'createService',
);

type UpdateServiceArgs = {
    serviceId: string;
    businessId: string;

    basicInfo: {
        categoryId: string;
        serviceType: string;

        name: Record<string, string>;
        linkName: string;
        description?: string;
        afterCareDescription?: string;
    };

    serviceSettings: {
        duration: number;
        priceType: string;
        amount: number | null;
        currency: string;
        isCombinable: boolean;
        allowBookings: boolean;
        dayLimitBookings?: number | null;
    };

    professionalRefs: string[];
    rentRefs: string[];

    isActiveRebookReminder: boolean;
    notificationReminder: {
        value: number;
        afterType: string;
    } | null;
};

export const updateService = httpsCallable<UpdateServiceArgs>(
    functions,
    'updateService',
);

type DeleteServiceArgs = {
    businessId: string;
    serviceId: string;
};

export const deleteService = httpsCallable<DeleteServiceArgs>(
    functions,
    'deleteService',
);

type UpdateFavoriteServiceArgs = {
    businessId: string;
    serviceId: string;
    isFavorite: boolean;
};

export const updateFavoriteService = httpsCallable<UpdateFavoriteServiceArgs>(
    functions,
    'updateFavoriteService',
);

type UpdateOrderServiceArgs = {
    businessId: string;
    serviceId: string;
    order: number;
};

export const updateOrderService = httpsCallable<UpdateOrderServiceArgs>(
    functions,
    'updateOrderService',
);
