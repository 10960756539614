import { GoogleEvent } from '@bookinbio/interface';
import { Icon, IconType } from '@bookinbio/ui';
import { getPosFromTime } from '@bookinbio/utils';
import classNames from 'classnames';
import { addMinutes, format } from 'date-fns';

import { SchedulerConfig } from '../../utils/config';

import { useCalendar } from './AppointmentsContext';

interface GoogleEventCardProps {
    event: GoogleEvent;
    additionalTop?: boolean;
    className?: string;
}

export const GoogleEventItem = ({
    event,
    additionalTop = false,
    className,
}: GoogleEventCardProps) => {
    const { setHover } = useCalendar();
    if (!event.start) {
        return null;
    }

    const start = new Date(event.start);
    const evtStyle = getPosFromTime(start, event.duration, {
        gridCellTypeNumber: SchedulerConfig.gridCellTypeNumber,
        gridPixelHeight: SchedulerConfig.gridPixelHeight,
        additionalTop,
    });

    const startStr = format(start, 'HH:mm');
    const endStr = format(addMinutes(start, event.duration), 'HH:mm');

    return (
        <div
            style={{
                top: evtStyle.top,
                height: evtStyle.height,
            }}
            className={classNames(
                'rounded-smd absolute z-50 flex w-full flex-col justify-between gap-x-5 overflow-hidden border-2 border-blue-400 bg-white px-3 text-black outline-none',
                event.duration > 30 ? 'py-3' : 'py-1',
                className,
            )}
            onMouseEnter={() => {
                setHover(null);
            }}
            // onClick={() => navigate(`event/${event.id}`)}
        >
            <div className="flex items-start justify-between">
                <div className="flex items-center gap-x-3 text-sm font-semibold">
                    <Icon
                        name={IconType.Google}
                        size={12}
                        className="flex-shrink-0"
                    />
                    {event.name ?? 'Google event'}
                </div>
                <div className="flex flex-shrink-0 flex-col items-center">
                    <div className="text-sm">{startStr}</div>
                    <div className="text-xs text-gray-900">{endStr}</div>
                </div>
            </div>
            <div className="flex-1 overflow-hidden text-xs">
                {event.description}
            </div>
        </div>
    );
};
