export type CurrencyType = 'eur' | 'usd';

export const unitAmountToPrice = (
    amount: number,
    curr: CurrencyType,
    locale?: string,
) => {
    if (!locale) {
        locale = navigator.language;
    }

    switch (curr) {
        default:
        case 'eur':
            return new Intl.NumberFormat(locale, {
                currency: 'EUR',
                style: 'currency',
            }).format(amount / 100);
        case 'usd':
            return new Intl.NumberFormat(locale, {
                currency: 'USD',
                style: 'currency',
            }).format(amount / 100);
    }
};

export const unitAmountToString = (amount: number) => {
    return '' + amount / 100;
};

export const stringPriceToUnitAmount = (price: string, curr: CurrencyType) => {
    let unitAmount = price;
    if (unitAmount.includes('.')) {
        const [integralPart, rawDecimalPart] = unitAmount.split('.');
        let decimalPart = rawDecimalPart;

        if (curr === 'eur' || curr === 'usd') {
            decimalPart = decimalPart.slice(0, 2).padEnd(2, '0');
        }

        unitAmount = integralPart + decimalPart;
    } else {
        if (curr === 'eur' || curr === 'usd') {
            unitAmount = unitAmount + '00';
        }
    }

    return +unitAmount;
};
