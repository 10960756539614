import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Icon, IconType } from '@bookinbio/ui';

import logo from '../assets/images/logo-black-full.svg';

interface MobileContextProps {
    isMobileView: boolean;
}

const MobileContext = createContext<MobileContextProps>({
    isMobileView: false,
});

export const useMobile = () => useContext(MobileContext);

interface MobileProviderProps {
    children: ReactNode;
}

export const MobileProvider = ({ children }: MobileProviderProps) => {
    const { t } = useTranslation();
    const [isMobileView, setIsMobileView] = useState<boolean>(
        window.innerWidth <= 768,
    );
    const [isLandscape, setIsLandscape] = useState<boolean>(
        window.screen.orientation.type.startsWith('landscape'),
    );

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        const handleOrientationChange = () => {
            setIsLandscape(
                window.screen.orientation.type.startsWith('landscape'),
            );
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleOrientationChange);
        // Perform initial checks
        handleResize();
        handleOrientationChange();

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener(
                'orientationchange',
                handleOrientationChange,
            );
        };
    }, []);

    return (
        <MobileContext.Provider value={{ isMobileView }}>
            {isLandscape && isMobile ? (
                <div className="h-custom-screen fixed z-[130] flex w-screen flex-col items-center justify-center gap-4 bg-white">
                    <img
                        src={logo}
                        alt="bookbio-logo"
                        className="rounded-smd w-40"
                    />
                    <Icon name={IconType.RotatePortrait} size={68} />
                    <div className="font-excon flex">
                        {t('orientation.mobile.error')}
                    </div>
                </div>
            ) : (
                children
            )}
        </MobileContext.Provider>
    );
};
