import { useState } from 'react';
import { DayPicker, useDayPicker, useNavigation } from 'react-day-picker';
import { IconButton, IconType } from '@bookinbio/ui';
import { useQuery } from '@tanstack/react-query';
import { format, getDay, startOfWeek } from 'date-fns';

import { useAuth } from '../../context/AuthContext';
import { useBusiness } from '../../context/BusinessContext';
import i18n from '../../locales/i18n';
import { getAppointmentsByWeekProfessional } from '../../utils/firebase/api/get-appointment-week-professional';
import { getOverridesByBusiness } from '../../utils/firebase/api/get-overrides-business';
import { getUsersWeekGoogleEvents } from '../../utils/firebase/callable';
import { getDateFnsLocale } from '../../utils/locale';
import { getClosedHours } from '../calendar/ClosedHourItem';

import { DayCalendar } from './DayCalendar';

export const SidebarCalendar = () => {
    const { user } = useAuth();
    const { business } = useBusiness();
    const [selectedDate, setSelectedDay] = useState<Date>(new Date());
    const dateName = format(selectedDate, `dd-MM-yyyy`);

    const transformedDate = startOfWeek(selectedDate, { weekStartsOn: 1 });

    const { data: groupedAppointments } = useQuery({
        queryKey: [
            'business-appointments',
            business?.id,
            user?.id,
            transformedDate,
        ],
        queryFn: () =>
            getAppointmentsByWeekProfessional(
                transformedDate,
                business,
                user?.id,
            ),
        enabled: !!business && !!user,
    });

    const { data: groupedOverrides } = useQuery({
        queryKey: ['business-overrides', business, user?.id, transformedDate],
        queryFn: () =>
            getOverridesByBusiness(transformedDate, business, user?.id),
        enabled: !!business && !!user?.id,
    });

    const { data: groupedGoogleEvents } = useQuery({
        queryKey: ['user-google-events', transformedDate, user?.id],
        queryFn: () =>
            getUsersWeekGoogleEvents({
                userId: user?.id,
                date: transformedDate.toISOString(),
            }),
        enabled: !!business && !!user,
    });

    const handleDateSelection = (date?: Date) => {
        if (!date) {
            return;
        }
        setSelectedDay(date);
    };

    const locale = getDateFnsLocale(i18n.language);

    const appointments =
        groupedAppointments && groupedAppointments[dateName]
            ? groupedAppointments[dateName]
            : [];

    const overrides =
        groupedOverrides && groupedOverrides[dateName]
            ? groupedOverrides[dateName]
            : [];

    const googleEvents =
        groupedGoogleEvents?.data && groupedGoogleEvents.data[dateName]
            ? groupedGoogleEvents.data[dateName]
            : [];

    const closedHours = getClosedHours(
        user?.calendarSettings?.daySettings[getDay(selectedDate)],
    );

    return (
        <div className="flex flex-1 flex-col overflow-auto">
            <DayPicker
                mode="single"
                onSelect={handleDateSelection}
                selected={selectedDate}
                components={{
                    Caption: CustomCaption,
                }}
                className="!m-0 w-full"
                classNames={{
                    months: 'flex w-full',
                    month: 'rdp-month w-full',
                    table: 'w-[400px] table-fixed mx-auto',
                    head_row: 'h-full w-full flex',
                    head_cell: 'leading-7 text-sm font-normal flex-1',
                    day_selected: '!bg-black !text-white',
                    row: 'h-full w-full flex',
                    cell: 'flex justify-center items-center flex-1 text-sm',
                }}
                showOutsideDays
                // fixedWeeks
                weekStartsOn={1}
                formatters={{
                    formatWeekdayName: (date, options) => {
                        return format(date, 'E', {
                            ...options,
                            locale: locale,
                        });
                    },
                }}
            />
            <DayCalendar
                currentDate={selectedDate}
                appointments={appointments}
                googleEvents={googleEvents}
                closedHours={closedHours}
                overrides={overrides}
            />
        </div>
    );
};

const CustomCaption = () => {
    const { previousMonth, nextMonth, goToMonth, currentMonth } =
        useNavigation();
    const { selected } = useDayPicker();

    return (
        <div className="my-3 flex flex-col gap-y-4 px-10">
            <div className="text-xl">
                {format(selected as Date, 'LLLL, dd EEEE')}
            </div>
            <div className="flex items-center justify-between text-xl text-neutral-200">
                <IconButton
                    name={IconType.ChevronLeft}
                    onClick={() => previousMonth && goToMonth(previousMonth)}
                    size={24}
                    className="text-black transition-all duration-200 hover:text-neutral-500"
                />
                <span className="text-base text-black">
                    {format(currentMonth, "LLLL ''yy")}
                </span>
                <IconButton
                    name={IconType.ChevronRight}
                    onClick={() => nextMonth && goToMonth(nextMonth)}
                    size={24}
                    className="text-black transition-all duration-200 hover:text-neutral-500"
                />
            </div>
        </div>
    );
};
