import { ReactNode } from 'react';
import { Button, ButtonColor, Modal } from '@bookinbio/ui';
import { t } from 'i18next';

interface GuardModalProps {
    isLoading?: boolean;
    title: string;
    text: string;
    buttonText: string;
    buttonColor?: ButtonColor;
    additionalButton?: ReactNode;
    close: () => void;
    onClick: () => void;
}

// TODO: Use component composition
export const GuardModal = ({
    isLoading = false,
    title,
    text,
    buttonText,
    buttonColor = 'error',
    additionalButton,
    onClick,
    close,
}: GuardModalProps) => {
    return (
        <Modal isOpen={true} close={close}>
            <h2
                className={'flex-1 text-left text-2xl font-bold tracking-wider'}
            >
                {title}
            </h2>
            <div className="my-10 flex flex-col gap-y-6 md:my-8">
                <p className="text-left">{text}</p>
            </div>
            <div className="fixed bottom-0 left-0 flex h-24 w-full items-center justify-center gap-x-4 px-6 py-6 md:relative md:h-auto md:w-auto md:justify-end md:px-0 md:py-0">
                <Button
                    type="button"
                    color="white-black"
                    size="sm"
                    className="h-full flex-1 md:h-auto md:flex-none"
                    disabled={isLoading}
                    onClick={close}
                >
                    {t('close')}
                </Button>
                <Button
                    type="button"
                    size="sm"
                    className="h-full flex-1 md:h-auto md:flex-none"
                    onClick={onClick}
                    color={buttonColor}
                    disabled={isLoading}
                >
                    {buttonText}
                </Button>
                {additionalButton}
            </div>
        </Modal>
    );
};
