import { loadStripe } from '@stripe/stripe-js';

export const AppEnvVars = {
    FirebaseApiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    FirebaseAuthDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    FirebaseProjectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    FirebaseStorageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    FirebaseMessageSenderId: import.meta.env.VITE_FIREBASE_MESSAGE_SENDER_ID,
    FirebaseAppId: import.meta.env.VITE_FIREBASE_APP_ID,
    FirebaseVapid: import.meta.env.VITE_FIREBASE_VAPID,
    isDevelopment: import.meta.env.VITE_DEVELOPMENT === 'true' ? true : false,
    StripeAPIKey: import.meta.env.VITE_STRIPE_API_KEY ?? '',
    GoogleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    UnderConstruction:
        import.meta.env.VITE_UNDER_CONTSTRUCTION === 'true' ? true : false,
    GridCellType: import.meta.env.VITE_GRID_CELL_TYPE ?? 'HOUR',
    SentryDsn: import.meta.env.VITE_SENTRY_DSN,
    SentryAuthToken: import.meta.env.VITE_SENTRY_AUTH_TOKEN,
    SentryEnabled: import.meta.env.VITE_SENTRY_ENABLED,
    ClientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
    ClientSecret: import.meta.env.VITE_GOOGLE_CLIENT_SECRET,
    IsDevelopment: import.meta.env.DEV,
    IsProduction: import.meta.env.PROD,
    Mode: import.meta.env.MODE,
    AppVersion: import.meta.env.COMMIT_HASH,
};

export const AppConfig = {
    mode: AppEnvVars.Mode,
    isDev: AppEnvVars.IsDevelopment,
    isProd: AppEnvVars.IsProduction,
    appVersion: AppEnvVars.AppVersion,
};

export const FirebasConfig = {
    apiKey: AppEnvVars.FirebaseApiKey,
    authDomain: AppEnvVars.FirebaseAuthDomain,
    projectId: AppEnvVars.FirebaseProjectId,
    storageBucket: AppEnvVars.FirebaseStorageBucket,
    messagingSenderId: AppEnvVars.FirebaseMessageSenderId,
    appId: AppEnvVars.FirebaseAppId,
    vapid: AppEnvVars.FirebaseVapid,
};

export const GoogleConfig = {
    clientSecret: AppEnvVars.ClientSecret,
    clientId: AppEnvVars.ClientId,
    mapKey: AppEnvVars.GoogleMapsApiKey,
    libraries: ['places'],
};

export const SentryConfig = {
    dsn: AppEnvVars.SentryDsn,
    authToken: AppEnvVars.SentryAuthToken,
    enabled: AppEnvVars.SentryEnabled,
};

export const SchedulerConfig = {
    gridCellType: AppEnvVars.GridCellType,
    gridCellTypeNumber: AppEnvVars.GridCellType === 'HOUR' ? 60 : 30,
    maxCellCount: AppEnvVars.GridCellType === 'HOUR' ? 24 : 48,
    gridPixelHeight: 60,
};

export const StripePromise = loadStripe(AppEnvVars.StripeAPIKey);
