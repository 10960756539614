import { NotificationsDropdown } from './NotificationsDrawer';
import { ProfileDropdown } from './ProfileDropdown';
import { SidebarCalendar } from './SidebarCalendar';
import { SidebarRequests } from './SidebarRequests';

export interface SidebarProps {
    type: 'calendar' | 'requests';
}

export const Sidebar = ({ type }: SidebarProps) => {
    return (
        <div
            className={`fixed top-0 right-0 hidden h-full w-[450px] flex-col bg-[#F8F8F8] md:flex`}
        >
            <div className="flex items-center justify-between px-10 pt-10 md:px-10">
                <NotificationsDropdown />
                <ProfileDropdown />
            </div>
            {type === 'calendar' ? <SidebarCalendar /> : null}
            {type === 'requests' ? <SidebarRequests /> : null}
        </div>
    );
};
