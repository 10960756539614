type NestedErrors = {
    [key: string]: unknown;
};

export const getNestedError = (errors: NestedErrors, path: string) => {
    const keys = path.split('.');

    let current = errors;

    for (const key of keys) {
        if (current == null || !(key in current)) {
            return null;
        }
        current = current[key] as unknown as NestedErrors;
    }

    return current;
};
