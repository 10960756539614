import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Icon, IconButton, IconType } from '@bookinbio/ui';
import classNames from 'classnames';

import { SideDrawerContainer } from '../SideDrawerContainer';

interface ProgressDrawerProps {
    isOpen: boolean;
    progress: {
        currProgress: number;
        progressItems: {
            businessProfile: boolean;
            services: boolean;
            locations: boolean;
        };
    };
    close: () => void;
}

export const ProgressDrawer = ({
    isOpen,
    progress,
    close,
}: ProgressDrawerProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <SideDrawerContainer isOpen={isOpen}>
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-10">
                    <IconButton
                        name={IconType.Cross}
                        onClick={close}
                        size={20}
                        className="flex h-10 w-10 items-center justify-center rounded text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-100 hover:!text-black"
                    />
                    <div className="text-xl">{t('setup.progress')}</div>
                </div>
                <div
                    className="daisy-radial-progress bg-gray-100 text-sm transition-all duration-300 ease-in-out group-hover:bg-gray-200"
                    style={{
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        '--value': progress.currProgress,
                        '--size': '4rem',
                    }}
                    role="progressbar"
                >
                    {progress.currProgress}%
                </div>
            </div>
            {/* TODO: Add small longer text, like setup these following things to finish your setup and fully utilize you experience or whatever */}
            <div className="my-10 text-gray-500">
                {t('setup.progress.subtext')}
            </div>
            <div className="flex flex-col gap-y-4">
                <div
                    className={classNames(
                        'flex w-full items-center justify-between rounded-xl border p-4',
                        progress.progressItems.businessProfile
                            ? 'border-green-500'
                            : 'border-amber-500',
                    )}
                >
                    {progress.progressItems.businessProfile ? (
                        <Icon
                            name={IconType.SuccessCircled}
                            size={36}
                            className="text-green-700"
                        />
                    ) : (
                        <Icon
                            name={IconType.Waiting}
                            size={36}
                            className="text-amber-500"
                        />
                    )}
                    <div className="text-right">
                        <div
                            className={classNames(
                                'mb-2 text-xl font-medium',
                                progress.progressItems.businessProfile
                                    ? 'text-gray-500'
                                    : 'text-black',
                            )}
                        >
                            {t('business.profile')}
                        </div>
                        <button
                            className={classNames(
                                'hover:text-blue-500',
                                progress.progressItems.businessProfile
                                    ? 'text-gray-500'
                                    : 'text-blue-700',
                            )}
                            onClick={() => {
                                navigate('/settings/business/profile');
                                close();
                            }}
                        >
                            {t('go.there')}
                        </button>
                    </div>
                </div>
                <div
                    className={classNames(
                        'flex w-full items-center justify-between rounded-xl border p-4',
                        progress.progressItems.locations
                            ? 'border-green-500'
                            : 'border-amber-500',
                    )}
                >
                    {progress.progressItems.locations ? (
                        <Icon
                            name={IconType.SuccessCircled}
                            size={36}
                            className="text-green-700"
                        />
                    ) : (
                        <Icon
                            name={IconType.Waiting}
                            size={36}
                            className="text-amber-500"
                        />
                    )}
                    <div className="text-right">
                        <div
                            className={classNames(
                                'mb-2 text-xl font-medium',
                                progress.progressItems.locations
                                    ? 'text-gray-500'
                                    : 'text-black',
                            )}
                        >
                            {t('locations')}
                        </div>
                        <button
                            className={classNames(
                                'hover:text-blue-500',
                                progress.progressItems.locations
                                    ? 'text-gray-500'
                                    : 'text-blue-700',
                            )}
                            onClick={() => {
                                navigate('/management/locations');
                                close();
                            }}
                        >
                            {t('go.there')}
                        </button>
                    </div>
                </div>
                <div
                    className={classNames(
                        'flex w-full items-center justify-between rounded-xl border p-4',
                        progress.progressItems.services
                            ? 'border-green-500'
                            : 'border-amber-500',
                    )}
                >
                    {progress.progressItems.services ? (
                        <Icon
                            name={IconType.SuccessCircled}
                            size={36}
                            className="text-green-700"
                        />
                    ) : (
                        <Icon
                            name={IconType.Waiting}
                            size={36}
                            className="text-amber-500"
                        />
                    )}
                    <div className="text-right">
                        <div
                            className={classNames(
                                'mb-2 text-xl font-medium',
                                progress.progressItems.services
                                    ? 'text-gray-500'
                                    : 'text-black',
                            )}
                        >
                            {t('services')}
                        </div>
                        <button
                            className={classNames(
                                'hover:text-blue-500',
                                progress.progressItems.services
                                    ? 'text-gray-500'
                                    : 'text-blue-700',
                            )}
                            onClick={() => {
                                navigate('/management/services');
                                close();
                            }}
                        >
                            {t('go.there')}
                        </button>
                    </div>
                </div>
            </div>
        </SideDrawerContainer>
    );
};
