export enum BookinbioRoutes {
    // Non Auth
    Login = '/login',
    Register = '/register/*',
    Recovery = '/recovery',
    ResetPassword = '/reset-password',
    Invitation = '/invitation',
    AuthAction = '/auth-action',

    // Auth
    Dashboard = '/dashboard',
    Calendar = '/calendar/*',
    Management = '/management/*',
    CreateService = '/management/services/create',
    EditService = '/management/services/edit/:id/*',
    CreateTeamMember = '/management/team/create',
    EditTeamMember = '/management/team/edit/:id/*',
    EditTeamMemberWorkingHours = '/management/team/edit/working-hours/:id/*',
    CreateLocation = '/management/locations/create',
    EditLocation = '/management/locations/edit/:id/*',
    CreateRent = '/management/rents/create',
    EditRent = '/management/rents/edit/:id/*',
    Clients = '/clients/*',
    Requests = '/requests/*',
    CreateClient = '/clients/create',
    EditClient = '/clients/edit/:id/*',
    MergeClient = '/clients/merge/:id/*',
    Creative = '/creative/*',
    Settings = '/settings/*',
    InitialSetup = '/initial-setup/*',
    DefaultPath = '/',
}
