import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useBusiness } from '../../context/BusinessContext';

import { ProgressDrawer } from './ProgressDrawer';

interface ProgressButtonProps {
    className?: string;
}

export const ProgressButton = ({ className }: ProgressButtonProps) => {
    const { t } = useTranslation();
    const {
        business,
        services,
        locations,
        isLocationLoading,
        isServsLoading,
        isLoading,
    } = useBusiness();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const progress = useMemo(() => {
        let currProgress = 0;
        const progressItems = {
            businessProfile: false,
            locations: false,
            services: false,
        };

        if (business?.socialName !== null) {
            currProgress += 30;
            progressItems.businessProfile = true;
        }

        if (services && services.length > 0) {
            currProgress += 35;
            progressItems.services = true;
        }

        if (locations && locations.length > 0) {
            currProgress += 35;
            progressItems.locations = true;
        }

        return { currProgress, progressItems };
    }, [business, services, locations]);

    const handleToggle = () => {
        setIsOpen((p) => !p);
    };

    const handleClose = () => {
        setIsOpen((p) => !p);
    };

    if (isLocationLoading || isServsLoading || isLoading) {
        return null;
    }

    return progress.currProgress !== 100 ? (
        <>
            <button
                className={classNames(
                    'group flex h-full items-center gap-x-3 rounded-xl transition-all duration-300 md:bg-gray-50 md:px-2 md:shadow-xl hover:md:bg-gray-100',
                    className,
                )}
                onClick={handleToggle}
            >
                <div className="hidden text-lg md:block">
                    {t('finish.your.setup')}
                </div>
                <div
                    className="daisy-radial-progress bg-gray-100 text-sm transition-all duration-300 ease-in-out group-hover:bg-gray-200"
                    style={{
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        '--value': progress.currProgress,
                        '--size': '3rem',
                    }}
                    role="progressbar"
                >
                    {progress.currProgress}%
                </div>
            </button>
            <ProgressDrawer
                isOpen={isOpen}
                close={handleClose}
                progress={progress}
            />
        </>
    ) : null;
};
