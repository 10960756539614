import { Business, Category, Service } from '@bookinbio/interface';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';

import { businessesColl } from '../collections';

export interface ServiceWCategory extends Service {
    category: Category;
}

export const getServicesByBusiness = async (
    business?: Business,
): Promise<ServiceWCategory[]> => {
    if (!business) {
        throw new Error('Missing business id');
    }

    const serviceSnaps = await getDocs(
        collection(doc(businessesColl, business.id), 'services'),
    );

    const categoryCache: Record<string, Category> = {};

    return Promise.all(
        serviceSnaps.docs.map(async (doc, idx) => {
            const serviceData = { ...doc.data() } as Omit<Service, 'id'>;
            const categoryRef = serviceData.basicInfo.categoryRef;

            // Check if category is already in cache
            const category = categoryCache[categoryRef.id]
                ? Promise.resolve(categoryCache[categoryRef.id])
                : getDoc(categoryRef).then((categorySnap) => {
                      const categoryData = {
                          ...categorySnap.data(),
                          id: categorySnap.id,
                      } as Category;
                      categoryCache[categoryRef.id] = categoryData;
                      return categoryData;
                  });

            return {
                ...serviceData,
                basicInfo: {
                    ...serviceData.basicInfo,
                    order: serviceData.basicInfo.order ?? 999,
                    isFavorite: serviceData.basicInfo.isFavorite ?? false,
                },
                id: doc.id,
                category: await category,
            } as ServiceWCategory;
        }),
    );
};
