export enum RepeatType {
    DoesNotRepeat = 'DOES_NOT_REPEAT',
    EveryDay = 'EVERY_DAY',
    EveryWeek = 'EVERY_WEEK',
    EveryMonth = 'EVERY_MONTH',
    Custom = 'CUSTOM',
}

export enum FrequencyType {
    Day = 'DAY',
    Week = 'WEEK',
    Month = 'MONTH',
}
