import { SyntheticEvent, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { IconButton, IconType } from './Icon';
import { getNestedError } from './utils';
import { WithLabel, WithLabelProps } from './WithLabel';

export interface TextInputControllerProps<T extends string> {
    name: T;
    type?:
        | 'text'
        | 'password'
        | 'email'
        | 'number'
        | 'time'
        | 'tel'
        | 'textarea';
    step?: number;
    placeholder?: string;
    errorless?: boolean;
    className?: string;
    ctrClassName?: string;
    prefix?: string;
    prefixClassName?: string;
    errorClassName?: string;
    disabled?: boolean;
    min?: number;
    asType?: 'string' | 'number';
    additionalChangeHandler?: (value: string) => void;
}

export const TextInputController = <T extends string = string>({
    name,
    type = 'text',
    step,
    placeholder,
    errorless = false,
    className,
    ctrClassName,
    prefix,
    prefixClassName,
    errorClassName,
    disabled,
    min,
    asType = 'string',
    additionalChangeHandler,
}: TextInputControllerProps<T>) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [currentType, setCurrentType] = useState(type);
    const {
        register,
        setFocus,
        formState: { errors },
    } = useFormContext();
    const { ref, ...rest } = register(name, {
        valueAsNumber: asType === 'number' && type === 'number' ? true : false,
        onChange: (e: SyntheticEvent) => {
            const value = (e.target as HTMLInputElement).value;
            additionalChangeHandler && additionalChangeHandler(value);
        },
    });

    const error = getNestedError(errors, name);

    return (
        <div className={classNames('w-full', ctrClassName)}>
            <div
                className={classNames(
                    'relative flex w-full items-center gap-x-1 border-b',
                    error && !errorless ? 'border-red-500' : 'border-gray-500',
                )}
            >
                {prefix ? (
                    <span
                        className={classNames(
                            'select-none pl-3 text-base text-black sm:text-base',
                            prefixClassName,
                        )}
                        onClick={() => setFocus(name)}
                    >
                        {prefix}
                    </span>
                ) : null}
                <input
                    ref={(e) => {
                        ref(e);
                        inputRef.current = e; // you can still assign to ref
                    }}
                    {...rest}
                    type={currentType}
                    className={classNames(
                        'w-full bg-transparent px-3 py-3 text-base outline-none sm:text-base',
                        className,
                        prefix ? '!pl-0' : null,
                    )}
                    step={step}
                    min={min}
                    disabled={disabled}
                    placeholder={placeholder}
                    onWheel={(e) => {
                        e.preventDefault();
                        if (inputRef.current) {
                            inputRef.current.blur();
                        }
                    }}
                />
                {type === 'password' ? (
                    currentType === 'password' ? (
                        <IconButton
                            className="h-full shrink-0 py-1 sm:p-3"
                            name={IconType.CloseEye}
                            onClick={() => setCurrentType('text')}
                        />
                    ) : (
                        <IconButton
                            className="h-full shrink-0 py-1 sm:p-3"
                            name={IconType.OpenEye}
                            onClick={() => setCurrentType('password')}
                        />
                    )
                ) : null}
            </div>
            {!errorless && error ? (
                <p
                    className={classNames(
                        'mb-2 h-3 text-left text-xs text-red-700',
                        errorClassName,
                    )}
                >
                    {t(error.message as string)}
                </p>
            ) : null}
        </div>
    );
};

export interface TextInputProps<T extends string>
    extends TextInputControllerProps<T>,
        Omit<WithLabelProps, 'name' | 'children'> {
    label: string;
    className?: string;
    labelClassName?: string;
    inputCtrClassName?: string;
    inputClassName?: string;
}

export const TextInput = <T extends string = string>({
    name,
    label,
    type = 'text',
    step,
    placeholder,
    className,
    labelClassName,
    inputCtrClassName,
    inputClassName,
    prefixClassName,
    errorClassName,
    prefix,
    errorless,
    right,
    asType,
    disabled,
    additionalChangeHandler,
}: TextInputProps<T>) => {
    return (
        <WithLabel
            label={label}
            name={name}
            className={className}
            labelClassName={labelClassName}
            right={right}
        >
            <TextInputController
                name={name}
                placeholder={placeholder}
                type={type}
                step={step}
                prefix={prefix}
                errorless={errorless}
                ctrClassName={inputCtrClassName}
                className={inputClassName}
                prefixClassName={prefixClassName}
                disabled={disabled}
                asType={asType}
                errorClassName={errorClassName}
                additionalChangeHandler={additionalChangeHandler}
            />
        </WithLabel>
    );
};
