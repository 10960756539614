import { useEffect, useState } from 'react';
import { Path, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EndType, FrequencyType, RepeatType } from '@bookinbio/enums';
import { Client, Rent, Service, User } from '@bookinbio/interface';
import {
    Button,
    DatePickerInput,
    Form,
    IconButton,
    IconType,
    SelectInput,
    SelectInputController,
    Switch,
    TextareaInput,
    TextInputController,
    WithLabel,
} from '@bookinbio/ui';
import {
    combineDateAndTime,
    CurrencyType,
    END_TYPE_OPTIONS,
    formatDuration2,
    FREQUENCY_TYPE_OPTIONS,
    REPEAT_TYPE_OPTIONS,
    TIME_OPTIONS,
    timeSearchParamToTime,
    timeToSearchParamTime,
    transformToDate,
    unitAmountToPrice,
} from '@bookinbio/utils';
import { Tab } from '@headlessui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { addMonths, addYears, format } from 'date-fns';
import { z } from 'zod';

import { useBusiness } from '../../../context/BusinessContext';
import { createAppointment } from '../../../utils/firebase/callable';
import { DaysCheckbox } from '../../CheckboxDays';
import { DefaultTab } from '../../DefaultTab';
import { SideDrawerContainer } from '../../SideDrawerContainer';
import { useCalendar } from '../AppointmentsContext';

import { ClientSearchSelect } from './ClientSearchSelect';
import { ServiceSearchSelect } from './ServicesSearchSelect';

interface NewAppointmentDrawerProps {
    close: () => void;
}

export const AppointmentSchema = z
    .object({
        serviceIds: z.array(z.string()),
        clientIds: z.array(z.string()).nullable(),
        rentIds: z.array(z.string()).nullable(),
        professionalIds: z.array(z.string()).nullable(),
        date: z.date(),
        startTime: z.string(),
        notes: z.string().trim().optional(),

        repeatType: z.string(),
        endType: z.string(),
        endDate: z.date(),
        occurenceValue: z.number(),
        customRepeatSettings: z.object({
            frequencyType: z.string(),
            days: z.array(z.number()),
            value: z.number(),
        }),
    })
    .refine((val) => {
        if (
            (!val.professionalIds || val.professionalIds.length === 0) &&
            !val.rentIds
        ) {
            return false;
        }

        return true;
    });

export type AppointmentForm = z.infer<typeof AppointmentSchema>;
export type AppointmentFormProps = Path<AppointmentForm>;

export const NewAppointmentDrawer = ({ close }: NewAppointmentDrawerProps) => {
    const { t } = useTranslation();
    const { business, locations } = useBusiness();
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        clients,
        services,
        isServicesLoading,
        isClientsLoading,
        refetchAppointments,
    } = useCalendar();
    const [isServicesShowing, setIsServicesShowing] = useState(false);
    const [isClientsShowing, setIsClientsShowing] = useState(false);
    // const [isProfessionalsShowing, setIsProfessionalsShowing] = useState(false);
    // const [isRentsShowing, setIsRentsShowing] = useState(false);
    const dateParam = searchParams.get('appt_date');
    const timeParam = searchParams.get('appt_time');
    const isProposition = searchParams.get('is_proposition');

    const methods = useForm<AppointmentForm>({
        defaultValues: {
            clientIds: [],
            serviceIds: [],
            rentIds: null,
            professionalIds: null,
            endDate: addMonths(new Date(), 1),
            occurenceValue: 5,
            repeatType: REPEAT_TYPE_OPTIONS[0].value,
            endType: END_TYPE_OPTIONS[0].value,

            customRepeatSettings: {
                frequencyType: FREQUENCY_TYPE_OPTIONS[1].value,
                value: 1,
                days: [1, 3, 5],
            },
        },
        mode: 'all',
        reValidateMode: 'onChange',
        // resolver: zodResolver(AppointmentSchema),
        resolver: async (data, context, options) => {
            console.log(
                'validation result',
                await zodResolver(AppointmentSchema)(data, context, options),
            );
            return zodResolver(AppointmentSchema)(data, context, options);
        },
    });
    const [repeatType, endType, frequencyType] = methods.watch([
        'repeatType',
        'endType',
        'customRepeatSettings.frequencyType',
    ]);

    // Search params
    const clientIdsString = searchParams.get('appt_client_ids');
    const clientIds = clientIdsString
        ? (JSON.parse(clientIdsString) as string[])
        : null;
    const serviceIdsString = searchParams.get('appt_service_ids');
    const serviceIds = serviceIdsString
        ? (JSON.parse(serviceIdsString) as string[])
        : null;
    // const professionalIdsString = searchParams.get('appt_professional_ids');
    // const professionalIds = professionalIdsString
    //     ? (JSON.parse(professionalIdsString) as string[])
    //     : null;

    // const rentIdsString = searchParams.get('appt_rent_ids');
    // const rentIds = rentIdsString
    //     ? (JSON.parse(rentIdsString) as string[])
    //     : null;

    const professionalIdString = searchParams.get('professional_id');
    const professionalId = professionalIdString ? professionalIdString : null;

    const rentIdString = searchParams.get('rent_id');
    const rentId = rentIdString ? rentIdString : null;

    const selectedClients =
        clients && clientIds
            ? clients.filter((client) => clientIds.includes(client.id))
            : null;

    const selectedServices =
        services && serviceIds
            ? services.filter((service) => serviceIds.includes(service.id))
            : null;

    // const selectedProfessionals =
    //     professionals && professionalIds
    //         ? professionals.filter((professional) =>
    //               professionalIds.includes(professional.id),
    //           )
    //         : null;

    // const selectedRents =
    //     rents && rentIds
    //         ? rents.filter((rent) => rentIds.includes(rent.id))
    //         : null;

    const total = selectedServices
        ? selectedServices.reduce(
              (acc, curr) => acc + (curr.serviceSettings.amount ?? 0),
              0,
          )
        : 0;

    const duration = selectedServices
        ? selectedServices.reduce(
              (acc, curr) => acc + curr.serviceSettings.duration,
              0,
          )
        : 0;

    // const serviceType = selectedServices
    //     ? selectedServices[0].basicInfo.serviceType
    //     : ServiceType.None;

    const { mutateAsync: createAppoint, isLoading: isCreating } = useMutation({
        mutationFn: createAppointment,
    });

    useEffect(() => {
        if (!timeParam || !dateParam) {
            close();
        } else {
            const startTime = timeSearchParamToTime(timeParam);
            const date = transformToDate(dateParam);
            methods.setValue('startTime', startTime ?? '09:00');
            methods.setValue('date', date);
        }
    }, [close, dateParam, methods, timeParam]);

    useEffect(() => {
        if (clientIds) {
            methods.setValue('clientIds', clientIds);
        }
    }, [methods, clientIds]);

    useEffect(() => {
        if (serviceIds) {
            methods.setValue('serviceIds', serviceIds);
        }
    }, [methods, serviceIds]);

    useEffect(() => {
        if (professionalId) {
            methods.setValue('professionalIds', [professionalId]);
        }
    }, [methods, professionalId]);

    useEffect(() => {
        if (rentId) {
            methods.setValue('rentIds', [rentId]);
        }
    }, [methods, rentId]);

    // Handlers
    const handleDateSelect = (date?: Date) => {
        if (!date) {
            return;
        }
        searchParams.set('appt_date', format(date, 'dd-MM-yyyy'));
        setSearchParams(searchParams);
    };

    const handleStartTimeSelect = (value: string | number | null) => {
        if (!value || typeof value === 'number') {
            return;
        }
        const time = timeToSearchParamTime(value);

        if (!time) {
            return;
        }

        searchParams.set('appt_time', time);
        setSearchParams(searchParams);
    };

    // Select only one
    const handleClientSelect = (clientId: string) => {
        let newClientIds = clientIds ? [...clientIds] : [];

        if (newClientIds.includes(clientId)) {
            newClientIds = newClientIds.filter((id) => id !== clientId);
        } else {
            newClientIds.push(clientId);
        }

        if (newClientIds.length === 0) {
            searchParams.delete('appt_client_ids');
        } else {
            searchParams.set('appt_client_ids', JSON.stringify(newClientIds));
        }
        setSearchParams(searchParams);
    };

    const handleServiceSelect = (serviceId: string) => {
        let newServiceIds = serviceIds ? [...serviceIds] : [];

        if (newServiceIds.includes(serviceId)) {
            newServiceIds = newServiceIds.filter((id) => id !== serviceId);
        } else {
            newServiceIds.push(serviceId);
        }

        if (newServiceIds.length === 0) {
            searchParams.delete('appt_service_ids');
        } else {
            searchParams.set('appt_service_ids', JSON.stringify(newServiceIds));
        }
        setSearchParams(searchParams);
    };

    const handleSubmit = async (data: AppointmentForm) => {
        if (!business || !selectedServices || !locations) {
            return;
        }

        const endDate = data.endDate ? data.endDate.toISOString() : null;

        const serviceType = selectedServices[0].basicInfo.serviceType;

        try {
            const start = combineDateAndTime(
                data.date.toISOString(),
                data.startTime,
            ).toISOString();

            await createAppoint({
                serviceIds: data.serviceIds,
                businessId: business.id,
                professionalIds: data.professionalIds,
                rentIds: data.rentIds,
                locationId: locations[0].id,
                serviceType: serviceType,
                clientIds: data.clientIds ?? null,
                start,
                timeZone: business.calendarSettings.timeZone,
                duration: duration,
                notes: data.notes,
                repeatType: data.repeatType,
                customRepeatSettings:
                    data.repeatType === RepeatType.Custom
                        ? data.customRepeatSettings
                        : null,
                endType:
                    data.repeatType !== RepeatType.DoesNotRepeat
                        ? data.endType
                        : null,
                occurenceValue:
                    data.endType === EndType.After ? data.occurenceValue : null,
                endDate: data.endType === EndType.SpecificDate ? endDate : null,
                isProposition:
                    isProposition && isProposition === 'true' ? true : false,
            });
            toast.success(t('appointment.create.success'));
            refetchAppointments();
            close();
        } catch (error) {
            console.error(error);
            toast.error(t('appointment.create.error'));
        }
    };

    return (
        <SideDrawerContainer isOpen size="sm">
            <div className="flex items-center gap-x-10 md:mb-6">
                <IconButton name={IconType.Cross} onClick={close} size={20} />

                <h3 className="font-excon text-2xl font-bold tracking-wider">
                    {isProposition && isProposition === 'true'
                        ? t('appointment.counteroffer')
                        : t('appointment.new')}
                </h3>
            </div>
            <Form<AppointmentForm>
                methods={methods}
                onSubmit={handleSubmit}
                className="flex flex-1 flex-col overflow-hidden"
            >
                <Tab.Group>
                    <Tab.List className="flex items-center gap-x-6 pt-3 pb-6">
                        <DefaultTab>{t('information')}</DefaultTab>
                        <DefaultTab>{t('notes')}</DefaultTab>
                    </Tab.List>
                    <Tab.Panels className="flex flex-1 flex-col overflow-auto">
                        <Tab.Panel className="flex flex-col gap-y-5 pb-6">
                            <ClientCard
                                clients={selectedClients}
                                edit={() => setIsClientsShowing(true)}
                                disabled={
                                    !!isProposition && isProposition === 'true'
                                }
                            />
                            <ServiceCard
                                services={selectedServices}
                                edit={() => setIsServicesShowing(true)}
                            />
                            {/* {serviceType === ServiceType.Rent ? (
                                <>
                                    <ProfessionalCard
                                        professionals={selectedProfessionals}
                                        edit={() =>
                                            setIsProfessionalsShowing(true)
                                        }
                                    />
                                    <RentCard
                                        rents={selectedRents}
                                        edit={() => setIsRentsShowing(true)}
                                    />
                                </>
                            ) : null} */}
                            <DatePickerInput<AppointmentFormProps>
                                label={t('date')}
                                name="date"
                                onChange={handleDateSelect}
                            />
                            <SelectInput<AppointmentFormProps>
                                label={t('time.start')}
                                name="startTime"
                                translate={false}
                                options={TIME_OPTIONS}
                                menuPlacement="top"
                                onChange={handleStartTimeSelect}
                            />
                            <SelectInput<AppointmentFormProps>
                                label={t('repeat')}
                                name="repeatType"
                                options={REPEAT_TYPE_OPTIONS}
                                className="col-span-2"
                                menuPlacement="top"
                            />
                            {repeatType !== RepeatType.DoesNotRepeat ? (
                                <>
                                    {repeatType === RepeatType.Custom ? (
                                        <>
                                            <WithLabel
                                                label={t('repeat.every')}
                                                className="col-span-2 "
                                            >
                                                <div className="flex items-center justify-start gap-x-4">
                                                    <TextInputController<AppointmentFormProps>
                                                        name="customRepeatSettings.value"
                                                        type="number"
                                                        asType="number"
                                                        ctrClassName="!w-32"
                                                    />
                                                    <div className="flex-1">
                                                        <SelectInputController<AppointmentFormProps>
                                                            name="customRepeatSettings.frequencyType"
                                                            options={
                                                                FREQUENCY_TYPE_OPTIONS
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </WithLabel>
                                            {frequencyType ===
                                            FrequencyType.Week ? (
                                                <WithLabel
                                                    label={t('ondays')}
                                                    className="col-span-2"
                                                >
                                                    <DaysCheckbox<AppointmentFormProps> name="customRepeatSettings.days" />
                                                </WithLabel>
                                            ) : null}
                                        </>
                                    ) : null}
                                    <SelectInput<AppointmentFormProps>
                                        name="endType"
                                        label={t('ends')}
                                        options={END_TYPE_OPTIONS}
                                        className="col-span-2"
                                    />
                                    {endType === EndType.After ? (
                                        <div className="flex items-center">
                                            <TextInputController<AppointmentFormProps>
                                                name="occurenceValue"
                                                type="number"
                                                asType="number"
                                                min={1}
                                            />
                                            <span>{t('occurences')}</span>
                                        </div>
                                    ) : null}
                                    {endType === EndType.SpecificDate ? (
                                        <DatePickerInput<AppointmentFormProps>
                                            label={t('date.end')}
                                            name="endDate"
                                            className="col-span-2"
                                            fromYear={new Date().getFullYear()}
                                            toYear={addYears(
                                                new Date(),
                                                5,
                                            ).getFullYear()}
                                        />
                                    ) : null}
                                </>
                            ) : null}
                        </Tab.Panel>
                        <Tab.Panel className="flex flex-1 flex-col pb-16">
                            <TextareaInput<AppointmentFormProps>
                                label={t('notes')}
                                placeholder={t('notes.appointment.placeholder')}
                                name="notes"
                                className="flex-1"
                                inputCtrClassName="flex-1"
                                inputClassName="h-full"
                            />
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
                <div className="flex w-full flex-col justify-center gap-y-5">
                    <div className="flex items-center justify-between text-lg">
                        <div>{t('total')}</div>
                        <div className="font-bold">
                            {total > 0
                                ? unitAmountToPrice(total, 'eur')
                                : t('free')}
                        </div>
                    </div>
                    <Button
                        type="submit"
                        color="black"
                        className="w-full"
                        disabled={isCreating}
                    >
                        {isCreating ? (
                            <div className="flex items-center justify-center">
                                <ThreeDots
                                    height="24"
                                    radius="9"
                                    color="#ffffff"
                                    ariaLabel="three-dots-loading"
                                    visible={true}
                                />
                            </div>
                        ) : (
                            t('save')
                        )}
                    </Button>
                </div>
                <SideDrawerContainer isOpen={isServicesShowing}>
                    <ServiceSearchSelect
                        services={services}
                        isLoading={isServicesLoading}
                        close={() => setIsServicesShowing(false)}
                        onSelect={handleServiceSelect}
                    />
                </SideDrawerContainer>
                <SideDrawerContainer isOpen={isClientsShowing}>
                    <ClientSearchSelect
                        clients={clients}
                        isLoading={isClientsLoading}
                        onSelect={handleClientSelect}
                        close={() => setIsClientsShowing(false)}
                    />
                </SideDrawerContainer>
                {/* <SideDrawerContainer isOpen={isProfessionalsShowing}>
                    <ClientSearchSelect
                        name="professionalIds"
                        clients={clients}
                        isLoading={isProfessionalsLoading}
                        onSelect={handleProfessionalSelect}
                        close={() => setIsProfessionalsShowing(false)}
                    />
                </SideDrawerContainer>
                <SideDrawerContainer isOpen={isRentsShowing}>
                    <ClientSearchSelect
                        name="rentIds"
                        clients={clients}
                        isLoading={isRentsLoading}
                        onSelect={handleRentSelect}
                        close={() => setIsRentsShowing(false)}
                    />
                </SideDrawerContainer> */}
            </Form>
        </SideDrawerContainer>
    );
};

interface ClientCardProps {
    clients?: Client[] | null;
    disabled?: boolean;
    edit: () => void;
}

export const ClientCard = ({ clients, disabled, edit }: ClientCardProps) => {
    const { t } = useTranslation();
    return (
        <button
            type="button"
            className="h-24 w-full rounded-lg border border-gray-500 p-5 transition-all duration-200 hover:bg-gray-100 disabled:bg-gray-100"
            onClick={edit}
            disabled={disabled}
        >
            <Switch>
                <Switch.Case condition={!!clients && clients.length === 1}>
                    {clients ? (
                        <div className="flex items-center gap-x-6">
                            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-black text-white">
                                {clients[0].firstName[0].toUpperCase()}
                                {clients[0].lastName
                                    ? clients[0].lastName[0].toUpperCase()
                                    : ''}
                            </div>
                            <div className="text-lg">
                                {clients[0].firstName} {clients[0].lastName}
                            </div>
                        </div>
                    ) : null}
                </Switch.Case>
                {/* TODO: Add multi services design card */}
                <Switch.Case condition={!!clients && clients.length > 1}>
                    <div className="text-lg">
                        {t('clientcard.multiple.clients')}
                    </div>
                </Switch.Case>
                <Switch.Default>
                    <div>
                        <div className="text-lg">{t('clientcard.empty')}</div>
                        <span className="text-sm text-gray-400">
                            {t('clientcard.empty.subtitle')}
                        </span>
                    </div>
                </Switch.Default>
            </Switch>
        </button>
    );
};

interface ServiceCardProps {
    services?: Service[] | null;
    disabled?: boolean;
    edit: () => void;
}

export const ServiceCard = ({ services, disabled, edit }: ServiceCardProps) => {
    const { t } = useTranslation();
    return (
        <button
            type="button"
            className="min-h-[6rem] w-full rounded-lg border border-gray-500 p-5 transition-all duration-200 hover:bg-gray-100 disabled:bg-gray-100"
            onClick={edit}
            disabled={disabled}
        >
            <Switch>
                <Switch.Case condition={!!services && services.length === 1}>
                    {services ? (
                        <div className="flex h-full flex-col justify-between">
                            <div className="flex items-center justify-between gap-x-6">
                                <div className="text-left text-lg">
                                    {services[0].basicInfo.name.default}
                                </div>
                                <div className="font-bold">
                                    {services[0].serviceSettings.amount
                                        ? unitAmountToPrice(
                                              services[0].serviceSettings
                                                  .amount,
                                              services[0].serviceSettings
                                                  .currency as CurrencyType,
                                          )
                                        : t('free')}
                                </div>
                            </div>
                            <div className="text-left">
                                {formatDuration2(
                                    services[0].serviceSettings.duration,
                                )}
                            </div>
                        </div>
                    ) : null}
                </Switch.Case>
                {/* TODO: Add multi services design card */}
                <Switch.Case condition={!!services && services.length > 1}>
                    <div className="text-lg">
                        {t('servicecard.multiple.services')}
                    </div>
                </Switch.Case>
                <Switch.Default>
                    <div className="text-lg">{t('servicecard.empty')}</div>
                </Switch.Default>
            </Switch>
        </button>
    );
};

interface ProfessionalCardProps {
    professionals?: User[] | null;
    disabled?: boolean;
    edit: () => void;
}

export const ProfessionalCard = ({
    professionals,
    disabled,
    edit,
}: ProfessionalCardProps) => {
    const { t } = useTranslation();
    return (
        <button
            type="button"
            className="h-24 w-full rounded-lg border border-gray-500 p-5 transition-all duration-200 hover:bg-gray-100 disabled:bg-gray-100"
            onClick={edit}
            disabled={disabled}
        >
            <Switch>
                <Switch.Case
                    condition={!!professionals && professionals.length === 1}
                >
                    {professionals ? (
                        <div className="flex items-center gap-x-6">
                            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-black text-white">
                                {professionals[0].firstName
                                    ? professionals[0].firstName[0].toUpperCase()
                                    : ''}
                                {professionals[0].lastName
                                    ? professionals[0].lastName[0].toUpperCase()
                                    : ''}
                            </div>
                            <div className="text-lg">
                                {professionals[0].firstName}{' '}
                                {professionals[0].lastName}
                            </div>
                        </div>
                    ) : null}
                </Switch.Case>
                {/* TODO: Add multi professionals design card */}
                <Switch.Case
                    condition={!!professionals && professionals.length > 1}
                >
                    <div className="text-lg">
                        {t('professionalcard.multiple.clients')}
                    </div>
                </Switch.Case>
                <Switch.Default>
                    <div>
                        <div className="text-lg">
                            {t('professionalcard.empty')}
                        </div>
                        <span className="text-sm text-gray-400">
                            {t('professionalcard.empty.subtitle')}
                        </span>
                    </div>
                </Switch.Default>
            </Switch>
        </button>
    );
};

interface RentCardProps {
    rents?: Rent[] | null;
    disabled?: boolean;
    edit: () => void;
}

export const RentCard = ({ rents, disabled, edit }: RentCardProps) => {
    const { t } = useTranslation();
    return (
        <button
            type="button"
            className="min-h-[6rem] w-full rounded-lg border border-gray-500 p-5 transition-all duration-200 hover:bg-gray-100 disabled:bg-gray-100"
            onClick={edit}
            disabled={disabled}
        >
            <Switch>
                <Switch.Case condition={!!rents && rents.length === 1}>
                    {rents ? (
                        <div className="flex h-full flex-col justify-between">
                            <div className="flex items-center justify-between gap-x-6">
                                <div className="text-left text-lg">
                                    {rents[0].name}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </Switch.Case>
                {/* TODO: Add multi services design card */}
                <Switch.Case condition={!!rents && rents.length > 1}>
                    <div className="text-lg">
                        {t('servicecard.multiple.services')}
                    </div>
                </Switch.Case>
                <Switch.Default>
                    <div className="text-lg">{t('servicecard.empty')}</div>
                </Switch.Default>
            </Switch>
        </button>
    );
};
