import {
    AppointmentHandleOption,
    AppointmentStatusType,
} from '@bookinbio/enums';
import {
    collection,
    doc,
    serverTimestamp,
    updateDoc,
} from 'firebase/firestore';

import { businessesColl } from '../collections';

interface ApproveRequestArgs {
    businessId: string;
    appointmentId: string;
}

export const approveRequest = async ({
    businessId,
    appointmentId,
}: ApproveRequestArgs): Promise<void> => {
    const appointmentsColl = collection(
        businessesColl,
        businessId,
        'appointments',
    );
    const appointmentDoc = doc(appointmentsColl, appointmentId);

    updateDoc(appointmentDoc, {
        status: AppointmentStatusType.Approved,
        purpose: AppointmentHandleOption.ActionByBusiness,
        updatedAt: serverTimestamp(),
    });
};
