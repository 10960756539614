import { useTranslation } from 'react-i18next';

export const SidebarRequests = () => {
    const { t } = useTranslation();
    return (
        <div className="mt-3 flex flex-1 flex-col overflow-auto pl-10 pr-6">
            <h3 className="text-xl">{t('requests')}</h3>
            <span
                role="img"
                aria-label="in development"
                className="mt-10 text-2xl"
            >
                🚧 IN DEVELOPMENT 🚧
            </span>
        </div>
    );
};
