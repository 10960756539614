import { Business, Override } from '@bookinbio/interface';
import { endOfWeek, format, startOfWeek } from 'date-fns';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';

import { businessesColl, usersColl } from '../collections';

export type GroupedOverrides = Record<string, Override[]>;

export const getOverridesByBusiness = async (
    date: Date,
    business?: Business,
    professionalId?: string | null,
    rentId?: string | null,
) => {
    if (!business) {
        throw new Error('Missing business');
    }

    if (!professionalId && !rentId) {
        throw new Error('Missing professional id and rent id');
    }

    const type = professionalId ? 'service' : rentId ? 'rent' : null;

    if (!type) {
        throw new Error('Error occured while resolving type');
    }

    const startOfTheWeek = startOfWeek(date, { weekStartsOn: 1 });
    const endOfTheWeek = endOfWeek(date, { weekStartsOn: 1 });

    const overridesColl = collection(
        doc(businessesColl, business.id),
        'overrides',
    );

    let overridesQ = null;

    if (type === 'service' && professionalId) {
        const profRef = doc(usersColl, professionalId);

        overridesQ = query(
            overridesColl,
            where('start', '>=', startOfTheWeek),
            where('start', '<=', endOfTheWeek),
            where('professionalRefs', 'array-contains', profRef),
        );
    }

    if (type === 'rent' && rentId) {
        const rentRef = doc(
            collection(businessesColl, business.id, 'rents'),
            rentId,
        );

        overridesQ = query(
            overridesColl,
            where('start', '>=', startOfTheWeek),
            where('start', '<=', endOfTheWeek),
            where('rentRefs', 'array-contains', rentRef),
        );
    }

    if (!overridesQ) {
        return {};
    }

    const overridesSnaps = await getDocs(overridesQ);
    const allOverrides = overridesSnaps.docs;

    const extendedOverrides: GroupedOverrides = {};

    allOverrides.forEach((override) => {
        if (!override.exists()) {
            return;
        }

        const overrideData = override.data() as Omit<Override, 'id'>;
        const dateName = format(overrideData.start.toDate(), 'dd-MM-yyyy');

        extendedOverrides[dateName] = extendedOverrides[dateName] || [];
        extendedOverrides[dateName].push({
            ...overrideData,
            id: override.id,
        });
    });

    return extendedOverrides;
};
