import { FunctionComponent, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';

export interface ToggleControllerProps<T extends string> {
    name: T;
    className?: string;
    ctrClassName?: string;
    children?: ReactNode | FunctionComponent<{ value: boolean }>;
    onChange?: (value: boolean) => void;
}

export const Toggle = <T extends string = string>({
    name,
    className,
    ctrClassName,
    children,
    onChange: additionalChangeHandler,
}: ToggleControllerProps<T>) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => {
                return (
                    <div className={classNames(ctrClassName)}>
                        <Switch
                            checked={value}
                            onChange={() => {
                                onChange(!value);
                                additionalChangeHandler &&
                                    additionalChangeHandler(!value);
                            }}
                            className={classNames(
                                'relative inline-flex h-[20px] w-[40px] shrink-0 cursor-pointer items-center rounded-full border pl-[1px] transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 md:h-[26px] md:w-[52px]',
                                value
                                    ? 'border-black bg-black'
                                    : 'border-black',
                                className,
                            )}
                        >
                            <span
                                aria-hidden="true"
                                className={classNames(
                                    'pointer-events-none inline-block h-[16px] w-[16px] transform rounded-full shadow-lg ring-0 transition duration-500 ease-in-out md:h-[22px] md:w-[22px]',
                                    value
                                        ? 'translate-x-5 bg-white md:translate-x-[26px]'
                                        : 'translate-x-0 bg-black',
                                )}
                            />
                        </Switch>
                        {children
                            ? typeof children === 'function'
                                ? children({ value })
                                : children
                            : null}
                    </div>
                );
            }}
        />
    );
};
