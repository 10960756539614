import { useTranslation } from 'react-i18next';
import { Override } from '@bookinbio/interface';
import { getPosFromTime } from '@bookinbio/utils';
import classNames from 'classnames';

import { SchedulerConfig } from '../../utils/config';

interface OverrideItemProps {
    override: Override;
    additionalTop?: boolean;
    className?: string;
    onClick: () => void;
}

export const OverrideItem = ({
    override,
    additionalTop = false,
    className,
    onClick,
}: OverrideItemProps) => {
    const { t } = useTranslation();

    const appointmentStyle = getPosFromTime(
        override.start.toDate(),
        override.duration,
        {
            gridCellTypeNumber: SchedulerConfig.gridCellTypeNumber,
            gridPixelHeight: SchedulerConfig.gridPixelHeight,
            additionalTop,
        },
    );

    return (
        <button
            type="button"
            className={classNames(
                'absolute z-50 flex w-full flex-col justify-start rounded bg-blue-300 p-3 text-white ring-blue-700 hover:z-[51] hover:ring-1',
                className,
            )}
            style={{
                top: appointmentStyle.top,
                height: appointmentStyle.height,
            }}
            onClick={onClick}
        >
            <span className="text-sm">{t('timeoff')}</span>
        </button>
    );
};
