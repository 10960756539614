import { SyntheticEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { getNestedError } from './utils';
import { WithLabel, WithLabelProps } from './WithLabel';

export interface TextareaInputControllerProps<T extends string> {
    name: T;
    placeholder?: string;
    errorless?: boolean;
    className?: string;
    ctrClassName?: string;
    errorClassName?: string;
    disabled?: boolean;
    additionalChangeHandler?: (value: string) => void;
}

export const TextareaInputController = <T extends string = string>({
    name,
    placeholder,
    errorless = true,
    className,
    ctrClassName,
    errorClassName,
    disabled,
    additionalChangeHandler,
}: TextareaInputControllerProps<T>) => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors },
    } = useFormContext();

    const error = getNestedError(errors, name);

    return (
        <div className={classNames('w-full', ctrClassName)}>
            <div
                className={classNames(
                    'relative h-full w-full border-b',
                    error && !errorless ? 'border-red-500' : 'border-gray-500',
                )}
            >
                <textarea
                    className={classNames(
                        'w-full px-3 py-3 text-base outline-none sm:text-base',
                        className,
                    )}
                    {...register(name, {
                        onChange: (e: SyntheticEvent) => {
                            const value = (e.target as HTMLInputElement).value;
                            additionalChangeHandler &&
                                additionalChangeHandler(value);
                        },
                    })}
                    disabled={disabled}
                    placeholder={placeholder}
                />
            </div>
            {!errorless && error ? (
                <p
                    className={classNames(
                        'mb-2 h-3 text-xs text-red-700',
                        errorClassName,
                    )}
                >
                    {t(error.message as string)}
                </p>
            ) : null}
        </div>
    );
};

export interface TextareaInputProps<T extends string>
    extends TextareaInputControllerProps<T>,
        Omit<WithLabelProps, 'name' | 'children'> {
    label: string;
    className?: string;
    labelClassName?: string;
    inputCtrClassName?: string;
    inputClassName?: string;
}

export const TextareaInput = <T extends string = string>({
    name,
    label,
    placeholder,
    className,
    labelClassName,
    inputCtrClassName,
    inputClassName,
    errorClassName,
    errorless,
    right,
    disabled,
    additionalChangeHandler,
}: TextareaInputProps<T>) => {
    return (
        <WithLabel
            label={label}
            name={name}
            className={className}
            labelClassName={labelClassName}
            right={right}
        >
            <TextareaInputController
                name={name}
                placeholder={placeholder}
                errorless={errorless}
                ctrClassName={inputCtrClassName}
                className={inputClassName}
                disabled={disabled}
                errorClassName={errorClassName}
                additionalChangeHandler={additionalChangeHandler}
            />
        </WithLabel>
    );
};
