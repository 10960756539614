import { Fragment, ReactNode } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';

interface DropdownMenuProps {
    trigger: ReactNode;
    children: ReactNode;
    className?: string;
}

export const DropdownMenu = ({
    trigger,
    children,
    className,
}: DropdownMenuProps) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <Menu.Button as="div">{trigger}</Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={classNames(
                        'absolute right-0 mt-2 origin-bottom-right divide-y divide-gray-100 rounded-md bg-white p-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                        className,
                    )}
                >
                    {children}
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

interface DefaultItemProps {
    disabled?: boolean;
    onClick: () => void;
    children: ReactNode;
}

DropdownMenu.DefaultItem = ({
    disabled,
    children,
    onClick,
}: DefaultItemProps) => {
    return (
        <Menu.Item>
            {({ active }) => (
                <button
                    type="button"
                    className={`${
                        active ? 'bg-black text-white' : 'text-gray-900'
                    } group flex w-full items-center gap-x-4 rounded-md px-2 py-2 text-base`}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {children}
                </button>
            )}
        </Menu.Item>
    );
};
