import { httpsCallable } from 'firebase/functions';

import { functions } from '../../firebase.config';

type CreateClientArgs = {
    businessId: string;

    firstName: string;
    lastName: string;
    phoneNumber: string;
    email?: string | null;
    gender?: string | null;
    dateOfBirth?: string | null;
    clientInfo?: string | null;
    showInBookings: boolean;
    sendEmailNotification: boolean;
    sendSmsNotification: boolean;
    sendEmailMarketing: boolean;
    sendSmsMarketing: boolean;
    preferredLanguage?: string | null;
};

export const createClient = httpsCallable<CreateClientArgs>(
    functions,
    'createClient',
);

type UpdateClientArgs = {
    businessId: string;
    clientId: string;

    firstName: string;
    lastName: string;
    phoneNumber: string;
    email?: string | null;
    gender?: string | null;
    dateOfBirth?: string | null;
    clientInfo?: string | null;
    showInBookings: boolean;
    sendEmailNotification: boolean;
    sendSmsNotification: boolean;
    sendEmailMarketing: boolean;
    sendSmsMarketing: boolean;
    preferredLanguage?: string | null;
};

export const updateClient = httpsCallable<UpdateClientArgs>(
    functions,
    'updateClient',
);

type DeleteClientArgs = {
    businessId: string;
    clientId: string;
};

export const deleteClient = httpsCallable<DeleteClientArgs>(
    functions,
    'deleteClient',
);

// TODO: Merge client
// type MergeClientsArgs = {
//     clientId: string;
// };

// export const mergeClients = httpsCallable<MergeClientsArgs>(
//     functions,
//     'mergeClients',
// );
