import {
    AppointmentHandleOption,
    RecurringUpdateOption,
} from '@bookinbio/enums';
import { GroupedGoogleEvents } from '@bookinbio/interface';
import { httpsCallable } from 'firebase/functions';

import { functions } from '../../firebase.config';

type ApproveRequestArgs = {
    businessId: string;
    appointmentId: string;
};

export const approveRequest = httpsCallable<ApproveRequestArgs>(
    functions,
    'approveRequest',
);

type DeclineRequestArgs = {
    businessId: string;
    appointmentId: string;
    isProposed: boolean;
};

export const declineRequest = httpsCallable<DeclineRequestArgs>(
    functions,
    'declineRequest',
);

type CreateAppointmentArgs = {
    businessId: string;
    locationId: string;
    serviceIds: string[];
    clientIds: string[] | null;
    professionalIds: string[] | null;
    rentIds: string[] | null;

    start: string;
    duration: number;
    timeZone: string;
    serviceType: string;

    notes?: string;

    repeatType: string;
    endType: string | null;
    endDate: string | null;
    occurenceValue: number | null;
    customRepeatSettings: {
        value: number;
        frequencyType: string;
        days: number[] | null;
    } | null;

    isProposition: boolean;
};

export const createAppointment = httpsCallable<CreateAppointmentArgs>(
    functions,
    'createAppointment',
);

type UpdateAppointmentArgs = {
    appointmentId: string;

    businessId: string;
    locationId: string;
    serviceIds: string[];
    clientIds: string[] | null;
    professionalIds: string[] | null;
    rentIds: string[] | null;

    start: string;
    duration: number;
    timeZone: string;
    serviceType: string;

    notes?: string;

    repeatType: string;
    endType: string | null;
    endDate: string | null;
    occurenceValue: number | null;
    customRepeatSettings: {
        value: number;
        frequencyType: string;
        days: number[] | null;
    } | null;

    updateOption: string;
};

export const updateAppointment = httpsCallable<UpdateAppointmentArgs>(
    functions,
    'updateAppointment',
);

type ChangeAppointmentStatusArgs = {
    apponintmentId: string;
    status: string;
    reason?: string;
    sendEmail: boolean;
};

export const changeAppointmentStatus =
    httpsCallable<ChangeAppointmentStatusArgs>(
        functions,
        'changeAppointmentStatus',
    );

type CancelAppointmentArgs = {
    businessId: string;
    appointmentId: string;
    cancelOption: RecurringUpdateOption;
    purpose: AppointmentHandleOption;
};

export const cancelAppointment = httpsCallable<CancelAppointmentArgs>(
    functions,
    'cancelAppointment',
);

type GetUsersWeekGoogleEvents = {
    userId?: string;
    date: string;
};

export const getUsersWeekGoogleEvents = httpsCallable<
    GetUsersWeekGoogleEvents,
    GroupedGoogleEvents
>(functions, 'getWeekGoogleEvents');
