import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { UserRole } from '@bookinbio/enums';
import { SelectOption, Switch } from '@bookinbio/ui';
import { transformToDate } from '@bookinbio/utils';
import classNames from 'classnames';
import { format } from 'date-fns';

import { useAuth } from '../../context/AuthContext';
import { ExtendedPendindAppointments } from '../../utils/firebase/api/get-requests-business';

import { RequestItem } from './RequestItem';
import { RequestsPlaceholder } from './RequestsPlaceholder';

interface RequestsListProps {
    currentOption: SelectOption | null;
    options: SelectOption[];
    onChange: (value?: SelectOption | null) => void;

    extendedAppointments?: ExtendedPendindAppointments;
    isLoading: boolean;
}

export const RequestsList = ({
    currentOption,
    options,
    onChange,
    extendedAppointments,
    isLoading,
}: RequestsListProps) => {
    const { t } = useTranslation();
    const { user } = useAuth();

    return (
        <>
            <div className="flex items-center gap-x-3">
                <div className="flex flex-shrink-0 items-center justify-start divide-x-2 divide-gray-300 overflow-y-hidden text-sm text-gray-400">
                    <div className="pr-2">{t('customer')}</div>
                    <div className="px-2">{t('service')}</div>
                    <div className="px-2">{t('time')}</div>
                    <div className="hidden px-2 md:block">{t('created')}</div>
                </div>
                {user?.userRole === UserRole.Admin ||
                user?.userRole === UserRole.SuperAdmin ? (
                    <Select
                        value={currentOption}
                        options={options}
                        onChange={onChange}
                        isClearable={false}
                        isSearchable={false}
                        classNames={{
                            indicatorSeparator: () => 'hidden',
                            control: () =>
                                classNames(
                                    '!border-r-none !border-b !border-b-gray-700 flex cursor-pointer h-[33px] md:h-[49px] md:h-auto select-none w-44 md:w-48 mr-1',
                                ),
                            placeholder: () => classNames('text-left'),
                            singleValue: () => 'text-base text-left',
                            menu: () => classNames('!z-[1000] '),
                            option: () => 'text-base text-left',
                        }}
                        styles={{
                            control: () => ({
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderRight: 'none',
                            }),
                            menu: (base) => ({
                                ...base,
                                transform: 'translate3d(0, 0, 0)',
                            }),
                        }}
                    />
                ) : null}
            </div>
            <div className="overflow-y-hidden">
                <div className="overflow-y-auto">
                    <Switch>
                        <Switch.Case condition={isLoading}>
                            <RequestsPlaceholder />
                        </Switch.Case>
                        <Switch.Case
                            condition={
                                !!extendedAppointments &&
                                Object.keys(extendedAppointments).length > 0
                            }
                        >
                            {!!extendedAppointments &&
                                Object.keys(extendedAppointments).map(
                                    (dateStr) => {
                                        const appointments =
                                            extendedAppointments[dateStr] ?? [];
                                        const date = transformToDate(dateStr);

                                        return (
                                            <div
                                                key={`request-group-${dateStr}`}
                                                className="flex flex-col"
                                            >
                                                <div className="flex items-center justify-between border-b border-gray-400 text-sm text-gray-400">
                                                    <span>
                                                        {format(
                                                            date,
                                                            "dd MMMM ''23",
                                                        )}
                                                    </span>
                                                </div>
                                                {appointments.map(
                                                    (appointment) => {
                                                        return (
                                                            <RequestItem
                                                                key={`request-item-${appointment.id}`}
                                                                appointment={
                                                                    appointment
                                                                }
                                                            />
                                                        );
                                                    },
                                                )}
                                            </div>
                                        );
                                    },
                                )}
                        </Switch.Case>
                        <Switch.Default>
                            <div className="text-gray-500">
                                {t('request.empty')}
                            </div>
                        </Switch.Default>
                    </Switch>
                </div>
            </div>
        </>
    );
};
