import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SelectOption } from '@bookinbio/ui';
import { useQuery } from '@tanstack/react-query';

import { AppointmentDrawer } from '../components/appointment/AppointmentDrawer';
import { Layout } from '../components/AuthLayout';
import { RequestsList } from '../components/requests/RequestsList';
import { useAuth } from '../context/AuthContext';
import { useBusiness } from '../context/BusinessContext';
import { getRequestsByProfessional } from '../utils/firebase/api/get-requests-business';

export const Requests = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useAuth();
    const { business, rents, professionals } = useBusiness();
    const [currentOption, setCurrentOption] = useState<SelectOption>({
        value: null,
        label: t('all'),
    });
    const selectedUser =
        currentOption.value !== null
            ? professionals?.find((prof) => prof.id === currentOption?.value)
            : null;

    // BE data
    const {
        data: requests,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ['business-requests', business, selectedUser],
        queryFn: () =>
            getRequestsByProfessional(
                business,
                null,
                rents,
                user,
                selectedUser,
            ),
        enabled: !!business && !!user,
    });

    const professionalOptions = useMemo(() => {
        const options: SelectOption[] = [{ value: null, label: t('all') }];

        professionals?.forEach((prof) => {
            options.push({
                value: prof.id,
                label: prof.fullName ?? 'Undefined',
            });
        });

        return options;
    }, [professionals, t]);

    const handleProfChange = (option?: SelectOption | null) => {
        if (option) setCurrentOption(option);
    };

    return (
        <>
            <Layout.Heading heading={t('requests')} />
            <div className="mt-4 flex flex-col gap-y-4">
                <RequestsList
                    onChange={handleProfChange}
                    currentOption={currentOption}
                    options={professionalOptions}
                    extendedAppointments={requests}
                    isLoading={isLoading}
                />
            </div>
            <Routes>
                <Route
                    path="appointment/:id/*"
                    element={
                        <AppointmentDrawer
                            onApprove={refetch}
                            onDecline={refetch}
                            close={() => navigate('/requests')}
                        />
                    }
                />
            </Routes>
        </>
    );
};
