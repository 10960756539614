import { httpsCallable } from 'firebase/functions';

import { functions } from '../../firebase.config';

type BookingSettings = {
    businessId: string;

    timeSlotSize: number;
    cancelationTime: number;
    beforeBookTime: number;
    bookInFutureTime: number;

    needApprovement: boolean;
    requestCancelationTime: number | null;
    propositionCancelationTime: number | null;

    restTimeBeforeAppointment: number;
    restTimeAfterAppointment: number;

    professionalSelectionEnabled: boolean;

    timeSlotCreationStrategy: string;
    eliminateTimeSlotStrategy: string | null;

    emailLanguage: string;
    timeZone: string;
    timeFormat: number;

    bookingIntroText?: string | null;
    bookingOutroText?: string | null;
};

export const updateBusinessBookingSettings = httpsCallable<BookingSettings>(
    functions,
    'updateBusinessBookingSettings',
);

type BusinessInfo = {
    businessId: string;

    businessName: string;
    socialName: string;
    // address: string;
    phoneNumber: string;
    businessType: string | null;
};

export const updateBusinessInfo = httpsCallable<BusinessInfo>(
    functions,
    'updateBusinessInfo',
);
