import { Business, Rent } from '@bookinbio/interface';
import { collection, doc, getDoc } from 'firebase/firestore';

import { businessesColl } from '../collections';

export const getRentByBusiness = async (
    business?: Business,
    rentId?: string,
): Promise<Rent> => {
    if (!business || !rentId) {
        throw new Error('Missing business id');
    }

    const rentSnap = await getDoc(
        doc(collection(doc(businessesColl, business.id), 'rents'), rentId),
    );

    return { ...rentSnap.data(), id: rentSnap.id } as Rent;
};
