import { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useOutlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { LoaderProvider } from '@bookinbio/ui';
import { Libraries, useLoadScript } from '@react-google-maps/api';

import { Layout } from '../components/AuthLayout';
import { CreateClient } from '../components/clients/CreateClient';
import { EditClient } from '../components/clients/EditClient';
import { MergeClients } from '../components/clients/MergeClients';
import { LoadingSplash } from '../components/LoadingSplash';
import { CreateLocation } from '../components/management/locations/CreateLocation';
import { EditLocation } from '../components/management/locations/EditLocation';
import { CreateRent } from '../components/management/rents/CreateRent';
import { EditRent } from '../components/management/rents/EditRent';
import { CreateService } from '../components/management/services/CreateService';
import { EditService } from '../components/management/services/EditService';
import { CreateTeamMember } from '../components/management/team/CreateTeamMember';
import { EditTeamMember } from '../components/management/team/EditTeamMember';
import { EditTeamMemberWorkingHours } from '../components/management/team/EditTeamMemberWorkingHours';
import { NonAuthLayout } from '../components/NonAuthLayout';
import { useAuth } from '../context/AuthContext';
import { BusinessProvider, useBusiness } from '../context/BusinessContext';
import { MobileProvider } from '../context/MobileContext';
import { DefaultPage } from '../pages/DefaultPage';
import { Invitation } from '../pages/Invitation';
import { Requests } from '../pages/Requests';
import { AppConfig, GoogleConfig } from '../utils/config';
import { BookinbioRoutes } from '../utils/routes';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-day-picker/dist/style.css';

// Auth Router

// const InitialSetupPage = lazy(() =>
//     import('../pages/InitialSetup').then((module) => ({
//         default: module.InitialSetup,
//     })),
// );

const Dashboard = lazy(() =>
    import('../pages/Dashboard').then((module) => ({
        default: module.Dashboard,
    })),
);

const Calendar = lazy(() =>
    import('../pages/Calendar').then((module) => ({
        default: module.Calendar,
    })),
);

const Management = lazy(() =>
    import('../pages/Management').then((module) => ({
        default: module.Management,
    })),
);

const Clients = lazy(() =>
    import('../pages/Clients').then((module) => ({
        default: module.Clients,
    })),
);

const Creative = lazy(() =>
    import('../pages/Creative').then((module) => ({
        default: module.Creative,
    })),
);

const Settings = lazy(() =>
    import('../pages/Settings').then((module) => ({
        default: module.Settings,
    })),
);

// Non Auth Router

const Login = lazy(() =>
    import('../pages/Login').then((module) => ({ default: module.Login })),
);

const Register = lazy(() =>
    import('../pages/Register').then((module) => ({
        default: module.Register,
    })),
);

const Recovery = lazy(() =>
    import('../pages/Recovery').then((module) => ({
        default: module.Recovery,
    })),
);

const ResetPassword = lazy(() =>
    import('../pages/ResetPassword').then((module) => ({
        default: module.ResetPassword,
    })),
);

const AuthActionHandler = lazy(() =>
    import('../pages/AuthActionHandler').then((module) => ({
        default: module.AuthActionHandler,
    })),
);

export const AuthRoute = () => {
    const { isLoggedIn, user } = useAuth();
    const { business } = useBusiness();
    const outlet = useOutlet();

    if (isLoggedIn === false) {
        return <Navigate to="/login" replace />;
    }

    if (!business || !user) {
        return <LoadingSplash />;
    }

    return outlet;
};

export const NonAuthRoute = () => {
    const { isLoggedIn } = useAuth();

    if (isLoggedIn === true) {
        return <Navigate to="/calendar" />;
    }

    return <NonAuthLayout />;
};

export function App() {
    useLoadScript({
        googleMapsApiKey: GoogleConfig.mapKey,
        libraries: GoogleConfig.libraries as Libraries,
    });

    const { isLoggedIn, isLoading } = useAuth();

    useEffect(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        window.addEventListener('resize', () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
    }, []);

    return (
        <MobileProvider>
            <LoaderProvider>
                <Suspense fallback={<LoadingSplash />}>
                    {isLoggedIn !== null && !isLoading ? (
                        <Routes>
                            <Route element={<NonAuthRoute />}>
                                <Route
                                    path={BookinbioRoutes.Login}
                                    element={<Login />}
                                />
                                <Route
                                    path={BookinbioRoutes.Register}
                                    element={<Register />}
                                />
                                <Route
                                    path={BookinbioRoutes.Recovery}
                                    element={<Recovery />}
                                />
                                <Route
                                    path={BookinbioRoutes.ResetPassword}
                                    element={<ResetPassword />}
                                />
                                <Route
                                    path={BookinbioRoutes.Invitation}
                                    element={<Invitation />}
                                />
                            </Route>

                            <Route
                                element={
                                    <BusinessProvider>
                                        <AuthRoute />
                                    </BusinessProvider>
                                }
                            >
                                {/* Default page */}
                                {/* <Route
                                    path={BookinbioRoutes.DefaultPath}
                                    element={<DefaultPage />}
                                /> */}
                                {/* Routes with sidebar */}
                                <Route
                                    element={
                                        <Layout.SidebarLayout type="calendar" />
                                    }
                                >
                                    {/* Dashboard */}
                                    <Route
                                        path={BookinbioRoutes.Dashboard}
                                        element={<Dashboard />}
                                    />
                                    {/* Management */}
                                    <Route
                                        path={BookinbioRoutes.Management}
                                        element={<Management />}
                                    />
                                    {/* Requests */}
                                    <Route
                                        path={BookinbioRoutes.Requests}
                                        element={<Requests />}
                                    />
                                    {/* Clients */}
                                    <Route
                                        path={BookinbioRoutes.Clients}
                                        element={<Clients />}
                                    />
                                    {/* Creative */}
                                    {AppConfig.mode !== 'production' ? (
                                        <Route
                                            path={BookinbioRoutes.Creative}
                                            element={<Creative />}
                                        />
                                    ) : null}
                                    {/* Settings */}
                                    <Route
                                        path={BookinbioRoutes.Settings}
                                        element={<Settings />}
                                    />
                                </Route>
                                {/* Routes without sidebar calendar */}
                                <Route element={<Layout />}>
                                    {/* Calendar */}
                                    <Route
                                        path={BookinbioRoutes.Calendar}
                                        element={<Calendar />}
                                    />
                                </Route>

                                {/* TODO: Initial setup page */}
                                {/* <Route
                                    path={BookinbioRoutes.InitialSetup}
                                    element={<InitialSetupPage />}
                                /> */}
                                {/* Management/Location */}
                                <Route
                                    path={BookinbioRoutes.CreateLocation}
                                    element={<CreateLocation />}
                                />
                                <Route
                                    path={BookinbioRoutes.EditLocation}
                                    element={<EditLocation />}
                                />
                                {/* Management/Rent */}
                                <Route
                                    path={BookinbioRoutes.CreateRent}
                                    element={<CreateRent />}
                                />
                                <Route
                                    path={BookinbioRoutes.EditRent}
                                    element={<EditRent />}
                                />
                                {/* Management/Services */}
                                <Route
                                    path={BookinbioRoutes.CreateService}
                                    element={<CreateService />}
                                />
                                <Route
                                    path={BookinbioRoutes.EditService}
                                    element={<EditService />}
                                />
                                {/* Management/Team */}
                                <Route
                                    path={BookinbioRoutes.CreateTeamMember}
                                    element={<CreateTeamMember />}
                                />
                                <Route
                                    path={BookinbioRoutes.EditTeamMember}
                                    element={<EditTeamMember />}
                                />
                                <Route
                                    path={
                                        BookinbioRoutes.EditTeamMemberWorkingHours
                                    }
                                    element={<EditTeamMemberWorkingHours />}
                                />
                                {/* Clients */}
                                <Route
                                    path={BookinbioRoutes.CreateClient}
                                    element={<CreateClient />}
                                />
                                <Route
                                    path={BookinbioRoutes.EditClient}
                                    element={<EditClient />}
                                />
                                <Route
                                    path={BookinbioRoutes.MergeClient}
                                    element={<MergeClients />}
                                />
                            </Route>

                            <Route
                                path={BookinbioRoutes.AuthAction}
                                element={<AuthActionHandler />}
                            />
                            <Route path="*" element={<DefaultPage />} />
                        </Routes>
                    ) : (
                        <LoadingSplash />
                    )}
                </Suspense>
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </LoaderProvider>
        </MobileProvider>
    );
}
