import { Business, Client } from '@bookinbio/interface';
import { collection, doc, getDocs } from 'firebase/firestore';

import { businessesColl } from '../collections';

export const getClientsByBusiness = async (business?: Business) => {
    if (!business) {
        throw new Error('Missing business');
    }

    const clientSnaps = await getDocs(
        collection(doc(businessesColl, business.id), 'clients'),
    );

    return clientSnaps.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() } as Client),
    );
};
