import { Path, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Button,
    DatePickerInput,
    FixedHeader,
    Form,
    IconButton,
    IconType,
    PhoneInput,
    SelectInput,
    TextareaInput,
    TextInput,
    Toggle,
} from '@bookinbio/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { z } from 'zod';

import { useAuth } from '../../context/AuthContext';
import { useBusiness } from '../../context/BusinessContext';
import { createClient } from '../../utils/firebase/callable';
import { FormSectionHeader } from '../FormSectionHeader';
import { LANGUAGES } from '../sidebar/LanguageDropdown';

export const ClientSchema = z.object({
    // basic info
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    phoneNumber: z.string().min(4, { message: 'Please enter phone number' }),
    email: z.string().email().optional().or(z.literal('')),
    gender: z.string().optional().nullable(),
    dateOfBirth: z.date().optional().nullable(),
    // important info
    clientInfo: z.string().nullable(),
    showInBookings: z.boolean(),
    // notification
    sendEmailNotification: z.boolean(),
    sendSmsNotification: z.boolean(),
    sendEmailMarketing: z.boolean(),
    sendSmsMarketing: z.boolean(),
    preferredLanguage: z.string().optional().nullable(),
});

export type ClientForm = z.infer<typeof ClientSchema>;
export type ClientFormProps = Path<ClientForm>;

export const GENDER_OPTIONS = [
    {
        value: 'FEMALE',
        label: 'Female',
    },
    {
        value: 'MALE',
        label: 'Male',
    },
    {
        value: 'NON_BINARY',
        label: 'Non-binary',
    },
    {
        value: 'PREFER_NOT_TO_SAY',
        label: 'Prefer not to say',
    },
];

export const CreateClient = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const { business } = useBusiness();
    const navigate = useNavigate();

    const methods = useForm<ClientForm>({
        mode: 'all',
        reValidateMode: 'onSubmit',
        resolver: zodResolver(ClientSchema),
        defaultValues: {
            sendEmailMarketing: true,
            sendSmsMarketing: true,
            sendEmailNotification: true,
            sendSmsNotification: true,
            showInBookings: true,
        },
    });

    // BE data
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: createClient,
    });

    const handleClose = () => {
        navigate(-1);
    };

    const handleSubmit = async (data: ClientForm) => {
        if (!business || !user) {
            return;
        }

        try {
            await mutateAsync({
                ...data,
                dateOfBirth: data.dateOfBirth
                    ? data.dateOfBirth.toISOString()
                    : undefined,
                businessId: business.id,
            });
            toast.success(t('client.create.success'));
            handleClose();
        } catch (error) {
            console.error(error);
            toast.error(t('client.create.error'));
        }
    };

    return (
        <Form
            methods={methods}
            className="min-h-full w-full"
            onSubmit={handleSubmit}
        >
            <FixedHeader>
                {({ isMoved }) => (
                    <div
                        className={classNames(
                            'fixed top-0 left-0 z-50 flex w-full flex-row-reverse items-center justify-end px-8 pt-6 pb-5 md:h-[88px] md:flex-row md:justify-between',
                            isMoved
                                ? 'shadow-xl backdrop-blur-xl'
                                : 'bg-transparent',
                        )}
                    >
                        <div className="text-right md:flex-1 md:text-left">
                            <IconButton
                                name={IconType.Cross}
                                onClick={handleClose}
                            />
                        </div>
                        <h2
                            className={classNames(
                                'flex-1 text-center text-2xl font-bold tracking-wider',
                                isMoved ? 'visible' : 'invisible',
                            )}
                        >
                            {t('client.create')}
                        </h2>
                        <div className="hidden flex-1 text-right md:block">
                            <Button
                                type="submit"
                                color="black"
                                className="rounded-lg !px-14 !py-3 font-bold tracking-wider"
                                disabled={isLoading}
                            >
                                {t('save')}
                            </Button>
                        </div>
                    </div>
                )}
            </FixedHeader>
            <div className="mx-auto max-w-2xl px-5 pt-10 pb-20 md:pt-[88px] md:pb-[60px]">
                <h1 className="text-left text-3xl font-bold tracking-wider md:text-center">
                    {t('client.create')}
                </h1>
                <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('basic.information')}
                        subTitle={t('client.basic.information.subtitle')}
                    />
                    <div className="mt-6 grid grid-cols-2 gap-y-4 gap-x-4">
                        <TextInput<ClientFormProps>
                            label={t('firstname')}
                            placeholder={t('firstname.placeholder')}
                            name="firstName"
                        />
                        <TextInput<ClientFormProps>
                            label={t('lastname')}
                            placeholder={t('lastname.placeholder')}
                            name="lastName"
                        />
                        <PhoneInput<ClientFormProps>
                            label={t('phonenumber')}
                            name="phoneNumber"
                            className="col-span-2"
                        />
                        <TextInput<ClientFormProps>
                            label={t('email.optional')}
                            placeholder={t('email.placeholder')}
                            name="email"
                            className="col-span-2"
                        />
                        <DatePickerInput<ClientFormProps>
                            label={t('date.birth.optional')}
                            name="dateOfBirth"
                            className="col-span-2"
                        />
                    </div>
                </div>
                <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('client.important.information')}
                        subTitle={t('client.important.information.subtitle')}
                    />
                    <div className="mt-6 grid grid-cols-1 gap-y-7">
                        <TextareaInput<ClientFormProps>
                            label={t('client.information.optional')}
                            name="clientInfo"
                            placeholder={t('client.information.placeholder')}
                        />
                        <Toggle<ClientFormProps>
                            name="showInBookings"
                            ctrClassName="flex items-center gap-x-6"
                        >
                            <div>{t('show.in.bookings')}</div>
                        </Toggle>
                    </div>
                </div>
                <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('notifications')}
                        subTitle={t('notifications.subtitle')}
                    />
                    <div className="mt-6 grid grid-cols-1 gap-y-7">
                        <Toggle<ClientFormProps>
                            name="sendEmailNotification"
                            ctrClassName="flex items-center gap-x-6"
                        >
                            <div>{t('send.email.notifications')}</div>
                        </Toggle>
                        <Toggle<ClientFormProps>
                            name="sendSmsNotification"
                            ctrClassName="flex items-center gap-x-6"
                        >
                            <div>{t('send.sms.notifications')}</div>
                        </Toggle>
                        <Toggle<ClientFormProps>
                            name="sendEmailMarketing"
                            ctrClassName="flex items-center gap-x-6"
                        >
                            <div>{t('send.email.marketing')}</div>
                        </Toggle>
                        <Toggle<ClientFormProps>
                            name="sendSmsMarketing"
                            ctrClassName="flex items-center gap-x-6"
                        >
                            <div>{t('send.sms.marketing')}</div>
                        </Toggle>
                        <SelectInput<ClientFormProps>
                            label={t('preferred.language.optional')}
                            placeholder={t('preferred.language.placeholder')}
                            name="preferredLanguage"
                            options={LANGUAGES}
                        />
                    </div>
                </div>
            </div>
            <div
                className={classNames(
                    'fixed bottom-0 left-0 z-50 h-20 w-full items-center justify-between px-5 py-5 shadow-xl backdrop-blur-xl md:hidden',
                )}
            >
                <Button
                    type="submit"
                    color="black"
                    className="w-full rounded-lg !py-3 font-bold tracking-wide"
                    disabled={isLoading}
                >
                    {t('save')}
                </Button>
            </div>
        </Form>
    );
};
