/// <reference types="vite-plugin-svgr/client" />

import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutlet } from 'react-router-dom';
import classNames from 'classnames';

import { default as BookibioIcon } from '../assets/images/logo-black-full.svg?react';
import { useMobile } from '../context/MobileContext';

import { LanguageMenu } from './non-auth/LanguageMenu';

export interface NonAuthLayoutProps {
    size?: 'sm' | 'md';
}

export const NonAuthLayout = ({ size = 'sm' }: NonAuthLayoutProps) => {
    const { t } = useTranslation();
    const outlet = useOutlet();
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const { isMobileView: isMobile } = useMobile();

    useEffect(() => {
        if (!videoRef.current) {
            return;
        }
        const video = videoRef.current;

        const videoHandler = (e: MouseEvent) => {
            e.preventDefault();
        };

        video.addEventListener('dblclick', videoHandler);

        return () => {
            video.removeEventListener('dblclick', videoHandler);
        };
    }, [videoRef]);

    return (
        <div className={classNames('h-custom-screen flex w-full')}>
            <div className="flex w-full flex-1 flex-col justify-center">
                <div
                    className={classNames(
                        'flex w-full flex-1 flex-col justify-center',
                        size === 'sm' ? 'items-center' : 'max-w-xs sm:max-w-lg',
                    )}
                >
                    <div className="flex h-32 items-center justify-center">
                        <BookibioIcon className="h-[3.25rem]" />
                    </div>
                    {outlet}
                </div>
                <div className="mx-auto flex items-center divide-x pb-4 text-xs text-gray-500">
                    <LanguageMenu />
                    <a
                        href="https://bookinb.io/assets/terms-and-conditions.pdf"
                        referrerPolicy="no-referrer"
                        rel="noreferrer"
                        target="_blank"
                        className="px-3 text-center hover:text-blue-500 md:px-6"
                    >
                        {t('terms.conditions')}
                    </a>
                    <a
                        href="https://bookinb.io/assets/privacy-policy.pdf"
                        referrerPolicy="no-referrer"
                        rel="noreferrer"
                        target="_blank"
                        className="px-3 text-right hover:text-blue-500 md:px-6"
                    >
                        {t('privacy.policy')}
                    </a>
                </div>
            </div>
            {!isMobile ? (
                <aside className="hidden w-[43%] overflow-hidden lg:block">
                    <video
                        src="https://firebasestorage.googleapis.com/v0/b/bookinbio.appspot.com/o/login_loader.mp4?alt=media&token=6494da4b-5f68-47d9-90a1-b3afa3ca8364"
                        autoPlay
                        className="w-full"
                        loop
                        controls={false}
                        controlsList="nofullscreen"
                        ref={videoRef}
                    />
                </aside>
            ) : null}
        </div>
    );
};
