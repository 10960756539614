import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, Modal } from '..';

interface DeleteModalProps {
    title?: string;
    text?: string;
    isLoading?: boolean;
    deleteText?: string;
    onDelete: (id?: string) => void;
    close: () => void;
}

export const DeleteModal = ({
    title,
    text,
    isLoading,
    deleteText,
    close,
    onDelete,
}: DeleteModalProps) => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    return (
        <Modal
            isOpen={true}
            close={isLoading ? () => console.log('disabled close') : close}
        >
            <h2
                className={'flex-1 text-left text-2xl font-bold tracking-wider'}
            >
                {title ?? t('delete')}
            </h2>
            <p className="my-8 text-left">
                {text ?? t('delete.modal.default.subtext')}
            </p>
            <div className="fixed bottom-0 left-0 flex items-center justify-center w-full h-24 px-6 py-6 gap-x-4 md:relative md:h-auto md:w-auto md:justify-end md:px-0 md:py-0">
                <Button
                    type="button"
                    color="white-black"
                    size="sm"
                    className="flex-1 h-full md:h-auto md:flex-none"
                    onClick={close}
                    disabled={isLoading}
                >
                    {t('close')}
                </Button>
                <Button
                    type="button"
                    color="error"
                    size="sm"
                    className="flex-1 h-full md:h-auto md:flex-none"
                    onClick={() => onDelete(id)}
                    disabled={isLoading}
                >
                    {deleteText ?? t('delete')}
                </Button>
            </div>
        </Modal>
    );
};
