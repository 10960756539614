import logo from '../assets/images/logo_white.jpeg';

export const LoadingSplash = () => {
    return (
        <div className="absolute flex items-center justify-center w-screen h-screen">
            <img
                src={logo}
                alt="bookinbio-logo"
                className="w-40 h-40 rounded-smd"
            />
        </div>
    );
};
