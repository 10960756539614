import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Appointment, GoogleEvent, Override } from '@bookinbio/interface';
import { getCurrentTimePos, timeIdToTime } from '@bookinbio/utils';
import classNames from 'classnames';

import { SchedulerConfig } from '../../utils/config';
import { AppointmentItem } from '../calendar/AppointmentItem';
import { ClosedHour, ClosedHourItem } from '../calendar/ClosedHourItem';
import { GoogleEventItem } from '../calendar/GoogleEventItem';
import { OverrideItem } from '../calendar/OverrideItem';

interface DayCalendarProps {
    currentDate: Date;
    appointments: Appointment[];
    googleEvents: GoogleEvent[];
    closedHours: ClosedHour[];
    overrides: Override[];
}

export const DayCalendar = ({
    currentDate,
    appointments,
    googleEvents,
    closedHours,
    overrides,
}: DayCalendarProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const columnRef = useRef<HTMLDivElement>(null);
    const nowTimeRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!nowTimeRef.current) {
            return;
        }

        nowTimeRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [nowTimeRef]);

    const now = getCurrentTimePos(currentDate, {
        gridCellTypeNumber: SchedulerConfig.gridCellTypeNumber,
        gridPixelHeight: SchedulerConfig.gridPixelHeight,
    });

    const handleNavigate = (id: string) => {
        navigate(`${location.pathname}/appointment/${id}`);
    };

    const handleNavigateOverride = (id: string) => {
        navigate(`${location.pathname}/override/${id}`);
    };

    return (
        <div className="w-full flex-1 overflow-hidden pl-10 pr-6">
            <div
                id="scroller"
                className="flex h-full w-full select-none overflow-y-scroll pr-4"
            >
                <div className="flex w-8 flex-col pt-2">
                    <div className="flex flex-col">
                        {Array.from({
                            length: SchedulerConfig.maxCellCount,
                        }).map((_, idx) => {
                            return (
                                <div
                                    key={`timegrid-${idx}`}
                                    className={classNames(
                                        'relative w-full flex-shrink-0 border-t border-transparent text-neutral-400',
                                        idx === SchedulerConfig.maxCellCount - 1
                                            ? 'border-b border-transparent'
                                            : null,
                                    )}
                                    style={{
                                        height: SchedulerConfig.gridPixelHeight,
                                    }}
                                >
                                    <span className="absolute mt-[-7px] h-3 text-[10px] leading-3">
                                        {timeIdToTime(idx)}
                                    </span>
                                    {idx ===
                                    SchedulerConfig.maxCellCount - 1 ? (
                                        <span
                                            className="absolute h-3 text-[10px] leading-3"
                                            style={{
                                                marginTop:
                                                    SchedulerConfig.gridPixelHeight -
                                                    8,
                                            }}
                                        >
                                            {timeIdToTime(idx + 1)}
                                        </span>
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div
                    className={classNames(
                        'relative flex flex-1 cursor-auto flex-col pt-2',
                    )}
                    ref={columnRef}
                >
                    {Array.from({
                        length: SchedulerConfig.maxCellCount,
                    }).map((_, idx) => {
                        return (
                            <div
                                key={`grid-${idx}`}
                                className={classNames(
                                    'w-full flex-shrink-0 border-t border-neutral-300 text-white',
                                    idx === SchedulerConfig.maxCellCount - 1
                                        ? 'border-b'
                                        : null,
                                )}
                                style={{
                                    height: SchedulerConfig.gridPixelHeight,
                                }}
                            ></div>
                        );
                    })}
                    {now ? (
                        <div
                            ref={nowTimeRef}
                            id="now-time"
                            className="absolute z-30 h-[1px] w-full bg-red-500"
                            style={{
                                top: now,
                            }}
                        ></div>
                    ) : null}
                    {appointments.map((appointment) => {
                        return (
                            <AppointmentItem
                                key={`appointment-${appointment.id}`}
                                appointment={appointment}
                                additionalTop
                                onClick={() => handleNavigate(appointment.id)}
                            />
                        );
                    })}
                    {overrides.map((override) => {
                        return (
                            <OverrideItem
                                key={`override-${override.id}`}
                                override={override}
                                additionalTop
                                onClick={() =>
                                    handleNavigateOverride(override.id)
                                }
                            />
                        );
                    })}
                    {googleEvents.map((evt) => {
                        return (
                            <GoogleEventItem
                                key={`google-${evt.id}`}
                                event={evt}
                                className="!w-full"
                                additionalTop
                            />
                        );
                    })}
                    {closedHours.map((closedHour, idx) => (
                        <ClosedHourItem
                            key={`closed-hours-${idx}`}
                            closedHour={closedHour}
                            additionalTop
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
