import { createContext, ReactNode, useContext, useState } from 'react';

import logo from '../assets/images/logo_white.jpeg';

export interface LoaderContextProps {
    isLoading: boolean;
    start: () => void;
    stop: () => void;
}

const LoaderContext = createContext<LoaderContextProps>({
    isLoading: false,
    start: () => {
        console.log('LoaderContext [start]');
    },
    stop: () => {
        console.log('LoaderContext [stop]');
    },
});

export const useLoader = () => useContext(LoaderContext);

export interface LoaderProviderProps {
    children: ReactNode;
}

export const LoaderProvider = ({ children }: LoaderProviderProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <LoaderContext.Provider
            value={{
                isLoading,
                start: () => setIsLoading(true),
                stop: () => setIsLoading(false),
            }}
        >
            {children}
            {isLoading ? (
                <div className="h-custom-screen absolute top-0 left-0 flex w-screen items-center justify-center bg-black bg-opacity-80">
                    <img
                        src={logo}
                        alt="bookinbio-logo"
                        className="rounded-smd h-40 w-40"
                    />
                    Loading...
                </div>
            ) : null}
        </LoaderContext.Provider>
    );
};
