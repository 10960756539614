import { FunctionComponent, ReactNode, useEffect, useState } from 'react';

interface FixedHeaderProps {
    children: ReactNode | FunctionComponent<{ isMoved: boolean }>;
}

export const FixedHeader = ({ children }: FixedHeaderProps) => {
    const [isMoved, setIsMoved] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsMoved(true);
            } else {
                setIsMoved(false);
            }
        };
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [setIsMoved]);

    return typeof children === 'function' ? children({ isMoved }) : children;
};
