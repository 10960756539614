import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Icon, IconButton, IconType } from '@bookinbio/ui';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';

import { useAuth } from '../../context/AuthContext';
import { useBusiness } from '../../context/BusinessContext';
import { useSidebar } from '../../context/SidebarContext';
import { getTotalAppointmentCount } from '../../utils/firebase/api/get-requests-business';
import { LanguageMenuWButton } from '../sidebar/LanguageDropdown';

import { MobileSideNavbarItem, NavItem } from './MobileSideNavbarItem';

export const MobileSidebar = () => {
    const { t } = useTranslation();
    const { isOpen, close } = useSidebar();
    const { business, requests } = useBusiness();
    const { signOut } = useAuth();

    const AdminItems: NavItem[] = [
        // {
        //     id: 'dashboard-nav-item',
        //     type: 'link',
        //     title: t('Dashboard'),
        //     to: '/dashboard',
        //     isProd: true,
        // },
        {
            id: 'management-nav-item',
            type: 'link',
            title: t('management'),
            to: '/management',
            isProd: true,
        },
        {
            id: 'clients-nav-item',
            type: 'link',
            title: t('clients'),
            to: '/clients',
            isProd: true,
        },
        {
            id: 'calendar-nav-item',
            type: 'link',
            title: t('calendar'),
            to: '/calendar',
            isProd: true,
        },
        {
            id: 'requests-nav-item',
            type: 'link',
            title: t('requests'),
            to: '/requests',
            isProd: true,
        },
        {
            id: 'settings-nav-item',
            type: 'menu',
            title: t('settings'),
            to: '/settings',
            isProd: true,
            subItems: [
                {
                    id: 'profile-nav-item',
                    type: 'link',
                    title: t('profile'),
                    to: '/settings/personal/profile',
                    isProd: true,
                },
                {
                    id: 'business-profile-nav-item',
                    type: 'link',
                    title: t('business.profile'),
                    to: '/settings/business/profile',
                    isProd: true,
                },
                {
                    id: 'working-hours-nav-item',
                    type: 'link',
                    title: t('working.hours'),
                    to: '/settings/personal/working-hours',
                    isProd: true,
                },
                {
                    id: 'booking-settings-nav-item',
                    type: 'link',
                    title: t('booking.settings'),
                    to: '/settings/business/booking',
                    isProd: true,
                },
            ],
        },
    ];

    const handleCopyLinkToClipboard = async () => {
        if (!business?.socialName) {
            return;
        }

        try {
            await navigator.clipboard.writeText(
                `reserve.bookinb.io/${business.socialName}`,
            );
            toast.success(t('social.link.copy.success'));
        } catch (err) {
            toast.success(t('social.link.copy.error'));
        }
    };

    const requestsCount = getTotalAppointmentCount(requests);

    return (
        <Transition show={isOpen} className="absolute top-0 h-0">
            <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="fixed inset-0 z-[998] bg-black/70 backdrop-blur-sm"
            />
            <Transition.Child
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 translate-x-full"
                enterTo="transform opacity-100 translate-x-0"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 translate-x-0"
                leaveTo="transform opacity-0 translate-x-full"
            >
                <div
                    className={classNames(
                        'h-custom-screen fixed top-0 right-0 z-[999] flex h-screen w-full justify-end transition-all duration-300 ease-in-out md:hidden',
                    )}
                    onClick={close}
                >
                    <nav
                        className="z-10 flex h-full w-[80%] flex-col gap-10 bg-black px-10 py-6"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="relative flex justify-end">
                            <IconButton
                                type="button"
                                size={20}
                                name={IconType.Cross}
                                onClick={close}
                                className="text-white hover:!bg-white/10 hover:!text-gray-300"
                            />
                        </div>
                        <div className="flex flex-1 flex-col items-start gap-4">
                            {AdminItems.map((el) => {
                                return (
                                    <MobileSideNavbarItem
                                        key={el.id}
                                        item={{
                                            ...el,
                                            requestsCount:
                                                el.id === 'requests-nav-item'
                                                    ? requestsCount
                                                    : undefined,
                                        }}
                                    />
                                );
                            })}
                        </div>
                        <div className="flex flex-col gap-6">
                            <LanguageMenuWButton placement="top">
                                <div
                                    className={classNames(
                                        'flex items-center gap-6 text-2xl font-medium text-gray-400',
                                    )}
                                >
                                    <span
                                        className={classNames(
                                            'w-5 bg-transparent',
                                        )}
                                    >
                                        <Icon
                                            name={IconType.Globe}
                                            size={24}
                                            className="text-gray-400"
                                        />
                                    </span>
                                    {t('languages')}
                                </div>
                            </LanguageMenuWButton>
                            {business?.socialName ? (
                                <button
                                    type="button"
                                    className={classNames(
                                        'flex items-center gap-6 text-left text-2xl font-medium text-gray-400',
                                    )}
                                    onClick={handleCopyLinkToClipboard}
                                >
                                    <span
                                        className={classNames(
                                            'w-5 bg-transparent',
                                        )}
                                    >
                                        <Icon
                                            name={IconType.Copy}
                                            size={24}
                                            className="text-gray-400"
                                        />
                                    </span>
                                    {t('social.link.copy')}
                                </button>
                            ) : null}
                            <button
                                type="button"
                                className={classNames(
                                    'flex items-center gap-6 text-2xl font-medium text-gray-400',
                                )}
                                onClick={signOut}
                            >
                                <span
                                    className={classNames('w-5 bg-transparent')}
                                >
                                    <Icon name={IconType.Logout} size={24} />
                                </span>
                                {t('logout')}
                            </button>
                        </div>
                    </nav>
                </div>
            </Transition.Child>
        </Transition>
    );
};
