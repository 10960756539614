import { Children, ReactElement, ReactNode } from 'react';

interface SwitchProps {
    children: ReactElement[];
}

export const Switch = ({ children }: SwitchProps) => {
    let matchChild: ReactElement | null = null;
    let defaultCase: ReactElement | null = null;

    Children.forEach(children, (child: ReactElement) => {
        if (!matchChild && child.type === Switch.Case) {
            const { condition } = (child as ReactElement<CaseProps>).props;

            const conditionResult = Boolean(condition);

            if (conditionResult) {
                matchChild = child;
            }
        } else if (!defaultCase && child.type === Switch.Default) {
            defaultCase = child;
        }
    });

    return matchChild ?? defaultCase ?? null;
};

interface CaseProps {
    condition: boolean;
    children: ReactNode;
}

Switch.Case = ({ condition, children }: CaseProps) => {
    return children;
};

Switch.Default = ({ children }: Omit<CaseProps, 'condition'>) => {
    return children;
};
