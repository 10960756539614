import { useTranslation } from 'react-i18next';
import { IconButton, IconType } from '@bookinbio/ui';
import { format } from 'date-fns';

import logo from '../../assets/images/logo-black-full.svg';
import { useBusiness } from '../../context/BusinessContext';
import { useSidebar } from '../../context/SidebarContext';
import { AppConfig } from '../../utils/config';
import { getTotalAppointmentCount } from '../../utils/firebase/api/get-requests-business';
import { ViewType } from '../calendar/AppointmentsContext';
import { ProgressButton } from '../setup-progress/ProgressButton';
import { NotificationsDropdown } from '../sidebar/NotificationsDrawer';
import { ProfileRow } from '../sidebar/ProfileRow';

import { MobileSidebar } from './MobileSidebar';
import { NavbarItem } from './NavbarItem';

interface NavbarProps {
    profileRow?: boolean;
}

export const Navbar = ({ profileRow = false }: NavbarProps) => {
    const { t } = useTranslation();
    const { open } = useSidebar();
    const { requests } = useBusiness();

    const AdminItems = [
        // {
        //     id: 'dashboard-nav-item',
        //     title: t('Dashboard'),
        //     to: '/dashboard',
        //     isProd: true,
        // },
        {
            id: 'management-nav-item',
            title: t('management'),
            to: '/management',
            isProd: true,
        },
        {
            id: 'clients-nav-item',
            title: t('clients'),
            to: '/clients',
            isProd: true,
        },
        {
            id: 'calendar-nav-item',
            title: t('calendar'),
            to: '/calendar',
            searchParams: `date=${format(new Date(), 'dd-MM-yyyy')}&view=${
                ViewType.WeekView
            }`,
            isProd: true,
        },
        {
            id: 'request-nav-item',
            title: t('requests'),
            to: '/requests',
            isProd: true,
        },
    ];

    const requestsCount = getTotalAppointmentCount(requests);

    return (
        <div className="mb-4 flex h-14 flex-shrink-0 items-center justify-between sm:items-center">
            <div className="flex items-center justify-start">
                <img
                    src={logo}
                    alt="bookinbio"
                    className="rounded-smd h-7 self-start"
                />
                <nav className="ml-20 hidden flex-none items-center justify-start md:flex">
                    {AdminItems.map((item) => {
                        if (!item.isProd && AppConfig.mode === 'production') {
                            return null;
                        }

                        return (
                            <NavbarItem
                                key={item.id}
                                title={t(item.title)}
                                to={item.to}
                                searchParams={item.searchParams}
                                requestsCount={
                                    item.id === 'request-nav-item'
                                        ? requestsCount
                                        : undefined
                                }
                            />
                        );
                    })}
                </nav>
            </div>
            <ProgressButton className="hidden md:flex" />
            {profileRow ? <ProfileRow /> : null}

            <div className="flex items-center gap-x-4 md:hidden">
                <ProgressButton />
                <NotificationsDropdown />
                <span className="relative block md:hidden">
                    <IconButton
                        type="button"
                        size={32}
                        name={IconType.MenuBurger}
                        onClick={open}
                    />
                    {requestsCount ? (
                        <span className="absolute right-[-10px] top-[-6px] flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-xs text-white">
                            {requestsCount}
                        </span>
                    ) : null}
                </span>
            </div>
            <MobileSidebar />
        </div>
    );
};
