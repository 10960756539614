import { DaySetting } from '@bookinbio/interface';
import { minutesToPosition, timeToMinutes } from '@bookinbio/utils';
import classNames from 'classnames';

import { SchedulerConfig } from '../../utils/config';

export interface ClosedHour {
    start: number;
    end: number;
}

export const getClosedHours = (daySetting?: DaySetting): ClosedHour[] => {
    if (!daySetting || !daySetting.isOpen) {
        return [{ start: 0, end: 1440 }]; // Closed for the entire day
    }

    const closedHours: ClosedHour[] = [];
    const openSlots = daySetting.timeSlots;
    let lastEnd = 0; // Start of the day in minutes

    // Check for closed time before the first open slot
    if (openSlots.length > 0) {
        const firstStart = timeToMinutes(openSlots[0].start);
        if (firstStart > 0) {
            closedHours.push({ start: 0, end: firstStart });
        }
    }

    // Check for gaps between open slots
    openSlots.forEach((slot, index) => {
        const start = timeToMinutes(slot.start);
        const end = timeToMinutes(slot.end);

        if (index > 0 && lastEnd < start) {
            // Gap between last end time and current start time
            closedHours.push({ start: lastEnd, end: start });
        }
        lastEnd = end;
    });

    // Check for closed time after the last open slot
    if (lastEnd < 1440) {
        closedHours.push({ start: lastEnd, end: 1440 });
    }

    return closedHours;
};
interface ClosedHourItemProps {
    closedHour: ClosedHour;
    additionalTop?: boolean;
    className?: string;
}

export const ClosedHourItem = ({
    closedHour,
    additionalTop,
    className,
}: ClosedHourItemProps) => {
    const closedHourTop = minutesToPosition(
        closedHour.start,
        SchedulerConfig.gridPixelHeight,
    );
    const top = additionalTop ? closedHourTop + 8 : closedHourTop;
    return (
        <div
            style={{
                top: top,
                height: minutesToPosition(
                    closedHour.end - closedHour.start,
                    SchedulerConfig.gridPixelHeight,
                ),
            }}
            className={classNames(
                'custom-bg-pattern bbio-accessible absolute w-full rounded p-3 text-white opacity-50',
                className,
            )}
        />
    );
};
