import { Path, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserRole } from '@bookinbio/enums';
import {
    Button,
    Checkbox2,
    FixedHeader,
    Form,
    IconButton,
    IconType,
    PhoneInput,
    SelectInput,
    TextInput,
    Toggle,
} from '@bookinbio/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { z } from 'zod';

import { useAuth } from '../../../context/AuthContext';
import { useBusiness } from '../../../context/BusinessContext';
import i18n from '../../../locales/i18n';
import { createTeamMember } from '../../../utils/firebase/callable/team-members';
import { FormSectionHeader } from '../../FormSectionHeader';
import { SelectAllCheckbox } from '../services/CreateService';

// Constants
export const MEMBER_ROLE_OPTIONS = [
    {
        label: i18n.t('admin'),
        value: UserRole.Admin,
    },
    {
        label: i18n.t('member'),
        value: UserRole.Member,
    },
];

export const TeamMemberSchema = z.object({
    // basic info
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    // username: z.string(),
    phoneNumber: z.string().optional(),
    email: z.string().email(),

    jobTitle: z.string().optional(),

    userRole: z.string(),

    serviceRefs: z.array(z.string()),
    allowBookings: z.boolean(),
});

export type TeamMemberForm = z.infer<typeof TeamMemberSchema>;
export type TeamMemberFormProps = Path<TeamMemberForm>;

export const CreateTeamMember = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const { business, services, businessRefetch, professionalsRefetch } =
        useBusiness();
    const navigate = useNavigate();

    const methods = useForm<TeamMemberForm>({
        mode: 'all',
        reValidateMode: 'onSubmit',
        resolver: zodResolver(TeamMemberSchema),
        // resolver: async (data, context, options) => {
        //     console.log(
        //         'validation result',
        //         await zodResolver(TeamMemberSchema)(data, context, options),
        //     );
        //     return zodResolver(TeamMemberSchema)(data, context, options);
        // },
        defaultValues: {
            allowBookings: true,
            serviceRefs: [],
            userRole: UserRole.Member,
        },
    });
    const [servRefs] = methods.watch(['serviceRefs']);

    // BE data
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: createTeamMember,
    });

    const handleClose = () => {
        navigate(-1);
    };

    const handleSubmit = async (data: TeamMemberForm) => {
        if (!business || !user) {
            return;
        }

        try {
            await mutateAsync({
                ...data,
                phoneNumber: data.phoneNumber ?? null,
                businessId: business.id,
            });
            toast.success(t('team.create.success'));
            await businessRefetch();
            await professionalsRefetch();
            handleClose();
        } catch (error) {
            console.error(error);
            toast.error(t('team.create.error'));
        }
    };

    const handleSelectAll = () => {
        if (services) {
            const profLength = services.length;

            if (profLength === servRefs.length) {
                methods.setValue('serviceRefs', []);
            } else {
                methods.setValue(
                    'serviceRefs',
                    services.map((serv) => serv.id),
                );
            }
        }
    };

    const selectAllValue = services
        ? services.length === servRefs.length
            ? 'all'
            : servRefs.length > 0
            ? 'some'
            : 'none'
        : 'none';

    return (
        <Form
            methods={methods}
            className="min-h-full w-full"
            onSubmit={handleSubmit}
        >
            <FixedHeader>
                {({ isMoved }) => (
                    <div
                        className={classNames(
                            'fixed top-0 left-0 z-50 flex w-full flex-row-reverse items-center justify-end px-8 pt-6 pb-5 md:h-[88px] md:flex-row md:justify-between',
                            isMoved
                                ? 'shadow-xl backdrop-blur-xl'
                                : 'bg-transparent',
                        )}
                    >
                        <div className="text-right md:flex-1 md:text-left">
                            <IconButton
                                name={IconType.Cross}
                                onClick={handleClose}
                            />
                        </div>
                        <h2
                            className={classNames(
                                'flex-1 text-center text-2xl font-bold tracking-wider',
                                isMoved ? 'visible' : 'invisible',
                            )}
                        >
                            {t('team.create')}
                        </h2>
                        <div className="hidden flex-1 text-right md:block">
                            <Button
                                type="submit"
                                color="black"
                                className="rounded-lg !px-14 !py-3 font-bold tracking-wider"
                                disabled={isLoading}
                            >
                                {t('save')}
                            </Button>
                        </div>
                    </div>
                )}
            </FixedHeader>
            <div className="mx-auto max-w-2xl px-5 pt-10 pb-20 md:pt-[88px] md:pb-[60px]">
                <h1 className="text-left text-3xl font-bold tracking-wider md:text-center">
                    {t('team.create')}
                </h1>
                <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('basic.information')}
                        subTitle={t('team.basic.information.subtitle')}
                    />
                    <div className="mt-6 grid grid-cols-2 gap-y-4 gap-x-4">
                        <TextInput<TeamMemberFormProps>
                            label={t('firstname')}
                            placeholder={t('firstname.placeholder')}
                            name="firstName"
                        />
                        <TextInput<TeamMemberFormProps>
                            label={t('lastname')}
                            placeholder={t('lastname.placeholder')}
                            name="lastName"
                        />
                        {/* <TextInput<TeamMemberFormProps>
                            label={t('username')}
                            placeholder={t('username.placeholder')}
                            prefixClassName="text-blue-700"
                            prefix={`/${business?.socialName}/p/`}
                            name="username"
                            className="col-span-2"
                        /> */}
                        <TextInput<TeamMemberFormProps>
                            label={t('email')}
                            placeholder={t('email.placeholder')}
                            name="email"
                            className="col-span-2"
                        />
                        <PhoneInput<TeamMemberFormProps>
                            label={t('phonenumber.optional')}
                            name="phoneNumber"
                            className="col-span-2"
                        />
                        <TextInput<TeamMemberFormProps>
                            label={t('team.jobtitle.optional')}
                            placeholder={t('jobtitle.placeholder')}
                            name="jobTitle"
                        />
                        <SelectInput<TeamMemberFormProps>
                            label={t('user.role')}
                            placeholder={t('team.user.role.placeholder')}
                            name="userRole"
                            options={MEMBER_ROLE_OPTIONS}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('team.services.booking')}
                        subTitle={t('team.service.choose')}
                    />
                    <div className="mt-6 grid grid-cols-1 gap-y-7">
                        <div className="flex items-center gap-x-6">
                            <SelectAllCheckbox
                                onClick={handleSelectAll}
                                value={selectAllValue}
                            />
                            <span className="text-lg">{t('select.all')}</span>
                        </div>
                        <div className="grid grid-cols-1 gap-y-2">
                            {services && services.length > 0
                                ? services.map((serv) => (
                                      <div
                                          key={`prof-item-${serv.id}`}
                                          className="flex items-center gap-x-6"
                                      >
                                          <Checkbox2<TeamMemberFormProps>
                                              name="serviceRefs"
                                              ctrClassName="!items-center"
                                              labelClassName="flex items-center gap-x-6"
                                              label={
                                                  <div className="text-lg">
                                                      {
                                                          serv.basicInfo.name
                                                              .default
                                                      }
                                                  </div>
                                              }
                                              value={serv.id}
                                          />
                                      </div>
                                  ))
                                : null}
                        </div>
                        <Toggle<TeamMemberFormProps>
                            name="allowBookings"
                            ctrClassName="flex items-center gap-x-6 mt-4"
                        >
                            <div>{t('allow.calendar.bookings')}</div>
                        </Toggle>
                    </div>
                </div>
            </div>
            <div
                className={classNames(
                    'fixed bottom-0 left-0 z-50 h-20 w-full items-center justify-between px-5 py-5 shadow-xl backdrop-blur-xl md:hidden',
                )}
            >
                <Button
                    type="submit"
                    color="black"
                    className="w-full rounded-lg !py-3 font-bold tracking-wide"
                    disabled={isLoading}
                >
                    {t('save')}
                </Button>
            </div>
        </Form>
    );
};
