import { Fragment, ReactNode } from 'react';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';

interface DefaultTabProps {
    children: ReactNode;
}

export const DefaultTab = ({ children }: DefaultTabProps) => {
    return (
        <Tab as={Fragment}>
            {({ selected }) => (
                <button className="flex flex-col text-lg outline-none">
                    {children}
                    <span
                        className={classNames(
                            'mx-auto mt-1 h-1 w-6 rounded-sm',
                            selected ? 'bg-black' : 'bg-transparent',
                        )}
                    ></span>
                </button>
            )}
        </Tab>
    );
};
