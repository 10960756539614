import {
    createContext,
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';

import { useMobile } from './MobileContext';

interface SidebarContextProps {
    isOpen: boolean;

    open: () => void;
    close: () => void;
    toggle: () => void;
}

const SidebarContext = createContext<SidebarContextProps>({
    isOpen: false,
    open: () => {
        console.log('SidebarContext [open]');
    },
    close: () => {
        console.log('SidebarContext [close]');
    },
    toggle: () => {
        console.log('SidebarContext [toggle]');
    },
});

export const useSidebar = () => useContext(SidebarContext);

interface SidebarProviderProps {
    children: FunctionComponent<{ isOpen: boolean }>;
}

export const MobileSidebarProvider = ({ children }: SidebarProviderProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { isMobileView: isMobile } = useMobile();

    useEffect(() => {
        if (!isMobile) {
            setIsOpen(false);
        }
    }, [isMobile]);

    return (
        <SidebarContext.Provider
            value={{
                isOpen,
                open: () => setIsOpen(true),
                close: () => setIsOpen(false),
                toggle: () => setIsOpen((p) => !p),
            }}
        >
            {children({ isOpen })}
        </SidebarContext.Provider>
    );
};
