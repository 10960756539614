import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DropdownMenu, Icon, IconType } from '@bookinbio/ui';
import { Menu } from '@headlessui/react';
import classNames from 'classnames';

import { useAuth } from '../../context/AuthContext';
import { useBusiness } from '../../context/BusinessContext';

import { LanguageMenu } from './LanguageDropdown';

export const ProfileDropdown = () => {
    const { t } = useTranslation();
    const { user, signOut } = useAuth();
    const { business } = useBusiness();

    const handleCopyLinkToClipboard = async () => {
        if (!business?.socialName) {
            return;
        }

        try {
            await navigator.clipboard.writeText(
                `reserve.bookinb.io/${business.socialName}`,
            );
            toast.success(t('social.link.copy.success'));
        } catch (err) {
            toast.success(t('social.link.copy.error'));
        }
    };

    return (
        <div className="flex justify-start gap-x-4">
            <div className="flex flex-col items-end">
                <h2>{user?.fullName}</h2>
                <span className="text-xs text-gray-500">{user?.email}</span>
            </div>
            <DropdownMenu
                className="z-[100] w-40"
                trigger={
                    <button className="flex items-center justify-center gap-1 rounded-xl p-1 duration-200 hover:bg-gray-200">
                        {user && user.photoUrl ? (
                            <img
                                className="h-10 w-10 rounded-full"
                                src={user?.photoUrl}
                                alt={user?.username ?? 'user profile photo'}
                                referrerPolicy="no-referrer"
                            />
                        ) : (
                            <div className="h-10 w-10 rounded-full bg-gray-500"></div>
                        )}
                        <Icon
                            name={IconType.ChevronRight}
                            className="rotate-90"
                            size={18}
                        />
                    </button>
                }
            >
                <Menu.Item>
                    {({ active }) => (
                        <NavLink
                            to="/settings"
                            className={`${
                                active ? 'bg-black text-white' : 'text-gray-900'
                            } group flex w-full items-center gap-x-4 rounded-md px-2 py-2 text-sm`}
                        >
                            <Icon name={IconType.Settings} />
                            {t('settings')}
                        </NavLink>
                    )}
                </Menu.Item>
                <LanguageMenu />
                <Menu.Item>
                    {({ active }) => (
                        <button
                            className={classNames(
                                'group flex w-full items-center justify-start gap-x-4 rounded-md px-2 py-2 text-left text-sm',
                                active
                                    ? 'bg-black text-white'
                                    : 'text-gray-900',
                            )}
                            type="button"
                            onClick={handleCopyLinkToClipboard}
                        >
                            <Icon
                                name={IconType.Copy}
                                className="flex-shrink-0"
                            />
                            {t('social.link.copy')}
                        </button>
                    )}
                </Menu.Item>
                <Menu.Item>
                    {({ active }) => (
                        <button
                            className={classNames(
                                'group flex w-full items-center justify-start gap-x-4 rounded-md px-2 py-2 text-sm',
                                active
                                    ? 'bg-black text-white'
                                    : 'text-gray-900',
                            )}
                            type="button"
                            onClick={signOut}
                        >
                            <Icon name={IconType.Logout} />
                            {t('logout')}
                        </button>
                    )}
                </Menu.Item>
            </DropdownMenu>
        </div>
    );
};
