import { ReactNode } from 'react';
import { useOutlet } from 'react-router-dom';
import classNames from 'classnames';

import { MobileSidebarProvider } from '../context/SidebarContext';

import { Navbar } from './navbar/Navbar';
import { Sidebar } from './sidebar/Sidebar';

export const Layout = () => {
    const outlet = useOutlet();
    return (
        <MobileSidebarProvider>
            {({ isOpen }) => (
                <div
                    className={classNames(
                        'h-custom-screen relative m-auto mr-0 flex flex-col overflow-x-hidden px-6 pt-6 pb-4 sm:px-10 sm:pt-10',
                        isOpen ? 'overflow-y-hidden' : null,
                    )}
                >
                    <Navbar profileRow={true} />
                    {outlet}
                </div>
            )}
        </MobileSidebarProvider>
    );
};

interface HeadingProps {
    heading: string;
    right?: ReactNode;
    hideOn?: 'mobile' | 'desktop';
}

const Heading = ({ heading, right, hideOn }: HeadingProps) => {
    return (
        <div
            className={classNames(
                'mb-5 items-start justify-start',
                right ? 'items-start justify-between' : null,
                hideOn === 'mobile' ? 'hidden md:flex' : null,
                hideOn === 'desktop' ? 'flex md:hidden' : null,
                !hideOn ? 'flex' : null,
            )}
        >
            <div className="font-excon text-2xl font-medium tracking-wide">
                {heading}
            </div>
            {right ? right : null}
        </div>
    );
};

Layout.Heading = Heading;

export interface SidebarLayoutProps {
    type: 'calendar' | 'requests';
}

const SidebarLayout = ({ type }: SidebarLayoutProps) => {
    const outlet = useOutlet();
    return (
        <MobileSidebarProvider>
            {({ isOpen }) => (
                <div
                    className={classNames(
                        'h-custom-screen relative m-auto mr-0 flex flex-col overflow-x-hidden px-6 pt-6 pb-4 sm:px-10 sm:pt-10 md:mr-[450px]',
                        isOpen ? 'overflow-y-hidden' : null,
                    )}
                >
                    <Navbar />
                    {outlet}
                    <Sidebar type={type} />
                </div>
            )}
        </MobileSidebarProvider>
    );
};

Layout.SidebarLayout = SidebarLayout;
