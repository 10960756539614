import { ReactNode, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select, { components, MenuPlacement, SingleValue } from 'react-select';
import classNames from 'classnames';

import { SelectOption } from './SelectDropdown';
import { getNestedError } from './utils';
import { WithLabel } from './WithLabel';

export interface SelectInputControllerProps<T extends string> {
    name: T;
    isClearable?: boolean;
    isSearchable?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
    errorClassName?: string;
    options: SelectOption[];
    menuPlacement?: MenuPlacement;
    translate?: boolean;
    onChange?: (value: number | string | null) => void;
}

export const SelectInputController = <T extends string = string>({
    name,
    placeholder,
    isClearable = false,
    isSearchable = false,
    isDisabled = false,
    errorClassName,
    menuPlacement = 'auto',
    options,
    translate = true,
    onChange: onAdditionalChange,
}: SelectInputControllerProps<T>) => {
    const { t } = useTranslation();
    const {
        control,
        trigger,
        formState: { errors },
    } = useFormContext();

    const error = getNestedError(errors, name);

    const translatedOpts = useMemo(
        () =>
            translate
                ? options.map((opt) => ({
                      label: t(opt.label),
                      value: opt.value,
                  }))
                : options,
        [options, t, translate],
    );

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                const currVal = translatedOpts.find(
                    (opt) => opt.value === value,
                );
                return (
                    <>
                        <Select
                            value={currVal}
                            options={translatedOpts}
                            onChange={(val) => {
                                onChange(val?.value);
                                onAdditionalChange &&
                                    onAdditionalChange(val ? val.value : null);
                                trigger(name);
                            }}
                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            isMulti={false}
                            placeholder={placeholder}
                            isDisabled={isDisabled}
                            menuPlacement={menuPlacement}
                            components={{
                                Input: ({ ...rest }) => {
                                    return (
                                        <components.Input
                                            {...rest}
                                            isDisabled={true}
                                            autoComplete="nope"
                                        />
                                    );
                                },
                            }}
                            classNames={{
                                indicatorSeparator: () => 'hidden',
                                control: () =>
                                    classNames(
                                        '!border-r-none !border-b flex cursor-pointer h-[49px] select-none text-left',
                                        error
                                            ? '!border-red-500'
                                            : '!border-gray-500',
                                    ),
                                singleValue: () =>
                                    classNames('text-base text-black px-1'),
                                placeholder: () => classNames('!text-gray-400'),
                                menu: () => classNames('!z-[1000] mx-[0.5px]'),
                                option: () => 'text-base text-left',
                            }}
                            styles={{
                                control: () => ({
                                    borderTop: 'none',
                                    borderLeft: 'none',
                                    borderRight: 'none',
                                }),
                                menu: (base) => ({
                                    ...base,
                                    transform: 'translate3d(0, 0, 0)',
                                }),
                            }}
                        />
                        {error ? (
                            <p
                                className={classNames(
                                    'mb-2 h-3 text-left text-xs text-red-700',
                                    errorClassName,
                                )}
                            >
                                {t(error.message as string)}
                            </p>
                        ) : null}
                    </>
                );
            }}
        />
    );
};

export interface SelectInputProps<T extends string>
    extends SelectInputControllerProps<T> {
    label: string;
    className?: string;
    labelClassName?: string;
    inputCtrClassName?: string;
    inputClassName?: string;
    right?: ReactNode;
}

export const SelectInput = <T extends string = string>({
    name,
    label,
    placeholder,
    className,
    labelClassName,
    options,
    right,
    menuPlacement,
    isClearable,
    isDisabled,
    isSearchable,
    errorClassName,
    translate,
    onChange,
}: SelectInputProps<T>) => {
    return (
        <WithLabel
            label={label}
            name={name}
            className={className}
            labelClassName={labelClassName}
            right={right}
        >
            <SelectInputController
                name={name}
                placeholder={placeholder}
                options={options}
                menuPlacement={menuPlacement}
                isClearable={isClearable}
                isDisabled={isDisabled}
                isSearchable={isSearchable}
                errorClassName={errorClassName}
                onChange={onChange}
                translate={translate}
            />
        </WithLabel>
    );
};

interface CustomSelectProps {
    options: SelectOption[];
    value: SelectOption | null;
    className?: string;
    onSelect: (value: SingleValue<SelectOption>) => void;
}

export const CustomSelect = ({
    value,
    options,
    className,
    onSelect,
}: CustomSelectProps) => {
    const { t } = useTranslation();
    const translatedOpts = useMemo(
        () =>
            options.map((opt) => ({
                label: t(opt.label),
                value: opt.value,
            })),
        [options, t],
    );
    return (
        <Select
            value={value}
            options={translatedOpts}
            onChange={onSelect}
            isClearable={false}
            isSearchable={false}
            classNames={{
                indicatorSeparator: () => 'hidden',
                control: () =>
                    classNames(
                        '!border-r-none !border-b !border-b-gray-700 flex cursor-pointer h-[33px] md:h-[49px] md:h-auto select-none',
                        className,
                    ),
                placeholder: () => classNames('text-left'),
                singleValue: () => 'text-base text-left',
                menu: () => classNames('!z-[1000] '),
                option: () => 'text-base text-left',
            }}
            styles={{
                control: () => ({
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                }),
                menu: (base) => ({
                    ...base,
                    transform: 'translate3d(0, 0, 0)',
                }),
            }}
        />
    );
};

interface CustomSelect2Props {
    onSelect: (value: SingleValue<SelectOption>) => void;
    options: SelectOption[];
    value: number | string | null;
}

export const CustomSelect2 = ({
    value,
    options,
    onSelect,
}: CustomSelect2Props) => {
    const { t } = useTranslation();
    const translatedOpts = useMemo(
        () =>
            options.map((opt) => ({
                label: t(opt.label),
                value: opt.value,
            })),
        [options, t],
    );
    return (
        <Select
            value={options.find((opt) => opt.value === value)}
            options={translatedOpts}
            onChange={onSelect}
            isClearable={false}
            isSearchable={false}
            classNames={{
                indicatorSeparator: () => 'hidden',
                control: () =>
                    classNames(
                        '!border-r-none !border-b !border-b-gray-700 flex cursor-pointer h-[33px] md:h-[49px] md:h-auto select-none',
                    ),
                placeholder: () => classNames('text-left'),
                singleValue: () => 'text-base text-left',
                menu: () => classNames('!z-[1000] '),
                option: () => 'text-base text-left',
            }}
            styles={{
                control: () => ({
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                }),
                menu: (base) => ({
                    ...base,
                    transform: 'translate3d(0, 0, 0)',
                }),
            }}
        />
    );
};
