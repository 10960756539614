import { Business, Rent } from '@bookinbio/interface';
import { collection, doc, getDocs } from 'firebase/firestore';

import { businessesColl } from '../collections';

export const getRentsByBusiness = async (
    business?: Business,
): Promise<Rent[]> => {
    if (!business) {
        throw new Error('Missing business id');
    }

    const rentSnaps = await getDocs(
        collection(doc(businessesColl, business.id), 'rents'),
    );

    if (rentSnaps.empty) {
        return [];
    }

    return rentSnaps.docs.map((rent) => {
        return { ...rent.data(), id: rent.id } as Rent;
    });
};
