import { useEffect, useMemo } from 'react';
import { Path, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PriceType, ServiceType } from '@bookinbio/enums';
import {
    Button,
    Checkbox2,
    CreatableSelectInput,
    FixedHeader,
    Form,
    IconButton,
    IconType,
    SelectInput,
    TextareaInput,
    TextInput,
    Toggle,
} from '@bookinbio/ui';
import {
    stringPriceToUnitAmount,
    TIME_DURATION_OPTIONS,
} from '@bookinbio/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { z } from 'zod';

import { useBusiness } from '../../../context/BusinessContext';
import i18n from '../../../locales/i18n';
import { getCategoriesByBusiness } from '../../../utils/firebase/api/get-categories-business';
import { createCategory } from '../../../utils/firebase/callable/category';
import { createService } from '../../../utils/firebase/callable/services';
import { FormSectionHeader } from '../../FormSectionHeader';

export const NOTIFICATION_DURATION_OPTIONS = [
    {
        value: 'WEEKS_AFTER',
        label: i18n.t('after.weeks'), // Days after
    },
    {
        value: 'DAYS_AFTER',
        label: i18n.t('after.days'), // Days after
    },
];

export const PRICE_TYPE_OPTIONS = [
    {
        value: PriceType.Fixed,
        label: i18n.t('fixed'),
    },
    {
        value: PriceType.Free,
        label: i18n.t('free'),
    },
];

export const SERVICE_TYPE_OPTIONS = [
    {
        value: ServiceType.Service,
        label: i18n.t('service'),
    },
    {
        value: ServiceType.Rent,
        label: i18n.t('rental'),
    },
];

export const ServiceSchema = z
    .object({
        name: z.string().min(1, i18n.t('service.name.message')),
        linkName: z
            .string()
            .min(1)
            .refine(
                (value) =>
                    /^[a-zA-Z0-9-.]*$/.test(value) &&
                    !value.endsWith('-') &&
                    !value.endsWith('.'),
                {
                    message: i18n.t('service.link.name.error'),
                },
            ),
        categoryId: z.string().min(1, i18n.t('service.category.error')),

        serviceType: z.string().min(1),

        description: z.string().optional(),
        afterCareDescription: z.string().optional(),

        professionalRefs: z.array(z.string()),
        rentRefs: z.array(z.string()),

        priceType: z
            .string({ invalid_type_error: i18n.t('service.price.type.error') })
            .min(1, i18n.t('service.price.type.error')),
        amount: z.string().optional(),
        duration: z.number().min(5, i18n.t('service.duration.error')),
        isCombinable: z.boolean(),
        allowBookings: z.boolean(),

        hasDayLimitBookings: z.boolean(),
        dayLimitBookings: z.number().nullable(),

        isActiveRebookReminder: z.boolean(),
        timeNotification: z.number().optional(),
        afterType: z.string().optional(),
    })
    .refine(
        (val) => {
            return !(val.priceType === PriceType.Fixed && !val.amount);
        },
        { path: ['amount'], message: i18n.t('service.amount.error') },
    )
    .refine(
        (val) => {
            if (val.isActiveRebookReminder === true && !val.timeNotification) {
                return false;
            }
            return true;
        },
        {
            path: ['timeNotification'],
            message: i18n.t('service.notification.error'),
        },
    )
    .refine((val) => {
        if (
            (val.serviceType === ServiceType.Rent &&
                val.rentRefs.length <= 0) ||
            (val.serviceType === ServiceType.Service &&
                val.professionalRefs.length <= 0)
        ) {
            return false;
        }
        return true;
    });

export type ServiceForm = z.infer<typeof ServiceSchema>;
export type ServiceFormProps = Path<ServiceForm>;

export const CreateService = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        business,
        professionals: allProfs,
        rents: allRents,
        servicesRefetch,
    } = useBusiness();
    const professionals = useMemo(
        () => (allProfs ? allProfs.filter((p) => p.allowBookings) : []),
        [allProfs],
    );
    const rents = useMemo(
        () => (allRents ? allRents.filter((p) => p.allowBookings) : []),
        [allRents],
    );

    const methods = useForm<ServiceForm>({
        mode: 'all',
        reValidateMode: 'onSubmit',
        defaultValues: {
            duration: TIME_DURATION_OPTIONS[5].value,
            priceType: PRICE_TYPE_OPTIONS[0].value,
            serviceType: SERVICE_TYPE_OPTIONS[0].value,
            isActiveRebookReminder: true,
            isCombinable: false,
            timeNotification: 4,
            afterType: NOTIFICATION_DURATION_OPTIONS[0].value,
            professionalRefs: [],
            rentRefs: [],
            hasDayLimitBookings: true,
            allowBookings: true,
            dayLimitBookings: 5,
        },
        resolver: zodResolver(ServiceSchema),
        // resolver: async (data, context, options) => {
        //     // you can debug your validation schema here
        //     console.log('formData', data);
        //     console.log(
        //         'validation result',
        //         await zodResolver(ServiceSchema)(data, context, options),
        //     );
        //     return zodResolver(ServiceSchema)(data, context, options);
        // },
    });
    const [profRefs, rentRefs, priceType, serviceType, hasDayLimitBookings] =
        methods.watch([
            'professionalRefs',
            'rentRefs',
            'priceType',
            'serviceType',
            'hasDayLimitBookings',
        ]);

    // BE data
    const { refetch: refetchCategories, data: categories } = useQuery({
        queryKey: ['business-categories', business?.id],
        queryFn: () => getCategoriesByBusiness(business),
        enabled: !!business,
    });

    const { mutateAsync, isLoading } = useMutation({
        mutationFn: createService,
    });

    const { mutateAsync: createCateg, isLoading: isLoadingCategory } =
        useMutation({
            mutationFn: createCategory,
        });

    // Options
    const categoryOptions = categories
        ? categories.map((category) => ({
              value: category.id,
              label: category.name.default,
          }))
        : [];

    // Effects
    useEffect(() => {
        if (professionals && professionals.length > 0) {
            methods.setValue(
                'professionalRefs',
                professionals.map((prof) => prof.id),
            );
            methods.trigger('professionalRefs');
        }
    }, [methods, professionals]);

    useEffect(() => {
        if (rents && rents.length > 0) {
            methods.setValue(
                'rentRefs',
                rents.map((rent) => rent.id),
            );
            methods.trigger('rentRefs');
        }
    }, [methods, rents]);

    // Handlers
    const handleCreateCategory = async (inputName: string) => {
        if (!business) {
            return;
        }

        const result = await createCateg({
            businessId: business.id,
            name: {
                default: inputName,
            },
            description: null,
            color: null,
        });

        await refetchCategories();

        methods.setValue('categoryId', result.data);
    };

    const handleClose = () => {
        navigate(-1);
    };

    const handleSubmit = async (data: ServiceForm) => {
        if (!business) {
            return;
        }

        try {
            await mutateAsync({
                ...data,
                basicInfo: {
                    categoryId: data.categoryId,
                    name: {
                        default: data.name,
                    },
                    serviceType: data.serviceType,
                    linkName: data.linkName,
                    description: data.description,
                    afterCareDescription: data.afterCareDescription,
                },
                serviceSettings: {
                    duration: data.duration,
                    priceType: data.priceType,
                    amount:
                        data.priceType === PriceType.Fixed && data.amount
                            ? stringPriceToUnitAmount(data.amount, 'eur')
                            : null,
                    currency: 'eur',
                    isCombinable: data.isCombinable,
                    allowBookings: data.allowBookings,
                    dayLimitBookings: data.hasDayLimitBookings
                        ? data.dayLimitBookings
                        : null,
                },
                professionalRefs:
                    data.serviceType === ServiceType.Service
                        ? data.professionalRefs
                        : [],
                rentRefs:
                    data.serviceType === ServiceType.Rent ? data.rentRefs : [],
                isActiveRebookReminder: data.isActiveRebookReminder,
                notificationReminder:
                    data.isActiveRebookReminder &&
                    data.timeNotification &&
                    data.afterType
                        ? {
                              value: data.timeNotification,
                              afterType: data.afterType,
                          }
                        : null,
                businessId: business.id,
            });
            toast.success(t('service.create.success'));
            handleClose();
            await servicesRefetch();
        } catch (error) {
            console.error(error);
            toast.error(t('service.create.error'));
        }
    };

    const handleSelectAllProfessionals = () => {
        if (professionals) {
            const profLength = professionals.length;

            if (profLength === profRefs.length) {
                methods.setValue('professionalRefs', []);
            } else {
                methods.setValue(
                    'professionalRefs',
                    professionals.map((prof) => prof.id),
                );
            }
        }
    };

    const handleSelectAllRents = () => {
        if (rents) {
            const rentLength = rents.length;

            if (rentLength === rentRefs.length) {
                methods.setValue('rentRefs', []);
            } else {
                methods.setValue(
                    'rentRefs',
                    rents.map((rent) => rent.id),
                );
            }
        }
    };

    const selectAllValueProfs = professionals
        ? professionals.filter((prof) => prof.allowBookings).length ===
          profRefs.length
            ? 'all'
            : profRefs.length > 0
            ? 'some'
            : 'none'
        : 'none';

    const selectAllValueRents = rents
        ? rents.filter((prof) => prof.allowBookings).length === rentRefs.length
            ? 'all'
            : rentRefs.length > 0
            ? 'some'
            : 'none'
        : 'none';

    return (
        <Form
            methods={methods}
            className="min-h-full w-full"
            onSubmit={handleSubmit}
        >
            <FixedHeader>
                {({ isMoved }) => (
                    <div
                        className={classNames(
                            'fixed top-0 left-0 z-50 flex w-full flex-row-reverse items-center justify-end px-8 pt-6 pb-5 md:h-[88px] md:flex-row md:justify-between',
                            isMoved
                                ? 'shadow-xl backdrop-blur-xl'
                                : 'bg-transparent',
                        )}
                    >
                        <div className="text-right md:flex-1 md:text-left">
                            <IconButton
                                name={IconType.Cross}
                                onClick={handleClose}
                            />
                        </div>
                        <h2
                            className={classNames(
                                'flex-1 text-center text-2xl font-bold tracking-wider',
                                isMoved ? 'visible' : 'invisible',
                            )}
                        >
                            {t('service.create')}
                        </h2>
                        <div className="hidden flex-1 text-right md:block">
                            <Button
                                type="submit"
                                color="black"
                                className="rounded-lg !px-14 !py-3 font-bold tracking-wider"
                                disabled={isLoading}
                            >
                                {t('save')}
                            </Button>
                        </div>
                    </div>
                )}
            </FixedHeader>
            <div className="mx-auto max-w-2xl px-5 pt-10 pb-20 md:pt-[88px] md:pb-[60px]">
                <h1 className="text-left text-3xl font-bold tracking-wider md:text-center">
                    {t('service.create')}
                </h1>
                <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('basic.information')}
                        subTitle={t('service.basic.information.subtitle')}
                    />
                    <div className="mt-6 grid grid-cols-1 gap-y-4">
                        <TextInput<ServiceFormProps>
                            label={t('service.name')}
                            placeholder={t('service.name.placeholder')}
                            name="name"
                            additionalChangeHandler={(val) => {
                                methods.setValue(
                                    'linkName',
                                    val
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, '')
                                        .replace(/[^a-zA-Z0-9]+/g, '-')
                                        .replace(/^-|-$/g, '')
                                        .toLocaleLowerCase(),
                                );
                                methods.trigger('linkName');
                            }}
                            errorless={false}
                        />
                        <TextInput<ServiceFormProps>
                            label={t('service.link')}
                            prefix={`/${business?.socialName}/`}
                            prefixClassName="text-blue-500"
                            placeholder={t('service.link.placeholder')}
                            name="linkName"
                            errorless={false}
                        />
                        <SelectInput<ServiceFormProps>
                            label={t('service.type')}
                            name="serviceType"
                            options={SERVICE_TYPE_OPTIONS}
                        />
                        <CreatableSelectInput<ServiceFormProps>
                            label={t('category')}
                            placeholder={t('category.placeholder')}
                            name="categoryId"
                            options={categoryOptions}
                            onCreateOption={handleCreateCategory}
                            isLoading={isLoadingCategory}
                        />
                        <TextareaInput<ServiceFormProps>
                            label={t('description.optional')}
                            placeholder={t('description.short.placeholder')}
                            name="description"
                            errorless={false}
                        />
                        <TextareaInput<ServiceFormProps>
                            label={t('service.description.after')}
                            placeholder={t(
                                'service.description.after.placeholder',
                            )}
                            name="afterCareDescription"
                            errorless={false}
                        />
                    </div>
                </div>
                {serviceType === ServiceType.Service ? (
                    <div className="grid grid-cols-1 py-10">
                        <FormSectionHeader
                            title={t('team')}
                            subTitle={t('service.assign.team.member.subtitle')}
                        />
                        <div className="mt-6 grid grid-cols-1 gap-y-4">
                            <div className="flex items-center gap-x-6">
                                <SelectAllCheckbox
                                    onClick={handleSelectAllProfessionals}
                                    value={selectAllValueProfs}
                                />
                                <span className="text-lg">
                                    {t('select.all')}
                                </span>
                            </div>
                            <div className="mt-4 grid grid-cols-1">
                                {professionals && professionals.length > 0
                                    ? professionals.map((prof) => (
                                          <div
                                              key={`prof-item-${prof.id}`}
                                              className="flex items-center gap-x-6"
                                          >
                                              <Checkbox2<ServiceFormProps>
                                                  name="professionalRefs"
                                                  ctrClassName="!items-center"
                                                  labelClassName="flex items-center gap-x-6"
                                                  label={
                                                      <>
                                                          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-black text-white">
                                                              {prof.firstName &&
                                                              prof.lastName
                                                                  ? prof.firstName[0].toLocaleUpperCase() +
                                                                    prof.lastName[0].toLocaleUpperCase()
                                                                  : 'U'}
                                                          </div>
                                                          <div>
                                                              {prof.fullName}
                                                          </div>
                                                      </>
                                                  }
                                                  value={prof.id}
                                              />
                                          </div>
                                      ))
                                    : null}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 py-10">
                        <FormSectionHeader
                            title={t('rentals')}
                            subTitle={t('service.assign.rental.subtitle')}
                        />
                        <div className="mt-6 grid grid-cols-1 gap-y-4">
                            <div className="flex items-center gap-x-6">
                                <SelectAllCheckbox
                                    onClick={handleSelectAllRents}
                                    value={selectAllValueRents}
                                />
                                <span className="text-lg">
                                    {t('select.all')}
                                </span>
                            </div>
                            <div className="mt-4 grid grid-cols-1 gap-y-4">
                                {rents && rents.length > 0
                                    ? rents.map((rent) => (
                                          <div
                                              key={`rent-item-${rent.id}`}
                                              className="flex items-center gap-x-6"
                                          >
                                              <Checkbox2<ServiceFormProps>
                                                  name="rentRefs"
                                                  ctrClassName="!items-center"
                                                  labelClassName="flex items-center gap-x-6"
                                                  label={<div>{rent.name}</div>}
                                                  value={rent.id}
                                              />
                                          </div>
                                      ))
                                    : null}
                            </div>
                        </div>
                    </div>
                )}
                <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('service.price.duration')}
                        subTitle={t('service.price.duration.subtitle')}
                    />
                    <div className="mt-6 grid grid-cols-1 gap-y-4">
                        <SelectInput<ServiceFormProps>
                            label={t('price.type')}
                            name="priceType"
                            options={PRICE_TYPE_OPTIONS}
                        />
                        {priceType === PriceType.Fixed ? (
                            <TextInput<ServiceFormProps>
                                label={t('price')}
                                name="amount"
                                placeholder={`15.99`}
                                type="number"
                                step={0.01}
                                prefix="€"
                            />
                        ) : null}
                        <SelectInput<ServiceFormProps>
                            label={t('service.duration')}
                            name={`duration`}
                            options={TIME_DURATION_OPTIONS}
                        />
                        {/* {serviceType === ServiceType.Service ? (
                            <Checkbox<ServiceFormProps>
                                name="isCombinable"
                                label={t('service.combinable')}
                                ctrClassName="mt-3"
                            />
                        ) : null} */}
                        <Toggle<ServiceFormProps>
                            name="allowBookings"
                            ctrClassName="flex items-center gap-x-6 mt-4"
                        >
                            <div>{t('allow.calendar.bookings')}</div>
                        </Toggle>
                        <Toggle<ServiceFormProps>
                            name="hasDayLimitBookings"
                            ctrClassName="flex items-center gap-x-6 mt-4"
                        >
                            <div>{t('has.day.limit.bookings')}</div>
                        </Toggle>
                        {hasDayLimitBookings ? (
                            <TextInput<ServiceFormProps>
                                name="dayLimitBookings"
                                type="number"
                                min={1}
                                asType="number"
                                label={t('day.limit.bookings')}
                            />
                        ) : null}
                    </div>
                </div>
                {/* <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('notifications')}
                        subTitle={t('service.automated.message')}
                    />
                    <div className="grid grid-cols-2 gap-4 mt-6 gap-y-">
                        <Checkbox<ServiceFormProps>
                            name="isActiveRebookReminder"
                            ctrClassName="col-span-2"
                            label={t('reminder.rebook')}
                        />
                        {isActiveRebookReminder ? (
                            <>
                                <TextInputController<ServiceFormProps>
                                    name="timeNotification"
                                    type="number"
                                    min={1}
                                    asType="number"
                                />
                                <SelectInputController<ServiceFormProps>
                                    name="afterType"
                                    options={NOTIFICATION_DURATION_OPTIONS}
                                />
                            </>
                        ) : null}
                    </div>
                </div> */}
            </div>
            <div
                className={classNames(
                    'fixed bottom-0 left-0 z-50 h-20 w-full items-center justify-between px-5 py-5 shadow-xl backdrop-blur-xl md:hidden',
                )}
            >
                <Button
                    type="submit"
                    color="black"
                    className="w-full rounded-lg !py-3 font-bold tracking-wide"
                    disabled={isLoading}
                >
                    {t('save')}
                </Button>
            </div>
        </Form>
    );
};

interface SelectAllCheckboxProps {
    value: 'all' | 'some' | 'none';
    onClick: () => void;
}

export const SelectAllCheckbox = ({
    value,
    onClick,
}: SelectAllCheckboxProps) => {
    return (
        <button
            className="h-6 w-6 cursor-pointer rounded border border-black p-[3px] text-gray-500"
            type="button"
            onClick={onClick}
        >
            <div
                className={classNames(
                    ' w-full rounded',
                    value === 'all' ? 'h-full bg-black' : null,
                    value === 'some' ? 'h-1/2 bg-black' : null,
                    value === 'none' ? 'bg-white' : null,
                )}
            ></div>
        </button>
    );
};
