import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getNestedError } from '@bookinbio/ui';
import classNames from 'classnames';

import i18n from '../locales/i18n';

interface DaysCheckboxProps<T> {
    name: T;
    disabled?: boolean;
    errorless?: boolean;
    errorClassName?: string;
}

const getDayShort = (day: number) => {
    switch (day) {
        case 0: {
            return i18n.t('su');
        }
        case 1: {
            return i18n.t('mo');
        }
        case 2: {
            return i18n.t('tu');
        }
        case 3: {
            return i18n.t('we');
        }
        case 4: {
            return i18n.t('th');
        }
        case 5: {
            return i18n.t('frs');
        }
        default:
        case 6: {
            return i18n.t('sa');
        }
    }
};

export const DaysCheckbox = <T extends string = string>({
    name,
    errorless = false,
    disabled,
    errorClassName,
}: DaysCheckboxProps<T>) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const {
        formState: { errors },
        field: { onChange, value },
    } = useController({ name: name, control: control });

    const handleCheck = (day: number) => {
        const values = value as number[];
        if (values.includes(day)) {
            const newValues = values.filter((i) => i !== day);
            return newValues;
        } else {
            const newValues = [...values];
            newValues.push(day);
            return newValues;
        }
    };

    const error = getNestedError(errors, name);

    return (
        <>
            <div className="flex items-center justify-start gap-x-4">
                {[1, 2, 3, 4, 5, 6, 0].map((item) => (
                    <button
                        type="button"
                        key={`day-checkbox-${item}`}
                        className={classNames(
                            'h-9 w-9 rounded-lg border border-black',
                            value.includes(item)
                                ? 'bg-black text-white'
                                : 'bg-white text-black',
                        )}
                        disabled={disabled}
                        onClick={() => onChange(handleCheck(item))}
                    >
                        {t(getDayShort(item))}
                    </button>
                ))}
            </div>
            {!errorless && error ? (
                <p
                    className={classNames(
                        'mb-2 h-3 text-left text-xs text-red-700',
                        errorClassName,
                    )}
                >
                    {error.message as string}
                </p>
            ) : null}
        </>
    );
};
