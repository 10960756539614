import { UserCredential } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';

import { functions } from '../../firebase.config';

interface RegisterData {
    email: string;
    password: string;
    fullName: string;
    businessName: string;
    language: string;
    deviceToken: string | null;
}

export const signUpWithBusiness = httpsCallable<RegisterData, UserCredential>(
    functions,
    'signUpWithBusiness',
);
