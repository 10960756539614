/// <reference types="vite-plugin-svgr/client" />

import { CSSProperties, ReactElement } from 'react';
import classNames from 'classnames';

import { default as AddCalendarIcon } from '../assets/icons/add-calendar.svg?react';
import { default as AppleIcon } from '../assets/icons/apple.svg?react';
import { default as BackIcon } from '../assets/icons/back.svg?react';
import { default as BinFilledIcon } from '../assets/icons/bin-filled.svg?react';
import { default as BoldIcon } from '../assets/icons/bold.svg?react';
import { default as BulletListIcon } from '../assets/icons/bullet-list.svg?react';
import { default as CheckIcon } from '../assets/icons/check.svg?react';
import { default as ChevronLeftIcon } from '../assets/icons/chevron-left.svg?react';
import { default as ChevronRightIcon } from '../assets/icons/chevron-right.svg?react';
import { default as ChevronUpDownIcon } from '../assets/icons/chevron-up-down.svg?react';
import { default as CloseEyeIcon } from '../assets/icons/close-eye.svg?react';
import { default as ContinueIcon } from '../assets/icons/continue.svg?react';
import { default as CopyIcon } from '../assets/icons/copy.svg?react';
import { default as CrossIcon } from '../assets/icons/cross.svg?react';
import { default as DecreaseFontIcon } from '../assets/icons/decrease-font.svg?react';
import { default as DragHandleIcon } from '../assets/icons/drag-handle.svg?react';
import { default as GlobeIcon } from '../assets/icons/globe.svg?react';
import { default as GoogleIcon } from '../assets/icons/google.svg?react';
import { default as HyperlinkIcon } from '../assets/icons/hyperlink.svg?react';
import { default as IncreaseFontIcon } from '../assets/icons/increase-font.svg?react';
import { default as InfoCircledIcon } from '../assets/icons/info-circled.svg?react';
import { default as ItalicIcon } from '../assets/icons/italic.svg?react';
import { default as LogoutIcon } from '../assets/icons/logout.svg?react';
import { default as MainIcon } from '../assets/icons/main.svg?react';
import { default as MenuBurgerIcon } from '../assets/icons/menu-burger.svg?react';
import { default as MenuDotsVerticalIcon } from '../assets/icons/menu-dots-vertical.svg?react';
import { default as NewMessageIcon } from '../assets/icons/new-message.svg?react';
import { default as NotificationIcon } from '../assets/icons/notification.svg?react';
import { default as OpenEyeIcon } from '../assets/icons/open-eye.svg?react';
import { default as OrderedListIcon } from '../assets/icons/ordered-list.svg?react';
import { default as PlusIcon } from '../assets/icons/plus.svg?react';
import { default as ProfileIcon } from '../assets/icons/profile.svg?react';
import { default as RedoIcon } from '../assets/icons/redo.svg?react';
import { default as RotatePortraitIcon } from '../assets/icons/rotate-portrait.svg?react';
import { default as SendIcon } from '../assets/icons/send.svg?react';
import { default as SettingsIcon } from '../assets/icons/settings.svg?react';
import { default as SettingsSlidersIcon } from '../assets/icons/settings-sliders.svg?react';
import { default as StarFilledIcon } from '../assets/icons/star-filled.svg?react';
import { default as StarOutlineIcon } from '../assets/icons/start-outline.svg?react';
import { default as StrikethroughIcon } from '../assets/icons/strikethrough.svg?react';
import { default as SuccessCircledIcon } from '../assets/icons/success-outline.svg?react';
import { default as TryAgainIcon } from '../assets/icons/try-again.svg?react';
import { default as UnderlineIcon } from '../assets/icons/underline.svg?react';
import { default as UndoIcon } from '../assets/icons/undo.svg?react';
import { default as WaitingIcon } from '../assets/icons/waiting.svg?react';

export enum IconType {
    Apple = 'apple',
    CloseEye = 'close-eye',
    Google = 'google',
    OpenEye = 'open-eye',
    Notification = 'notification',
    ChevronRight = 'chevron-right',
    ChevronLeft = 'chevron-left',
    Logout = 'logout',
    Send = 'send',
    NewMessage = 'new-message',
    Profile = 'profile',
    InfoCircled = 'info-circled',
    SuccessCircled = 'success-circled',
    BinFilled = 'bin-filled',
    ChevronUpDown = 'chevron-up-down',
    Check = 'check',
    MenuBurger = 'menu-burger',
    Cross = 'cross',
    Plus = 'plus',
    SettingsSliders = 'settings-sliders',
    MenuDotsVertical = 'menu-dots-vertical',
    Settings = 'settings',
    Globe = 'globe',
    Copy = 'copy',
    Back = 'back',
    Continue = 'continue',
    TryAgain = 'try-again',
    AddCalendar = 'add-calendar',
    StarFilled = 'star-filled',
    StarOutline = 'star-outline',
    DragHandle = 'drag-handle',
    Main = 'main',
    Redo = 'redo',
    Undo = 'undo',
    Bold = 'bold',
    Italic = 'italic',
    Underline = 'underline',
    Strikethrough = 'strikethrough',
    BulletList = 'bullet-list',
    OrderedList = 'ordered-list',
    Hyperlink = 'hyperlink',
    IncreaseFont = 'increase-font',
    DecreaseFont = 'decrease-font',
    RotatePortrait = 'rotate-portrait',
    Waiting = 'waiting',
}

interface IconProps {
    name: IconType;
    id?: string;
    size?: number;
    className?: string;
    style?: CSSProperties;
}

export const Icon = ({
    name,
    size = 16,
    style,
    ...rest
}: IconProps): ReactElement | null => {
    style = { width: size + 'px', height: size + 'px', ...style };

    switch (name) {
        case IconType.Apple:
            return <AppleIcon style={style} {...rest} />;
        case IconType.CloseEye:
            return <CloseEyeIcon style={style} {...rest} />;
        case IconType.Google:
            return <GoogleIcon style={style} {...rest} />;
        case IconType.OpenEye:
            return <OpenEyeIcon style={style} {...rest} />;
        case IconType.Notification:
            return <NotificationIcon style={style} {...rest} />;
        case IconType.ChevronRight:
            return <ChevronRightIcon style={style} {...rest} />;
        case IconType.ChevronLeft:
            return <ChevronLeftIcon style={style} {...rest} />;
        case IconType.Logout:
            return <LogoutIcon style={style} {...rest} />;
        case IconType.Send:
            return <SendIcon style={style} {...rest} />;
        case IconType.NewMessage:
            return <NewMessageIcon style={style} {...rest} />;
        case IconType.Profile:
            return <ProfileIcon style={style} {...rest} />;
        case IconType.InfoCircled:
            return <InfoCircledIcon style={style} {...rest} />;
        case IconType.SuccessCircled:
            return <SuccessCircledIcon style={style} {...rest} />;
        case IconType.BinFilled:
            return <BinFilledIcon style={style} {...rest} />;
        case IconType.ChevronUpDown:
            return <ChevronUpDownIcon style={style} {...rest} />;
        case IconType.Check:
            return <CheckIcon style={style} {...rest} />;
        case IconType.MenuBurger:
            return <MenuBurgerIcon style={style} {...rest} />;
        case IconType.Cross:
            return <CrossIcon style={style} {...rest} />;
        case IconType.SettingsSliders:
            return <SettingsSlidersIcon style={style} {...rest} />;
        case IconType.Plus:
            return <PlusIcon style={style} {...rest} />;
        case IconType.MenuDotsVertical:
            return <MenuDotsVerticalIcon style={style} {...rest} />;
        case IconType.Settings:
            return <SettingsIcon style={style} {...rest} />;
        case IconType.Globe:
            return <GlobeIcon style={style} {...rest} />;
        case IconType.Copy:
            return <CopyIcon style={style} {...rest} />;
        case IconType.Back:
            return <BackIcon style={style} {...rest} />;
        case IconType.Continue:
            return <ContinueIcon style={style} {...rest} />;
        case IconType.TryAgain:
            return <TryAgainIcon style={style} {...rest} />;
        case IconType.AddCalendar:
            return <AddCalendarIcon style={style} {...rest} />;
        case IconType.StarFilled:
            return <StarFilledIcon style={style} {...rest} />;
        case IconType.StarOutline:
            return <StarOutlineIcon style={style} {...rest} />;
        case IconType.DragHandle:
            return <DragHandleIcon style={style} {...rest} />;
        case IconType.Main:
            return <MainIcon style={style} {...rest} />;
        case IconType.IncreaseFont:
            return <IncreaseFontIcon style={style} {...rest} />;
        case IconType.DecreaseFont:
            return <DecreaseFontIcon style={style} {...rest} />;
        case IconType.Bold:
            return <BoldIcon style={style} {...rest} />;
        case IconType.Italic:
            return <ItalicIcon style={style} {...rest} />;
        case IconType.Underline:
            return <UnderlineIcon style={style} {...rest} />;
        case IconType.Strikethrough:
            return <StrikethroughIcon style={style} {...rest} />;
        case IconType.BulletList:
            return <BulletListIcon style={style} {...rest} />;
        case IconType.Hyperlink:
            return <HyperlinkIcon style={style} {...rest} />;
        case IconType.OrderedList:
            return <OrderedListIcon style={style} {...rest} />;
        case IconType.Redo:
            return <RedoIcon style={style} {...rest} />;
        case IconType.Undo:
            return <UndoIcon style={style} {...rest} />;
        case IconType.RotatePortrait:
            return <RotatePortraitIcon style={style} {...rest} />;
        case IconType.Waiting:
            return <WaitingIcon style={style} {...rest} />;
        default:
            return null;
    }
};

interface IconButtonProps {
    id?: string;
    className?: string;
    name: IconType;
    size?: number;
    iconClassName?: string;
    disabled?: boolean;
    tabIndex?: number;
    type?: 'button' | 'submit';
    onClick?: () => void;
}

export const IconButton = ({
    id,
    className,
    name,
    disabled,
    size = 16,
    iconClassName = 'icon',
    onClick,
    type = 'button',
    tabIndex = -1,
}: IconButtonProps): ReactElement => {
    return (
        <button
            id={id}
            type={type}
            className={classNames(
                'flex h-10 w-10 items-center justify-center rounded text-gray-500 transition-all duration-200 hover:border-black hover:bg-gray-100 hover:!text-black',
                className,
            )}
            onClick={onClick}
            disabled={disabled}
            tabIndex={tabIndex}
        >
            <Icon name={name} size={size} className={iconClassName} />
        </button>
    );
};
