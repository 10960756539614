import { createContext, ReactNode, useContext } from 'react';
import { InitialSetup, UserRole } from '@bookinbio/enums';
import { Business, Location, Professional, Rent } from '@bookinbio/interface';
import { useMutation, useQuery } from '@tanstack/react-query';

import { changeBusinessInitSetupStep } from '../utils/firebase/api/change-initital-setup-step';
import { getBusinessByMember } from '../utils/firebase/api/get-business-owner';
import { getBusinessSubscription } from '../utils/firebase/api/get-business-subscription';
import { getLocationsByBusiness } from '../utils/firebase/api/get-locations-business';
import { getProfessionalsByBusiness } from '../utils/firebase/api/get-professionals-business';
import { getRentsByBusiness } from '../utils/firebase/api/get-rents-business';
import {
    ExtendedPendindAppointments,
    getRequestsByProfessional,
} from '../utils/firebase/api/get-requests-business';
import {
    getServicesByBusiness,
    ServiceWCategory,
} from '../utils/firebase/api/get-services-business';

import { useAuth } from './AuthContext';

interface BusinessContextProps {
    business?: Business;
    isSubscribed?: boolean;
    isLoading: boolean;

    professionals?: Professional[];
    isProfsLoading: boolean;

    services?: ServiceWCategory[];
    isServsLoading: boolean;

    locations?: Location[];
    isLocationLoading: boolean;

    rents?: Rent[];
    isRentsLoading: boolean;

    requests?: ExtendedPendindAppointments;
    isRequestsLoading: boolean;

    businessRefetch: () => Promise<void>;
    professionalsRefetch: () => Promise<void>;
    servicesRefetch: () => Promise<void>;
    locationsRefetch: () => Promise<void>;
    rentsRefetch: () => Promise<void>;
    requestsRefetch: () => Promise<void>;

    changeInitSetupStep: (step: InitialSetup) => Promise<void>;
}

export const BusinessContext = createContext<BusinessContextProps>({
    isLoading: false,
    isServsLoading: false,
    isProfsLoading: false,
    isRequestsLoading: false,
    isLocationLoading: false,
    isRentsLoading: false,

    businessRefetch: () => {
        return new Promise(() => {
            console.log('BusinessContext [businessRefetch]');
        });
    },

    professionalsRefetch: () => {
        return new Promise(() => {
            console.log('BusinessContext [professionalsRefetch]');
        });
    },

    servicesRefetch: () => {
        return new Promise(() => {
            console.log('BusinessContext [servicesRefetch]');
        });
    },

    locationsRefetch: () => {
        return new Promise(() => {
            console.log('BusinessContext [locationsRefetch]');
        });
    },

    rentsRefetch: () => {
        return new Promise(() => {
            console.log('BusinessContext [rentsRefetch]');
        });
    },

    requestsRefetch: () => {
        return new Promise(() => {
            console.log('BusinessContext [requestsRefetch]');
        });
    },

    changeInitSetupStep: (step) => {
        return new Promise(() => {
            console.log('BusinessContext [changeInitSetupStep]', step);
        });
    },
});

export const useBusiness = () => useContext(BusinessContext);

interface BusinessProviderProps {
    children: ReactNode;
}

export const BusinessProvider = ({ children }: BusinessProviderProps) => {
    const { user } = useAuth();

    const {
        data: business,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ['business', user?.id],
        queryFn: () => getBusinessByMember(user?.id, user?.userRole),
        enabled: !!user,
    });

    const { data: isSubscribed, isLoading: isSubLoading } = useQuery({
        queryKey: ['business-subscription', business?.id],
        queryFn: () => getBusinessSubscription(business?.id),
        enabled: !!business,
    });

    const {
        data: professionals,
        isLoading: isProfsLoading,
        refetch: refetchProfs,
    } = useQuery({
        queryKey: [
            'business-professionals',
            business?.id,
            business?.memberRefs,
        ],
        queryFn: () => getProfessionalsByBusiness(business),
        enabled:
            !!business &&
            !!user &&
            (user.userRole === UserRole.Admin ||
                user.userRole === UserRole.SuperAdmin),
    });

    const {
        data: services,
        isLoading: isServsLoading,
        refetch: refetchServs,
    } = useQuery({
        queryKey: ['business-services', business],
        queryFn: () => getServicesByBusiness(business),
        enabled: !!business,
    });

    const {
        data: rents,
        isLoading: isRentsLoading,
        refetch: refetchRents,
    } = useQuery({
        queryKey: ['business-rents', business],
        queryFn: () => getRentsByBusiness(business),
        enabled: !!business,
    });

    const {
        data: requests,
        isLoading: areRequestsLoading,
        refetch: refetchRequests,
    } = useQuery({
        queryKey: ['business-requests', business],
        queryFn: () => getRequestsByProfessional(business, null, rents, user),
        enabled: !!business && !!user && !!rents,
    });

    const {
        data: locations,
        isLoading: isLocationsLoading,
        refetch: refetchLocations,
    } = useQuery({
        queryKey: ['business-locations', business],
        queryFn: () => getLocationsByBusiness(business),
        enabled: !!business,
    });

    const { mutateAsync } = useMutation({
        mutationFn: (step: InitialSetup) =>
            changeBusinessInitSetupStep(step, business?.id),
    });

    const handleBusinessRefetch = async () => {
        await refetch();
    };

    const handleServicesRefetch = async () => {
        await refetchServs();
    };

    const handleProfessionalsRefetch = async () => {
        await refetchProfs();
    };

    const handleLocationsRefetch = async () => {
        await refetchLocations();
    };

    const handleRentsRefetch = async () => {
        await refetchRents();
    };

    const handleRequestsRefetch = async () => {
        await refetchRequests();
    };

    return (
        <BusinessContext.Provider
            value={{
                business,
                isLoading: isLoading || isSubLoading,

                professionals,
                isProfsLoading,

                services,
                isServsLoading,

                locations,
                isLocationLoading: isLocationsLoading,

                rents,
                isRentsLoading: isRentsLoading,

                requests,
                isRequestsLoading: areRequestsLoading,

                isSubscribed,
                businessRefetch: handleBusinessRefetch,
                professionalsRefetch: handleProfessionalsRefetch,
                servicesRefetch: handleServicesRefetch,
                locationsRefetch: handleLocationsRefetch,
                rentsRefetch: handleRentsRefetch,
                requestsRefetch: handleRequestsRefetch,
                changeInitSetupStep: mutateAsync,
            }}
        >
            {children}
        </BusinessContext.Provider>
    );
};
