import { createContext, useContext, useEffect } from 'react';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { User } from '@bookinbio/interface';
import {
    Auth,
    confirmPasswordReset,
    getRedirectResult,
    onAuthStateChanged,
    sendPasswordResetEmail as resetPasswordEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signInWithRedirect,
    signOut as signUserOut,
    verifyPasswordResetCode as verifyPassResetCode,
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

import { AppEnvVars } from '../utils/config';
import { auth, googleProvider } from '../utils/firebase.config';
import { signUpWithBusiness } from '../utils/firebase/callable';
import { usersColl } from '../utils/firebase/collections';

interface CredentialData {
    email: string;
    password: string;
}

export interface FullCredentialData extends CredentialData {
    fullName: string;
    businessName: string;
    deviceToken: string | null;
}

export interface AuthContextProps {
    isLoggedIn: boolean | null;
    isLoading: boolean;
    isLoadingReg: boolean;
    user: User | null;
    auth: Auth;

    setIsLoading: (v: boolean) => void;

    setUser: (user: User) => void;

    refetch: () => Promise<void>;

    signIn: (credential: CredentialData) => Promise<void>;
    signInWithGoogle: (showConsent?: boolean) => Promise<void>;
    signInWithGoogleMobile: (showConsent?: boolean) => Promise<void>;

    signUp: (credential: FullCredentialData) => Promise<void>;

    signOut: () => Promise<void>;

    sendPasswordResetEmail: (email: string) => Promise<void>;
    resetPassword: (oobCode: string, password: string) => Promise<void>;
    verifyPasswordResetCode: (oobCode: string) => Promise<string>;
}

export const AuthContext = createContext<AuthContextProps>({
    isLoggedIn: false,
    isLoading: false,
    isLoadingReg: false,
    user: null,
    auth: auth,

    setIsLoading: (v: boolean) => {
        console.log('AuthContext [setIsLoading]', v);
    },

    setUser: (user: User) => {
        console.log('AuthContext [user]', user);
    },

    refetch: () => {
        return new Promise(() => {
            console.log('AuthContext [refetch]');
        });
    },

    signIn: (credential: CredentialData) => {
        return new Promise(() => {
            console.log('AuthContext [signIn]', credential);
        });
    },
    signInWithGoogle: (showConsent = false) => {
        return new Promise(() => {
            console.log('AuthContext [signInWithGoogle]', showConsent);
        });
    },
    signInWithGoogleMobile: (showConsent = false) => {
        return new Promise(() => {
            console.log('AuthContext [signInWithGoogleMobile]', showConsent);
        });
    },

    signUp: (credential: FullCredentialData) => {
        return new Promise(() => {
            console.log('AuthContext [signUp]', credential);
        });
    },

    signOut: () => {
        return new Promise(() => {
            console.log('AuthContext [signOut]');
        });
    },

    sendPasswordResetEmail: (email: string) => {
        return new Promise(() => {
            console.log('AuthContext [sendPasswordResetEmail]', email);
        });
    },
    resetPassword: (oobCode: string, password: string) => {
        return new Promise(() => {
            console.log('AuthContext [resetPassword]', oobCode, password);
        });
    },
    verifyPasswordResetCode: (oobCode: string) => {
        return new Promise(() => {
            console.log('AuthContext [verifyPasswordResetCode]', oobCode);
            return oobCode;
        });
    },
});

export const useAuth = () => useContext(AuthContext);

export interface AuthProps {
    children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProps) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingReg, setIsLoadingReg] = useState<boolean>(false);
    const [user, setUser] = useState<User | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        const isLogIn = localStorage.getItem('isLoggedIn');
        setIsLoggedIn(isLogIn === 'true' ? true : false);
    }, [setIsLoggedIn]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setIsLoading(true);

            if (user && user.emailVerified) {
                try {
                    const userSnap = await getDoc(doc(usersColl, user.uid));

                    if (userSnap.exists()) {
                        const userData = userSnap.data();

                        setUser({
                            ...userData,
                            id: userSnap.id,
                        });

                        setIsLoggedIn(true);
                        setIsLoading(false);
                        localStorage.setItem('isLoggedIn', 'true');
                    } else {
                        setIsLoading(false);
                        setIsLoggedIn(false);
                        localStorage.setItem('isLoggedIn', 'false');
                    }
                } catch (error) {
                    console.error(error);
                }
            } else {
                await signOut();
                setIsLoggedIn(false);
                setIsLoading(false);
                localStorage.setItem('isLoggedIn', 'false');
            }
        });
        return () => {
            unsubscribe();
        };
    }, []);

    const refetch = async () => {
        if (!user) {
            return;
        }

        try {
            const userSnap = await getDoc(doc(usersColl, user.id));

            if (userSnap.exists()) {
                const userData = userSnap.data();

                setUser({
                    ...userData,
                    id: userSnap.id,
                });

                setIsLoggedIn(true);
                setIsLoading(false);
                localStorage.setItem('isLoggedIn', 'true');
            }
        } catch (error) {
            console.log(error);
        }
    };

    const signUp = async ({
        email,
        password,
        fullName,
        businessName,
        deviceToken,
    }: FullCredentialData): Promise<void> => {
        setIsLoadingReg(true);
        const userLang = navigator.language.split('-')[1];
        await signUpWithBusiness({
            email: email,
            password: password,
            fullName: fullName,
            businessName: businessName,
            language: userLang,
            deviceToken: deviceToken,
        });
        setIsLoadingReg(false);
    };

    const signIn = async ({
        email,
        password,
    }: {
        email: string;
        password: string;
    }): Promise<void> => {
        const response = await signInWithEmailAndPassword(
            auth,
            email,
            password,
        );

        if (!response.user.emailVerified) {
            toast.error(t('user.not.verified'));

            await signOut();
        } else {
            const userSnap = await getDoc(doc(usersColl, response.user.uid));

            if (userSnap.exists()) {
                const userData = userSnap.data();

                setUser({
                    ...userData,
                    id: userSnap.id,
                });

                toast.success(t('sign.in.success'));
            } else {
                await signOut();
                toast.error(t('sign.in.miss.data'));
            }
        }
    };

    const signOut = (): Promise<void> => {
        return signUserOut(auth);
    };

    const signInWithGoogleMobile = async (): Promise<void> => {
        try {
            googleProvider.setCustomParameters({
                client_id: AppEnvVars.ClientId,
                access_type: 'offline',
                prompt: 'consent',
                include_granted_scopes: 'true',
            });
            googleProvider.addScope('https://www.googleapis.com/auth/calendar');

            await signInWithRedirect(auth, googleProvider);

            const result = await getRedirectResult(auth);

            if (!result) {
                throw new Error('Google Sign In Failed');
            }

            const userSnap = await getDoc(doc(usersColl, result.user.uid));

            if (userSnap.exists()) {
                const userData = userSnap.data();

                setUser({
                    ...userData,
                    id: userSnap.id,
                });

                toast.success(t('sign.in.success'));
            } else {
                await signOut();
                toast.error(t('sign.in.miss.data'));
            }
        } catch (error) {
            // Handle Errors here.
            console.error('Error during Google Sign-In: ', error);
            toast.error(t('sign.in.error'));
        }
    };

    const signInWithGoogle = async (showConsent = false): Promise<void> => {
        try {
            // TODO: Check if remove or not
            googleProvider.addScope('https://www.googleapis.com/auth/calendar');
            googleProvider.setCustomParameters({
                client_id: AppEnvVars.ClientId,
                access_type: 'offline',
                include_granted_scopes: 'true',
                prompt: 'consent',
            });
            const result = await signInWithPopup(auth, googleProvider);

            const userSnap = await getDoc(doc(usersColl, result.user.uid));

            if (userSnap.exists()) {
                const userData = userSnap.data();

                setUser({
                    ...userData,
                    id: userSnap.id,
                });

                toast.success(t('sign.in.success'));
            } else {
                await signOut();
                toast.error(t('sign.in.miss.data'));
            }
        } catch (error) {
            console.error(error);
            toast.error(t('sign.in.error'));
        }
    };

    const sendPasswordResetEmail = (email: string): Promise<void> => {
        setIsLoading(true);
        return resetPasswordEmail(auth, email);
    };

    const resetPassword = (
        oobCode: string,
        password: string,
    ): Promise<void> => {
        return confirmPasswordReset(auth, oobCode, password);
    };

    const verifyPasswordResetCode = (oobCode: string): Promise<string> => {
        return verifyPassResetCode(auth, oobCode);
    };

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                isLoading,
                isLoadingReg,
                user,
                auth,

                setIsLoading,

                setUser,

                refetch,

                signIn,
                signInWithGoogle,
                signInWithGoogleMobile,

                signUp,

                signOut,

                sendPasswordResetEmail,
                resetPassword,
                verifyPasswordResetCode,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
