import { Path, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Button,
    FixedHeader,
    Form,
    IconButton,
    IconType,
    PlacesAutocomplete,
    TextareaInput,
    TextInput,
} from '@bookinbio/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { z } from 'zod';

import { useBusiness } from '../../../context/BusinessContext';
import i18n from '../../../locales/i18n';
import { createLocation } from '../../../utils/firebase/callable/location';
import { FormSectionHeader } from '../../FormSectionHeader';

export const LocationSchema = z.object({
    name: z.string().min(1, i18n.t('location.name.error.message')),
    description: z.string().optional(),
    address: z.string().min(1, i18n.t('location.address.error.message')),
});

export type LocationForm = z.infer<typeof LocationSchema>;
export type LocationFormProps = Path<LocationForm>;

export const CreateLocation = () => {
    const { t } = useTranslation();
    const { business, locationsRefetch } = useBusiness();
    const navigate = useNavigate();

    const methods = useForm<LocationForm>({
        mode: 'all',
        reValidateMode: 'onSubmit',
        resolver: zodResolver(LocationSchema),
    });

    // BE data
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: createLocation,
    });

    // Effects
    const handleClose = () => {
        navigate(-1);
    };

    const handleSubmit = async (data: LocationForm) => {
        if (!business) {
            return;
        }

        try {
            await mutateAsync({
                ...data,
                businessId: business.id,
            });
            toast.success(t('location.create.success'));
            await locationsRefetch();
            handleClose();
        } catch (error) {
            console.error(error);
            toast.error(t('location.create.error'));
        }
    };

    return (
        <Form
            methods={methods}
            className="min-h-full w-full"
            onSubmit={handleSubmit}
        >
            <FixedHeader>
                {({ isMoved }) => (
                    <div
                        className={classNames(
                            'fixed top-0 left-0 z-50 flex w-full flex-row-reverse items-center justify-end px-8 pt-6 pb-5 md:h-[88px] md:flex-row md:justify-between',
                            isMoved
                                ? 'shadow-xl backdrop-blur-xl'
                                : 'bg-transparent',
                        )}
                    >
                        <div className="text-right md:flex-1 md:text-left">
                            <IconButton
                                name={IconType.Cross}
                                onClick={handleClose}
                            />
                        </div>
                        <h2
                            className={classNames(
                                'flex-1 text-center text-2xl font-bold tracking-wider',
                                isMoved ? 'visible' : 'invisible',
                            )}
                        >
                            {t('location.create')}
                        </h2>
                        <div className="hidden flex-1 text-right md:block">
                            <Button
                                type="submit"
                                color="black"
                                className="rounded-lg !px-14 !py-3 font-bold tracking-wider"
                                disabled={isLoading}
                            >
                                {t('save')}
                            </Button>
                        </div>
                    </div>
                )}
            </FixedHeader>
            <div className="mx-auto max-w-2xl px-5 pt-10 pb-20 md:pt-[88px] md:pb-[60px]">
                <h1 className="text-left text-3xl font-bold tracking-wider md:text-center">
                    {t('location.create')}
                </h1>
                <div className="grid grid-cols-1 py-10">
                    <FormSectionHeader
                        title={t('location.basic.info')}
                        subTitle={t('location.basic.info.subtitle')}
                    />
                    <div className="mt-6 grid grid-cols-1 gap-y-4">
                        <TextInput<LocationFormProps>
                            label={t('location.name')}
                            placeholder={t('location.name.placeholder')}
                            name="name"
                        />
                        <PlacesAutocomplete<LocationFormProps>
                            label={t('location.address')}
                            name="address"
                            placeholder={t('location.address.placeholder')}
                        />
                        <TextareaInput<LocationFormProps>
                            label={t('description.optional')}
                            placeholder={t('location.description.placeholder')}
                            name="description"
                        />
                    </div>
                </div>
            </div>
            <div
                className={classNames(
                    'fixed bottom-0 left-0 z-50 h-20 w-full items-center justify-between px-5 py-5 shadow-xl backdrop-blur-xl md:hidden',
                )}
            >
                <Button
                    type="submit"
                    color="black"
                    className="w-full rounded-lg !py-3 font-bold tracking-wide"
                    disabled={isLoading}
                >
                    {t('save')}
                </Button>
            </div>
        </Form>
    );
};
