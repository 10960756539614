import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';

let lng = 'en';
if (typeof window !== 'undefined') {
    const language = localStorage.getItem('I18N_LANGUAGE');
    if (!language) {
        localStorage.setItem('I18N_LANGUAGE', 'en');
    }

    lng = localStorage.getItem('I18N_LANGUAGE') || 'en';
}

i18n.use(Backend)
    .use(detector)
    .use(initReactI18next)
    .init<HttpBackendOptions>({
        returnNull: true,
        debug: false,
        // resources: resources,
        ns: 'resources',
        defaultNS: 'resources',
        fallbackNS: 'resources',
        lng: lng,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
    });

export default i18n;
