import { Locale } from 'date-fns';
import {
    ar,
    bg,
    cs,
    da,
    de,
    el,
    enUS,
    es,
    fi,
    fr,
    he,
    hu,
    is,
    it,
    nb,
    nl,
    pl,
    pt,
    ro,
    ru,
    sk,
    sv,
    tr,
    uk,
} from 'date-fns/locale';

// Mapping of i18next language codes to date-fns locale objects
const localeMap: { [key: string]: Locale } = {
    ar: ar,
    bg: bg,
    cs: cs, // Assuming 'cz' is for Czech, use the 'cs' locale from date-fns
    da: da,
    nl: nl,
    en: enUS, // Assuming 'en' should map to 'enUS'
    fi: fi,
    fr: fr,
    de: de,
    el: el,
    he: he,
    hu: hu,
    is: is,
    it: it,
    no: nb, // 'no' for Norwegian maps to 'nb' (Norwegian Bokmål)
    pl: pl,
    pt: pt,
    ro: ro,
    ru: ru,
    sk: sk,
    es: es,
    sv: sv,
    tr: tr,
    uk: uk,
};

// Function to get the correct date-fns locale object based on the i18next language code
export const getDateFnsLocale = (code: string): Locale => {
    return localeMap[code] ?? enUS; // Default to enUS if the locale is not found
};
