import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import { User } from '@bookinbio/interface';
import {
    Button,
    CustomSelect,
    Form,
    SelectOption,
    Switch,
} from '@bookinbio/ui';
import { transformToDate } from '@bookinbio/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { add, addDays, format, startOfWeek } from 'date-fns';
import { z } from 'zod';

import { useAuth } from '../../../context/AuthContext';
import { getWorkingHoursByStartDate } from '../../../utils/firebase/api/get-working-hours';
import { updateUsersWorkingHours } from '../../../utils/firebase/callable/profile';
import { AppointmentDrawer } from '../../appointment/AppointmentDrawer';
import { Layout } from '../../AuthLayout';
import { TimeOffDrawer } from '../../overrides/TimeOffDrawer';

import { DaySettings, DaysSettingsSchema } from './DaySettings';

// Constants
export const DAY_ARRAY = [1, 2, 3, 4, 5, 6, 0];

// Verifications schemas
const WorkingHoursSchema = z.object({
    initialized: z.boolean(),
    daySettings: DaysSettingsSchema,
});

// Form typings
type WorkingHoursForm = z.infer<typeof WorkingHoursSchema>;

// Calendar settings form
interface CalendarSettsFormProps {
    user: User;
}

const DEFAULT_WH_OPTION = { label: 'working.hours.default', value: 'default' };
const DEFAULT_WH = {
    0: {
        timeSlots: [],
        isOpen: false,
    },
    1: {
        timeSlots: [{ start: '09:00', end: '17:00' }],
        isOpen: true,
    },
    2: {
        timeSlots: [{ start: '09:00', end: '17:00' }],
        isOpen: true,
    },
    3: {
        timeSlots: [{ start: '09:00', end: '17:00' }],
        isOpen: true,
    },
    4: {
        timeSlots: [{ start: '09:00', end: '17:00' }],
        isOpen: true,
    },
    5: {
        timeSlots: [{ start: '09:00', end: '17:00' }],
        isOpen: true,
    },
    6: {
        timeSlots: [],
        isOpen: false,
    },
};

// IMPORTANT NOTE: zod errors won't show unless submit button is clicked/triggered/submitted
export const WorkingHoursEditForm = ({ user }: CalendarSettsFormProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { refetch, isLoading } = useAuth();
    const [whOption, setWhOption] = useState<SelectOption>(DEFAULT_WH_OPTION);

    const methods = useForm<WorkingHoursForm>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            initialized: true,
            daySettings: {
                ...user.calendarSettings?.daySettings,
            },
        },
        resolver: zodResolver(WorkingHoursSchema),
    });
    const initialized = methods.watch('initialized');

    const {
        data: workingHours,
        isLoading: isLoadingWh,
        refetch: refetchWh,
    } = useQuery({
        queryKey: ['working-hours', user.id, whOption.value],
        queryFn: () =>
            getWorkingHoursByStartDate(whOption.value as string, user.id),
        enabled: whOption.value !== DEFAULT_WH_OPTION.value,
    });

    const { mutateAsync: updateWorkingHours, isLoading: isUpdating } =
        useMutation({
            mutationFn: updateUsersWorkingHours,
        });

    useEffect(() => {
        if (isLoading || (isLoadingWh && whOption.value !== 'default')) {
            return;
        }

        if (whOption.value === 'default') {
            methods.reset({
                initialized: true,
                daySettings: {
                    ...(user.calendarSettings?.daySettings ?? DEFAULT_WH),
                },
            });
            return;
        }
        if (workingHours) {
            methods.reset({
                initialized: true,
                daySettings: { ...workingHours },
            });
        } else {
            methods.reset({
                initialized: false,
                daySettings: DEFAULT_WH,
            });
        }
    }, [
        methods,
        workingHours,
        whOption,
        user.calendarSettings?.daySettings,
        isLoading,
        isLoadingWh,
    ]);

    const handleSubmit = async (data: WorkingHoursForm) => {
        const whType = whOption.value === 'default' ? 'default' : 'date-range';
        const startDate =
            whOption.value !== 'default' &&
            whOption.value !== null &&
            typeof whOption.value === 'string'
                ? transformToDate(whOption.value).toISOString()
                : null;

        try {
            await updateWorkingHours({
                userId: user.id,
                daySettings: data.daySettings,
                whType,
                startDate,
            });
            toast.success(t('working.hours.update.success'));
            refetch();
            refetchWh();
        } catch (error) {
            toast.error(t('working.hours.update.error'));
        }
    };

    const handleWhSelect = (option: SingleValue<SelectOption>) => {
        if (!option) {
            return;
        }
        setWhOption(option);
    };

    return (
        <>
            <Layout.Heading heading={t('personal.settings')} hideOn="desktop" />
            <div className="mb-6 w-1/2">
                <WeekSelect value={whOption} onSelect={handleWhSelect} />
            </div>
            <Form<WorkingHoursForm>
                methods={methods}
                onSubmit={handleSubmit}
                className="grid grid-cols-1 gap-y-6 overflow-y-auto"
            >
                <Switch>
                    <Switch.Case
                        condition={isLoadingWh && whOption.value !== 'default'}
                    >
                        <div>Loading...</div>
                    </Switch.Case>
                    <Switch.Case condition={!isLoadingWh && !initialized}>
                        <div className="mt-28 mb-28 flex flex-col gap-y-8 text-center md:mb-0">
                            {t('no.working.hours')}
                            <Button
                                type="button"
                                onClick={() =>
                                    methods.setValue('initialized', true)
                                }
                                className="self-center"
                            >
                                {t('add.new.working.hours')}
                            </Button>
                        </div>
                    </Switch.Case>
                    <Switch.Case
                        condition={
                            (whOption.value === 'default' || !isLoadingWh) &&
                            initialized
                        }
                    >
                        <div
                            key={`${whOption.value}`}
                            className="mb-28 grid grid-cols-1 gap-y-4 md:mb-0"
                        >
                            {DAY_ARRAY.map((el, index) => (
                                <DaySettings
                                    key={`${el}-day-setting-${index}`}
                                    name={`daySettings.${el}`}
                                    day={el}
                                    date={
                                        whOption.value !== 'default'
                                            ? addDays(
                                                  transformToDate(
                                                      whOption.value as string,
                                                  ),
                                                  index,
                                              )
                                            : undefined
                                    }
                                />
                            ))}
                        </div>
                    </Switch.Case>
                    <Switch.Default>{null}</Switch.Default>
                </Switch>
                {initialized ? (
                    <div className="fixed bottom-0 left-0 z-10 order-3 mt-5 flex w-full items-center justify-end border-t border-t-gray-100 bg-white p-5 md:static md:col-span-3 md:mt-5 md:w-auto md:border-t-0 md:bg-transparent">
                        <Button
                            type="submit"
                            color="black"
                            disabled={isUpdating}
                            className="w-full md:w-auto"
                        >
                            {t('save.changes')}
                        </Button>
                    </div>
                ) : null}
            </Form>
            <Routes>
                <Route
                    path="appointment/:id/*"
                    element={<AppointmentDrawer close={() => navigate(-1)} />}
                />
                <Route
                    path="time-off/:id/*"
                    element={<TimeOffDrawer close={() => navigate(-1)} />}
                />
            </Routes>
        </>
    );
};

interface WeekSelectProps {
    value: SelectOption;
    onSelect: (value: SingleValue<SelectOption>) => void;
}

export const WeekSelect = ({ value, onSelect }: WeekSelectProps) => {
    const options = [DEFAULT_WH_OPTION, ...generateWeekOptions()];

    return <CustomSelect options={options} value={value} onSelect={onSelect} />;
};

const generateWeekOptions = (): SelectOption[] => {
    const options = [];
    const today = new Date();
    // Starting from the next week
    let start = startOfWeek(today, { weekStartsOn: 1 });

    for (let i = 0; i < 8; i++) {
        // Generate options for the next 4 weeks as an example
        const endOfWeek = add(start, { days: 6 });
        const label = `${format(start, 'dd.MM.yyyy')} - ${format(
            endOfWeek,
            'dd.MM.yyyy',
        )}`;
        const option = {
            label,
            value: format(start, 'dd-MM-yyyy'),
        };
        options.push(option);
        // Move to the next week
        start = add(start, { weeks: 1 });
    }

    return options;
};
