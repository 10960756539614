import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, IconType } from '@bookinbio/ui';
import {
    autoUpdate,
    FloatingFocusManager,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole,
} from '@floating-ui/react';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';

import i18n from '../../locales/i18n';

export const LANGUAGES = [
    // { label: i18n.t('ar'), value: 'ar' },
    // { label: i18n.t('bg'), value: 'bg' },
    { label: i18n.t('cs'), value: 'cs' },
    // { label: i18n.t('da'), value: 'da' },
    // { label: i18n.t('nl'), value: 'nl' },
    { label: i18n.t('en'), value: 'en' },
    // { label: i18n.t('fi'), value: 'fi' },
    { label: i18n.t('fr'), value: 'fr' },
    { label: i18n.t('de'), value: 'de' },
    // { label: i18n.t('el'), value: 'el' },
    // { label: i18n.t('he'), value: 'he' },
    { label: i18n.t('hu'), value: 'hu' },
    // { label: i18n.t('is'), value: 'is' },
    { label: i18n.t('it'), value: 'it' },
    // { label: i18n.t('no'), value: 'no' },
    { label: i18n.t('pl'), value: 'pl' },
    // { label: i18n.t('pt'), value: 'pt' },
    // { label: i18n.t('ro'), value: 'ro' },
    // { label: i18n.t('ru'), value: 'ru' },
    { label: i18n.t('sk'), value: 'sk' },
    // { label: i18n.t('es'), value: 'es' },
    // { label: i18n.t('sv'), value: 'sv' },
    // { label: i18n.t('tr'), value: 'tr' },
    { label: i18n.t('uk'), value: 'uk' },
];

// TODO: Fix dropdown, remove isOpen wrapper so Transition component works correctly
export const LanguageMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { t, i18n } = useTranslation();

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        whileElementsMounted: autoUpdate,
        placement: 'top-start',
    });

    const hover = useHover(context);
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'menu' });

    // Merge all the interactions into prop getters
    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
        focus,
        dismiss,
        role,
    ]);

    const handleLanguageChange = async (value: string) => {
        localStorage.setItem('I18N_LANGUAGE', value);
        await i18n.changeLanguage(value);
    };

    return (
        <>
            <button ref={refs.setReference} {...getReferenceProps()}>
                <span
                    className={classNames(
                        'group flex w-full items-center justify-start gap-x-4 rounded-md px-2 hover:text-blue-500 md:px-4',
                    )}
                >
                    <Icon name={IconType.Globe} /> {t('languages')}
                </span>
            </button>
            {isOpen && (
                <FloatingFocusManager context={context} modal={false}>
                    <Transition
                        as={Fragment}
                        show={isOpen}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <div
                            ref={refs.setFloating}
                            {...getFloatingProps()}
                            className="grid w-64 grid-cols-3 divide-y divide-gray-100 rounded-md bg-white p-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            style={floatingStyles}
                        >
                            {LANGUAGES.map(({ label, value }) => {
                                return (
                                    <LanguageItem
                                        key={`language-item-${value}`}
                                        label={t(label)}
                                        onClick={() =>
                                            handleLanguageChange(value)
                                        }
                                    />
                                );
                            })}
                        </div>
                    </Transition>
                </FloatingFocusManager>
            )}
        </>
    );
};

interface LanguageItemProps {
    label: string;
    onClick: () => void;
}

export const LanguageItem = ({ label, onClick }: LanguageItemProps) => {
    return (
        <button
            className={classNames(
                'group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-black hover:text-white',
            )}
            type="button"
            onClick={onClick}
        >
            {label}
        </button>
    );
};
