// eslint-disable-next-line simple-import-sort/imports
import { useEffect, useMemo } from 'react';
import { Path, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EndType, FrequencyType, OverrideType } from '@bookinbio/enums';
import { RepeatType } from '@bookinbio/enums';
import {
    Button,
    DatePickerInput,
    Form,
    IconButton,
    IconType,
    MultiSelectInput,
    SelectInput,
    SelectInputController,
    Switch,
    TextareaInput,
    TextInput,
    TextInputController,
    WithLabel,
} from '@bookinbio/ui';
import {
    combineDateAndTime,
    END_TYPE_OPTIONS,
    FREQUENCY_TYPE_OPTIONS,
    REPEAT_TYPE_OPTIONS,
    TIME_OPTIONS,
    timeSearchParamToTime,
    timeToMinutes,
    timeToSearchParamTime,
    transformToDate,
} from '@bookinbio/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { addMonths, addYears, format } from 'date-fns';
import { z } from 'zod';

import { useBusiness } from '../../context/BusinessContext';
import { createOverride } from '../../utils/firebase/callable';
import { useCalendar } from '../calendar/AppointmentsContext';
import { SideDrawerContainer } from '../SideDrawerContainer';
import { DaysCheckbox } from '../CheckboxDays';

// TODO: Add refine for validation
export const TimeOffSchema = z
    .object({
        title: z.string().trim(),
        professionalIds: z
            .array(z.object({ value: z.string(), label: z.string() }))
            .optional(),
        rentIds: z
            .array(z.object({ value: z.string(), label: z.string() }))
            .optional(),
        date: z.date(),
        startTime: z.string(),
        endTime: z.string(),
        repeatType: z.string(),
        endType: z.string(),
        endDate: z.date(),
        occurenceValue: z.number(),
        description: z.string().trim(),
        customRepeatSettings: z.object({
            frequencyType: z.string(),
            days: z.array(z.number()),
            value: z.number(),
        }),
    })
    .superRefine((data, ctx) => {
        const hasProfessionalIds =
            data.professionalIds && data.professionalIds.length > 0;
        const hasRentIds = data.rentIds && data.rentIds.length > 0;

        if (!hasProfessionalIds && !hasRentIds) {
            ctx.addIssue({
                path: ['professionalIds'],
                message:
                    'At least one of professionalIds or rentIds must be non-empty.',
                code: z.ZodIssueCode.custom,
            });
            ctx.addIssue({
                path: ['rentIds'],
                message:
                    'At least one of professionalIds or rentIds must be non-empty.',
                code: z.ZodIssueCode.custom,
            });
        }
    });

export type TimeOffForm = z.infer<typeof TimeOffSchema>;
export type TimeOffFormProps = Path<TimeOffForm>;

interface NewTimeOffDrawerProps {
    close: () => void;
}

export const NewTimeOffDrawer = ({ close }: NewTimeOffDrawerProps) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { business, professionals, rents, isLoading, isProfsLoading } =
        useBusiness();
    const { refetchOverrides } = useCalendar();
    const startTimeParam = searchParams.get('toff_start_time');
    const endTimeParam = searchParams.get('toff_end_time');
    const dateTimeParam = searchParams.get('toff_date');

    const methods = useForm<TimeOffForm>({
        mode: 'all',
        reValidateMode: 'onChange',
        resolver: zodResolver(TimeOffSchema),
        // resolver: async (data, context, options) => {
        //     console.log(
        //         'validation result',
        //         await zodResolver(TimeOffSchema)(data, context, options),
        //     );
        //     return zodResolver(TimeOffSchema)(data, context, options);
        // },
    });
    const [repeatType, endType, frequencyType] = methods.watch([
        'repeatType',
        'endType',
        'customRepeatSettings.frequencyType',
    ]);

    const { mutateAsync, isLoading: isCreating } = useMutation({
        mutationFn: createOverride,
    });

    const professionalsOptions = useMemo(() => {
        return professionals
            ? professionals.map((professional) => ({
                  value: professional.id,
                  label: professional.fullName ?? 'Unknown',
              }))
            : [];
    }, [professionals]);

    const rentsOptions = useMemo(() => {
        return rents
            ? rents.map((rent) => ({
                  value: rent.id,
                  label: rent.name ?? 'Unknown',
              }))
            : [];
    }, [rents]);

    useEffect(() => {
        if (professionals && professionals.length > 0) {
            methods.reset({
                endDate: addMonths(new Date(), 1),
                occurenceValue: 5,
                repeatType: REPEAT_TYPE_OPTIONS[0].value,
                endType: END_TYPE_OPTIONS[0].value,
                customRepeatSettings: {
                    frequencyType: FREQUENCY_TYPE_OPTIONS[1].value,
                    value: 1,
                    days: [1, 3, 5],
                },
            });
        }
    }, [professionals, methods]);

    useEffect(() => {
        if (startTimeParam && endTimeParam) {
            const startTime = timeSearchParamToTime(startTimeParam);
            const endTime = timeSearchParamToTime(endTimeParam);
            methods.setValue('startTime', startTime ?? '09:00');
            methods.setValue('endTime', endTime ?? '10:00');
        }
    }, [startTimeParam, endTimeParam, methods]);

    useEffect(() => {
        if (dateTimeParam) {
            const date = transformToDate(dateTimeParam);
            methods.setValue('date', date);
        }
    }, [dateTimeParam, methods]);

    const handleSubmit = async (data: TimeOffForm) => {
        if (!business) {
            return;
        }
        const endDate = data.endDate ? data.endDate.toISOString() : null;

        const start = combineDateAndTime(
            data.date.toISOString(),
            data.startTime,
        ).toISOString();
        const duration =
            timeToMinutes(data.endTime) - timeToMinutes(data.startTime);

        try {
            await mutateAsync({
                ...data,
                customRepeatSettings:
                    data.repeatType === RepeatType.Custom
                        ? data.customRepeatSettings
                        : null,
                endType:
                    data.repeatType !== RepeatType.DoesNotRepeat
                        ? data.endType
                        : null,
                occurenceValue:
                    data.endType === EndType.After ? data.occurenceValue : null,
                endDate: data.endType === EndType.SpecificDate ? endDate : null,
                start,
                duration: duration,
                type: OverrideType.OffTime,
                professionalIds: data.professionalIds
                    ? data.professionalIds.map((p) => p.value)
                    : null,
                rentIds: data.rentIds ? data.rentIds.map((r) => r.value) : null,
                businessId: business.id,
                timeZone: business.calendarSettings.timeZone,
            });
            toast.success(t('timeoff.create.success'));
            refetchOverrides();
            close();
        } catch (error) {
            console.error(error);
            toast.error(t('timeoff.create.error'));
        }
    };

    const handleStartTimeChange = (value: string | number | null) => {
        if (!value || typeof value === 'number') {
            return;
        }

        const timeParam = timeToSearchParamTime(value);

        if (!timeParam) {
            return;
        }

        searchParams.set('toff_start_time', timeParam);
        setSearchParams(searchParams);
    };

    const handleEndTimeChange = (value: string | number | null) => {
        if (!value || typeof value === 'number') {
            return;
        }

        const timeParam = timeToSearchParamTime(value);

        if (!timeParam) {
            return;
        }

        searchParams.set('toff_end_time', timeParam);
        setSearchParams(searchParams);
    };

    const handleDateChange = (date?: Date) => {
        if (!date) {
            return;
        }

        const dateParam = format(date, 'dd-MM-yyyy');

        searchParams.set('toff_date', dateParam);
        setSearchParams(searchParams);
    };

    // TODO: add loading for business, professionals
    return (
        <SideDrawerContainer isOpen>
            <Switch>
                <Switch.Case condition={isProfsLoading || isLoading}>
                    <span className="flex h-full items-center justify-center">
                        {t('loading')}
                    </span>
                </Switch.Case>
                <Switch.Case condition={!!professionals && !!business}>
                    <Form<TimeOffForm>
                        methods={methods}
                        onSubmit={handleSubmit}
                        className="flex h-full flex-col"
                    >
                        <div className="mb-2 flex items-center gap-x-10 md:mb-6">
                            <IconButton
                                name={IconType.Cross}
                                onClick={close}
                                size={20}
                            />
                            <h3 className="font-excon text-2xl font-bold tracking-wider">
                                {t('timeoff.new')}
                            </h3>
                        </div>
                        <div className="grid flex-1 gap-y-6 gap-x-4 overflow-auto pb-4 md:gap-y-4">
                            <TextInput<TimeOffFormProps>
                                label={t('title')}
                                name="title"
                                placeholder={t('timeoff.title.placeholder')}
                            />
                            <DatePickerInput<TimeOffFormProps>
                                label={t('date')}
                                name="date"
                                fromYear={new Date().getFullYear()}
                                toYear={addYears(new Date(), 5).getFullYear()}
                                onChange={handleDateChange}
                            />
                            <SelectInput<TimeOffFormProps>
                                label={t('time.start')}
                                name="startTime"
                                translate={false}
                                options={TIME_OPTIONS}
                                onChange={handleStartTimeChange}
                            />
                            <SelectInput<TimeOffFormProps>
                                label={t('time.end')}
                                name="endTime"
                                translate={false}
                                options={TIME_OPTIONS}
                                onChange={handleEndTimeChange}
                            />
                            <MultiSelectInput<TimeOffFormProps>
                                label={t('team.members')}
                                name="professionalIds"
                                options={professionalsOptions}
                            />
                            <MultiSelectInput<TimeOffFormProps>
                                label={t('rentals')}
                                name="rentIds"
                                options={rentsOptions}
                            />
                            <SelectInput<TimeOffFormProps>
                                label={t('repeat')}
                                name="repeatType"
                                options={REPEAT_TYPE_OPTIONS}
                                menuPlacement="top"
                            />
                            {repeatType !== RepeatType.DoesNotRepeat ? (
                                <>
                                    {repeatType === RepeatType.Custom ? (
                                        <>
                                            <WithLabel
                                                label={t('repeat.every')}
                                            >
                                                <div className="flex items-center justify-start gap-x-4">
                                                    <TextInputController<TimeOffFormProps>
                                                        name="customRepeatSettings.value"
                                                        type="number"
                                                        asType="number"
                                                        ctrClassName="!w-32"
                                                        min={1}
                                                    />
                                                    <div className="flex-1">
                                                        <SelectInputController<TimeOffFormProps>
                                                            name="customRepeatSettings.frequencyType"
                                                            options={
                                                                FREQUENCY_TYPE_OPTIONS
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </WithLabel>
                                            {frequencyType ===
                                            FrequencyType.Week ? (
                                                <WithLabel label={t('ondays')}>
                                                    <DaysCheckbox<TimeOffFormProps> name="customRepeatSettings.days" />
                                                </WithLabel>
                                            ) : null}
                                        </>
                                    ) : null}
                                    <SelectInput<TimeOffFormProps>
                                        name="endType"
                                        label={t('ends')}
                                        options={END_TYPE_OPTIONS}
                                    />
                                    {endType === EndType.After ? (
                                        <div className="flex items-center">
                                            <TextInputController<TimeOffFormProps>
                                                name="occurenceValue"
                                                type="number"
                                                asType="number"
                                            />
                                            <span>{t('occurences')}</span>
                                        </div>
                                    ) : null}
                                    {endType === EndType.SpecificDate ? (
                                        <DatePickerInput<TimeOffFormProps>
                                            label={t('date.end')}
                                            name="endDate"
                                            fromYear={new Date().getFullYear()}
                                            toYear={addYears(
                                                new Date(),
                                                5,
                                            ).getFullYear()}
                                        />
                                    ) : null}
                                </>
                            ) : null}
                            <TextareaInput<TimeOffFormProps>
                                label={t('description.optional')}
                                name="description"
                                placeholder={t(
                                    'timeoff.description.placeholder',
                                )}
                            />
                        </div>
                        <div className="flex">
                            <Button
                                type="submit"
                                color="black"
                                className="w-full"
                                disabled={isCreating}
                            >
                                {isCreating ? (
                                    <div className="flex items-center justify-center">
                                        <ThreeDots
                                            height="24"
                                            radius="9"
                                            color="#ffffff"
                                            ariaLabel="three-dots-loading"
                                            visible={true}
                                        />
                                    </div>
                                ) : (
                                    t('save.changes')
                                )}
                            </Button>
                        </div>
                    </Form>
                </Switch.Case>
            </Switch>
        </SideDrawerContainer>
    );
};
