import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

interface NavbarItemProps {
    title: string;
    to: string;
    searchParams?: string;
    requestsCount?: number;
}

export const NavbarItem = ({
    title,
    to,
    searchParams,
    requestsCount,
}: NavbarItemProps) => {
    return (
        <NavLink to={{ pathname: to, search: searchParams }}>
            {({ isActive }) => (
                <div className="font-excon group flex flex-col items-center px-5 text-xl">
                    <span
                        className={classNames(
                            'relative transition-all duration-200 group-hover:text-gray-600',
                            isActive ? 'text-black' : 'text-gray-400',
                        )}
                    >
                        {title}
                        {requestsCount ? (
                            <span className="absolute right-[-16px] top-[-8px] flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-xs text-white">
                                {requestsCount}
                            </span>
                        ) : null}
                    </span>
                    <span
                        className={classNames(
                            'mt-1 h-1 w-6 rounded-lg transition-all duration-200 group-hover:bg-gray-600',
                            isActive ? 'bg-black' : 'bg-transparent',
                        )}
                    ></span>
                </div>
            )}
        </NavLink>
    );
};
