import { DayNumbers, DaySetting } from '@bookinbio/interface';
import { httpsCallable } from 'firebase/functions';

import { functions } from '../../firebase.config';

type UpdateProfileArgs = {
    userId: string;
    firstName: string;
    lastName: string;
    // username: string; FYI: For now it will be hidden, later we will request setup of username
    phoneNumber: string;
    jobTitle?: string | null;
    allowBookings: boolean;
    webNotifications?: Record<string, string>;
    googleCalendarSynchronization: boolean;
};

export const updateProfile = httpsCallable<UpdateProfileArgs>(
    functions,
    'updateProfile',
);

type UpdateCalendarSettingsArgs = {
    userId: string;
    daySettings: Record<DayNumbers, DaySetting>;
    whType: 'default' | 'date-range';
    startDate: string | null;
};

export const updateUsersWorkingHours =
    httpsCallable<UpdateCalendarSettingsArgs>(functions, 'updateWorkingHours');
